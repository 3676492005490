import { Badge } from "@chakra-ui/react";
import React, { useState, DragEvent, FC } from 'react';
import IconComponent from '../IconComponent/IconComponent';

interface FileUploadProps {
  onFileUpload: (files: FileList) => void;
  description?: string;
}

const FileInput: FC<FileUploadProps> = ({ onFileUpload, description }) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string | null>(null);

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      setFileName(files[0].name);
      onFileUpload(files);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      setFileName(files[0].name); // Ustawienie nazwy pliku
      onFileUpload(files);
    }
  };

  return (
    <div
      className={`relative flex flex-col items-center justify-center w-full h-full p-spacing-4xl ${isDragging ? 'bg-gray-200' : 'bg-white'} border border-gray-200 p-spacing-4xl rounded-xl`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <input
        type="file"
        className='absolute inset-0 w-full h-full opacity-0 cursor-pointer'
        onChange={handleFileChange}
      />
      <div className='flex flex-col items-center gap-spacing-lg'>
        <div className='border border-gray-200 flex items-center justify-center rounded-lg w-10 h-10'>
          <IconComponent iconName='import' />
        </div>
        <div className='flex flex-col items-center gap-spacing-xs'>
          {fileName ? (
            <div className='flex flex-col'>
              <div className='flex items-center justify-center'>
                <Badge  variant='outline' colorScheme='green'>Poprawnie dodano plik</Badge>
              </div>
              <p className='text-sm font-semibold text-grayWarm-800 mt-2'>
                Wybrany plik: <span className='font-normal'>{fileName}</span>
              </p>
            </div>
          ) : (
            <>
              <p className='text-sm font-normal text-grayLight-600'>
                <b className='font-semibold text-grayWarm-800'>Wybierz plik z komputera</b> lub przeciągnij plik tutaj
              </p>
              <p className='text-xs font-normal text-grayLight-600'>
                {description}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileInput;
