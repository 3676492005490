import React from 'react';

const XCloseIcon = ({ className, color }: { className: string, color: string }) => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M9.16732 0.833496L0.833984 9.16683M0.833984 0.833496L9.16732 9.16683" stroke={color}
          strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default XCloseIcon;
