import React from "react";
import { Routes, Route } from "react-router-dom";
import Navigation from "../../../Navigation/Navigation";
import DishesDatabase from "./DishesDatabase/DishesDatabase";
import MealPlan from "./MealPlan/MealPlan";

interface NavOptionsInterface {
  link: string;
  name: string;
  component: React.ReactElement;
}

const FoodMenuContainer = () => {

  const navOptions: NavOptionsInterface[] = [
    {
      link: 'meal-plan',
      name: 'Jadłospis',
      component: <MealPlan/>
    },
    {
      link: 'dishes-database',
      name: 'Baza dań',
      component: <DishesDatabase />
    }
  ];

  return(
    <section className="FoodMenuContainer flex flex-col gap-spacing-xl p-spacing-md">
        <Navigation navOptions={navOptions} />
        <Routes>
          {navOptions.map((item) => (
            <Route key={item.link} path={`/${item.link}`} element={item.component} />
          ))}
        </Routes>
    </section>
  )
};

export default FoodMenuContainer;