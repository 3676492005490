import React, { useEffect } from "react";
import { Routes, Route } from 'react-router-dom';
import { useSideNavContext } from "../../../context/SideNavProvider";
import CompaniesTable from './CompaniesTable/CompaniesTable';
import SingleCompany from './SingleCompany/SingleCompany';

const Companies = () => {
  const { toggleShowNav } = useSideNavContext();

  useEffect(() => {
    toggleShowNav(false)
  }, []);

  return (
    <section className="Branches h-full bg-neutral-50">
      <Routes>
        <Route path="/" element={<CompaniesTable />} />
        <Route path="/id/:id/*" element={<SingleCompany />} />
      </Routes>
    </section>
  );
};
export default Companies;
