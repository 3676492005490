import moment from "moment";
moment.locale('pl');

const handleNavigate = function (
  action: Date | "PREV" | "NEXT" | "TODAY",
  setSelectedDates: React.Dispatch<React.SetStateAction<Date[]>>,
  calendarDate: Date,
  setCalendarDate:  React.Dispatch<React.SetStateAction<Date>>,
  minDate: null | moment.Moment,
  maxDate: null | moment.Moment,
){
  let newDate;

  setSelectedDates([])

  if (action === "PREV") {
    newDate = moment(calendarDate).subtract(1, "month");
  } else if (action === "NEXT") {
    newDate = moment(calendarDate).add(1, "month");
  } else if (action === "TODAY") {
    newDate = moment();
  } else {
    newDate = moment(action);
  }

  if (newDate.isBefore(minDate, 'month') || newDate.isAfter(maxDate, 'month')) {
    return;
  }

  setCalendarDate(newDate.toDate());
};

export default handleNavigate;