import { AxiosResponse, HttpStatusCode } from "axios";
import { Select } from "chakra-react-select";
import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter, Divider
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../context/ApiProvider";
import { OrganisationUnitInterface } from "../../../../../../shared";
import { NewKidInterface } from "../../../../../../shared/type/kid.type";
import { FullParentInterface } from "../../../../../../shared/type/parent.type";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import CustomInput from "../../../../../CustomInput/CustomInput";

interface Props{
  isOpen: boolean,
  handleClose: () => void
}

const AddKid = ({isOpen, handleClose}: Props) => {
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();
  const { apiTenantOrganisationUnitController, apiTenantParentController, apiTenantConsumerController } = useApi();
  const {id: tenantId} = useParams<{id: string}>();

  const [ organisationUnits, setOrganisationUnits] = useState<OrganisationUnitInterface[]>([]);
  const [ parents, setParents ] = useState<FullParentInterface[]>([]);

  const [ newKidInterface, setNewKidInterface ] = useState<NewKidInterface>({
    tenantId: Number(tenantId),
    firstName: '',
    lastName: '',
    organisationUnitId: null,
    cardNumber: '',
    remarks: '',
    parentIds: []
  })

  const fetchOrganisationUnits = async () => {
    try {
      const response: AxiosResponse<OrganisationUnitInterface[]> = await apiTenantOrganisationUnitController('children').get(`/${tenantId}`);
      setOrganisationUnits(response.data);
    } catch (error) {
      console.error(error)
    }
  };

  const fetchParents = async () => {
    try {
      const response: AxiosResponse<FullParentInterface[]> = await apiTenantParentController('').get(`/${tenantId}`);
      setParents(response.data);
    } catch (error) {
      console.error(error)
    }
  };

  const handleAddKid = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      const response = await apiTenantConsumerController('').post('', {
        ...newKidInterface,
        cardNumber: newKidInterface.cardNumber
      });
      if(response.status === HttpStatusCode.Created){
        setAlertProperties({
          status: 'success',
          title: 'Sukces',
          description: 'Poprawnie dodano dziecko',
          timeout: 2000
        });
        setShowAlert(true);
        handleClose();
      }
    } catch (error: any) {
      setAlertProperties({
        status: 'error',
        title: 'Błąd',
        description: `Błąd dodawania dziecka: ${error.response.data.violations[0].message}`,
        timeout: 5000
      });
      setShowAlert(true);
    }
  }

  useEffect(() => {
    fetchOrganisationUnits();
    fetchParents();
  }, [tenantId]);

  return(
    <Modal isOpen={isOpen} onClose={handleClose} size="2xl" isCentered>
      <form onSubmit={handleAddKid}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">Dodaj dziecko</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <CustomInput
              inputParams={{
                name: 'firstName',
                onChange: (e) => {setNewKidInterface((prev) => ({...prev, [e.target.name]: e.target.value}))},
                required: true
              }}
              type="default"
              size="md"
              destructive={false}
              label="Imię*"
            />
            <CustomInput
              inputParams={{
                name: 'lastName',
                onChange: (e) => {setNewKidInterface((prev) => ({...prev, [e.target.name]: e.target.value}))},
                required: true
              }}
              type="default"
              size="md"
              destructive={false}
              label="Nazwisko*"
            />
            <div className="flex flex-col gap-spacing-md">
              <label className="text-sm font-medium text-grayLight-700">Klasa</label>
              <Select
                colorScheme="gray"
                placeholder="Wybierz"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setNewKidInterface((prev) => ({
                      ...prev,
                      organisationUnitId: selectedOption.value || null
                    }));
                  }
                }}
                options={organisationUnits.map((unit) => ({
                  ...unit,
                  label: unit.name,
                  value: unit.id,
                }))}
              />
            </div>
            <CustomInput
              type="default"
              size="md"
              destructive={false}
              label="Numer identyfikatora"
              inputParams={{
                name: 'cardNumber',
                onChange: (e) => {
                  const target = e.target as HTMLInputElement;
                  const value = target.value.replace(/\D/g, '');
                  setNewKidInterface((prev) => ({ ...prev, [target.name]: value }));
                },
                onInput: (e) => {
                  const target = e.target as HTMLInputElement;
                  target.value = target.value.replace(/\D/g, '');
                },
                pattern: "\\d{10}",
                title: "Numer identyfikatora musi składać się z dokładnie 10 cyfr lub może być pusty.",
                maxLength: 10,
                required: false,
              }}
            />
            <div className="flex flex-col gap-spacing-md">
              <label className="text-sm font-medium text-grayLight-700">Wybierz rodzica z listy istniejących</label>
              <Select
                colorScheme="gray"
                placeholder="Wybierz"
                isSearchable
                isMulti
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setNewKidInterface((prev) => ({
                      ...prev,
                      parentIds: selectedOption.map(item => item.id)
                    }));
                  }
                }}
                options={parents.map((unit) => ({
                  ...unit,
                  label: unit.firstName + ' ' + unit.lastName,
                  value: unit.id,
                }))}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-grayLight-700 text-sm">Uwagi</label>
              <textarea cols={30}
                        rows={3}
                        style={{ resize: "none" }}
                        value={newKidInterface.remarks || ''}
                        onChange={(e) => {setNewKidInterface((prev) => ({...prev, remarks: e.target.value}))}}
                        maxLength={500}
                        className="border border-gray-300 rounded-lg p-spacing-lg" />
              <div className="flex justify-between">
                <label className="text-grayLight-700 text-sm">maks. 500 znaków</label>
                <label className="text-grayLight-700 text-sm">{newKidInterface.remarks?.length}/500</label>
              </div>
            </div>
          </ModalBody>
          <Divider className='pt-spacing-2xl pb-spacing-2xl' />
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: handleClose
              }}
            >
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                type: "submit",
              }}
            >
              Dodaj
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
};

export default AddKid;