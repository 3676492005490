import React from 'react';
import AlertCircleIcon from "./Icons/AlertCircleIcon";
import AnnotationAlertIcon from "./Icons/AnnotationAlertIcon";
import ArrowLeft from './Icons/ArrowLeft';
import BasketIcon from "./Icons/BasketIcon";
import CalendarIcon from "./Icons/CalendarIcon";
import CheckArrow from './Icons/CheckArrow';
import CheckCircleIcon from "./Icons/CheckCircleIcon";
import CheckIcon from './Icons/CheckIcon';
import ChevronIcon from './Icons/ChevronIcon';
import CircleCheckIcon from "./Icons/CircleCheckIcon";
import CopyIcon from "./Icons/CopyIcon";
import DotIcon from "./Icons/DotIcon";
import DownloadIcon from "./Icons/DownloadIcon";
import HidePasswordIcon from "./Icons/HidePasswordIcon";
import HistoryIcon from "./Icons/HistoryIcon";
import HomeIcon from './Icons/HomeIcon';
import ImportIcon from "./Icons/ImportIcon";
import InfoIcon from './Icons/InfoIcon';
import LockIcon from './Icons/LockIcon';
import MailIcon from './Icons/MailIcon';
import KeyIcon from './Icons/KeyIcon';
import MinusIcon from "./Icons/MinusIcon";
import PdfIcon from "./Icons/PdfIcon";
import PhoneIcon from "./Icons/PhoneIcon";
import QuestionIcon from "./Icons/QuestionIcon";
import RollBackIcon from "./Icons/RollBackIcon";
import SendIcon from "./Icons/SendIcon";
import SettingsIcon from './Icons/SettingsIcon';
import HappyHomeIcon from './Icons/HappyHomeIcon';
import GraduationHatIcon from './Icons/GraduationHatIcon';
import CutleryIcon from './Icons/CutleryIcon';
import ShareIcon from './Icons/ShareIcon';
import SlashCircleIcon from './Icons/SlashCircleIcon';
import TrashIcon from './Icons/TrashIcon';
import UserPlusIcon from "./Icons/UserPlusIcon";
import UsersIcon from './Icons/UsersIcon';
import FileIcon from './Icons/FileIcon';
import MessageIcon from './Icons/MessageIcon';
import LogoIcon from './Icons/LogoIcon';
import BellIcon from './Icons/BellIcon';
import UserCircle from './Icons/UserCircleIcon';
import LifeBuoyIcon from './Icons/LifeBuoyIcon';
import LogOutIcon from './Icons/LogOutIcon';
import PlusIcon from './Icons/PlusIcon';
import SearchIcon from './Icons/SearchIcon';
import ArrowIcon from './Icons/ArrowIcon';
import MenuIcon from './Icons/MenuIcon';
import LanguageIcon from './Icons/LanguageIcon';
import LogOutDoorIcon from './Icons/LogOutDoorIcon';
import VerticalDotsIcon from './Icons/VerticalDotsIcon';
import WalletIcon from "./Icons/WalletIcon";
import XCircleIcon from "./Icons/XCircleIcon";
import XCloseIcon from "./Icons/XCloseIcon";

import { IconName } from '../../shared';
import XlsIcon from "./Icons/XlsIcon";

const IconComponent = ({
  iconName,
  className,
  color
}: {
  iconName: IconName;
  className?: string;
  color?: string;
}) => {
  const classes = className || ' ';
  const colorName = color || '#667085';

  switch (iconName) {
    case 'arrowLeft':
      return <ArrowLeft className={classes}/>;
    case 'arrowRight':
      return <ArrowLeft className={`rotate-180 ${classes}`}/>;
    case 'checkIconTrue':
      return <CheckIcon state={true} />;
    case 'checkIconFalse':
      return <CheckIcon state={false} />;
    case 'lock':
      return <LockIcon className={classes} />;
    case 'mail':
      return <MailIcon className={classes} />;
    case 'key':
      return <KeyIcon className={classes} />;
    case 'settings':
      return <SettingsIcon className={classes} colorName={colorName} />;
    case 'happyHomeIcon':
      return <HappyHomeIcon className={classes} />;
    case 'graduationHat':
      return <GraduationHatIcon className={classes} />;
    case 'cutlery':
      return <CutleryIcon className={classes} />;
    case 'slashCircle':
      return <SlashCircleIcon className={classes} />;
    case 'users':
      return <UsersIcon className={classes} />;
    case 'file':
      return <FileIcon className={classes} />;
    case 'chevronUp':
      return <ChevronIcon className={classes} color={colorName} arrowPosition="up" />;
    case 'chevronDown':
      return <ChevronIcon className={classes} color={colorName} arrowPosition="down" />;
    case 'chevronRight':
      return <ChevronIcon className={classes} color={colorName} arrowPosition="right" />;
    case 'chevronLeft':
      return <ChevronIcon className={classes} color={colorName} arrowPosition="left" />;
    case 'message':
      return <MessageIcon className={classes} color={colorName} />;
    case 'logo':
      return <LogoIcon className={classes} />;
    case 'bell':
      return <BellIcon className={classes} color={colorName}/>;
    case 'userCircle':
      return <UserCircle className={classes} />;
    case 'user':
      return <UsersIcon className={classes} />;
    case 'lifeBuoy':
      return <LifeBuoyIcon className={classes} />;
    case 'logOut':
      return <LogOutIcon className={classes} />;
    case 'plus':
      return <PlusIcon className={classes} color={colorName}/>;
    case 'search':
      return <SearchIcon className={classes} />;
    case 'arrow':
      return <ArrowIcon className={classes} />;
    case 'menu':
      return <MenuIcon className={classes} />;
    case 'languagePl':
      return <LanguageIcon className={classes} name="pl" />;
    case 'logOutDoor':
      return <LogOutDoorIcon />;
    case 'verticalDot':
      return <VerticalDotsIcon className={classes} />;
    case 'trash':
      return <TrashIcon className={classes} color={colorName}/>;
    case 'home':
      return <HomeIcon className={classes} />;
    case 'share':
      return <ShareIcon className={classes} />;
    case 'info':
      return <InfoIcon className={classes} color={colorName} />;
    case 'check':
      return <CheckArrow className={classes} color={colorName} />;
    case "userPlus":
      return <UserPlusIcon className={classes} color={colorName}/>
    case "copy":
      return <CopyIcon className={classes} color={colorName}/>
    case "xClose":
      return <XCloseIcon className={classes} color={colorName}/>
    case "import":
      return <ImportIcon className={classes} color={colorName}/>
    case "download":
      return <DownloadIcon className={classes} color={colorName}/>
    case "annotation-alert":
      return <AnnotationAlertIcon className={classes} color={colorName}/>
    case "wallet":
      return <WalletIcon className={classes} color={colorName}/>
    case "question":
      return <QuestionIcon className={classes} color={colorName}/>
    case "circle-check":
      return <CircleCheckIcon className={classes} color={colorName}/>
    case "minus":
      return <MinusIcon className={classes} color={colorName}/>
    case "phone":
      return <PhoneIcon className={classes} color={colorName}/>
    case 'pdf':
      return <PdfIcon/>
    case 'basket':
      return <BasketIcon className={classes} color={colorName}/>
    case 'calendar':
      return <CalendarIcon className={classes} color={colorName}/>
    case "history":
      return <HistoryIcon className={classes} color={colorName}/>
    case "checkCircle":
      return <CheckCircleIcon className={classes} color={colorName}/>
    case "rollBack":
      return <RollBackIcon className={classes} color={colorName}/>
    case "alertCircle":
      return <AlertCircleIcon className={classes} color={colorName}/>
    case "xCircle":
      return <XCircleIcon className={classes} color={colorName}/>
    case "dot":
      return <DotIcon className={classes} color={colorName}/>
    case "hidePassword":
      return <HidePasswordIcon className={classes} color={colorName}/>
    case 'send':
      return <SendIcon className={classes} color={colorName}/>
    case 'xls':
      return <XlsIcon className={classes}/>
    default:
      return null;
  }
};

export default IconComponent;
