import { useContext, useDebugValue } from 'react';
import AuthContext from '../context/AuthProvider';

const useAuth = () => {
  const auth = useContext(AuthContext);

  useDebugValue(auth, (authValue) =>
    authValue.auth.loggedUserDto.login ? 'Logged In' : 'Logged Out'
  );
  return auth;
};

export default useAuth;
