import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter, Input
} from "@chakra-ui/react";
import { HttpStatusCode } from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../context/AlertProvider";
import { useApi } from '../../../../../context/ApiProvider';
import CustomChakraButton from "../../../../CustomChakraButton/CustomChakraButton";
import FileInput from "../../../../FileInput/FileInput";

interface Props {
  isOpen: boolean,
  onClose: () => void,
}

const ImportTenantStatute = ({ isOpen, onClose }: Props) => {
  const { setShow, setAlertProperties } = useAlertContext();
  const { apiTenantStatuteController } = useApi();
  const { id: tenantId } = useParams<{ id: string }>()
  const [file, setFile] = useState<FileList | undefined>(undefined);

  const handleImport = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!file || file.length === 0) {
      return;
    }

    const formData = new FormData();
    if (file) {
      formData.append("file", file[0]);
    }

    try {
      const response = await apiTenantStatuteController('import-tenant-statute').post(
        '',
        formData,
        {
          params: {
            tenantId: Number(tenantId),
          }
        }
      );
      if(response.status === HttpStatusCode.Ok){
        setAlertProperties({
          timeout: 2000,
          title: "Sukces",
          description: `Poprawnie dodano jadłospis z pliku`,
          status: "success"
        });
        setShow(true);
        onClose();
      }else {
        setAlertProperties({
          timeout: 2000,
          title: "Błąd",
          description: `Nie udało się dodać jadłospis z pliku`,
          status: "error"
        });
        setShow(true);
      }
      console.log(response);
    } catch (error: any) {
      setAlertProperties({
        timeout: 2000,
        title: "Błąd",
        description: `Nie udało się dodać jadłospis z pliku: ${error.reponse}}`,
        status: "error"
      });
      setShow(true);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <form onSubmit={handleImport}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">Importuj jadłospis</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <FileInput
              description='PDF, Word (max. 10 mb)'
              onFileUpload={(file) => {
                setFile(file);
              }} />
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: onClose
              }}
            >
              Anuluj
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                type: "submit"
              }}
            >
              Importuj
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
};

export default ImportTenantStatute;
