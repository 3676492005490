import { ParentOrderContextInterface } from "../../shared/type/parentOrderContext.type";
import moment from "moment";
moment.locale('pl');

export const dayPropGetter = (
  date: Date,
  selectedDates: Date[],
  parentOrderContext: ParentOrderContextInterface | undefined,
  consumerId: number | undefined): {
  style: {
    backgroundColor: string,
    border?: string,
    color: string,
    pointerEvents: string,
    cursor: string,
  } | {}
} => {
  const isSelectedDate = selectedDates.some((selectedDate) =>
    moment(selectedDate).isSame(moment(date), 'day')
  );

  const consumerOrderContext = parentOrderContext?.consumerOrderContexts.find(
    consumer => consumer.consumerId === consumerId
  );

  const orderDay = consumerOrderContext?.orderDays.find(day =>
    moment(day.when).isSame(date, 'day')
  );

  if (orderDay?.salesBlocked) {
    return {
      style: {
        backgroundColor: '#eaecf0',
        color: '#D0D5DD',
        pointerEvents: 'none',
        cursor: 'not-allowed',
      },
    };
  }

  if (orderDay?.workingDay === false) {
    return {
      style: {
        backgroundColor: '#eaecf0',
        color: '#D0D5DD',
        pointerEvents: 'none',
        cursor: 'not-allowed',
      },
    };
  }

  if (isSelectedDate) {
    return {
      style: {
        backgroundColor: '#FFF7E6',
        border: '1px solid #FFDB94',
        boxSizing: 'border-box',
        color: '#000',
        fontWeight: 'bold',

      },
    };
  }

  return {
    style: {},
  };
};