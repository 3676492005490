import React, { ReactNode, useMemo, useState, createContext, useContext } from 'react';
import { useApi } from './ApiProvider';

interface Props {
  children: ReactNode;
}

interface ResetPasswordProps {
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  resetPassword: () => Promise<number>;
}

const ResetPasswordContext = createContext<ResetPasswordProps | undefined>(undefined);

export const useResetPasswordContext = () => {
  const context = useContext(ResetPasswordContext);
  if (!context) {
    throw new Error('useResetPasswordContext must be used within an ResetPasswordProvider');
  }
  return context;
};

export const ResetPasswordProvider = ({ children }: Props) => {
  const { apiPublicSecurityController } = useApi();
  const [email, setEmail] = useState<string>('');

  const resetPassword = async (): Promise<number> => {
    const response = await apiPublicSecurityController('reset').post('', null, {
      params: {
        email
      }
    });

    return response.status;
  };

  const contextValue = useMemo(
    () => ({
      email,
      setEmail,
      resetPassword
    }),
    [email, setEmail, resetPassword]
  );

  return (
    <ResetPasswordContext.Provider value={contextValue}>{children}</ResetPasswordContext.Provider>
  );
};
