import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DaysOff from './DaysOff/DaysOff';
import ListOfCoFinancingInstitutions from "./ListOfCoFinancingInstitutions/ListOfCoFinancingInstitutions";
import SchoolClasses from './OrganisationUnits/OrganisationUnits';
import Settings from './Settings/Settings';
import SchoolStatutes from './SchoolStatutes/SchoolStatutes';

const School = () => {
  const schoolLinks = [
    {
      link: 'school-data',
      element: <p>Dane o szkole</p>
    },
    {
      link: 'classes',
      element: <SchoolClasses />
    },
    {
      link: 'free-days',
      element: <DaysOff />
    },
    {
      link: 'school-statutes',
      element: <SchoolStatutes />
    },
    {
      link: 'pricing-plans',
      element: <p>Stawki</p>
    },
    {
      link: 'co-financing-institutions',
      element: <ListOfCoFinancingInstitutions/>
    },
    {
      link: 'school-settings',
      element: <Settings />
    }
  ];

  return (
    <Routes>
      {schoolLinks.map((link) => (
        <Route key={link.link} path={`/${link.link}`} element={link.element} />
      ))}
    </Routes>
  );
};

export default School;
