import React from 'react';

import './Error.css';

const Error = () => (
  <section className="Error h-screen w-screen">
    <h1 className="font-bold text-4xl text-center">Tu nic nie ma</h1>
  </section>
);

export default Error;
