import React from 'react';
import IconComponent from '../IconComponent/IconComponent';

const StickyNotes = ({ message }: { message: string }) => {
  return (
    <div className="flex items-start bg-brand-100 border border-grayLight-300 rounded-xl p-spacing-xl gap-spacing-xl">
      <div
        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/outliner.png)` }}
        className="SettingsGreetings__container flex items-center justify-center relative"
      >
        <IconComponent iconName="info" color="#FFAC05" />
      </div>
      <p className="font-normal text-sm text-grayWarm-700">{message}</p>
    </div>
  );
};

export default StickyNotes;
