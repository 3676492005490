import { RadioProps } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

interface Props {
  children: ReactElement<RadioProps>;
  header: string;
  message?: string | ReactElement<RadioProps>;
}

const CustomInputRadio = ({ children, header, message }: Props) => {
  return (
    <div className="flex items-start gap-spacing-xl pt-spacing-xs pr-spacing-xl pb-spacing-xs pl-spacing-xl">
      {children}
      <div>
        <p className="font-medium text-base text-grayLight-700">{header}</p>
        {typeof message === 'string' ? (
          <p className="font-normal text-base text-grayLight-600">{message}</p>
        ) : (
          message
        )}
      </div>
    </div>
  );
};

export default CustomInputRadio;
