const handlePhoneNumberChange = (value: string) => {
  const onlyNums = value.replace(/[^0-9]/g, '');

  let formatted = '';
  for (let i = 0; i < onlyNums.length; i += 3) {
    if (i > 0) formatted += '-';
    formatted += onlyNums.slice(i, i + 3);
  }

  return formatted.slice(0, 11);
};

export default handlePhoneNumberChange;
