import React from "react";
import { Routes, Route } from "react-router-dom";
import { EditTeacherOrEmployeeProvider } from "../../../../context/EditTeacherOrEmployeeProvider";
import EmployeesTable from "./EmployeesTable/EmployeesTable";
import SingleEmployee from "./SingleEmployee/SingleEmployee";

const Employees = () => {
  const employeesLinks = [
    {
      link: "employees-table",
      element: <EmployeesTable/>,
    },
    {
      link: "single-employee/:employeeId",
      element: <SingleEmployee/>,
    }
  ];

  return (
    <EditTeacherOrEmployeeProvider>
        <Routes>
          {employeesLinks.map((link) => (
            <Route key={link.link} path={`/${link.link}/*`} element={link.element} />
          ))}
        </Routes>
      </EditTeacherOrEmployeeProvider>
      );
};

export default Employees;