import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { OrderItemInterface } from "../../../../shared/type/parentOrderHistory.type";
import calculatePrice from "../../../../utils/calculatePrice";

interface Props {
  isOpen: boolean,
  onClose: () => void,
  order: OrderItemInterface | undefined,
}

const ParentOrderHistoryDetails = ({ isOpen, onClose, order }: Props) => {
  const formattedOrderDate = order?.orderDate
    ? moment(order.orderDate).format("DD.MM.YYYY HH:mm")
    : "";

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl" isCentered>
      <ModalOverlay
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(0, 0, 0, 0.5)"
        }}
      />
      <ModalContent>
        <ModalHeader className="flex flex-col gap-spacing-xl">
          <h2 className="font-semibold text-lg text-grayLight-900">Zamówienie {formattedOrderDate}</h2>
          <div className="border-b border-grayLight-200" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="grid gap-spacing-3xl">
          <div className='w-96'>
            <table className="border border-gray-300 w-full">
              <thead>
              <tr className="border-b border-gray-300">
                <th className="p-2 border-r border-gray-300">
                  <p className="text-xs font-medium text-grayLight-600">Saldo przed</p>
                </th>
                <th className="p-2 border-r border-gray-300">
                  <p className="text-xs font-medium text-grayLight-600">Wpłata</p>
                </th>
                <th className="p-2">
                  <p className="text-xs font-medium text-grayLight-600">Saldo po</p>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr className="border-b border-gray-300">
                <td className="p-2 border-r border-gray-300 pt-spacing-xl">
                  <p className="text-xs font-medium text-grayLight-600">{order?.saldoBefore}</p>
                </td>
                <td className="p-2 border-r border-gray-300">
                  <p className="text-xs font-medium text-grayLight-600">{order?.paymentValue}</p>
                </td>
                <td className="p-2">
                  <p className="text-xs font-medium text-grayLight-600">{order?.saldoAfter}</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div>
            <table className="border border-gray-300 w-full">
              <thead className='bg-blue-25'>
              <tr className="border-b border-gray-300">
                <th className="p-2 border-r border-gray-300">
                  <p className="text-xs font-medium text-grayLight-600">Dzień</p>
                </th>
                <th className="p-2 border-r border-gray-300">
                  <p className="text-xs font-medium text-grayLight-600">Dla</p>
                </th>
                <th className="p-2 border-r border-gray-300">
                  <p className="text-xs font-medium text-grayLight-600">Nazwa</p>
                </th>
                <th className="p-2">
                  <p className="text-xs font-medium text-grayLight-600">Wartość stawki</p>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr className="border-b border-gray-300">
                <td className="p-2 border-r border-gray-300 pt-spacing-xl" />
                <td className="p-2 border-r border-gray-300 pt-spacing-xl" />
                <td className="p-2 border-r border-gray-300 pt-spacing-xl">
                  <p className="text-xs font-medium text-grayLight-600">Razem</p>
                </td>
                <td className="p-2 border-r border-gray-300 pt-spacing-xl">
                  <p className="text-xs font-medium text-grayLight-600">{order?.orderValueString}</p>
                </td>
              </tr>
              {order?.orderDto.boughtStakeDays.map((day, index) => (
                <tr key={index} className="border-b border-gray-300">
                  <td className="p-2 border-r border-gray-300 pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md">
                    <p className="text-xs font-medium text-grayLight-600">{day.when}</p>
                  </td>
                  <td className="p-2 border-r border-gray-300 pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md">
                    <p className="text-xs font-medium text-grayLight-600">{order?.consumerDto.firstName} {order?.consumerDto.lastName}</p>
                  </td>
                  <td className="p-2 border-r border-gray-300 pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md">
                    {day.boughtStakes.map((stake, stakeIndex) => (
                      <p key={stakeIndex} className="text-xs font-medium text-grayLight-600">{stake.name}</p>
                    ))}
                  </td>
                  <td className="p-2 border-r border-gray-300 pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md">
                    {day.boughtStakes.map((stake, stakeIndex) => (
                      <p key={stakeIndex} className="text-xs font-medium text-grayLight-600">{calculatePrice(stake.price, 'zł')}</p>
                    ))}
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ParentOrderHistoryDetails;
