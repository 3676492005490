import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../../../../../../context/ApiProvider";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import FileInput from "../../../../../FileInput/FileInput";

import './ImportParentsFromFilePopUp.css';
import IconComponent from "../../../../../IconComponent/IconComponent";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ImportParentsFromFilePopUp = ({ isOpen, onClose }: Props) => {
  const { apiTenantParentImportController } = useApi();
  const { id: tenantId } = useParams();
  const [ file, setFile ] = useState<FileList | undefined>(undefined);
  const [ step, setStep ] = useState<'choose-file' | 'summary-success' | 'summary-error'>('choose-file');

  const [ response, setResponse ] = useState<AxiosResponse>();
  const [ error, setError ] = useState<any>();

  const downloadSampleFile = async () => {
    try {
      const response = await apiTenantParentImportController('sample-import-file').get('', {
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'szablon.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading sample file:', error);
    }
  }

  const handleFileUpload = async (e: React.FormEvent) => {
    e.preventDefault();
    if (file?.length === 0) {
      return;
    }
    const formData = new FormData();
    if (file) {
      formData.append("file", file[0]);
    }

    try {
      const response = await apiTenantParentImportController('file').post(`/${tenantId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setResponse(response)
      setStep('summary-success')
      console.log('Upload successful:', response);
    } catch (error: any) {
      setError(error)
      setStep('summary-error')
      console.error('Error uploading file:', error);
    } finally {
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <form onSubmit={handleFileUpload}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className={`flex ${step === 'summary-success' && 'flex-col'} gap-spacing-xl`}>
            {step === 'choose-file' || (
              <div className={`h-12 w-12 rounded-full ${step === 'summary-error' ? 'bg-error-100' : 'bg-success-100'} flex items-center justify-center`}>
                <IconComponent iconName="circle-check" color={step === 'summary-error' ? '#D92D20' : '#079455'} />
              </div>
            )}
            <div className="flex flex-col border-r border-grayLight-200">
              <h2 className="text-grayLight-900 font-semibold text-lg">
                {step === "summary-error" && 'Błąd'}
                {step === 'choose-file' && 'Importuj listę rodziców'}
                {step === 'summary-success' && 'Lista zaimportowana z powodzeniem'}
              </h2>
              <p className='text-sm font-normal text-grayLight-600'>
                {step === 'summary-error' && 'Import listy się nie powiódł. Sprawdź listę błędów:'}
              </p>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex">
            { step === 'choose-file' ? (
              <div className="flex flex-col items-start w-full h-full gap-spacing-3xl">
                <FileInput
                  description='CSV lub plik Excela (max. 2000 mb)'
                  onFileUpload={(file) => {
                  setFile(file);
                }} />
                <CustomChakraButton size="lg" hierarchy="linkGray" icon="download" iconColor="#344054"
                                    iconPosition="left"  buttonProps={{
                                      onClick: downloadSampleFile,
                  type: 'button'
                }}>
                  Pobierz szablon listy (szablon.xlsx)
                </CustomChakraButton>
              </div>
            ) : null}
            { step === 'summary-error' ? (
              <div className='ImportParentsFromFilePopUp__successContainer w-full h-full border border-gray-300 rounded-lg pt-spacing-lg pb-spacing-lg'>
                <ul className='flex flex-col gap-spacing-sm'>
                  {error.response.data.errors.map((error: any) => <li>{error}</li>)}
                </ul>
              </div>
            ) : null}
            {step === "summary-success" ? (
              <div className="flex flex-col">
                <p className='text-grayLight-600 text-sm font-normal'>Lista zaimportowanych rodziców: {response?.data.childCount}</p>
                <p className='text-grayLight-600 text-sm font-normal'>Lista zaimportowanych dzieci: {response?.data.parentCount}</p>
              </div>
            ) : null}
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: onClose
              }}
            >
            Anuluj
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                disabled: !file,
                onClick: () => {
                  if(step === 'summary-error') {
                    setStep('choose-file');
                    setFile(undefined);
                  }
                  if(step === 'summary-success') {
                    onClose();
                  }
                },
                className: "grow",
                type: step === 'choose-file' ? 'submit' : 'button'
            }}
            >
              {step === 'summary-success' ? 'Zamknij' :
                step === 'summary-error' ? 'Importuj poprawiony plik' :
                  step === 'choose-file' ? 'Importuj' : ''}
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default ImportParentsFromFilePopUp;
