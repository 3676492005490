import React, { useState } from "react";
import { useParams, Routes, Route, useLocation } from "react-router-dom";
import { NavOptionsWithElementInterface } from "../../../shared/type/navOptions.type";
import CustomChakraButton from "../../CustomChakraButton/CustomChakraButton";
import Navigation from "../../Navigation/Navigation";
import CreateNewAdminThread from "./CreateNewAdminThread/CreateNewAdminThread";
import Messages from "./Messages/Messages";

const MailBox = () => {
  const { id: tenantId } = useParams();
  const location = useLocation();
  const [ refreshMessages, setRefreshMessages ] = useState<() => void>(() => () => {});
  const [showPopUp, setShowPopUp] = useState<boolean>(false)


  const navOptions: NavOptionsWithElementInterface[] = [
    {
      link: 'messages',
      name: 'Wiadomości',
      component: <Messages tenantId={tenantId || '0'} setRefreshMessages={setRefreshMessages} />
    },
    // {
    //   link: 'notifications',
    //   name: 'Komunikaty',
    //   component: <ParentNotification tenantId={chosenTenantId?.toString() || ''} />
    // }
  ];

  return (
    <section className='pt-spacing-3xl pb-12 gap-spacing-3xl'>
      <div className='flex justify-between pr-spacing-3xl pl-spacing-3xl'>
        <div>
          <h2 className='font-semibold text-grayLight-900' style={{ fontSize: '30px' }}>
            Wiadomości
          </h2>
        </div>

        {location.pathname.includes('messages') && (
          <div>
            <CustomChakraButton
              iconPosition='right'
              icon='plus'
              iconColor='#292524'
              hierarchy='primary'
              size='md'
              buttonProps={{
                onClick: () => {
                  setShowPopUp(true)
                }
              }}
            >
              Nowa wiadomość
            </CustomChakraButton>
          </div>
        )}
      </div>

      <div className='flex flex-col'>

        <div>
          <Navigation navOptions={navOptions} />
        </div>

        <div>
          <Routes>
            {navOptions.map((item) => (
              <Route key={item.link} path={`/${item.link}/*`} element={item.component} />
            ))}
          </Routes>
        </div>
      </div>
      {showPopUp && <CreateNewAdminThread isOpen={showPopUp} onClose={() => {
        refreshMessages();
        setShowPopUp(false);
      }} />}
    </section>
  )
};

export default MailBox;