import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal
} from "@chakra-ui/react";
import { AxiosResponse, HttpStatusCode } from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../../../context/ApiProvider";
import { CashTopUpContextInterface } from "../../../../../../../../shared/type/cashTopUpContext.type";
import { TPayPayOfTheBalanceResponseInterface } from "../../../../../../../../shared/type/tpayResponse.type";
import CustomChakraButton from "../../../../../../../CustomChakraButton/CustomChakraButton";
import TenantAdminSaldoUserCard from "./TenanAdminSaldoUserCard/TenanAdminSaldoUserCard";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const AddCashToSaldo = ({ isOpen, onClose }: Props) => {
  const { parentId } = useParams<{ parentId: string }>();
  const { apiTenantAdminPaymentController } = useApi();
  const [ cashToUpContext, setCashToUpContext ] = useState<CashTopUpContextInterface[]>([]);
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext()
  const [ consumerAmount, setConsumerAmount ] = useState<{
    consumerId: number,
    amount: string,
    parentId: number
  }[]>([]);

  const fetchCashToUpContext = async () => {
    try {
      const response: AxiosResponse<CashTopUpContextInterface[]> = await apiTenantAdminPaymentController('cash-top-up-context').get('',{
        params: {
          parentId: parentId,
        }
      })
      setCashToUpContext(response.data)
      if(response.data && parentId){
        setConsumerAmount(response.data.map(consumer => ({consumerId: consumer.consumerId, amount: '', parentId: Number(parentId)})))
      }
    } catch (error: any) {

    }
  }

  const updateConsumerAmount = (consumerId: number, amount: string) => {
    setConsumerAmount(prevAmounts =>
      prevAmounts.map(consumer =>
        consumer.consumerId === consumerId
          ? { ...consumer, amount }
          : consumer
      )
    );
  }

  const confirmPayment = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      const response: AxiosResponse<TPayPayOfTheBalanceResponseInterface> = await apiTenantAdminPaymentController('make-cash-top-up').post('',
        consumerAmount.filter(item => item.amount.length > 0)
      );
      const { status } = response;

      if (status === HttpStatusCode.Ok) {
        setAlertProperties({
          title: 'Sukces',
          description: 'Przyjęcie gotówki zakończone sukcesem',
          timeout: 2000,
          status: 'success'
        });
        onClose();
      }

    } catch (error) {
      setAlertProperties({
        title: 'Bład',
        description: 'Bład podczas przyjmowania gotówki',
        timeout: 5000,
        status: 'error'
      })
    } finally {
      setShowAlert(true)
    }
  };

  const totalAmount = consumerAmount.reduce((sum, consumer) => sum + parseFloat(consumer.amount || "0"), 0).toFixed(2);

  const buttonString: string =  `Wpłać ${totalAmount} zł`

  useEffect(() => {
    fetchCashToUpContext();
  }, []);

  return(
    <Modal isOpen={isOpen} onClose={onClose} isCentered size='xl'>
      <form onSubmit={() => {}}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className="flex">
            <div className="flex flex-col text-grayLight-900 font-semibold text-lg border-grayLight-200 gap-spacing-xl">
              <h2 className="font-semibold text-lg">Wpłata na saldo</h2>
              <p className='text-sm font-normal text-grayLight-600'>Wpłać na saldo sumę całkowitą lub jej część.</p>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex gap-spacing-xl">
            <div className="flex flex-col gap-spacing-3xl w-full">
              {cashToUpContext?.map((consumer) => (
                <TenantAdminSaldoUserCard key={consumer.consumerId} consumer={consumer}
                                          updateConsumerAmount={updateConsumerAmount} />
              ))
              }
            </div>
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <div className="flex w-72 justify-end gap-spacing-md">
              <div>
                <CustomChakraButton
                  hierarchy="secondaryColor"
                  size="lg"
                  iconPosition="onlyText"
                  buttonProps={{
                    className: "grow",
                    onClick: onClose
                  }}
                >
                  Anuluj
                </CustomChakraButton>
              </div>
             <div>
               <CustomChakraButton
                 hierarchy="primary"
                 size="lg"
                 iconPosition="onlyText"
                 buttonProps={{
                   onClick: confirmPayment,
                   className: "grow",
                   type: "submit"
                 }}
               >
                 {buttonString}
               </CustomChakraButton>
             </div>
            </div>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}

export default AddCashToSaldo;