import React, { ReactNode, useMemo, useState, createContext, useContext, useEffect } from 'react';
import { HttpStatusCode } from "axios";
import { useParams } from "react-router-dom";
import { TenantSettingsInterface } from '../shared/type/tenant.type';
import { useAlertContext } from "./AlertProvider";
import { useApi } from './ApiProvider';

interface Props {
  children: ReactNode;
}

interface SettingFormProps {
  settingsForm: TenantSettingsInterface | undefined;
  updateForm: (name: string, value: string | boolean | number) => void;
  cancelForm: () => void;
  submitForm: (e: React.FormEvent) => void;
  isEdited: boolean;
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
}

const SettingsFormContext = createContext<SettingFormProps | undefined>(undefined);

export const useSettingFormContext = () => {
  const context = useContext(SettingsFormContext);
  if (!context) {
    throw new Error('useSettingFormContext must be used within a SettingsFormProvider');
  }
  return context;
};

export const SettingsFormProvider = ({ children }: Props) => {
  const { apiTenantTenantController } = useApi();
  const { setShow, setAlertProperties } = useAlertContext();
  const { id: tenant } = useParams();
  const [settingsForm, setSettingForm] = useState<TenantSettingsInterface | undefined>();
  const [isEdited, setIsEdited] = useState<boolean>(false);

  const fetchSettings = async () => {
    try {
      const response = await apiTenantTenantController('').get(`/${tenant}`);
      setSettingForm(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const cancelForm = () => {
    setIsEdited(false);
    fetchSettings();
  };

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await apiTenantTenantController('').put('', { ...settingsForm, id: tenant });
      if(response.status === HttpStatusCode.Ok){
        setAlertProperties({
          timeout: 2000,
          title: "Sukces",
          description: `Poprawnie zaktualizowano ustawienia`,
          status: "success"
        });
        setShow(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsEdited(false);
      fetchSettings();
    }
  };

  const updateForm = (name: string, value: string | boolean | number) => {
    setIsEdited(true);
    setSettingForm((prev) => (prev ? { ...prev, [name]: value } : undefined));
    console.log(settingsForm)
  };

  const contextValue = useMemo(
    () => ({
      updateForm,
      settingsForm,
      cancelForm,
      submitForm,
      isEdited,
      setIsEdited
    }),
    [updateForm, settingsForm, cancelForm, submitForm, isEdited, setIsEdited]
  );

  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    fetchSettings();
  }, [tenant]);

  return (
    <SettingsFormContext.Provider value={contextValue}>
      <form onSubmit={submitForm}>{children}</form>
    </SettingsFormContext.Provider>
  );
};
