import React from 'react';
import { Box, Text } from "@chakra-ui/react";
import CustomChakraButton from "../CustomChakraButton/CustomChakraButton";

interface PaginationProps {
  pageNumber: number;
  maxPageNumber: number;
  hasNextPage: boolean;
  onPageChange: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ pageNumber, maxPageNumber, hasNextPage, onPageChange }) => {
  const renderPagination = () => {
    const pagination = [];

    if (maxPageNumber <= 7) {
      for (let i = 1; i <= maxPageNumber; i++) {
        pagination.push(i);
      }
    } else {
      if (pageNumber <= 4) {
        pagination.push(1, 2, 3, 4, 5, '...', maxPageNumber);
      } else if (pageNumber >= maxPageNumber - 3) {
        pagination.push(1, '...', maxPageNumber - 4, maxPageNumber - 3, maxPageNumber - 2, maxPageNumber - 1, maxPageNumber);
      } else {
        pagination.push(1, '...', pageNumber - 1, pageNumber, pageNumber + 1, '...', maxPageNumber);
      }
    }

    return pagination.map((pageNum, index) => (
      <div
        key={index}
        className={`flex items-center justify-center ${pageNumber === pageNum && 'bg-brand-100 border-2 box-border border-brand-300'} rounded-lg w-10 h-10`}
      >
        <Text
          as="button"
          onClick={() => typeof pageNum === 'number' && onPageChange(pageNum)}
          disabled={typeof pageNum !== 'number' || pageNum === pageNumber}
          style={{ cursor: typeof pageNum === 'number' ? 'pointer' : 'default' }}
          className={`p-spacing-md text-grayLight-600 text-sm`}
        >
          {pageNum}
        </Text>
      </div>
    ));
  };

  return (
    <Box position="sticky" bottom="0" bg="white" zIndex="1"
         className='flex flex-row w-full items-center justify-between border-grayLight-200 mt-20 pt-spacing-xl pr-spacing-xl pb-spacing-md pl-spacing-xl'>
      <CustomChakraButton size='sm' hierarchy='linkGray' iconPosition='left' icon='arrowLeft' buttonProps={{
        type: 'button',
        className: 'border-none',
        onClick: () => onPageChange(pageNumber - 1),
        disabled: pageNumber === 1,
      }}>Poprzednia</CustomChakraButton>
      <div className='flex gap-spacing-xxs'>
        {renderPagination()}
      </div>
      <CustomChakraButton size='sm' hierarchy='linkGray' iconPosition='right' icon='arrowRight' buttonProps={{
        type: 'button',
        className: 'border-none',
        onClick: () => onPageChange(pageNumber + 1),
        disabled: !hasNextPage
      }}>Następna</CustomChakraButton>
    </Box>
  );
};

export default Pagination;
