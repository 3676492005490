import React from 'react';

const DownloadIcon = ({ className, color }: { className: string, color: string }) => (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M16.5 11V12C16.5 13.4001 16.5 14.1002 16.2275 14.635C15.9878 15.1054 15.6054 15.4878 15.135 15.7275C14.6002 16 13.9001 16 12.5 16H5.5C4.09987 16 3.3998 16 2.86502 15.7275C2.39462 15.4878 2.01217 15.1054 1.77248 14.635C1.5 14.1002 1.5 13.4001 1.5 12V11M13.1667 6.83333L9 11M9 11L4.83333 6.83333M9 11V1"
      stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default DownloadIcon;
