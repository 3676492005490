import { OrderedItemInterface, OrderForParentInterface } from "../../shared/type/orderForParent.type";

export function groupedOrders(parentBasketContext: OrderForParentInterface){
  const groupedOrders: { [consumerId: number]: OrderedItemInterface[] } = parentBasketContext?.ordersForChildren.reduce((acc, order) => {
    const { consumerId, orderedItems } = order;
    if (!acc[consumerId]) {
      acc[consumerId] = [];
    }
    acc[consumerId].push(...orderedItems);
    return acc;
  }, {} as { [consumerId: number]: OrderedItemInterface[] });

  return groupedOrders
}