import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../../../context/ApiProvider';
import { CompanyType } from '../../../../../shared/type/company.type';
import handlePostCodeChange from '../../../../../utils/handlePostCodeChange';
import CustomChakraButton from '../../../../CustomChakraButton/CustomChakraButton';
import FormInput from '../../../../Forms/FormInput/FormInput';

const InvoiceData = () => {
  const { apiCompanyController } = useApi();
  const { id } = useParams();
  const [company, setCompany] = useState<CompanyType | null>(null);
  const [isNotEdited, setIsNotEdited] = useState(true);
  const formRef = useRef<HTMLFormElement>(null);

  const fetchCompanyData = async () => {
    try {
      const result = await apiCompanyController('').get(`/${id}`);
      setCompany(result.data);
    } catch (error) {
      console.warn('Błąd pobrania elementu');
    } finally {
      setIsNotEdited(true);
    }
  };

  const updateCompanyData = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      console.log(company);
      await apiCompanyController('').put(`/${id}`, { ...company });
    } catch (error) {
      console.warn('Błąd pobrania elementu');
    } finally {
      fetchCompanyData();
      setIsNotEdited(true);
    }
  };

  const updateState = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsNotEdited(false);
    setCompany((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  return (
    <section className="CompanyData flex flex-col gap-5 w-full h-full overflow-scroll">
      <div className="CompanyData__header flex justify-between w-full">
        <p className="m-0 p-0 font-semibold text-lg text-grayLight-900">Dane do faktury</p>
        <div className="CompanyData__header__actionButtons flex gap-spacing-lg">
          <CustomChakraButton
            iconPosition="onlyText"
            hierarchy="tertiaryColor"
            size="md"
            buttonProps={{
              onClick: fetchCompanyData,
              disabled: isNotEdited
            }}
          >
            Anuluj
          </CustomChakraButton>
          <CustomChakraButton
            iconPosition="onlyText"
            hierarchy="primary"
            size="md"
            buttonProps={{
              onClick: () => formRef.current?.requestSubmit(),
              disabled: isNotEdited
            }}
          >
            Zapisz zmiany
          </CustomChakraButton>
        </div>
      </div>
      <div className="CompanyData__formContainer">
        <form className="w-full flex flex-row gap-8" onSubmit={updateCompanyData} ref={formRef}>
          <div className="grow flex flex-col gap-spacing-xl">
            <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
              Dane nabywcy
            </div>
            <FormInput
              label="Nazwa"
              inputParams={{
                value: company?.buyerName ?? '',
                type: 'text',
                name: 'buyerName',
                onChange: updateState
              }}
            />
            <FormInput
              label="NIP"
              inputParams={{
                value: company?.buyerNip ?? '',
                type: 'text',
                name: 'buyerNip',
                onChange: updateState
              }}
            />
            <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
              Adres nabywcy
            </div>
            <FormInput
              label="Ulica"
              inputParams={{
                value: company?.buyerStreet ?? '',
                name: 'buyerStreet',
                onChange: updateState
              }}
            />
            <div className="flex justify-between gap-spacing-xl">
              <div className="grow">
                <FormInput
                  label="Kod pocztowy"
                  placeholder="00-000"
                  inputParams={{
                    value: company?.buyerPostCode ?? '',
                    maxLength: 6,
                    minLength: 6,
                    name: 'buyerPostCode',
                    onChange: (e) => {
                      handlePostCodeChange(e, setCompany);
                      setIsNotEdited(false);
                    }
                  }}
                />
              </div>
              <div className="grow">
                <FormInput
                  label="Miasto"
                  placeholder="Wybierz"
                  inputParams={{
                    value: company?.buyerCity ?? '',
                    className: 'grow',
                    name: 'buyerCity',
                    onChange: updateState
                  }}
                />
              </div>
            </div>
          </div>
          <div className="grow flex flex-col gap-spacing-xl">
            <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
              Dane odbiorcy
            </div>
            <FormInput
              label="Nazwa"
              placeholder="Podaj nazwę odbiorcy"
              inputParams={{
                value: company?.recipientName ?? '',
                name: 'recipientName',
                onChange: updateState
              }}
            />
            <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
              Adres odbiorcy
            </div>
            <FormInput
              label="Ulica"
              placeholder="Podaj ulicę odbiorcy"
              inputParams={{
                value: company?.recipientStreet ?? '',
                name: 'recipientStreet',
                onChange: updateState
              }}
            />
            <div className="flex justify-between gap-spacing-xl">
              <div className="grow">
                <FormInput
                  label="Kod pocztowy"
                  placeholder="00-000"
                  inputParams={{
                    value: company?.recipientPostCode ?? '',
                    maxLength: 6,
                    minLength: 6,
                    name: 'recipientPostCode',
                    onChange: (e) => handlePostCodeChange(e, setCompany)
                  }}
                />
              </div>
              <div className="grow">
                <FormInput
                  label="Miasto"
                  placeholder="Wybierz miasto"
                  inputParams={{
                    value: company?.recipientCity ?? '',
                    name: 'recipientCity',
                    onChange: updateState
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default InvoiceData;
