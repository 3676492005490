import { Thead, Th, TableContainer, useDisclosure } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useApi } from '../../../../../context/ApiProvider';
import { TenantInterface } from '../../../../../shared/type/tenant.type';
import { createColumnHelper } from '@tanstack/react-table';
import CustomAlert from '../../../../CustomAlert/CustomAlert';
import CustomChakraButton from '../../../../CustomChakraButton/CustomChakraButton';
import { DataTable } from '../../../../DataTable/DataTable';
import IconComponent from '../../../../IconComponent/IconComponent';
import AddNewBranch from '../../../Branches/AddNewBranch/AddNewBranch';

const defaultAlert = {
  isOpen: false,
  id: 0,
  name: ''
};

const ListOfTenants = () => {
  const { apiTenantController } = useApi();
  const { id } = useParams();
  const columnHelper = createColumnHelper<TenantInterface>();
  const [tenants, setTenants] = useState<TenantInterface[] | []>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [deleteElement, setDeleteElement] = useState<{
    isOpen: boolean;
    id: number;
    name: string;
  }>(defaultAlert);

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => {
        const { id } = info.row.original;
        return (
          <Link to={`/superAdmin/branches/id/${id}`}>
            <IconComponent iconName="share" />
          </Link>
        );
      },
      header: ''
    }),
    columnHelper.accessor('name', {
      cell: (info) => {
        const name = info.getValue();
        return <div className="text-sm font-medium">{name}</div>;
      },
      header: 'Nazwa oddziału'
    }),
    columnHelper.accessor('address', {
      cell: (info) => {
        const { address, postCode, city } = info.row.original;
        return (
          <div className="text-sm font-medium">
            {address} {postCode} {city}
          </div>
        );
      },
      header: 'Adres'
    }),
    columnHelper.accessor('email', {
      cell: (info) => {
        const email = info.getValue();
        return <div className="text-sm font-medium">{email}</div>;
      },
      header: 'Email'
    }),
    columnHelper.accessor('phone', {
      cell: (info) => {
        const phone = info.getValue();
        return <div className="text-sm font-medium">{phone}</div>;
      },
      header: 'Telefon'
    }),
    columnHelper.accessor('address', {
      cell: (info) => {
        const { id, name } = info.row.original;
        return (
          <button
            className="text-sm border-none bg-transparent font-semibold text-grayLight-600"
            onClick={() => {
              setDeleteElement({ id, name, isOpen: true });
            }}
          >
            Usuń
          </button>
        );
      },
      header: ''
    })
  ];

  const fetchTenants = async () => {
    setLoading(true);
    try {
      const response = await apiTenantController('find-by-company').get(`/${id}`);
      setTenants(response.data);
    } catch (error) {
      console.warn(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    fetchTenants();
  };

  useEffect(() => {
    fetchTenants();
  }, []);

  return (
    <section className="CompanyData flex flex-col gap-5 w-full h-full overflow-scroll">
      <div className="CompanyData__header flex justify-between w-full">
        <p className="m-0 p-0 font-semibold text-lg text-grayLight-900">
          Oddziały przypisane do firmy
        </p>
      </div>
      <div className="CompanyData__formContainer h-full flex flex-col justify-start lg:p-spacing-4xl">
        <TableContainer className="Branches__table lg:p-spacing-3xl  bg-white rounded-lg">
          <DataTable
            data={tenants}
            columns={columns}
            isLoading={loading}
            extraThead={
              <Thead>
                <Th colSpan={columns.length}>
                  <div className="w-full flex items-center justify-end">
                    <div className="hidden lg:block">
                      <CustomChakraButton
                        iconPosition="left"
                        icon="plus"
                        hierarchy="primary"
                        size="md"
                        buttonProps={{
                          onClick: onOpen
                        }}
                      >
                        Dodaj oddział
                      </CustomChakraButton>
                    </div>
                  </div>
                </Th>
              </Thead>
            }
          />
        </TableContainer>
        <AddNewBranch isOpen={isOpen} companyId={id} onClose={() => {
          fetchTenants();
          handleClose();
        }} />
      </div>
      <CustomAlert
        handleOpen={deleteElement.isOpen}
        icon={<IconComponent iconName="trash" />}
        header={`Usunąć Oddział ${deleteElement?.name}?`}
        content="Usunięcie oddziału jest nieodwracalne i wiążę się z utratą danych."
        cancelButtonText="Anuluj"
        confirmButtonText="Usuń"
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'primary'
        }}
        onCancelAction={() => {
          setDeleteElement(defaultAlert);
        }}
        onConfirmAction={async () => {
          try {
            await apiTenantController('').delete(`/${deleteElement?.id}`);
          } catch (error) {
            console.warn('Nie udało się usunąć tenanta');
          } finally {
            fetchTenants();
            setDeleteElement(defaultAlert);
          }
        }}
      />
    </section>
  );
};

export default ListOfTenants;
