import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter, Input
} from "@chakra-ui/react";
import { AxiosResponse, HttpStatusCode } from "axios";
import { Select } from "chakra-react-select";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../../context/ApiProvider";
import {
  NewCoFinancingAssignmentInterface,
} from "../../../../../../../shared/type/coFinancing.type";
import { CoFinancingInstitutionInterface } from "../../../../../../../shared/type/coFinancingInstitution.type";
import { RateInterface } from "../../../../../../../shared/type/rate.type";
import CustomChakraButton from "../../../../../../CustomChakraButton/CustomChakraButton";
import CustomInput from "../../../../../../CustomInput/CustomInput";
import { EditedInterface } from "../KidCoFinancing";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  mode: 'add' | 'edit';
  initData?: EditedInterface
}

const defaultCoFinancing = {
  fromDate: "",
  toDate: "",
  cofinancingInstitutionId: 0,
  consumerId: 0,
  tenantId: 0,
  stakeId: 0,
  coFinancingAmountPerDay: '0'
}

const AddEditCoFinancing = ({ isOpen, onClose, mode, initData }: Props) => {
  const { apiTenantCoFinancingInstitutionController, apiTenantStakeController } = useApi();
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();
  const { id: tenantId, kidId } = useParams<{ id: string, kidId: string }>();
  const [ coFinancingInstitutions, setCoFinancingInstitutions] = useState<CoFinancingInstitutionInterface[]>([]);
  const [ rates, setRates ] = useState<RateInterface[]>([]);

  const [ coFinancing, setCoFinancing ] = useState<NewCoFinancingAssignmentInterface>(defaultCoFinancing);

  const fetchCoFinancingInstitutions = async () => {
    try {
      const response: AxiosResponse<CoFinancingInstitutionInterface[]> = await apiTenantCoFinancingInstitutionController('').get(`/${tenantId}`);
      setCoFinancingInstitutions(response.data);
    } catch (error) {
      console.error("Błąd przy pobieraniu instytucji dofinansowujących:", error);
    }
  }

  const fetchRates = async () => {
    try {
      const response: AxiosResponse<RateInterface[]> = await apiTenantStakeController('').get(`/${tenantId}`);
      setRates(response.data);
    } catch (error) {
      console.error("Błąd przy pobieraniu stawek:", error);
    }
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (mode === 'add') {
      try {
        const response = await apiTenantCoFinancingInstitutionController('assign-cofinancingInstitution-to-consumer').post('', {
          ...coFinancing,
          consumerId: Number(kidId),
          tenantId: Number(tenantId),
        });
        if (response.status === HttpStatusCode.Ok) {
          setAlertProperties({
            timeout: 2000,
            title: "Sukces",
            description: `Poprawnie dodano dofinansowanie`,
            status: "success"
          });
          setShowAlert(true);
          onClose();
        }
      } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Błąd dodawania dofinansowania';
        setAlertProperties({
          timeout: 2000,
          title: "Błąd",
          description: errorMessage,
          status: "error"
        });
        setShowAlert(true);
      }
    }

    if (mode === 'edit') {
      try {
        const response = await apiTenantCoFinancingInstitutionController('cofinancing-assignment').put(`/${tenantId}/${initData?.id}`, {
          ...coFinancing,
          consumerId: kidId,
          tenantId: tenantId,
        });
        if (response.status === HttpStatusCode.Ok) {
          setAlertProperties({
            timeout: 2000,
            title: "Sukces",
            description: `Poprawnie zaktualizowano dofinansowanie`,
            status: "success"
          });
          setShowAlert(true);
          onClose();
        }
      } catch (error: any) {
        const errorMessage = error.response?.data?.message || 'Błąd aktualizacji dofinansowania';
        setAlertProperties({
          timeout: 2000,
          title: "Błąd",
          description: errorMessage,
          status: "error"
        });
        setShowAlert(true);
      }
    }
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[0-9]*\.?[0-9]{0,2}$/; // Allows up to two decimal places

    if (regex.test(value) || value === "") {
      setCoFinancing((prev) => ({...prev, coFinancingAmountPerDay: value}))
    }
  };

  useEffect(() => {
    if(mode === 'edit' || initData){
      setCoFinancing({
        fromDate: initData?.fromDate || '',
        toDate: initData?.toDate || '',
        cofinancingInstitutionId: initData?.cofinancingInstitutionId || 0,
        consumerId: Number(kidId),
        tenantId: Number(tenantId),
        stakeId: initData?.stakeId || 0,
        coFinancingAmountPerDay: initData?.coFinancingAmountPerDay || ''
      })
    }
    if(mode === 'add'){setCoFinancing(defaultCoFinancing)}
  }, []);

  useEffect(() => {
    fetchCoFinancingInstitutions();
    fetchRates();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <form onSubmit={handleSubmit}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">
              {mode === 'add' ? 'Dodaj dofinansowanie' : 'Edytuj dofinansowanie'}
            </h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <div className="flex flex-col gap-spacing-md">
              <label className="text-sm font-medium text-grayLight-700">Instytucja dofinansowująca</label>
              <Select
                colorScheme="gray"
                placeholder="Wybierz"
                required
                value={({
                  label: coFinancingInstitutions.find(item => item.id === coFinancing.cofinancingInstitutionId)?.name,
                  value: coFinancingInstitutions.find(item => item.id === coFinancing.cofinancingInstitutionId)?.id
                })}
                onChange={(value) => {
                  if (value) {
                    setCoFinancing((prev) => ({
                      ...prev,
                      cofinancingInstitutionId: value.value || 0
                    }));
                  }
                }}
                options={coFinancingInstitutions.map((unit) => ({
                  ...unit,
                  label: unit.name,
                  value: unit.id,
                }))}
              />
            </div>
            <div className="flex flex-col gap-spacing-md">
              <label className="text-sm font-medium text-grayLight-700">Okres trwania dofinansowania</label>
              <div className="flex gap-spacing-xl">
                <div className="flex flex-col gap-spacing-md w-36">
                  <label className="text-sm font-medium text-grayLight-700">Data od</label>
                  <Input
                    value={coFinancing?.fromDate}
                    placeholder="Wybierz datę"
                    isRequired={true}
                    type="date"
                    lang="pl"
                    required
                    onChange={(e) => {
                      setCoFinancing((prev) => ({...prev, fromDate: e.target.value}))
                    }}
                  />
                </div>
                <div className="flex flex-col gap-spacing-md w-36">
                  <label className="text-sm font-medium text-grayLight-700">Data do</label>
                  <Input
                    value={coFinancing?.toDate}
                    placeholder="Wybierz datę"
                    isRequired={true}
                    type="date"
                    lang="pl"
                    required
                    onChange={(e) => {
                      setCoFinancing((prev) => ({...prev, toDate: e.target.value}))
                    }}
                  />
                </div>
              </div>
            </div>
            <CustomInput
              type="default"
              size="md"
              destructive={false}
              label="Wartość dofinansowania"
              inputParams={{
                value: coFinancing?.coFinancingAmountPerDay,
                required: true,
                placeholder: "0,00 zł",
                step: 0.01,
                type: "number",
                min: 0,
                onChange: handleAmountChange,
              }}
            />


            <div className="flex flex-col gap-spacing-md">
              <label className="text-sm font-medium text-grayLight-700">Stawka</label>
              <Select
                colorScheme="gray"
                placeholder="Wybierz"
                required
                value={({
                  value: rates.find(item => item.id === coFinancing.stakeId)?.id,
                  label: rates.find(item => item.id === coFinancing.stakeId)?.name
                })}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setCoFinancing((prev) => ({
                      ...prev,
                      stakeId: selectedOption.value || 0
                    }));
                  }
                }}
                options={rates.map((unit) => ({
                  ...unit,
                  label: unit.name,
                  value: unit.id,
                }))}
              />
            </div>
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: onClose
              }}
            >
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                type: "submit"
              }}
            >
              {mode === "add" ? "Dodaj" : "Zapisz"}
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddEditCoFinancing;
