import React from 'react';

const ArrowIcon = ({ className }: { className: string }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.99998 1.33301V10.6663M5.99998 10.6663L10.6666 5.99967M5.99998 10.6663L1.33331 5.99967"
      stroke="#475467"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowIcon;
