import React, { useEffect } from 'react';
import './Branches.css';
import { Route, Routes, useParams } from "react-router-dom";
import { useSideNavContext } from '../../../context/SideNavProvider';
import { SideMenuOptionInterface } from '../../../shared';
import Absences from "../../TenantAdminComponents/Absences/Absences";
import Kitchen from '../../TenantAdminComponents/Kitchen/Kitchen';
import MailBox from "../../TenantAdminComponents/MailBox/MailBox";
import SingleAdminMessage from "../../TenantAdminComponents/MailBox/Messages/SingleAdminMessage/SingleAdminMessage";
import Reports from "../../TenantAdminComponents/Reports/Reports";
import School from '../../TenantAdminComponents/School/School';
import Users from "../../TenantAdminComponents/Users/Users";
import BranchesTable from './BranchesTable/BranchesTable';
import SingleBranch from './SingleBranch/SingleBranch';

const Branches = () => {
  const { setNavOptions } = useSideNavContext();
  const { id: tenant } = useParams();

  const menuOptions: SideMenuOptionInterface[] = [
    {
      header: 'Strona Główna',
      icon: 'happyHomeIcon',
      link: 'test'
    },
    {
      header: 'Szkoła',
      icon: 'graduationHat',
      children: [
        {
          header: 'Dane oddziału',
          link: `branches/id/${tenant}`
        },
        {
          header: 'Klasy',
          link: `branches/id/${tenant}/school/classes`
        },
        {
          header: 'Dni wolne / pracujące',
          link: `branches/id/${tenant}/school/free-days`
        },
        {
           header: 'Regulaminy',
           link: `branches/id/${tenant}/school/school-statutes`
        },
        {
          header: 'MOPR / MOPS / OPS',
          link: `branches/id/${tenant}/school/co-financing-institutions`
        },
        {
          header: 'Ustawienia',
          link: `branches/id/${tenant}/school/school-settings`
        }

      ]
    },
    {
      header: 'Kuchnia',
      icon: 'cutlery',
      children: [
        {
          header: 'System sprzedaży',
          link: `branches/id/${tenant}/kitchen/sales-system/servings`
        },
        {
          header: 'Alergeny',
          link: `branches/id/${tenant}/kitchen/allergens`
        },
        {
          header: 'Jadłospis',
          link: `branches/id/${tenant}/kitchen/food-menu/meal-plan`
        }
      ]
    },
    {
      header: 'Nieobecności',
      icon: 'slashCircle',
      link: `branches/id/${tenant}/absences`
    },
    {
      header: 'Użytkownicy',
      icon: 'users',
      children: [
        {
          header: 'Dzieci',
          link: `branches/id/${tenant}/users/children/children-table`
        },
        {
          header: 'Rodzice',
          link: `branches/id/${tenant}/users/parents/parents-table`
        },
        {
          header: 'Nauczyciele',
          link: `branches/id/${tenant}/users/teachers/teachers-table`
        },
        {
          header: 'Pracownicy',
          link: `branches/id/${tenant}/users/employees/employees-table`
        },
        {
          header: 'Administratorzy ',
          link: `branches/id/${tenant}/users/administrators/administrators-table`
        }
      ]
    },
    {
      header: 'Raporty',
      icon: 'file',
      link: `branches/id/${tenant}/reports`
    },
    {
      header: 'Wiadomości',
      icon: 'message',
      link: `branches/id/${tenant}/mail-box/messages`
    }
  ];

  useEffect(() => {
    setNavOptions(menuOptions);
  },[tenant]);

  return (
    <section className="Branches h-screen bg-neutral-50">
      <Routes>
        <Route path="/" element={<BranchesTable />} />
        <Route path="/id/:id" element={<SingleBranch />} />
        <Route path="/id/:id/kitchen/*" element={<Kitchen />} />
        <Route path="/id/:id/school/*" element={<School />} />
        <Route path="/id/:id/absences/*" element={<Absences/>} />
        <Route path="/id/:id/users/*" element={<Users/>} />
        <Route path="/id/:id/users/*" element={<Reports/>} />
        <Route path="/id/:id/mail-box/messages/message/:id/*" element={<SingleAdminMessage />} />
        <Route path="/id/:id/mail-box/*" element={<MailBox/>} />
      </Routes>
    </section>
  );
};
export default Branches;
