import React, { ReactNode, useMemo, useState, createContext, useContext } from 'react';
import CustomAlertDialogComponent from "../component/CustomAlertDialogComponent/CustomAlertDialogComponent";
import { CustomAlertDialogInterface } from "../shared/type/CustomAlertDialog.type";

interface Props {
  children: ReactNode;
}

interface AlertContextProps {
  setAlertProperties: React.Dispatch<React.SetStateAction<CustomAlertDialogInterface>>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined);

export const useAlertContext = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlertContext must be used within an AlertProvider');
  }
  return context;
};

export const AlertProvider = ({ children }: Props) => {
  const [alertProperties, setAlertProperties] = useState<CustomAlertDialogInterface>({
    title: 'Alert Title',
    description: 'This is an alert description',
    status: 'success',
    timeout: 5000
  });

  const [show, setShow] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      setAlertProperties,
      setShow
    }),
    []
  );

  return (
    <AlertContext.Provider value={contextValue}>
      {show ? (
        <CustomAlertDialogComponent
          onClose={() => {setShow(false)}}
          title={alertProperties.title}
          description={alertProperties.description}
          status={alertProperties.status}
          timeout={alertProperties.timeout}
        />
      ) : null}
      {children}
    </AlertContext.Provider>
  );
};
