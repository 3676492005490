import { ModalBody, Divider } from "@chakra-ui/react";
import { AxiosResponse, HttpStatusCode } from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../../../../context/ApiProvider";
import { useTenantAdminBasketContext } from "../../../../../../../../../context/TenantAdminBasketProvider";
import { ParentWithKidInterface } from "../../../../../../../../../shared/type/parent.type";
import calculatePrice from "../../../../../../../../../utils/calculatePrice";
import CustomChakraButton from "../../../../../../../../CustomChakraButton/CustomChakraButton";
import StickyNotes from "../../../../../../../../StickyNotes/StickyNotes";



interface Props {
  onClose: () => void;
}

const AdminCashOrderSummary = ({ onClose }: Props ) => {
  const { orderSummary, resetBasket } = useTenantAdminBasketContext();
  const { apiTenantParentController, apiTenantAdminPaymentController } = useApi();
  const { id: tenantId, parentId }  = useParams<{ id: string, parentId: string }>();
  const { setShow: setShowAlert, setAlertProperties} = useAlertContext();

  const [ parentWithKids, setParentWithKids ] = useState<ParentWithKidInterface>();

  const findUserName = (consumerId: number): { firstName: string; lastName: string } => {
    const consumer = parentWithKids?.consumers
      .find((item) => item.id === consumerId);

    return {
      firstName: consumer?.firstName || "",
      lastName: consumer?.lastName || "",
    };
  };

  const fetchKids = async () => {
    try {
      const response: AxiosResponse<ParentWithKidInterface> = await apiTenantParentController('details').get(`/${tenantId}/${parentId}`);
      setParentWithKids(response.data)
    } catch (error) {
      console.error(error)
    }
  };

  const confirmOrder = async () => {
    try {
      const response = await apiTenantAdminPaymentController('confirm-cash-order').post('',{
        parentId: Number(parentId),
        orderIds: orderSummary?.placedOrders.map(order => order.id)
      });
      if(response.status === HttpStatusCode.Ok){
        setAlertProperties({
          timeout: 2000,
          title: 'Sukces',
          description: 'Poprawnie złożono zamówienie',
          status: 'success'
        })
        onClose();
        resetBasket();
      }
    } catch (error: any) {
      const responseError = error.response.data.error
      setAlertProperties({
        timeout: 5000,
        title: 'Błąd',
        description: `Błąd składania zamówienia. ${responseError}`,
        status: 'error'
      })
    } finally {
      setShowAlert(true);
    }
  }

  useEffect(() => {
    fetchKids();
    console.log(orderSummary)
  }, []);

  if (!orderSummary || !orderSummary.placedOrders) {
    return (
      <section className="OrderSummary w-full flex flex-col justify-start">
        <div className='flex flex-col gap-spacing-lg pt-spacing-md pr-spacing-3xl pb-spacing-3xl pl-spacing-3xl'>
          <p className='font-semibold text-2xl text-grayLight-900'>Podsumowanie zamówienia</p>
          <p className='font-normal text-grayLight-600' style={{ fontSize: '16px' }}>
            Sprawdź czy ilość zamówionych posiłków i wybrane daty się zgadzają. Następnie przejdź do płatności.
          </p>
        </div>
        <div className='flex flex-grow pr-spacing-3xl pl-spacing-3xl gap-spacing-3xl pb-spacing-5xl'>
          <div className='flex flex-col basis-4/6 rounded-2xl bg-white p-spacing-3xl gap-spacing-3xl'>
            <p>Koszyk jest pusty.</p>
          </div>
        </div>
      </section>
    );
  }

  return(
    <ModalBody
      className="flex gap-spacing-xl"
      sx={{
        overflowY: "auto",
        maxHeight: "calc(100vh - 300px)"
      }}
    >
      <section className="OrderSummary w-full flex flex-col justify-start bg-grayLight-50">
        <div className='flex flex-col gap-spacing-lg pt-spacing-md pr-spacing-3xl pb-spacing-3xl pl-spacing-3xl'>
          <p className='font-semibold text-2xl text-grayLight-900'>Podsumowanie zamówienia</p>
          <p className='font-normal text-grayLight-600' style={{ fontSize: '16px' }}>
            Sprawdź czy ilość zamówionych posiłków i wybrane daty się zgadzają. Następnie przejdź do płatności.
          </p>
        </div>
        <div className='flex flex-grow pr-spacing-3xl pl-spacing-3xl gap-spacing-3xl pb-spacing-5xl'>
          {/* Sekcja główna z zamówieniami */}
          <div className='flex flex-col basis-4/6 rounded-2xl bg-white p-spacing-3xl gap-spacing-3xl'>
            {orderSummary.placedOrders.map((placedOrder) => (
              <div key={placedOrder.consumerId} className='flex flex-col gap-spacing-2xl'>
                <div
                  className='flex items-center justify-between pt-spacing-md pb-spacing-md border-b box-border border-brand-300'>
                  <div>
                    <p className="font-medium text-grayLight-700" style={{ fontSize: '16px' }}>
                      {findUserName(placedOrder.consumerId).firstName} {findUserName(placedOrder.consumerId).lastName}
                    </p>
                  </div>
                  <div className='flex items-center gap-spacing-sm'>
                    <p className='text-sm font-medium text-grayLight-700'>suma:</p>
                    <p className='font-semibold text-grayLight-900' style={{ fontSize: '16px' }}>
                      {calculatePrice(placedOrder.creditPaymentCalculation?.basicToPay,null) || '0.00'} zł
                    </p>
                  </div>
                </div>
                <div className='flex flex-col gap-spacing-md'>
                  {(placedOrder.orderErrors || []).map((error, index) => (
                    <StickyNotes key={index} message={error} />
                  ))}
                </div>
                {(placedOrder.orderedDays || []).map((orderDay, index) => {
                  const date = new Date(orderDay.forWhen);
                  const dayOfWeek = date.toLocaleDateString('pl-PL', { weekday: 'long' });
                  const capitalizedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);

                  return (
                    <div key={index} className="flex flex-col gap-spacing-md">
                      <div>
                        <p
                          className="text-sm font-normal text-grayLight-700">{capitalizedDayOfWeek} ({orderDay.forWhen})</p>
                      </div>
                      <div className='flex flex-col gap-spacing-xs'>
                        {orderDay.positions.map((position, index) => (
                          <React.Fragment key={index}>
                            <div className='flex justify-between'>
                              <p>{position.count}x {position.name}</p>
                              <div>
                                <p>{calculatePrice(position.price,'zł')}</p>
                              </div>
                            </div>
                            {index < orderDay.positions.length - 1 && (
                              <Divider borderColor="gray.300" my={2} />
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  )
                })}
              </div>
            ))}
          </div>

          {/* Sekcja podsumowania zamówienia */}
          <div className="flex flex-col justify-between basis-2/6 rounded-2xl bg-white">
            <div className='flex flex-col'>
              {orderSummary.placedOrders.map((order, index) => (
                <div key={index} className='flex flex-col p-spacing-3xl gap-spacing-xl'>
                  <div>
                    <p className='font-semibold text-lg text-grayLight-800'>
                      {findUserName(order.consumerId).firstName} {findUserName(order.consumerId).lastName}
                    </p>
                  </div>
                  <div className='flex flex-col gap-spacing-xl'>
                    <div className="flex flex-col gap-spacing-xl">
                      <div className="flex">
                        <div className="basis-1/3">
                          <p className="text-sm font-medium text-grayLight-700">Zamówienie:</p>
                        </div>
                        <div className="basis-1/3">
                          <p className="text-lg font-medium text-grayLight-700">
                            {calculatePrice(order.creditPaymentCalculation?.basicCost,null) || '0.00'} zł
                          </p>
                        </div>
                        <div className="basis-1/3"></div>
                      </div>
                    </div>
                    <Divider />
                  </div>
                </div>
              ))}
            </div>

            {/* Podsumowanie końcowe */}
            <div className="flex flex-col p-spacing-3xl gap-spacing-xl">
              <div className="flex flex-col gap-spacing-xl">
                <div className='flex justify-between'>
                  <p className='text-xl font-semibold text-grayLight-900'>Wartość zamówienia</p>
                  <p className='text-xl font-semibold text-grayLight-900'>
                    {calculatePrice(orderSummary.creditPaymentCalculation.basicToPay, 'zł')}
                  </p>
                </div>
              </div>
              <div className='flex flex-col gap-spacing-md'>
                <CustomChakraButton
                  size="2xl"
                  hierarchy="primary"
                  iconPosition="onlyText"
                  buttonProps={{
                    type: 'button',
                    disabled: orderSummary.placedOrders.length === 0,
                    onClick: confirmOrder
                  }}
                >
                  Potwierdź zamówienie
                </CustomChakraButton>
                <p className='font-normal text-grayLight-600' style={{ fontSize: '16px' }}>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ModalBody>
)
};

export default AdminCashOrderSummary;