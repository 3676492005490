import React from 'react';
import { useSettingFormContext } from '../../../../../../context/SettingsFormContext';
import CustomInput from '../../../../../CustomInput/CustomInput';
import StickyNotes from '../../../../../StickyNotes/StickyNotes';
import CheckboxInput from '../../../../../Forms/CheckboxInput/CheckboxInput';

const AbsenceReportingOptions = () => {
  const { settingsForm, updateForm } = useSettingFormContext();

  return (
    <div className="grow flex flex-col gap-spacing-lg">
      <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
        Ustawienia zgłaszania nieobecności
      </div>
      <div className="flex flex-col gap-spacing-4xl">
        <div className="w-full flex flex-col gap-spacing-lg">
          <div className="Setting__sectionHeader">
            <p>Zgłaszanie nieobecności</p>
            <p>Określ do kiedy Użytkownik może zgłaszać nieobecności:</p>
          </div>
          <div className="flex flex-col gap-spacing-3xl">
            <div className="flex">
              <div className="max-w-80">
                <CustomInput
                  destructive
                  type="default"
                  size="sm"
                  label="W dniu wydawki do godziny:"
                  inputParams={{
                    value: `${settingsForm?.absenceReportHour?.toString().padStart(2, '0')}:${settingsForm?.absenceReportMinute?.toString().padStart(2, '0')}`,
                    name: 'absenceReportHourLimit',
                    onChange: (e) => {
                      const [hours, minutes] = e.target.value.split(':');
                      e.target.name = 'absenceReportHour';
                      updateForm(e.target.name, Number(hours));
                      e.target.name = 'absenceReportMinute';
                      updateForm(e.target.name, Number(minutes));
                    },
                    placeholder: '1',
                    type: 'time',
                    min: 1,
                  }}
                />
              </div>
              <div className="flex-1"></div>
            </div>
            <div className="flex">
              <div className="flex-1">
                <div className="max-w-80">
                  <CustomInput
                    destructive
                    type="default"
                    size="sm"
                    label="Liczba godzin przed wydawką, do której można zgłosić nieobecność:"
                    inputParams={{
                      value: settingsForm?.absenceReportHourLimit,
                      name: 'absenceReportHourLimit',
                      onChange: (e) => {
                        const { name, value } = e.target;
                        updateForm(name, value);
                      },
                      placeholder: '1',
                      type: 'number',
                      min: 1,
                      max: 31
                    }}
                  />
                </div>
              </div>
              <div className="flex-1">
                <StickyNotes message="W przypadku braku zwrotów za nieobecność ustaw wartość 0." />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-spacing-4xl">
        <div className="w-full flex flex-col gap-spacing-lg">
          <div className="Setting__sectionHeader">
            <p>Zgłaszanie nieobecności</p>
            <p>Ustaw wartość zwrotów na saldo za nieobecność dziecka.</p>
          </div>
          <div className="flex flex-row gap-spacing-3xl">
            <div className="max-w-80">
              <CustomInput
                destructive={false}
                type="default"
                size="sm"
                label="Zwrot wartości w %:"
                inputParams={{
                  value: settingsForm?.absenceReturnPercentage,
                  name: 'absenceReturnPercentage',
                  onChange: (e) => {
                    const { name, value } = e.target;
                    updateForm(name, value);
                  },
                  placeholder: '0',
                  type: 'number',
                  min: 0,
                  max: 100
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-spacing-lg">
          <div className="Setting__sectionHeader">
            <p>Czas do zgłaszania nieobecności nie obejmuje weekendów</p>
            <p>Opcja działa w ten sposób, że jeśli jest włączona i godziny zgłaszania nieobecności ustawiono na 24h przed godziną 10.00, to w przypadku “poniedziałku” zgłaszanie nieobecności jest możliwe tylko do 10 rano w piątek.</p>
          </div>
          <div className="flex flex-row gap-spacing-3xl">
            <CheckboxInput
              text="Czas zgłaszania nieobecności tylko w dni robocze."
              textSize="lg"
              checkboxParams={{
                isChecked: settingsForm?.absenceReportSkipWeekend,
                name: 'absenceReportSkipWeekend',
                onChange: (e) => {
                  updateForm(e.target.name, !settingsForm?.absenceReportSkipWeekend);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AbsenceReportingOptions;
