import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAlertContext } from "../../../../context/AlertProvider";
import { useApi } from "../../../../context/ApiProvider";
import { MessageInterface } from "../../../../shared/type/message.type";
import { PaginationInterface } from "../../../../shared/type/paginationInterface.type";
import CustomAlert from "../../../CustomAlert/CustomAlert";
import IconComponent from "../../../IconComponent/IconComponent";

interface Props {
  tenantId: string;
  setRefreshMessages: React.Dispatch<React.SetStateAction<() => void>>;
}

const defaultDeleteMessagePopUpContext = {
  isOpen: false,
  threadId: 0
}

const ParentMessage = ({ tenantId, setRefreshMessages }: Props) => {
  const { apiParentMessageController } = useApi();
  const { setShow, setAlertProperties } = useAlertContext();
  const [messages, setMessages] = useState<MessageInterface[]>([]);
  const [deleteMessagePopUp, setDeleteMessagePopUp] = useState<{
    isOpen: boolean;
    threadId: number;
  }>(defaultDeleteMessagePopUpContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [actualPage, setActualPage] = useState<PaginationInterface>({
    totalCount: 0,
    hasNextPage: false,
    next: {
      limit: 0,
      offset: 0
    }
  });

  const fetchMessages = async (pageNumber: number) => {
    try {
      const response = await apiParentMessageController('thread-list').get(`/${tenantId}`, {
        params: {
          pageNumber: pageNumber,
          pageSize: 10
        }
      })

      const { data, next, hasNextPage, totalCount } = response.data;

      setActualPage({ next, hasNextPage, totalCount });
      setMessages(data);

    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  }

  useEffect(() => {
    fetchMessages(currentPage);
  }, [tenantId, currentPage]);

  useEffect(() => {
    setRefreshMessages(() => () => fetchMessages(currentPage));
  }, [fetchMessages, currentPage, setRefreshMessages]);

  const goToNextPage = () => {
    if (actualPage.hasNextPage) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now.getTime() - date.getTime();

    if (timeDiff < 24 * 60 * 60 * 1000) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
      return date.toLocaleDateString('pl-PL');
    }
  };

  return (
    <div className="ParentMessage flex flex-col">
      <div className='pt-spacing-md pr-spacing-4xl pb-spacing-md pl-spacing-4xl'>
        <div className='flex justify-end'>
          <div className='flex gap-spacing-lg'>
            <p className='text-sm font-medium text-grayLight-700'>
              Strona {currentPage} z {Math.ceil(actualPage.totalCount / 10)}
            </p>
            <div className='flex gap-spacing-lg'>
              <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                <IconComponent iconName='chevronLeft' className='h-5 w-5' />
              </button>
              <button onClick={goToNextPage} disabled={!actualPage.hasNextPage}>
                <IconComponent iconName='chevronRight' className='h-5 w-5' />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        {messages
          .sort((a, b) => new Date(b.lastUpdate).getTime() - new Date(a.lastUpdate).getTime())
          .map((message, messageIndex) => (
          <button
            key={`Message-${messageIndex}`}
            className={`flex pt-spacing-lg pr-spacing-4xl pb-spacing-lg pl-spacing-4xl hover:bg-brand-100 ${!message.hasBeanRead && 'bg-grayLight-100'}`}>
            <Link to={`message/${message.id}?title=${encodeURIComponent(message.topic)}`} className='basis-3/12 flex items-center gap-spacing-lg'>
              <p className={`text-sm font-${message.hasBeanRead ? 'normal' : 'semibold'} text-grayLight-700`}>
                {message.topic}
              </p>
            </Link>
            <div className='basis-6/12'></div>
            <div className="basis-3/12 flex items-center justify-center group">
              <p className="group-hover:hidden">{formatDate(message.lastUpdate)}</p>
              <button onClick={() => { setDeleteMessagePopUp({ isOpen: true, threadId: message.id }) }}>
                <IconComponent color='#171412' iconName="trash" className="hidden group-hover:block" />
              </button>
            </div>
          </button>
        ))}
      </div>
      <CustomAlert
        handleOpen={deleteMessagePopUp.isOpen}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
            <IconComponent iconName="trash" color='#D92D20' />
          </div>
        }
        header={`Usunąć wiadomość?`}
        content="Usunięcie wiadomości nieodwracalne."
        cancelButtonText="Anuluj"
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        confirmButtonText="Usuń"
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'warning'
        }}
        onCancelAction={() => {
          setDeleteMessagePopUp(defaultDeleteMessagePopUpContext);
        }}
        onConfirmAction={async () => {
          try {
            await apiParentMessageController('thread').delete(``, {
              params: {
                threadId: deleteMessagePopUp.threadId
              }
            });
            setAlertProperties({
              status: 'success',
              timeout: 5000,
              description: 'Wiadomość usunięta',
              title: 'Sukces'
            });
            setShow(true);
          } catch (error) {
            setAlertProperties({
              status: 'error',
              timeout: 5000,
              description: 'Nie udało się usunąć wiadomości',
              title: 'Błąd'
            });
            setShow(true);
          } finally {
            fetchMessages(currentPage);
            setDeleteMessagePopUp(defaultDeleteMessagePopUpContext);
          }
        }}
      />
    </div>
  )
};

export default ParentMessage;