import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavOptionsInterface } from '../../shared/type/navOptions.type';

interface Props {
  navOptions: NavOptionsInterface[];
}

const Navigation = ({ navOptions }: Props) => {
  const location = useLocation();

  const isLinkActive = (url: string): boolean => location.pathname.includes(url);

  const activeClass = 'border-b-2 border-brand-600 bg-brand-50';

  const navigationOptions = navOptions.map((option) => (
    <Link
      key={option.link}
      to={option.link}
      className={
        'p-spacing-lg text-grayLight-500 box-border' +
        ` ${isLinkActive(option.link) ? activeClass : null}`
      }
    >
      {option.name}
    </Link>
  ));

  return (
    <nav className="Navigation w-full font-semibold text-sm border-b border-grayLight-200 overflow-x-scroll lg:overflow-hidden">
      <div className="Navigation__optionContainer pl-spacing-3xl pr-spacing-3xl grid grid-flow-col auto-cols-max gap-spacing-md">
        {navigationOptions}
      </div>
    </nav>
  );
};

export default Navigation;
