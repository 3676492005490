import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useApi } from "../../../../../context/ApiProvider";
import {
  AnnouncementInterfaceWIthPagination,
  AnnouncementInterface
} from "../../../../../shared/type/announcement.interface";
import IconComponent from "../../../../IconComponent/IconComponent";

const SingleNotification = () => {
  const { apiParentMessageController } = useApi();
  const { id: announcementId } = useParams();
  const [ announcement, setAnnouncement ] = useState<AnnouncementInterface | undefined>(undefined);


  const fetchAnnouncements = async () => {
    try {
      const response: AxiosResponse<AnnouncementInterfaceWIthPagination> = await apiParentMessageController('announcement-list').get('', {
        params: {
          tenantId: 1,
          pageNumber: 1,
          pageSize: 1000
        }
      });

      const { data } = response.data;

      setAnnouncement(data[0]);

    } catch (error) {
      console.error("Error fetching announcements:", error);
    }
  }

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  return (
    <section className="flex flex-col h-full gap-spacing-xl w-full pb-20">
      <div className='pt-spacing-md pr-spacing-3xl pb-spacing-md pl-spacing-3xl gap-spacing-md'>
        <Link to='/parent/mail-box/messages'
              className='flex items-center gap-spacing-sm pt-2.5 pr-spacing-xl pb-2.5 pl-spacing-xl'>
          <IconComponent iconName='arrowLeft' />
          <p style={{ fontSize: '16px' }} className='font-semibold text-grayWarm-950'>Wróc {announcementId}</p>
        </Link>
        <h2 className='text-2xl font-semibold text-grayLight-900'>{announcement?.topic}</h2>
      </div>

      <div className='pr-spacing-7xl pl-spacing-7xl flex-grow overflow-y-auto'>

      </div>
    </section>

  )
};

export default SingleNotification;