import React from 'react';
import './Settings.css';
import { SettingsFormProvider } from '../../../../context/SettingsFormContext';
import SettingsForm from './SettingsForm/SettingsForm';

const Settings = () => {
  return (
    <>
      <SettingsFormProvider>
        <SettingsForm />
      </SettingsFormProvider>
    </>
  );
};

export default Settings;
