import React from 'react';

const XCircleIcon = ({ className, color }: { className: string, color: string }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clip-path="url(#clip0_6116_4130)">
      <path
        d="M7.5 4.5L4.5 7.5M4.5 4.5L7.5 7.5M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
        stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_6116_4130">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default XCircleIcon;
