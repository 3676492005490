import { useApi } from '../context/ApiProvider';
import useAuth from './useAuth';

const useRefreshToken = () => {
  const authContext = useAuth();
  const { apiPublicSecurityController } = useApi();

  return async () => {
    if (!authContext) return null;

    const token = localStorage.getItem('jwsToken');
    if (!token) {
      console.error('No token found in localStorage');
      return null;
    }

    try {
      const result = await apiPublicSecurityController('token-login').post('', null, {
        params: { token }
      });
      const { loggedUserDto, availableTenants, enforceEmailUpdate, jwToken } = result.data;
      const {
        id,
        login,
        firstName,
        lastName,
        availableTenantsIds,
        availableModules,
        roleName,
        admin,
        childOwner,
        tenantAdmin,
        superAdmin
      } = loggedUserDto;
      const { jwToken: tokenValue, expiresIn } = jwToken;

      authContext.updateAuth({
        loggedUserDto: {
          id,
          login,
          firstName,
          lastName,
          availableTenantsIds,
          availableModules,
          roleName,
          admin,
          childOwner,
          tenantAdmin,
          superAdmin
        },
        availableTenants,
        enforceEmailUpdate,
        jwToken: {
          jwToken: tokenValue,
          expiresIn
        }
      });

      return tokenValue;
    } catch (error) {
      console.error('Error refreshing token', error);
      return null;
    }
  };
};

export default useRefreshToken;
