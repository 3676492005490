import { Switch } from '@chakra-ui/react';
import React from 'react';
import { useSettingFormContext } from '../../../../../../context/SettingsFormContext';

const OtherSettings = () => {
  const { settingsForm, updateForm } = useSettingFormContext();

  return (
    <div className="grow flex flex-col gap-spacing-lg">
      <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
        Inne ustawienia
      </div>
      <div className="flex flex-col gap-spacing-4xl">
        <div className="w-full flex flex-row gap-spacing-lg justify-between">
          <div className="Setting__sectionHeader">
            <p>Wymagany adres email Rodzica</p>
            <p>Przy logowaniu tymczasowym Rodzic musi podać adres email</p>
          </div>
          <div className="flex gap-spacing-3xl">
            <Switch
              size="lg"
              colorScheme="customOrange"
              name="enforceEmail"
              isChecked={settingsForm?.enforceEmail}
              onChange={(e) => {
                updateForm(e.target.name, !settingsForm?.enforceEmail);
              }}
            />
          </div>
        </div>
        <div className="w-full flex flex-row gap-spacing-lg justify-between">
          <div className="Setting__sectionHeader">
            <p>Wymagany numer breloka</p>
            <p>
              Przy logowaniu Rodzic musi podać numer breloka dziecka, jeśli dziecko nie ma
              określonego numeru breloka
            </p>
          </div>
          <div className="flex gap-spacing-3xl">
            <Switch
              size="lg"
              colorScheme="customOrange"
              name="enforceCardUpdate"
              isChecked={settingsForm?.enforceCardUpdate}
              onChange={(e) => {
                updateForm(e.target.name, !settingsForm?.enforceCardUpdate);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherSettings;
