import React from 'react';

const GraduationHatIcon = ({ className }: { className: string }) => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16 12.4996V9.494C16 9.31452 16 9.22479 15.9727 9.14556C15.9485 9.0755 15.9091 9.01168 15.8572 8.95873C15.7986 8.89886 15.7183 8.85873 15.5578 8.77846L11 6.49958M3 7.49958V14.3062C3 14.6781 3 14.8641 3.05802 15.0269C3.10931 15.1708 3.1929 15.3011 3.30238 15.4077C3.42622 15.5283 3.59527 15.6057 3.93335 15.7607L10.3334 18.694C10.5786 18.8064 10.7012 18.8626 10.8289 18.8848C10.9421 18.9045 11.0579 18.9045 11.1711 18.8848C11.2988 18.8626 11.4214 18.8064 11.6666 18.694L18.0666 15.7607C18.4047 15.6057 18.5738 15.5283 18.6976 15.4077C18.8071 15.3011 18.8907 15.1708 18.942 15.0269C19 14.8641 19 14.6781 19 14.3062V7.49958M1 6.49958L10.6422 1.67846C10.7734 1.61287 10.839 1.58008 10.9078 1.56717C10.9687 1.55574 11.0313 1.55574 11.0922 1.56717C11.161 1.58008 11.2266 1.61287 11.3578 1.67846L21 6.49958L11.3578 11.3207C11.2266 11.3863 11.161 11.4191 11.0922 11.432C11.0313 11.4434 10.9687 11.4434 10.9078 11.432C10.839 11.4191 10.7734 11.3863 10.6422 11.3207L1 6.49958Z"
      stroke="#667085"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GraduationHatIcon;
