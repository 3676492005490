import React, { useEffect } from "react";
import { useParams, Link, Routes, Route } from "react-router-dom";
import { useEditParentContext } from "../../../../../../context/EditParentProvider";
import useAuth from "../../../../../../hooks/useAuth";
import { NavOptionsWithElementInterface } from "../../../../../../shared/type/navOptions.type";
import Breadcrumbs from "../../../../../Breadcrumbs/Breadcrumbs";
import IconComponent from "../../../../../IconComponent/IconComponent";
import Navigation from "../../../../../Navigation/Navigation";
import ListOfOrder from "./ListOfOrder/ListOfOrder";
import ParentData from "./ParentData/ParentData";
import ParentKinds from "./ParentKinds/ParentKinds";

const SingleParent = () => {
  const { setParentId } = useEditParentContext();
  const { parent } = useEditParentContext();
  const { auth } = useAuth();
  const { id: tenantId, parentId } = useParams<{ parentId: string, id: string }>();

  useEffect(() => {
    setParentId(Number(parentId));
  }, [tenantId, parentId]);

  const navOptions: NavOptionsWithElementInterface[] = [
    {
      link: 'list-of-orders',
      name: 'Lista zamówień',
      component: <ListOfOrder />
    },
    {
      link: 'parent-kinds',
      name: 'Dzieci',
      component: <ParentKinds />
    },
    {
      link: 'parent-data',
      name: 'Dane użytkownika',
      component: <ParentData />
    }]

  return (
    <section className="SingleCompany">
      <div className="SingleCompany__container pt-spacing-xl pr-spacing-3xl pb-spacing-xl pl-spacing-3xl gap-spacing-4xl flex flex-col">
        <div className="SingleCompany__actualCompanyStatus">
          <Breadcrumbs
            divider={<IconComponent iconName="chevronRight" />}
            elements={[
              <IconComponent iconName="home" />,
              <p className="font-medium text-sm text-grayLight-600">Rodzice</p>,
              <p className="font-semibold text-sm text-grayWarm-800">{parent?.firstName} {parent?.lastName}</p>
            ]}
          />
        </div>
        <div className="SingleCompany__container flex flex-col gap-spacing-4xl">
          <Link to={auth.loggedUserDto.superAdmin ? `/superAdmin/branches/id/${tenantId}/users/parents/parents-table` : `/tenantAdmin/id/${tenantId}/users/parents/parents-table`} className="SingleCompany__backButtonContainer flex gap-spacing-md font-semibold text-base text-grayWarm-950">
            <IconComponent iconName="arrowLeft" />
            <p>Wróć</p>
          </Link>
          <div className="SingleCompany__contextContainer flex flex-col gap-spacing-xl">
            <Navigation navOptions={navOptions} />
            <Routes>
              {navOptions.map((item) => (
                <Route key={item.link} path={`/${item.link}`} element={item.component} />
              ))}
            </Routes>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleParent;