import React from 'react';

const CopyIcon = ({ className, color }: { className: string, color: string }) => (
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
  <g clipPath="url(#clip0_3840_47550)">
    <path
      d="M13.3327 13.3332V15.6665C13.3327 16.5999 13.3327 17.0666 13.151 17.4232C12.9912 17.7368 12.7363 17.9917 12.4227 18.1515C12.0661 18.3332 11.5994 18.3332 10.666 18.3332H4.33268C3.39926 18.3332 2.93255 18.3332 2.57603 18.1515C2.26243 17.9917 2.00746 17.7368 1.84767 17.4232C1.66602 17.0666 1.66602 16.5999 1.66602 15.6665V9.33317C1.66602 8.39975 1.66602 7.93304 1.84767 7.57652C2.00746 7.26292 2.26243 7.00795 2.57603 6.84816C2.93255 6.6665 3.39926 6.6665 4.33268 6.6665H6.66602M9.33268 13.3332H15.666C16.5994 13.3332 17.0661 13.3332 17.4227 13.1515C17.7363 12.9917 17.9912 12.7368 18.151 12.4232C18.3327 12.0666 18.3327 11.5999 18.3327 10.6665V4.33317C18.3327 3.39975 18.3327 2.93304 18.151 2.57652C17.9912 2.26292 17.7363 2.00795 17.4227 1.84816C17.0661 1.6665 16.5994 1.6665 15.666 1.6665H9.33268C8.39926 1.6665 7.93255 1.6665 7.57603 1.84816C7.26243 2.00795 7.00746 2.26292 6.84767 2.57652C6.66602 2.93304 6.66602 3.39975 6.66602 4.33317V10.6665C6.66602 11.5999 6.66602 12.0666 6.84767 12.4232C7.00746 12.7368 7.26243 12.9917 7.57603 13.1515C7.93255 13.3332 8.39926 13.3332 9.33268 13.3332Z"
      stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
  </g>
  <defs>
    <clipPath id="clip0_3840_47550">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</svg>
);
export default CopyIcon;
