import { TableContainer, Thead, Th, Box, Text, Tr } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { AxiosResponse, HttpStatusCode } from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../context/AlertProvider";
import { useApi } from "../../../../context/ApiProvider";
import { CoFinancingInstitutionInterface } from "../../../../shared/type/coFinancingInstitution.type";
import CustomAlert from "../../../CustomAlert/CustomAlert";
import CustomChakraButton from "../../../CustomChakraButton/CustomChakraButton";
import { DataTable } from "../../../DataTable/DataTable";

import './ListOfCoFinancingInstitutions.css';
import IconComponent from "../../../IconComponent/IconComponent";
import AddEditCoFinancialInstitution from "./AddEditCoFinancialInstitution/AddEditCoFinancialInstitution";

const ListOfCoFinancingInstitutions = () => {
  const { apiTenantCoFinancingInstitutionController } = useApi();
  const columnHelper = createColumnHelper<CoFinancingInstitutionInterface>();
  const { id: tenantId } = useParams();
  const { setAlertProperties, setShow } = useAlertContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listOfCoFinancingInstitutions, setListOfCoFinancingInstitutions] = useState<CoFinancingInstitutionInterface[]>([]);
  const [showAlert, setShowAlert] = useState<{
    addEditCoFinancing: boolean;
    deleteCoFinancing: boolean;
  }>({
    addEditCoFinancing: false,
    deleteCoFinancing: false,
  });
  const [editedItem, setEditedItem] = useState<CoFinancingInstitutionInterface>();
  const [mode, setMode] = useState<'add' | 'edit'>('add');
  const [deletedElementId, setDeletedElementId] = useState<number>(0);

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => <div>{info.getValue()}</div>,
      header: 'Nazwa instytucji',
    }),
    columnHelper.accessor('nip', {
      cell: (info) => <p>{info.getValue()}</p>,
      header: 'NIP',
    }),
    columnHelper.accessor('street', {
      cell: (info) => <p>{info.getValue()}</p>,
      header: 'Ulica',
    }),
    columnHelper.accessor('postcode', {
      cell: (info) => <p>{info.getValue()}</p>,
      header: 'Kod',
    }),
    columnHelper.accessor('city', {
      cell: (info) => <p>{info.getValue()}</p>,
      header: 'Miasto',
    }),
    columnHelper.accessor('phone', {
      cell: (info) => <p>{info.getValue()}</p>,
      header: 'Telefon',
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Edycja',
      cell: (info) => {
        const item = info.row.original;

        return (
          <div className="w-16 flex gap-spacing-md">
            <button className="font-semibold text-sm text-grayLight-600 bg-transparent border-none"
                    onClick={() => {
                      setDeletedElementId(item.id);
                      setShowAlert((prev) => ({ ...prev, deleteCoFinancing: true }));
                    }}>
              Usuń
            </button>
            <button className="font-semibold text-sm text-grayLight-600 bg-transparent border-none" onClick={() => {
              setShowAlert((prev) => ({ ...prev, addEditCoFinancing: true }));
              setMode("edit");
              setEditedItem(item);
            }}>
              Edytuj
            </button>
          </div>
        );
      },
    }),
  ];

  const fetchListOfCoFinancingInstitutions = async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<CoFinancingInstitutionInterface[]> = await apiTenantCoFinancingInstitutionController('').get(`/${tenantId}`);
      setListOfCoFinancingInstitutions(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchListOfCoFinancingInstitutions();
  }, [tenantId]);

  return (
    <section className="w-full p-spacing-4xl" style={{ overflow: 'hidden' }}>
      <TableContainer h={800} overflowY="auto" overflowX="auto" className="Branches__table bg-white rounded-lg">
        <DataTable
          disableFilters={true}
          extraThead={
            <Thead>
              <Tr>
                <Th colSpan={columns.length + 1}>
                  <div className="w-full flex items-center justify-between">
                    <div className="flex flex-col basis-4/5">
                      <Box>
                        <Text
                          whiteSpace="normal"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          className="font-semibold text-base text-grayLight-900"
                        >
                          Instytucje dofinansowujące
                        </Text>
                      </Box>
                      <Box>
                        <Text
                          whiteSpace="normal"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          className="font-normal text-sm text-grayLight-700"
                        >
                          Lista dofinansowań MOPR/MOPS/OPS
                        </Text>
                      </Box>
                    </div>
                    <div className="flex gap-spacing-lg">
                      <CustomChakraButton
                        size="md"
                        hierarchy="primary"
                        iconPosition="left"
                        icon="plus"
                        buttonProps={{
                          type: 'button',
                          onClick: () => {
                            setShowAlert((prev) => ({ ...prev, addEditCoFinancing: true }));
                            setMode('add');
                          }
                        }}
                      >
                        Dodaj instytucję dofinansowującą
                      </CustomChakraButton>
                    </div>
                  </div>
                </Th>
              </Tr>
            </Thead>
          }
          columns={columns}
          isLoading={isLoading}
          data={listOfCoFinancingInstitutions}
        />
      </TableContainer>
      {showAlert.addEditCoFinancing && (
        <AddEditCoFinancialInstitution
          isOpen={showAlert.addEditCoFinancing}
          onClose={() => {
            setShowAlert((prev) => ({ ...prev, addEditCoFinancing: false }));
            fetchListOfCoFinancingInstitutions();
          }}
          mode={mode}
          initData={editedItem}
        />
      )}
      {showAlert.deleteCoFinancing && (
        <CustomAlert
          header="Usunąć instytucję?"
          content="Usunięcie instytucji jest nieodwracalne."
          confirmButton={{
            iconPosition: 'onlyText',
            size: 'lg',
            hierarchy: 'warning'
          }}
          confirmButtonText="Usuń"
          onConfirmAction={async () => {
            try {
              const response = await apiTenantCoFinancingInstitutionController('').delete(`/${tenantId}/${deletedElementId}`);
              if (response.status === HttpStatusCode.NoContent) {
                setAlertProperties({
                  timeout: 2000,
                  title: 'Sukces',
                  description: `Poprawnie usunięto instytucję`,
                  status: 'success',
                });
                setShow(true);
                setDeletedElementId(0);
                setShowAlert((prev) => ({ ...prev, deleteCoFinancing: false }));
                fetchListOfCoFinancingInstitutions();
              }
            } catch (error: any) {
              console.error(error);
              setAlertProperties({
                timeout: 2000,
                title: 'Błąd',
                description: `Nie udało się usunąć instytucji: ${error.response.data.errorMessage}`,
                status: 'error',
              });
              setShow(true);
            }
          }}
          cancelButton={{
            iconPosition: 'onlyText',
            size: 'lg',
            hierarchy: 'secondaryGray'
          }}
          cancelButtonText="Anuluj"
          onCancelAction={() => {
            setShowAlert((prev) => ({ ...prev, deleteCoFinancing: false }));
          }}
          handleOpen={showAlert.deleteCoFinancing}
          icon={
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
              <IconComponent iconName="trash" />
            </div>
          }
        />
      )}
    </section>
  );
};

export default ListOfCoFinancingInstitutions;