import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter, Input
} from "@chakra-ui/react";
import { HttpStatusCode } from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../context/ApiProvider";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import FileInput from "../../../../../FileInput/FileInput";

interface Props {
  isOpen: boolean,
  onClose: () => void,
}

const ImportMealPlanFromPdf = ({ isOpen, onClose }: Props) => {
  const { setShow, setAlertProperties } = useAlertContext();
  const { apiTenantPdfMealMenuController } = useApi();
  const { id: tenantId } = useParams<{ id: string }>()
  const [date, setDate] = useState<{ from: string, to: string }>({ from: '', to: '' });
  const [file, setFile] = useState<FileList | undefined>(undefined);

  const formatDate = (dateStr: string) => {
    const [year, month, day] = dateStr.split("-");
    return `${day}-${month}-${year}`;
  }

  const handleImport = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!file || file.length === 0) {
      return;
    }

    const formData = new FormData();
    if (file) {
      formData.append("file", file[0]);
    }

    try {
      const response = await apiTenantPdfMealMenuController('import-pdf-file').post(
        '',
        formData,
        {
          params: {
            tenantId: Number(tenantId),
            fromDate: formatDate(date.from),
            toDate: formatDate(date.to)
          }
        }
      );
      if(response.status === HttpStatusCode.Ok){
        setAlertProperties({
          timeout: 2000,
          title: "Sukces",
          description: `Poprawnie dodano jadłospis z pliku`,
          status: "success"
        });
        setShow(true);
        onClose();
      }else {
        setAlertProperties({
          timeout: 2000,
          title: "Błąd",
          description: `Nie udało się dodać jadłospis z pliku`,
          status: "error"
        });
        setShow(true);
      }
      console.log(response);
    } catch (error: any) {
      setAlertProperties({
        timeout: 2000,
        title: "Błąd",
        description: `Nie udało się dodać jadłospis z pliku: ${error.reponse}}`,
        status: "error"
      });
      setShow(true);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <form onSubmit={handleImport}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">Importuj jadłospis</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <div className="flex gap-4">
              <div className="flex flex-col gap-spacing-xs">
                <label className="text-sm font-medium text-grayLight-700">Data od</label>
                <Input
                  type="date"
                  lang="pl"
                  required
                  value={date.from}
                  name="from"
                  onChange={(e) => {
                    setDate((prev) => ({ ...prev, from: e.target.value }));
                  }}
                />
              </div>
              <div className="flex flex-col gap-spacing-xs">
                <label className="text-sm font-medium text-grayLight-700">Data do</label>
                <Input
                  type="date"
                  lang="pl"
                  required
                  value={date.to}
                  name="to"
                  onChange={(e) => {
                    setDate((prev) => ({ ...prev, to: e.target.value }));

                  }}
                />
              </div>
            </div>
            <FileInput
              description='PDF, PNG, JPG lub plik Excela (max. 2000 mb)'
              onFileUpload={(file) => {
                setFile(file);
              }} />
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: onClose
              }}
            >
              Anuluj
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                type: "submit"
              }}
            >
              Importuj
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
};

export default ImportMealPlanFromPdf;
