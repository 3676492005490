import React from 'react';

const XlsIcon = ({ className}: { className: string}) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <rect x="4.5" y="1.125" width="13.5" height="15.75" rx="2" fill="#2FB776" />
    <path
      d="M4.5 12.9375H18V14.875C18 15.9796 17.1046 16.875 16 16.875H6.5C5.39543 16.875 4.5 15.9796 4.5 14.875V12.9375Z"
      fill="url(#paint0_linear_6559_16091)" />
    <rect x="11.25" y="9" width="6.75" height="3.9375" fill="#229C5B" />
    <rect x="11.25" y="5.0625" width="6.75" height="3.9375" fill="#27AE68" />
    <path d="M4.5 3.125C4.5 2.02043 5.39543 1.125 6.5 1.125H11.25V5.0625H4.5V3.125Z" fill="#1D854F" />
    <rect x="4.5" y="5.0625" width="6.75" height="3.9375" fill="#197B43" />
    <rect x="4.5" y="9" width="6.75" height="3.9375" fill="#1B5B38" />
    <path
      d="M4.5 8.0625C4.5 6.40565 5.84315 5.0625 7.5 5.0625H8.25C9.90685 5.0625 11.25 6.40565 11.25 8.0625V12.1875C11.25 13.8444 9.90685 15.1875 8.25 15.1875H4.5V8.0625Z"
      fill="black" fill-opacity="0.3" />
    <rect y="3.9375" width="10.125" height="10.125" rx="2" fill="url(#paint1_linear_6559_16091)" />
    <path
      d="M7.3125 11.8125L5.72745 8.94375L7.24291 6.1875H6.0058L5.07023 7.94732L4.15013 6.1875H2.87436L4.39755 8.94375L2.8125 11.8125H4.04961L5.04704 9.94821L6.03673 11.8125H7.3125Z"
      fill="white" />
    <defs>
      <linearGradient id="paint0_linear_6559_16091" x1="4.5" y1="14.9062" x2="18" y2="14.9063"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#163C27" />
        <stop offset="1" stop-color="#2A6043" />
      </linearGradient>
      <linearGradient id="paint1_linear_6559_16091" x1="0" y1="9" x2="10.125" y2="9" gradientUnits="userSpaceOnUse">
        <stop stop-color="#185A30" />
        <stop offset="1" stop-color="#176F3D" />
      </linearGradient>
    </defs>
  </svg>


);

export default XlsIcon;
