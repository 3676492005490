import React, { useEffect } from "react";
import { useParams, Link, Routes, Route } from "react-router-dom";
import { useKidConsumerContext } from "../../../../../context/KidConsumerProvider";
import useAuth from "../../../../../hooks/useAuth";
import Breadcrumbs from "../../../../Breadcrumbs/Breadcrumbs";
import IconComponent from "../../../../IconComponent/IconComponent";
import Navigation from "../../../../Navigation/Navigation";
import AccountHistory from "../../AccountHistory/AccountHistory";
import KidBalanceHistory from "./KidBalanceHistory/KidBalanceHistory";
import KidCoFinancing from "./KidCoFinancing/KidCoFinancing";
import KidData from "./KidData/KidData";
import KidOrders from "./KidOrders/KidOrders";
import KidParents from "./KidParents/KidParents";

interface NavOptionsInterface {
  link: string;
  name: string;
  component: React.ReactElement;
}

const SingleKid = () => {
  const { auth } = useAuth();
  const { kid, setKidId } = useKidConsumerContext();
  const { id: tenantId, kidId } = useParams<{ id: string, kidId: string }>();

  useEffect(() => {
    setKidId(Number(kidId));
  }, [tenantId, kidId]);

  const navOptions: NavOptionsInterface[] = [
    {
      link: 'orders',
      name: 'Zamówione stawki',
      component: <KidOrders/>
    },
    {
      link: "transactions",
      name: "Lista transakcji",
      component: <KidBalanceHistory/>
    },
    {
      link: "parents",
      name: 'Rodzice',
      component: <KidParents/>
    },
    {
      link: "cofinancing",
      name: 'Dofinansowania',
      component: <KidCoFinancing/>
    },
    {
      link: "history",
      name: 'Historia konta',
      component: <AccountHistory/>
    },
    {
      link: "data",
      name: 'Dane użytkownika',
      component: <KidData/>
    }
    ]

  return (
    <section className="SingleCompany">
      <div className="SingleCompany__container pt-spacing-xl pr-spacing-3xl pb-spacing-xl pl-spacing-3xl gap-spacing-4xl flex flex-col">
        <div className="SingleCompany__actualCompanyStatus">
          <Breadcrumbs
            divider={<IconComponent iconName="chevronRight" />}
            elements={[
              <IconComponent iconName="home" />,
              <p className="font-medium text-sm text-grayLight-600">Dzieci</p>,
              <p className="font-semibold text-sm text-grayWarm-800">{kid?.consumerDto.firstName} {kid?.consumerDto.lastName} ({kid?.consumerDto.organisationUnitName})</p>
            ]}
          />
        </div>
        <div className="SingleCompany__container flex flex-col gap-spacing-4xl">
          <Link to={auth.loggedUserDto.superAdmin ? `/superAdmin/branches/id/${tenantId}/users/children/children-table` : `/tenantAdmin/id/${tenantId}/users/children/children-table`} className="SingleCompany__backButtonContainer flex gap-spacing-md font-semibold text-base text-grayWarm-950">
            <IconComponent iconName="arrowLeft" />
            <p>Wróć</p>
          </Link>
          <div className="SingleCompany__contextContainer flex flex-col gap-spacing-xl">
            <Navigation navOptions={navOptions} />
            <Routes>
              {navOptions.map((item) => (
                <Route key={item.link} path={`/${item.link}`} element={item.component} />
              ))}
            </Routes>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleKid;