import React, { ReactNode, useMemo, createContext, useContext, useEffect } from "react";
import { LocalStorageInterface } from "../shared/type/localStorage.type";

interface Props {
  children: ReactNode;
}

type AvailableDataInterface = 'selectedTenantId';

// {
//   selectedTenantId: 123,
//   ...
// }

interface LocalStorageProps {
  getValueFromLocalStorageObj: (name: AvailableDataInterface) => any;
  updateValueFromLocalStorageObj: (name: AvailableDataInterface, value: any) => void;
}

const LocalStorageContext = createContext<LocalStorageProps | undefined>(undefined);

export const useLocalStorageContext = () => {
  const context = useContext(LocalStorageContext);
  if (!context) {
    throw new Error('useLocalStorageContext must be used within an LocalStorageProvider');
  }
  return context;
};

export const LocalStorageProvider = ({ children }: Props) => {

  const updateValueFromLocalStorageObj = (name: AvailableDataInterface, value: any): void => {
    const jsonString = localStorage.getItem('jwsObj');
    const json = jsonString ? JSON.parse(jsonString) : {};

    json[name] = value;
    localStorage.setItem('jwsObj', JSON.stringify(json));
  }

  const getValueFromLocalStorageObj = (name: AvailableDataInterface): any => {
    const jsonString = localStorage.getItem('jwsObj');
    if (!jsonString) return null;

    const json = JSON.parse(jsonString) as LocalStorageInterface;

    return json[name];
  }

  const initLocalStorageIfNotExist = () => {
    const jsonString = localStorage.getItem('jwsObj');
    if (!jsonString) {
      const initialData: LocalStorageInterface = {
        selectedTenantId: 0
      };
      localStorage.setItem('jwsObj', JSON.stringify(initialData));
    }
  }

  useEffect(() => {
    initLocalStorageIfNotExist();
  }, []);

  const contextValue = useMemo(
    () => ({
      getValueFromLocalStorageObj,
      updateValueFromLocalStorageObj
    }),
    [getValueFromLocalStorageObj, updateValueFromLocalStorageObj]
  );

  return (
    <LocalStorageContext.Provider value={contextValue}>{children}</LocalStorageContext.Provider>
  );
};
