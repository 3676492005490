import React from "react";
import { useEditTeacherOrEmployeeContext } from "../../../../../../context/EditTeacherOrEmployeeProvider";
import handlePhoneNumberChange from "../../../../../../utils/handlePhoneNumberChange";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import FormInput from "../../../../../Forms/FormInput/FormInput";

const TeacherData = () => {
  // const { teacherId, id } = useParams<{ teacherId: string, id: string }>();
  const {
    user,
    setUser,
    fetchUsers,
    editButtonState,
    setEditButtonState,
    setShowActivateAccount,
    updateEmailPassword,
    setShowRestartPasswordPopUp
  } = useEditTeacherOrEmployeeContext();

  return (
    <div className="AdministratorSettings__formContainer pt-spacing-xl flex flex-col gap-spacing-5xl">
      <div className='flex'>
        <div className='basis-3/5 flex flex-col gap-spacing-3xl'>
          <FormInput
            label="Imię"
            placeholder="Podaj imię"
            inputParams={{
              value: user?.firstName,
              required: true,
              minLength: 3,
              onChange: (e) => {
                setEditButtonState((prev) => ({...prev, isTopButtonActive: true}));
                setUser((prev) => ({...prev, firstName: e.target.value }));
              }
            }}
          />
          <FormInput
            label="Nazwisko"
            placeholder="Podaj nazwisko"
            inputParams={{
              value: user?.lastName,
              required: true,
              minLength: 3,
              onChange: (e) => {
                setEditButtonState((prev) => ({...prev, isTopButtonActive: true}));
                setUser((prev) => ({...prev, lastName: e.target.value }));
              }
            }}
          />
          <FormInput
            label="Numer telefonu"
            placeholder="Podaj numer telefonu"
            inputParams={{
              value: user?.phoneNumber,
              required: false,
              minLength: 6,
              onChange: (e) => {
                setEditButtonState((prev) => ({...prev, isTopButtonActive: true}));
                setUser((prev) => ({...prev, phoneNumber: handlePhoneNumberChange(e.target.value) }));
              }
            }}
          />
          <FormInput
            label="Numer identyfikatora"
            placeholder="Podaj numer idenyfikatora"
            inputParams={{
              value: user?.cardNumber || '',
              minLength: 6,
              pattern: "\\d{10}",
              title: "Numer identyfikatora musi składać się z dokładnie 10 cyfr lub może być pusty.",
              maxLength: 10,
              required: false,
              onWheel: (e) => (e.target as HTMLInputElement).blur(),
              onInput: (e) => {
                const input = e.target as HTMLInputElement;
                input.value = input.value.replace(/[^0-9]/g, '');
              },
              onChange: (e) => {
                setEditButtonState((prev) => ({...prev, isTopButtonActive: true}));
                setUser((prev) => ({...prev, cardNumber: e.target.value }));
              }
            }}
          />
        </div>
        <div className="basis-2/5 flex items-start justify-end">
          <div className="AdministratorSettings__actionButtons flex gap-spacing-lg">
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="tertiaryColor"
              size="md"
              buttonProps={{
                onClick: () => {
                  fetchUsers();
                  setEditButtonState((prev) => ({...prev, isTopButtonActive: false}));
                },
                type: 'button',
                disabled: !editButtonState.isTopButtonActive,
              }}
            >
              Anuluj
            </CustomChakraButton>
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="primary"
              size="md"
              buttonProps={{
                type: 'submit',
                disabled: !editButtonState.isTopButtonActive
              }}
            >
              Zapisz zmiany
            </CustomChakraButton>
          </div>
        </div>
      </div>
      <hr className="bg-grayLight-200" />
      <div className="flex">
        <div className="basis-3/5 flex flex-col gap-spacing-3xl">
          <FormInput
            label="Adres email"
            placeholder="Podaj email"
            inputParams={{
              value: user?.login,
              minLength: 3,
              autoComplete: "off",
              onChange: (e) => {
                setEditButtonState((prev) => ({...prev, isBottomButtonActive: true}));
                setUser((prev) => ({...prev, login: e.target.value }));
              }
            }}
          />
          <div>
            <FormInput
              label="Hasło"
              placeholder="******"
              bottomElement={
                user?.password === null ? (
                  <p className="text-sm font-normal text-grayLight-500">
                    Użytkownik zmienił hasło tymczasowe, nie masz już podglądu hasła.
                  </p>
                ) : null
              }
              inputParams={{
                type: "text",
                value: user?.password,
                minLength: 5,
                autoComplete: "new-password",
                onChange: (e) => {
                  setEditButtonState((prev) => ({ ...prev, isBottomButtonActive: true }));
                  setUser((prev) => ({ ...prev, password: e.target.value }));
                }
              }}
            />
            <div className="relative" style={{
              left: "calc(100% + 24px)",
              top: "-50%"
            }}><CustomChakraButton
              iconPosition="onlyText"
              hierarchy="linkGray"
              size="md"
              buttonProps={{
                onClick: () => {
                  setShowRestartPasswordPopUp(true);
                },
                type: "button"
              }}
              textProps={{
                style: { color: "#D92D20" }
              }}
            >
              Resetuj hasło
            </CustomChakraButton></div>
          </div>
        </div>
        <div className="basis-2/5">
          <div className="basis-2/5 flex items-start justify-end">
            <div className="AdministratorSettings__actionButtons flex gap-spacing-lg">
              <CustomChakraButton
                iconPosition="onlyText"
                hierarchy="tertiaryColor"
                size="md"
                buttonProps={{
                  onClick: () => {
                    fetchUsers();
                    setEditButtonState((prev) => ({...prev, isBottomButtonActive: false}));
                  },
                  type: "button",
                  disabled: !editButtonState.isBottomButtonActive
                }}
              >
                Anuluj
              </CustomChakraButton>
              <CustomChakraButton
                iconPosition="onlyText"
                hierarchy="primary"
                size="md"
                buttonProps={{
                  type: 'button',
                  disabled: !editButtonState.isBottomButtonActive,
                  onClick: updateEmailPassword
                }}
              >
                Zapisz zmiany
              </CustomChakraButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default TeacherData;