import { ConsumerCategoryType } from '../shared';

const translateOrganisationUnitCategory = (category: ConsumerCategoryType): string => {
  if (category === 'TEACHER') return 'Nauczyciel';
  if (category === 'CHILD') return 'Dziecko';
  if (category === 'EMPLOYEE') return 'Pracownik';
  return '';
};

export default translateOrganisationUnitCategory;
