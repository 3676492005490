import { TabList, Tab, Tabs } from "@chakra-ui/react";
import { useState, useEffect } from "react";

interface Props {
  data: {
    value: number;
    label: string;
  }[] | null | undefined;
  onChange: (value: number) => void;
}

const CustomHorizontalTabs = ({ data, onChange }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (data && data.length === 1) {
      onChange(data[0].value);
    } else if (data && data.length > 1) {
      onChange(data[0].value);
    }
  }, []);

  if (!data || data.length === 1) return null;

  return (
    <Tabs
      variant="unstyled"
      index={selectedIndex}
      onChange={(index) => {
        setSelectedIndex(index);
        onChange(data[index].value);
      }}
    >
      <TabList className="w-full flex rounded-xl box-border border border-grayLight-200 gap-spacing-xs p-spacing-sm bg-grayNeutral-50">
        {data.map((item) => (
          <Tab
            key={item.value}
            _selected={{
              color: '#344054',
              bg: '#FFFFFF',
              borderRadius: '6px',
              boxShadow: '0px 1px 3px 0px #1018281A',
            }}
            className="grow font-semibold text-grayLight-500 rounded-md"
          >
            {item.label}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};

export default CustomHorizontalTabs;
