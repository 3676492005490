import { AxiosResponse } from "axios";
import React, { ReactNode, useState, createContext, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { FullKidInterface } from "../shared/type/kid.type";
import { useApi } from "./ApiProvider";

interface Props {
  children: ReactNode;
}

interface KidConsumerContextProps {
  kid: FullKidInterface,
  setKid: React.Dispatch<React.SetStateAction<FullKidInterface>>,
  saveButtonState: boolean,
  setSaveButtonState: React.Dispatch<React.SetStateAction<boolean>>,
  setKidId:  React.Dispatch<React.SetStateAction<number>>,
  fetchKidConsumer: () => void
}

const defaultKid: FullKidInterface = {
  "consumerDto": {
    "id": 0,
    "organisationUnitName": "string",
    "firstName": "string",
    "lastName": "string",
    "cardNumber": "string",
    "consumerCategory": "string",
    "remarks": "string",
    "saldo": "string"
  },
  "parents": [
    {
      "id": 0,
      "firstName": "string",
      "lastName": "string",
      "login": "string",
      "phone": "string",
      "password": "string",
      "bankAccountNumber": "string",
      "roleName":  "PARENT",
      "active": true,
      "archived": true,
      "lastLogin": "2024-08-13T08:20:16.989Z"
    }
  ],
  "coFinancingToConsumerDtoList": [
    {
      "id": 0,
      "from": "2024-08-13",
      "to": "2024-08-13",
      "coFinancingInstitution": {
        "id": 0,
        "name": "string",
        "street": "string",
        "city": "string",
        "postcode": "string",
        "phone": "string",
        "mail": "string",
        "nip": "string"
      },
      "coFinancingAmountPerDay": '0',
      "stakeDto": {
        "id": 0,
        "name": "string",
        "price": 0
      }
    }
  ]
}

const KidConsumerContext = createContext<KidConsumerContextProps | undefined>(undefined);

export const useKidConsumerContext = () => {
  const context = useContext(KidConsumerContext);
  if (!context) {
    throw new Error('useKidConsumerContext must be used within a KidConsumerProvider');
  }
  return context;
};

export const KidConsumerProvider = ({ children }: Props) => {
  const { apiTenantConsumerController } = useApi();
  const [ kidId, setKidId ] = useState<number>(0)
  const {id: tenantId} = useParams<{
    id: string, kidId: string
  }>();
  const [kid, setKid] = useState<FullKidInterface>(defaultKid);
  const [ saveButtonState, setSaveButtonState ] = useState<boolean>(false);

  const fetchKidConsumer = async () => {
    setSaveButtonState(false);
    try {
      const response: AxiosResponse<FullKidInterface> = await apiTenantConsumerController('consumer-details').get(`/${tenantId}/${kidId}`);
      setKid(response.data)
    } catch (error) {

    }
  };

  useEffect(() => {
    if(Number(kidId) !== 0 && Number(tenantId) !== 0){
      fetchKidConsumer();
    }
  },[kidId])

  const contextValue = useMemo(
    () => ({
      kid,
      setKid,
      saveButtonState,
      setSaveButtonState,
      setKidId,
      fetchKidConsumer
    }),
    [
      kid,
      setKid,
      saveButtonState,
      setSaveButtonState,
      setKidId,
      fetchKidConsumer
    ]
  );

  return (
    <KidConsumerContext.Provider value={contextValue}>
        {children}
    </KidConsumerContext.Provider>
  );
};
