import React from 'react';
import Select, { components } from 'react-select';
import { useSettingFormContext } from '../../../../../../context/SettingsFormContext';
import { TransferModeType } from "../../../../../../shared/type/tenant.type";
import CheckboxInput from '../../../../../Forms/CheckboxInput/CheckboxInput';
import IconComponent from '../../../../../IconComponent/IconComponent';

const BillingSettings: React.FC = () => {
  const { settingsForm, updateForm } = useSettingFormContext();

  const options = [
    { value: 'CURRENT_MONTH', label: 'Bieżący miesiąc' },
    { value: 'PREVIOUS_MONTH', label: 'Poprzedni miesiąc' },
    { value: 'ALL_MONTHS', label: 'Każdy miesiąc' }
  ];

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#FFF7E6' : 'white',
      color: '#101828',
      padding: '8px'
    })
  };

  const { Option } = components;

  const IconOption = (props: any) => {
    const { data, isSelected } = props;
    return (
      <Option {...props}>
        <div className="flex justify-between items-center">
          <p className="text-base font-medium text-grayLight-900">{data.label}</p>
          {isSelected && <IconComponent iconName="check" color="#FFAC05" />}
        </div>
      </Option>
    );
  };

  return (
    <div className="grow flex flex-col gap-spacing-lg">
      <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
        Ustawienia rozliczeń*
      </div>
      <div className="flex flex-col gap-spacing-4xl">
        <div className="w-full flex flex-col gap-spacing-lg justify-between">
          <div className="Setting__sectionHeader">
            <p>Tryb rozliczeń oddziału*</p>
            <p>Określ kiedy mają być przesłane pieniądze do klienta:</p>
          </div>
          <div className="flex flex-col gap-spacing-3xl">
            <div className="w-96">
              <Select
                options={options}
                name="transferMode"
                styles={customStyles}
                value={options.filter(option => option.value === settingsForm?.transferMode)}
                onChange={(selectedOption) => {
                  if (selectedOption && typeof selectedOption.value === 'string') {
                    updateForm('transferMode', selectedOption.value as TransferModeType);
                  }
                }}
                components={{ Option: IconOption }}
              />
            </div>
            <CheckboxInput
              text="Rozliczanie tylko bieżącego roku kalendarzowego*"
              textSize="lg"
              checkboxParams={{
                isChecked: settingsForm?.transferOnlyForCurrentYear,
                name: 'transferOnlyForCurrentYear',
                onChange: (e) => {
                  updateForm(e.target.name, !settingsForm?.transferOnlyForCurrentYear);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingSettings;
