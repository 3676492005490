import React from 'react';

const BasketIcon = ({ className, color }: { className: string; color: string }) => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M3.6 2.19984L2.3 3.93317C2.04251 4.27648 1.91377 4.44814 1.91676 4.59183C1.91936 4.71687 1.97799 4.83413 2.07646 4.91123C2.18962 4.99984 2.40419 4.99984 2.83333 4.99984H15.1667C15.5958 4.99984 15.8104 4.99984 15.9235 4.91123C16.022 4.83413 16.0806 4.71687 16.0832 4.59183C16.0862 4.44814 15.9575 4.27649 15.7 3.93317L14.4 2.19984M3.6 2.19984C3.74667 2.00428 3.82 1.9065 3.91294 1.83598C3.99525 1.77352 4.08846 1.72692 4.18782 1.69854C4.3 1.6665 4.42222 1.6665 4.66667 1.6665H13.3333C13.5778 1.6665 13.7 1.6665 13.8122 1.69854C13.9115 1.72692 14.0047 1.77352 14.0871 1.83598C14.18 1.9065 14.2533 2.00428 14.4 2.19984M3.6 2.19984L2.03333 4.28872C1.83545 4.55257 1.73651 4.68449 1.66625 4.82977C1.6039 4.95869 1.55843 5.0951 1.53096 5.23564C1.5 5.39401 1.5 5.55892 1.5 5.88872L1.5 15.6665C1.5 16.5999 1.5 17.0666 1.68166 17.4232C1.84144 17.7368 2.09641 17.9917 2.41002 18.1515C2.76654 18.3332 3.23325 18.3332 4.16667 18.3332L13.8333 18.3332C14.7668 18.3332 15.2335 18.3332 15.59 18.1515C15.9036 17.9917 16.1586 17.7368 16.3183 17.4232C16.5 17.0666 16.5 16.5999 16.5 15.6665V5.88873C16.5 5.55892 16.5 5.39402 16.469 5.23564C16.4416 5.0951 16.3961 4.95869 16.3338 4.82977C16.2635 4.68449 16.1646 4.55257 15.9667 4.28873L14.4 2.19984M12.3333 8.33317C12.3333 9.21722 11.9821 10.0651 11.357 10.6902C10.7319 11.3153 9.88405 11.6665 9 11.6665C8.11594 11.6665 7.2681 11.3153 6.64298 10.6902C6.01786 10.0651 5.66667 9.21722 5.66667 8.33317"
      stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
  </svg>


);

export default BasketIcon;
