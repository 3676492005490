import React, { useState, useEffect } from 'react';
import { Thead, Th, Box, Text, TableContainer } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useParams } from "react-router-dom";
import { useApi } from '../../../../context/ApiProvider';
import { OrganisationUnitInterface } from '../../../../shared';
import translateOrganisationUnitCategory from '../../../../utils/translateOrganisationUnitCategory';
import CustomAlert from '../../../CustomAlert/CustomAlert';
import CustomChakraButton from '../../../CustomChakraButton/CustomChakraButton';
import { DataTable } from '../../../DataTable/DataTable';
import IconComponent from '../../../IconComponent/IconComponent';
import AddEditOrganisationUnit from './AddEditOrganisationUnit/AddEditOrganisationUnit';

interface PopUpInterface {
  name?: string;
  id?: number;
  mode: 'add' | 'edit';
  isOpen: boolean;
}

interface DeletedInterface {
  id: number;
  isOpen: boolean;
}

const defaultPopUp: PopUpInterface = {
  name: '',
  id: 0,
  mode: 'add',
  isOpen: false
};

const defaultDeleted: DeletedInterface = {
  id: 0,
  isOpen: false
};

const OrganisationUnits = () => {
  const { apiTenantOrganisationUnitController } = useApi();
  const { id: tenant } = useParams();
  const [organisationUnits, setOrganisationUnits] = useState<OrganisationUnitInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addEditPopUp, setAddEditPopUp] = useState<PopUpInterface>(defaultPopUp);
  const [deletedElement, setDeletedElement] = useState<DeletedInterface>(defaultDeleted);

  const columnHelper = createColumnHelper<OrganisationUnitInterface>();

  const fetchOrganizationUnits = async () => {
    try {
      setIsLoading(true);
      const result = await apiTenantOrganisationUnitController('').get(`/${tenant}`);
      setOrganisationUnits(result.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => {
        const organisationUnit = info.getValue();

        return <div>{organisationUnit}</div>;
      },
      header: 'Klasa'
    }),
    columnHelper.accessor('consumerCategory', {
      cell: (info) => {
        const category = info.getValue();

        return <p className="w-96">{translateOrganisationUnitCategory(category)}</p>;
      },
      header: 'Kategoria'
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Edycja',
      cell: (info) => {
        const { id, name, consumerCategory } = info.row.original;

        if (consumerCategory === 'CHILD') {
          return (
            <div className="w-16 flex gap-spacing-md">
              <button
                className="font-semibold text-sm text-grayLight-600 bg-transparent border-none"
                onClick={() => {
                  setDeletedElement({
                    isOpen: true,
                    id: id
                  });
                }}
              >
                Usuń
              </button>
              <button
                className="font-semibold text-sm text-grayLight-600 bg-transparent border-none"
                onClick={() => {
                  setAddEditPopUp({
                    name: name,
                    isOpen: true,
                    mode: 'edit',
                    id: id
                  });
                }}
              >
                Edytuj
              </button>
            </div>
          );
        }
        return;
      }
    })
  ];



  useEffect(() => {
    fetchOrganizationUnits();
  }, [tenant]);

  return (
    <section className="SchoolClasses p-spacing-4xl w-full">
      <TableContainer h={800} overflowY="auto" className="Branches__table bg-white rounded-lg">
        <DataTable
          disableFilters
          extraThead={
            <Thead>
              <Th colSpan={columns.length}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5">
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-semibold text-base text-grayLight-900"
                      >
                        Klasy
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-normal text-sm text-grayLight-700"
                      >
                        Lista klas w oddziale
                      </Text>
                    </Box>
                  </div>
                  <CustomChakraButton
                    size="md"
                    hierarchy="primary"
                    iconPosition="left"
                    icon="plus"
                    buttonProps={{
                      onClick: () => {
                        setAddEditPopUp({ isOpen: true, mode: 'add', id: 0, name: '' });
                      }
                    }}
                  >
                    Dodaj klasę
                  </CustomChakraButton>
                </div>
              </Th>
            </Thead>
          }
          columns={columns}
          isLoading={isLoading}
          data={organisationUnits}
        />
      </TableContainer>
      <AddEditOrganisationUnit
        isOpen={addEditPopUp.isOpen}
        unitId={addEditPopUp.id}
        unitName={addEditPopUp.name}
        mode={addEditPopUp.mode}
        onClose={() => {
          setAddEditPopUp(defaultPopUp);
          fetchOrganizationUnits();
        }}
      />
      <CustomAlert
        header={`Usunąć klasę? ${deletedElement.id}`}
        content="Usunięcie klasy jest nieodwracalne."
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'warning'
        }}
        confirmButtonText="Usuń"
        onConfirmAction={async () => {
          try {
            await apiTenantOrganisationUnitController('').delete(`/${tenant}/${deletedElement.id}`);
          } catch (error) {
            console.error(error);
          } finally {
            setDeletedElement(defaultDeleted);
            fetchOrganizationUnits();
          }
        }}
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        cancelButtonText="Anuluj"
        onCancelAction={() => {
          setDeletedElement(defaultDeleted);
          fetchOrganizationUnits();
        }}
        handleOpen={deletedElement.isOpen}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
            <IconComponent iconName="trash" />
          </div>
        }
      />
    </section>
  );
};

export default OrganisationUnits;
