import React from 'react';
import './AuthenticationScreen.css';
import { Route, Routes } from 'react-router-dom';
import Login from '../../component/Login/Login';
import RecoverPassword from '../../component/AutenticationComponents/RecoverPassword/RecoverPassword';
import ConfirmEmail from '../../component/AutenticationComponents/ConfirmEmail/ConfirmEmail';
import Registration from "../../component/AutenticationComponents/Registration/Registration";
import SetNewPassword from '../../component/AutenticationComponents/SetNewPassword/NewPassword';
import { ResetPasswordProvider } from '../../context/ResetPasswordProvider';

const AuthenticationScreen = () => (
  <section className="LoginPage dark:bg-grayIron-800">
    <div className="LoginPage__login-section min-h-screen flex items-center">
      <div className="LoginPage__logo hidden lg:block pl-container-padding-desktop pt-container-padding-desktop">
        <img src={`${process.env.PUBLIC_URL}/jws-logo.png`} alt="Logo na dużych ekranach" />
      </div>
      <div className="LoginPage__logo block lg:hidden pl-4 pt-4">
        <img src={`${process.env.PUBLIC_URL}/jws-logo-icon.png`} alt="Logo na małych ekranach" />
      </div>
      <div className="flex flex-col h-full w-full lg:pl-20 lg:pr-20  justify-center">
        <ResetPasswordProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/registration/*" element={<Registration />} />
            <Route path="/recover" element={<RecoverPassword />} />
            <Route path="/recover/confirm" element={<ConfirmEmail />} />
            <Route path="/recover/new-password/*" element={<SetNewPassword />} />
          </Routes>
        </ResetPasswordProvider>
        <div className="flex justify-between px-20 pt-spacing-xl">
          <p className='text-sm text-grayLight-600'>© Jemwszkole.pl 2024</p>
          <div className="flex gap-spacing-md">
            <p className='text-sm text-grayLight-600'>help@jemwszkole.pl</p>
          </div>
        </div>
      </div>
    </div>
    <div className="LoginPage__prom bg-brand-100 min-h-screen flex-grow hidden lg:block overflow-hidden relative">
      <img
        src={`${process.env.PUBLIC_URL}/img/screen-greeting.png`}
        className="LoginPage__screen-greeting absolute"
        alt="Logo na dużych ekranach"
      />
      <img
        src={`${process.env.PUBLIC_URL}/img/screen-table.png`}
        className="LoginPage__screen-table absolute"
        alt="Logo na dużych ekranach"
      />
      <img
        src={`${process.env.PUBLIC_URL}/img/phone-greeting.png`}
        className="LoginPage__phone-greeting absolute"
        alt="Logo na dużych ekranach"
      />
    </div>
  </section>
);

export default AuthenticationScreen;
