import React from 'react';

const ImportIcon = ({ className, color }: { className: string, color: string }) => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M6.66797 12.3333L10.0013 9M10.0013 9L13.3346 12.3333M10.0013 9V16.5M16.668 12.9524C17.6859 12.1117 18.3346 10.8399 18.3346 9.41667C18.3346 6.88536 16.2826 4.83333 13.7513 4.83333C13.5692 4.83333 13.3989 4.73833 13.3064 4.58145C12.2197 2.73736 10.2133 1.5 7.91797 1.5C4.46619 1.5 1.66797 4.29822 1.66797 7.75C1.66797 9.47175 2.36417 11.0309 3.49043 12.1613"
      stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default ImportIcon;
