import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from "@chakra-ui/react";
import { HttpStatusCode } from "axios";
import { Select } from "chakra-react-select";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../context/ApiProvider";
import { AllergenInterface } from "../../../../../../shared/type/allergen.type";
import { NewMealInterface, MealInterface } from "../../../../../../shared/type/mealsMenu.type";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import FormInput from "../../../../../Forms/FormInput/FormInput";

interface Props {
  isOpen: boolean,
  onClose: () => void,
  mealMenuId: number,
  initData?: MealInterface
}

const defaultMeal: NewMealInterface = {
  mealMenuId: 0,
  description: '',
  allergenIds: [],
  name: ''
}

const AddEditMeal = ({isOpen, onClose, mealMenuId, initData}: Props) => {
  const { apiTenantAllergenController, apiTenantMealController } = useApi();
  const { setAlertProperties, setShow } = useAlertContext();
  const { id: tenant } = useParams();
  const [ newMeal, setNewMeal ] = useState<NewMealInterface>(defaultMeal);
  const [allergens, setAllergens] = useState<AllergenInterface[]>([]);

  useEffect(() => {
    const fetchAllergens = async () => {
      try {
        const response = await apiTenantAllergenController('').get(`/${tenant}`);
        console.log("Fetched Allergens: ", response.data); // Debug log
        setAllergens(response.data);
      } catch (error) {
        console.warn(`Error fetching allergens: ${error}`);
      }
    };

    fetchAllergens();
  }, [apiTenantAllergenController, tenant]);

  useEffect(() => {
    if (initData) {
      setNewMeal({
        mealMenuId,
        description: initData.description,
        allergenIds: initData.allergens.map(allergen => allergen.id),
        name: initData.name
      });
    } else {
      setNewMeal(defaultMeal);
    }
  }, [initData, mealMenuId]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const newMealObj: NewMealInterface = {
      mealMenuId,
      description: newMeal.description,
      allergenIds: newMeal.allergenIds,
      name: newMeal.name,
    }
    try {
      let response;

      if(initData){
        response = await apiTenantMealController('').put(`/${initData.id}`, newMealObj);
      }else{
        response = await apiTenantMealController('').post('', newMealObj);
      }

      if(response.status === HttpStatusCode.Created || response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          timeout: 2000,
          title: "Sukces",
          description: `Poprawnie ${initData ? 'zaktualizowano' : 'dodano nową'} potrawę`,
          status: "success"
        });
        setShow(true);
        onClose();
      } else {
        setAlertProperties({
          timeout: 2000,
          title: "Błąd",
          description: `Błąd ${initData ? 'aktualizowania' : 'dodawania'} potrawy`,
          status: "error"
        });
        setShow(true);
      }
    } catch (error) {
      console.warn(`Error submitting meal: ${error}`);
      setAlertProperties({
        timeout: 2000,
        title: "Błąd",
        description: `Błąd ${initData ? 'aktualizowania' : 'dodawania'} potrawy`,
        status: "error"
      });
      setShow(true);
    } finally {
      onClose();
    }
  }

  return(
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <form onSubmit={handleSubmit}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">{initData ? "Edytuj danie" : "Dodaj danie"}</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <FormInput
              label="Nazwa"
              placeholder="Wpisz nazwę dania np. Owsianka z owocami"
              inputParams={{
                required: true,
                name: "name",
                value: newMeal.name,
                onChange: (e) => {setNewMeal((prev) => ({...prev, name: e.target.value}))}
              }}
            />
            <div className='flex flex-col'>
              <label className="text-sm font-medium text-grayLight-600">Opis</label>
              <textarea
                value={newMeal.description}
                onChange={(e) => {
                  setNewMeal((prev) => ({...prev, description: e.target.value}))
                }}
                className='pt-spacing-lg pr-3.5 pb-spacing-lg pl-3.5 border border-grayLight-300 rounded-lg' style={{resize: "none"}} maxLength={1000} cols={25} rows={10}/>
              <div className='flex justify-between'>
                <p className="text-sm font-normal text-grayLight-600">maks. 1000 znaków</p>
                <p className="text-sm font-normal text-grayLight-600">({newMeal.description.length}/1000)</p>
              </div>
            </div>
            <Select
              colorScheme="gray"
              isMulti
              placeholder="Wybierz..."
              value={allergens
                .filter((unit) => newMeal.allergenIds.includes(unit.id))
                .map((unit) => ({
                  label: unit.name,
                  value: unit.id
                }))}
              onChange={(value) => {
                setNewMeal((prev) => ({...prev, allergenIds: value.map((item: any) => item.value)}));
                console.log("Selected allergens: ", value); // Debug log
              }}
              options={allergens.map((base) => ({
                label: base.name,
                value: base.id,
                variant: "outline"
              }))}
            />
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: onClose
              }}
            >
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                type: "submit"
              }}
            >
              {initData ? "Zapisz" : "Dodaj"}
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
};

export default AddEditMeal;
