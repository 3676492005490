const hours = [
  { value: '00:00', label: '00:00', numValue: 0 },
  { value: '00:15', label: '00:15', numValue: 15 },
  { value: '00:20', label: '00:20', numValue: 20 },
  { value: '00:25', label: '00:25', numValue: 25 },
  { value: '00:30', label: '00:30', numValue: 30 },
  { value: '00:35', label: '00:35', numValue: 35 },
  { value: '00:40', label: '00:40', numValue: 40 },
  { value: '00:45', label: '00:45', numValue: 45 },
  { value: '00:50', label: '00:50', numValue: 50 },
  { value: '00:55', label: '00:55', numValue: 55 },
  { value: '01:00', label: '01:00', numValue: 100 },
  { value: '01:15', label: '01:15', numValue: 115 },
  { value: '01:20', label: '01:20', numValue: 120 },
  { value: '01:25', label: '01:25', numValue: 125 },
  { value: '01:30', label: '01:30', numValue: 130 },
  { value: '01:35', label: '01:35', numValue: 135 },
  { value: '01:40', label: '01:40', numValue: 140 },
  { value: '01:45', label: '01:45', numValue: 145 },
  { value: '01:50', label: '01:50', numValue: 150 },
  { value: '01:55', label: '01:55', numValue: 155 },
  { value: '02:00', label: '02:00', numValue: 200 },
  { value: '02:15', label: '02:15', numValue: 215 },
  { value: '02:20', label: '02:20', numValue: 220 },
  { value: '02:25', label: '02:25', numValue: 225 },
  { value: '02:30', label: '02:30', numValue: 230 },
  { value: '02:35', label: '02:35', numValue: 235 },
  { value: '02:40', label: '02:40', numValue: 240 },
  { value: '02:45', label: '02:45', numValue: 245 },
  { value: '02:50', label: '02:50', numValue: 250 },
  { value: '02:55', label: '02:55', numValue: 255 },
  { value: '03:00', label: '03:00', numValue: 300 },
  { value: '03:15', label: '03:15', numValue: 315 },
  { value: '03:20', label: '03:20', numValue: 320 },
  { value: '03:25', label: '03:25', numValue: 325 },
  { value: '03:30', label: '03:30', numValue: 330 },
  { value: '03:35', label: '03:35', numValue: 335 },
  { value: '03:40', label: '03:40', numValue: 340 },
  { value: '03:45', label: '03:45', numValue: 345 },
  { value: '03:50', label: '03:50', numValue: 350 },
  { value: '03:55', label: '03:55', numValue: 355 },
  { value: '04:00', label: '04:00', numValue: 400 },
  { value: '04:14', label: '04:14', numValue: 414 },
  { value: '04:20', label: '04:20', numValue: 420 },
  { value: '04:24', label: '04:24', numValue: 424 },
  { value: '04:30', label: '04:30', numValue: 430 },
  { value: '04:34', label: '04:34', numValue: 434 },
  { value: '04:40', label: '04:40', numValue: 440 },
  { value: '04:44', label: '04:44', numValue: 445 },
  { value: '04:40', label: '04:40', numValue: 440 },
  { value: '04:44', label: '04:44', numValue: 444 },
  { value: '05:00', label: '05:00', numValue: 500 },
  { value: '05:15', label: '05:15', numValue: 515 },
  { value: '05:20', label: '05:20', numValue: 520 },
  { value: '05:25', label: '05:25', numValue: 525 },
  { value: '05:30', label: '05:30', numValue: 530 },
  { value: '05:35', label: '05:35', numValue: 535 },
  { value: '05:40', label: '05:40', numValue: 540 },
  { value: '05:45', label: '05:45', numValue: 545 },
  { value: '05:50', label: '05:50', numValue: 550 },
  { value: '05:55', label: '05:55', numValue: 555 },
  { value: '06:00', label: '06:00', numValue: 600 },
  { value: '06:15', label: '06:15', numValue: 615 },
  { value: '06:20', label: '06:20', numValue: 620 },
  { value: '06:25', label: '06:25', numValue: 625 },
  { value: '06:30', label: '06:30', numValue: 630 },
  { value: '06:35', label: '06:35', numValue: 635 },
  { value: '06:40', label: '06:40', numValue: 640 },
  { value: '06:45', label: '06:45', numValue: 645 },
  { value: '06:50', label: '06:50', numValue: 650 },
  { value: '06:55', label: '06:55', numValue: 655 },
  { value: '07:00', label: '07:00', numValue: 700 },
  { value: '07:15', label: '07:15', numValue: 715 },
  { value: '07:20', label: '07:20', numValue: 720 },
  { value: '07:25', label: '07:25', numValue: 725 },
  { value: '07:30', label: '07:30', numValue: 730 },
  { value: '07:35', label: '07:35', numValue: 735 },
  { value: '07:40', label: '07:40', numValue: 740 },
  { value: '07:45', label: '07:45', numValue: 745 },
  { value: '07:50', label: '07:50', numValue: 750 },
  { value: '07:55', label: '07:55', numValue: 755 },
  { value: '08:00', label: '08:00', numValue: 800 },
  { value: '08:15', label: '08:15', numValue: 815 },
  { value: '08:20', label: '08:20', numValue: 820 },
  { value: '08:25', label: '08:25', numValue: 825 },
  { value: '08:30', label: '08:30', numValue: 830 },
  { value: '08:35', label: '08:35', numValue: 835 },
  { value: '08:40', label: '08:40', numValue: 840 },
  { value: '08:45', label: '08:45', numValue: 845 },
  { value: '08:50', label: '08:50', numValue: 850 },
  { value: '08:55', label: '08:55', numValue: 855 },
  { value: '09:00', label: '09:00', numValue: 900 },
  { value: '09:15', label: '09:15', numValue: 915 },
  { value: '09:20', label: '09:20', numValue: 920 },
  { value: '09:25', label: '09:25', numValue: 925 },
  { value: '09:30', label: '09:30', numValue: 930 },
  { value: '09:35', label: '09:35', numValue: 935 },
  { value: '09:40', label: '09:40', numValue: 940 },
  { value: '09:45', label: '09:45', numValue: 945 },
  { value: '09:50', label: '09:50', numValue: 950 },
  { value: '09:55', label: '09:55', numValue: 955 },
  { value: '10:00', label: '10:00', numValue: 1000 },
  { value: '10:15', label: '10:15', numValue: 1015 },
  { value: '10:20', label: '10:20', numValue: 1020 },
  { value: '10:25', label: '10:25', numValue: 1025 },
  { value: '10:30', label: '10:30', numValue: 1030 },
  { value: '10:35', label: '10:35', numValue: 1035 },
  { value: '10:40', label: '10:40', numValue: 1040 },
  { value: '10:45', label: '10:45', numValue: 1045 },
  { value: '10:50', label: '10:50', numValue: 1050 },
  { value: '10:55', label: '10:55', numValue: 1055 },
  { value: '11:00', label: '11:00', numValue: 1100 },
  { value: '11:15', label: '11:15', numValue: 1115 },
  { value: '11:20', label: '11:20', numValue: 1120 },
  { value: '11:25', label: '11:25', numValue: 1125 },
  { value: '11:30', label: '11:30', numValue: 1130 },
  { value: '11:35', label: '11:35', numValue: 1135 },
  { value: '11:40', label: '11:40', numValue: 1140 },
  { value: '11:45', label: '11:45', numValue: 1145 },
  { value: '11:50', label: '11:50', numValue: 1150 },
  { value: '11:55', label: '11:55', numValue: 1155 },
  { value: '12:00', label: '12:00', numValue: 1200 },
  { value: '12:15', label: '12:15', numValue: 1215 },
  { value: '12:20', label: '12:20', numValue: 1220 },
  { value: '12:25', label: '12:25', numValue: 1225 },
  { value: '12:30', label: '12:30', numValue: 1230 },
  { value: '12:35', label: '12:35', numValue: 1235 },
  { value: '12:40', label: '12:40', numValue: 1240 },
  { value: '12:45', label: '12:45', numValue: 1245 },
  { value: '12:50', label: '12:50', numValue: 1250 },
  { value: '12:55', label: '12:55', numValue: 1255 },
  { value: '13:00', label: '13:00', numValue: 1300 },
  { value: '13:15', label: '13:15', numValue: 1315 },
  { value: '13:20', label: '13:20', numValue: 1320 },
  { value: '13:25', label: '13:25', numValue: 1325 },
  { value: '13:30', label: '13:30', numValue: 1330 },
  { value: '13:35', label: '13:35', numValue: 1335 },
  { value: '13:40', label: '13:40', numValue: 1340 },
  { value: '13:45', label: '13:45', numValue: 1345 },
  { value: '13:50', label: '13:50', numValue: 1350 },
  { value: '13:55', label: '13:55', numValue: 1355 },
  { value: '14:00', label: '14:00', numValue: 1400 },
  { value: '14:15', label: '14:15', numValue: 1415 },
  { value: '14:20', label: '14:20', numValue: 1420 },
  { value: '14:25', label: '14:25', numValue: 1425 },
  { value: '14:30', label: '14:30', numValue: 1430 },
  { value: '14:35', label: '14:35', numValue: 1435 },
  { value: '14:40', label: '14:40', numValue: 1440 },
  { value: '14:45', label: '14:45', numValue: 1445 },
  { value: '14:50', label: '14:50', numValue: 1450 },
  { value: '14:55', label: '14:55', numValue: 1455 },
  { value: '15:00', label: '15:00', numValue: 1500 },
  { value: '15:15', label: '15:15', numValue: 1515 },
  { value: '15:20', label: '15:20', numValue: 1520 },
  { value: '15:25', label: '15:25', numValue: 1525 },
  { value: '15:30', label: '15:30', numValue: 1530 },
  { value: '15:35', label: '15:35', numValue: 1535 },
  { value: '15:40', label: '15:40', numValue: 1540 },
  { value: '15:45', label: '15:45', numValue: 1545 },
  { value: '15:50', label: '15:50', numValue: 1550 },
  { value: '15:55', label: '15:55', numValue: 1555 },
  { value: '16:00', label: '16:00', numValue: 1600 },
  { value: '16:15', label: '16:15', numValue: 1615 },
  { value: '16:20', label: '16:20', numValue: 1620 },
  { value: '16:25', label: '16:25', numValue: 1625 },
  { value: '16:30', label: '16:30', numValue: 1630 },
  { value: '16:35', label: '16:35', numValue: 1635 },
  { value: '16:40', label: '16:40', numValue: 1640 },
  { value: '16:45', label: '16:45', numValue: 1645 },
  { value: '16:50', label: '16:50', numValue: 1650 },
  { value: '16:55', label: '16:55', numValue: 1655 },
  { value: '17:00', label: '17:00', numValue: 1700 },
  { value: '17:15', label: '17:15', numValue: 1715 },
  { value: '17:20', label: '17:20', numValue: 1720 },
  { value: '17:25', label: '17:25', numValue: 1725 },
  { value: '17:30', label: '17:30', numValue: 1730 },
  { value: '17:35', label: '17:35', numValue: 1735 },
  { value: '17:40', label: '17:40', numValue: 1740 },
  { value: '17:45', label: '17:45', numValue: 1745 },
  { value: '17:50', label: '17:50', numValue: 1750 },
  { value: '17:55', label: '17:55', numValue: 1755 },
  { value: '18:00', label: '18:00', numValue: 1800 },
  { value: '18:15', label: '18:15', numValue: 1815 },
  { value: '18:20', label: '18:20', numValue: 1820 },
  { value: '18:25', label: '18:25', numValue: 1825 },
  { value: '18:30', label: '18:30', numValue: 1830 },
  { value: '18:35', label: '18:35', numValue: 1835 },
  { value: '18:40', label: '18:40', numValue: 1840 },
  { value: '18:45', label: '18:45', numValue: 1845 },
  { value: '18:50', label: '18:50', numValue: 1850 },
  { value: '18:55', label: '18:55', numValue: 1855 },
  { value: '19:00', label: '19:00', numValue: 1900 },
  { value: '19:15', label: '19:15', numValue: 1915 },
  { value: '19:20', label: '19:20', numValue: 1920 },
  { value: '19:25', label: '19:25', numValue: 1925 },
  { value: '19:30', label: '19:30', numValue: 1930 },
  { value: '19:35', label: '19:35', numValue: 1935 },
  { value: '19:40', label: '19:40', numValue: 1940 },
  { value: '19:45', label: '19:45', numValue: 1945 },
  { value: '19:50', label: '19:50', numValue: 1950 },
  { value: '19:55', label: '19:55', numValue: 1955 },
  { value: '20:00', label: '20:00', numValue: 2000 },
  { value: '20:15', label: '20:15', numValue: 2015 },
  { value: '20:20', label: '20:20', numValue: 2020 },
  { value: '20:25', label: '20:25', numValue: 2025 },
  { value: '20:30', label: '20:30', numValue: 2030 },
  { value: '20:35', label: '20:35', numValue: 2035 },
  { value: '20:40', label: '20:40', numValue: 2040 },
  { value: '20:45', label: '20:45', numValue: 2045 },
  { value: '20:50', label: '20:50', numValue: 2050 },
  { value: '20:55', label: '20:55', numValue: 2055 },
  { value: '21:00', label: '21:00', numValue: 2100 },
  { value: '21:15', label: '21:15', numValue: 2115 },
  { value: '21:20', label: '21:20', numValue: 2120 },
  { value: '21:25', label: '21:25', numValue: 2125 },
  { value: '21:30', label: '21:30', numValue: 2130 },
  { value: '21:35', label: '21:35', numValue: 2135 },
  { value: '21:40', label: '21:40', numValue: 2140 },
  { value: '21:45', label: '21:45', numValue: 2145 },
  { value: '21:50', label: '21:50', numValue: 2150 },
  { value: '21:55', label: '21:55', numValue: 2155 },
  { value: '22:00', label: '22:00', numValue: 2200 },
  { value: '22:15', label: '22:15', numValue: 2215 },
  { value: '22:20', label: '22:20', numValue: 2220 },
  { value: '22:25', label: '22:25', numValue: 2225 },
  { value: '22:30', label: '22:30', numValue: 2230 },
  { value: '22:35', label: '22:35', numValue: 2235 },
  { value: '22:40', label: '22:40', numValue: 2240 },
  { value: '22:45', label: '22:45', numValue: 2245 },
  { value: '22:50', label: '22:50', numValue: 2250 },
  { value: '22:55', label: '22:55', numValue: 2255 },
  { value: '23:00', label: '23:00', numValue: 2300 },
  { value: '23:15', label: '23:15', numValue: 2315 },
  { value: '23:20', label: '23:20', numValue: 2320 },
  { value: '23:25', label: '23:25', numValue: 2325 },
  { value: '23:30', label: '23:30', numValue: 2330 },
  { value: '23:35', label: '23:35', numValue: 2335 },
  { value: '23:40', label: '23:40', numValue: 2340 },
  { value: '23:45', label: '23:45', numValue: 2345 },
  { value: '23:50', label: '23:50', numValue: 2350 },
  { value: '23:55', label: '23:55', numValue: 2355 },
];

export default hours;