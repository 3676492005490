import React, { ReactNode } from 'react';
import './CustomInput.css';

export interface Props {
  label?: ReactNode;
  hintText?: ReactNode;
  size: 'sm' | 'md';
  type: 'default';
  destructive: boolean;
  inputParams?: React.InputHTMLAttributes<HTMLInputElement> & {
    ref?: React.RefObject<HTMLInputElement>;
  };
}

const CustomInput = ({ size, label, hintText, destructive, inputParams }: Props) => {
  return (
    <div className="flex flex-col gap-spacing-sm w-full">
      {label ? <label className="font-medium text-sm text-grayLight-700">{label}</label> : null}
      <input
        type="text"
        className={`CustomInput__state CustomInput__size--${size} ${destructive ? 'CustomInput__destructive' : ''}`}
        {...inputParams}
      />
      {hintText ? <p className="font-normal text-sm text-grayLight-600">{hintText}</p> : null}
    </div>
  );
};

export default CustomInput;
