import { Tooltip } from "@chakra-ui/react";
import React from "react";

interface Props {
  children: string | number;
  tooltipLabel?: React.ReactNode;
}

const BadgeWithMessage = ({ children, tooltipLabel }: Props) => {
  return (
    <Tooltip label={tooltipLabel} aria-label='A tooltip' placement='top' background={'#FFF7E6'} color={"#101828"}>
      <div className='border border-warning-200 bg-warning-50 text-warning-700 text-sm rounded-full pt-spacing-xs pr-spacing-xl pb-spacing-xs pl-spacing-xl'>{children}</div>
    </Tooltip>
  )
};

export default BadgeWithMessage;