import { TranslateRoleInterface } from '../shared/type/translateRole.type';

const translateRole = (role: TranslateRoleInterface) => {
  if (role.admin) {
    return 'Admin';
  }
  if (role.childOwner) {
    return 'Rodzic';
  }
  if (role.tenantAdmin) {
    return 'Admin oddziału';
  }
  if (role.superAdmin) {
    return 'Super admin';
  }
  return 'Unknown role';
};

export default translateRole;
