import React from "react";
import { Routes, Route } from "react-router-dom";
import { EditAdministratorProvider } from "../../../../context/EditAdministratorProvider";
import AdministratorsTable from "./AdministratorsTable/AdministratorsTable";
import SingleAdministrator from "./SingleAdministrator/SingleAdministrator";

const Administrators = () => {
  const administratorsLinks = [
    {
      link: "administrators-table",
      element: <AdministratorsTable/>,
    },
    {
      link: "single-administrator/:adminId",
      element: (
        <EditAdministratorProvider>
          <SingleAdministrator/>
        </EditAdministratorProvider>
      )
    },
  ];

  return (
      <Routes>
        {administratorsLinks.map((link) => (
          <Route key={link.link} path={`/${link.link}/*`} element={link.element} />
        ))}
      </Routes>
  );
};

export default Administrators;