import React from 'react';

const handlePostCodeChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  setBranch: React.Dispatch<React.SetStateAction<any | null>>
) => {
  let inputValue = event.target.value;

  // Remove non-numeric characters
  inputValue = inputValue.replace(/\D/g, '');

  // Add hyphen after two digits
  if (inputValue.length > 2) {
    inputValue = `${inputValue.slice(0, 2)}-${inputValue.slice(2, 5)}`;
  }

  const name = event.target.name;

  setBranch((prevBranch: any) => {
    if (prevBranch) {
      return { ...prevBranch, [name]: inputValue };
    }
    return prevBranch;
  });
};

export default handlePostCodeChange;
