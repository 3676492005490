/* eslint-disable */

const formatDate = (date: any, format?: 'yyyy-mm-dd' | 'dd-mmm-yyyy'): string => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  if(format === 'dd-mmm-yyyy') {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

/* eslint-enable */

export default formatDate;
