import { TableContainer } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { AxiosResponse, HttpStatusCode } from "axios";
import React, { useState, useEffect } from "react";
import { useAlertContext } from "../../../context/AlertProvider";
import { useApi } from "../../../context/ApiProvider";
import { useParentDashboardContextContext } from "../../../context/ParentDashboardContext";
import { ParentAbsenceInterfaceFormMoth } from "../../../shared/type/absence.type";
import { CustomButtonType } from "../../../shared/type/customButton.type";
import GetButtonMessage from "../../../utils/GetButtonMessage";
import ChooseMonths from "../../ChooseMonths/ChooseMonths";
import CustomBadge from "../../CustomBadge/CustomBadge";
import CustomChakraButton from "../../CustomChakraButton/CustomChakraButton";
import CustomHorizontalTabs from "../../CustomHorizontalTabs/CustomHorizontalTabs";
import { DataTable } from "../../DataTable/DataTable";
import IconComponent from "../../IconComponent/IconComponent";

import './ParentAbsences.css';

const ParentAbsences = () => {
  const today = new Date();
  const { setAlertProperties, setShow: setShowAlert } = useAlertContext();
  const { apiAbsenceAndCourseController } = useApi();
  const { parentDashboardContext } = useParentDashboardContextContext()
  const [ chosenTenantId, setChosenTenantId ] = useState<number | null>(null);
  const [ chosenConsumerId, setChosenConsumerId ] = useState<number | null>(null);
  const [ currentDate, setCurrentDate ] = React.useState<{
    year: number;
    month: number;
    day: number;
  }>({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate(),
  });
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ absences, setAbsences ] = useState<ParentAbsenceInterfaceFormMoth[]>([])
  const columnHelper = createColumnHelper<ParentAbsenceInterfaceFormMoth>();

  const reportOrRevokeAbsence = async (stakeId: number, presence: boolean) => {
    try {
      const response = await apiAbsenceAndCourseController('report-or-revoke-absence').post('',
        {
          consumerId: chosenConsumerId,
          boughtStakesId: [stakeId],
          absence: presence,
        });

      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          description: `Poprawnie zaktualizowano`,
          timeout: 2000,
          title: "Sukces",
          status: "success",
        });
        setShowAlert(true);

        setAbsences((prevAbsences) =>
          prevAbsences.map((absence) => ({
            ...absence,
            stakesAndCourses: absence.stakesAndCourses.map((stake) =>
              stake.boughtStakeId === stakeId
                ? { ...stake, presence: !stake.presence }
                : stake
            ),
          }))
        );
      }
    } catch (error: any) {
      setAlertProperties({
        description: `${error.response?.data?.errors?.[0] || "Wystąpił błąd"}`,
        timeout: 5000,
        title: "Błąd",
        status: "error",
      });
      setShowAlert(true);
    }
  };


  const columns = [
    columnHelper.display({
      id: 'when',
      cell: (info) => {
        const date = new Date(info.row.original.when);

        const isWeekend = (str: string): boolean => {
          if(str === 'Nd') return true;
          return str === 'Sb';

        }

        // @ts-ignore
        info.row.original.rowBackgroundColor = isWeekend(info.row.original.dayOfTheWeek) && '#F9FAFB';

        return (
          <div className="flex flex-col items-start">
            <span className="text-sm font-medium text-grayLight-900">{info.row.original.dayOfTheWeek}</span>
            <span className="text-sm font-normal text-grayLight-600">{date.getDate()}.{(date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}</span>
          </div>
        )
      },
      header: "Dzień"
    }),
    columnHelper.accessor("stakesAndCourses", {
      cell: (info) => {
        const { stakesAndCourses } = info.row.original;

        if (stakesAndCourses.length === 0) {
          return <div className='flex items-center justify-start'><p>-</p></div>;
        }

        const totalAssignedCourses = stakesAndCourses.reduce((acc, stake) => {
          return acc + stake.takenCourses.length;
        }, 0);

        return (
          <div className="flex flex-col justify-center bg-red" style={{ height: totalAssignedCourses * 65 + 'px' }}>
            {stakesAndCourses.map((stake, stakeIndex) => (
              <div key={`stakeName-${stakeIndex}`}
                   className={`flex flex-col justify-center`}
                   style={{ height: stake.takenCourses.length / totalAssignedCourses * 100 + "%" }}>
                  <p className="text-grayLight-900 font-medium text-sm">{stake.stakeName}</p>
              </div>
            ))}
          </div>
        );
      },
      header: "Nazwy posiłków"
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const { stakesAndCourses } = info.row.original;

        if(stakesAndCourses.length === 0) {
          return <div className='flex items-center justify-start'><p>-</p></div>;
        }

        const totalAssignedCourses = stakesAndCourses.reduce((acc, stake) => {
          return acc + stake.takenCourses.length;
        }, 0);

        return (
          <div className="flex flex-col justify-center bg-red" style={{ height: totalAssignedCourses * 65 + 'px' }}>
            {stakesAndCourses.map((stake, stakeIndex) => (
              <div key={`stakeCategory-${stakeIndex}`}
                   className={`flex flex-col justify-center`}
                   style={{ height: stake.takenCourses.length / totalAssignedCourses * 100 + "%"}}>
                {
                  stake.takenCourses.map((course, takeIndex) => (
                    <div  key={`singleStakeCategory-${takeIndex}`} className='flex flex-col justify-center' style={{ height: 1 / stakesAndCourses.length * 100 + "%" }}>
                      <p className="text-grayLight-900 font-medium text-sm">{course.courseName}</p>
                    </div>
                  ))
                }
              </div>
            ))}
          </div>
        );
      },
      header: "Kategorie posiłków"
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const { stakesAndCourses } = info.row.original;

        console.log(stakesAndCourses)

        if(stakesAndCourses.length === 0) {
          return <div className='flex items-center justify-center'><p>-</p></div>;
        }

        const totalAssignedCourses = stakesAndCourses.reduce((acc, stake) => {
          return acc + stake.takenCourses.length;
        }, 0);

        return (
          <div className="flex flex-col justify-center" style={{ height: totalAssignedCourses * 65 + 'px' }}>
            {stakesAndCourses.map((stake, stakeIndex) => (
              <div key={`stakeTaken-${stakeIndex}`}
                   className={`flex flex-col justify-center`}
                   style={{ height: stake.takenCourses.length / totalAssignedCourses * 100 + "%"}}>
                {
                  stake.takenCourses.map((course, courseIndex) => (
                    <div key={`singleStakeTaken-${courseIndex}`} className='flex flex-col justify-center items-center' style={{ height: 1 / stakesAndCourses.length * 100 + "%" }}>
                      <CustomBadge size='sm' color={course.taken ? 'success' : 'gray'}>
                        <div className='flex items-center gap-spacing-xs'>
                          {course.taken ? 'Tak' : 'Nie'}
                          <IconComponent iconName={course.taken ? 'check' : 'xClose'} className='h-2'/>
                        </div>
                      </CustomBadge>
                    </div>
                  ))
                }
              </div>
            ))}
          </div>
        );
      },
      header: "Odbiór"
    }),
    columnHelper.accessor('stakesAndCourses', {
      cell: (info) => {
        const { stakesAndCourses, reportingAbsencePossible } = info.row.original;

        if(stakesAndCourses.length == 0) return

        const totalAssignedCourses = stakesAndCourses.reduce((acc, stake) => {
          return acc + stake.takenCourses.length;
        }, 0);

        const getButton = (absencePossible: boolean, presence: boolean, boughtStakeId: number) => {
          let button: CustomButtonType = {
            size: 'sm',
            iconPosition: 'left',
            icon: 'xClose',
            iconColor: '#B42318',
            hierarchy: 'destructiveSecondary',
          };

          let buttonText = 'Odwołaj';

          if (!absencePossible && presence) {
              button.iconColor = '#98A2B3';
              button.buttonProps = {
              disabled: true,
            };
          }

          if (absencePossible && !presence) {
              buttonText = 'Przywróć';
              button.hierarchy = 'primary';
              button.icon = 'rollBack';
              button.iconPosition = 'right';
              button.iconColor = '#292524';
          }

          if (!absencePossible && !presence) {
            buttonText = 'Przywróć';
            button.hierarchy= 'primary';
            button.icon = 'rollBack';
            button.iconPosition = 'right';
            button.iconColor = '#98A2B3';
            button.buttonProps = {
              disabled: true,
            };
          }

          return (
            <CustomChakraButton
              buttonProps={{
                type: 'button',
                ...button.buttonProps,
                onClick: () => {reportOrRevokeAbsence(boughtStakeId, presence)}
              }}
              size={button.size}
              iconPosition={button.iconPosition}
              iconColor={button.iconColor}
              icon={button.icon}
              hierarchy={button.hierarchy}>
              {buttonText}
            </CustomChakraButton>
          );
        };

        return (
          <div className="flex flex-col justify-center" style={{ height: totalAssignedCourses * 65 + 'px' }}>
            {stakesAndCourses.map((stake, stakeIndex) => (
              <div key={`stakeButton-${stake.boughtStakeId}-${stakeIndex}`}
                   className={`flex flex-col justify-center`}
                   style={{ height: stake.takenCourses.length / totalAssignedCourses * 100 + "%" }}>
                    <div className='flex flex-col items-center justify-center'>
                      <div>
                        {getButton(reportingAbsencePossible, stake.presence, stake.boughtStakeId)}
                      </div>
                      <div>
                        <GetButtonMessage absencePossible={reportingAbsencePossible} presence={stake.presence}/>
                      </div>
                    </div>
              </div>
            ))}
          </div>
        );
      },
      header: ''
    })
  ];


  const fetchAbsencesForCustomer = async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<ParentAbsenceInterfaceFormMoth[]> = await apiAbsenceAndCourseController('absences-for-consumer-for-month').post('', {
        consumerId: chosenConsumerId,
        tenantId: chosenTenantId,
        month: currentDate.month,
        year: currentDate.year
      })
      setAbsences(response.data);
    } catch (error) {
      setAlertProperties({
        description: `Wystąpił błąd podczas pobierania danych`,
        timeout: 5000,
        title: "Błąd",
        status: "error",
      });
      setShowAlert(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const firstConsumerId = parentDashboardContext?.tenants[0].consumers[0].consumerDto.id;
    if(firstConsumerId){
      setChosenConsumerId(firstConsumerId)
    }
  }, []);

  useEffect(() => {
    if(chosenTenantId !== null && chosenConsumerId !== null){
      fetchAbsencesForCustomer()
    }
  }, [chosenTenantId, chosenConsumerId, currentDate]);

  return (
    <section className="ParentAbsences flex flex-col bg-grayLight-50 overflow-y-auto">
      <div className="pt-spacing-3xl pr-spacing-3xl pb-spacing-xl pl-spacing-3xl">
        <div className="flex justify-between">
          <div className='flex flex-col'>
            <h1 className="p-0 m-0 font-semibold text-grayLight-900" style={{ fontSize: "30px" }}>
              Nieobecności
            </h1>
            <p className='font-normal text-grayLight-600' style={{ fontSize: "16px" }}>
              Sprawdź zamówione posiłki i zgłoś nieobecność dziecka.
            </p>
          </div>
          <div>
            <ChooseMonths setDate={setCurrentDate} currentDate={currentDate} mode='month' />
          </div>
        </div>
      </div>
      <div className='flex flex-col pt-spacing-xl pr-spacing-4xl pl-spacing-3xl pb-spacing-xl gap-spacing-xl'>
        <CustomHorizontalTabs
          onChange={(value) => {
            setChosenTenantId(value);
          }}
          data={parentDashboardContext ? parentDashboardContext.tenants.map(tenant => ({
            label: tenant.tenantName,
            value: tenant.tenantId,
          })) : null}
        />

        <CustomHorizontalTabs
          onChange={(value) => {
            setChosenConsumerId(value);
          }}
          data={parentDashboardContext ? parentDashboardContext.tenants.find(tenant => tenant.tenantId === chosenTenantId)?.consumers.map(consumer => {
            const { firstName, lastName, id } = consumer.consumerDto;
            return ({
              value: id,
              label: firstName + " " + lastName,
            });
          }) : null}
        />
      </div>
      <div className="ParentAbsences__absenceTable flex flex-col pb-spacing-5xl"
           style={{ flexGrow: 1, overflowY: 'auto', marginBottom: '20px' }}>
        <TableContainer h="90%"  className="Branches__table bg-white rounded-lg">
          <DataTable
            disableFilters={true}
            columns={columns}
            isLoading={isLoading}
            data={absences}
          />
        </TableContainer>
      </div>
    </section>
  )
};

export default ParentAbsences;