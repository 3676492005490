import { Checkbox, Divider, TableContainer, Thead, Th, Box, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { AxiosResponse } from "axios";
import React, { useState, useEffect } from "react";
import { useApi } from "../../../context/ApiProvider";
import { useParentDashboardContextContext } from "../../../context/ParentDashboardContext";
import {
  ParentMealToDayInterface,
  MealToDayDayInterface
} from "../../../shared/type/mealToDay.type";
import BadgeWithMessage from "../../BadgeWithMessage/BadgeWithMessage";
import ChooseMonths from "../../ChooseMonths/ChooseMonths";
import CustomHorizontalTabs from "../../CustomHorizontalTabs/CustomHorizontalTabs";
import { DataTable } from "../../DataTable/DataTable";
import IconComponent from "../../IconComponent/IconComponent";

const ParentMealPlan = () => {
  const today = new Date();
  const { apiParentMealToDayController, apiParentPdfMealMenuController } = useApi();
  const { parentDashboardContext } = useParentDashboardContextContext()

  const [ mealPlans, setMealPlans] = useState<MealToDayDayInterface[]>([]);
  const [mealPlanDate, setMealPlanDate] = useState<{ year: number; month: number, day: number }>({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate()
  });
  const columnHelper = createColumnHelper<MealToDayDayInterface>();

  const [ selectedTenant, setSelectedTenant ] = useState<number>(10);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchMealPlans = async () => {
    try {
      setIsLoading(true);
      const response: AxiosResponse<ParentMealToDayInterface> = await apiParentMealToDayController('').post('', {
        tenantId: Number(selectedTenant),
        year: mealPlanDate.year,
        month: mealPlanDate.month,
      });
      setMealPlans(response.data.days);
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadMealPlanPdf = async (pdfId: number) => {
    try {
      const response = await apiParentPdfMealMenuController('').get(`/${selectedTenant}/${pdfId}/export`, {
        responseType: 'blob',
      });

      if (response.data) {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);

        window.open(pdfUrl, '_blank');

      }
    } catch (error: any) {
      console.error('Error downloading PDF:', error);
    }
  };

  const columns = [
    columnHelper.display({
      id: 'select',
      header: () => <Checkbox colorScheme="customOrange" />,
      cell: () => <Checkbox colorScheme="customOrange" />,
    }),
    columnHelper.accessor('when', {
      cell: (info) => {
        const { when } = info.row.original;
        const date = new Date(when);

        const options: Intl.DateTimeFormatOptions = { weekday: 'long' };
        const dayName = date.toLocaleDateString('pl-PL', options);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');

        return (
          <div className="flex flex-col">
            <p className="text-grayLight-900 font-medium text-sm">{dayName}</p>
            <p className="text-grayLight-600 font-normal text-sm">{`${day}.${month}`}</p>
          </div>
        );
      },
      header: "Dzień",
    }),
    columnHelper.accessor("mealsToCourse", {
      cell: (info) => {
        const { mealsToCourse, workingDay, pdfMenu } = info.row.original;

        if (!workingDay) {
          return <p>Wolne</p>;
        }

        if (pdfMenu !== null) {
          return <button className='flex gap-spacing-xs pt-2.5 pr-3 pb-2.5 pl-3' onClick={() => {
            downloadMealPlanPdf(pdfMenu?.id)
          }}>
            <IconComponent iconName='pdf'/>
            <p className='text-sm font-semibold text-grayWarm-950'>Zobacz jadłospis</p>
          </button>
        }

        return (
          <div
            className="flex flex-col"
            style={{ height: 60 * (mealsToCourse?.length || 0) + 'px'}}
          >
            {mealsToCourse?.map((meal, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider backgroundColor="#EAECF0" color="#EAECF0" />}
                <div
                  style={{ height: 1 / (mealsToCourse?.length || 0) * 100 + '%'}}
                  className={`h-full text-grayLight-900 text-sm font-medium pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md`}>
                  {meal.courseName.charAt(0).toUpperCase() + meal.courseName.slice(1)}
                </div>
              </React.Fragment>
            ))}
          </div>

        );
      },
      header: "Kategoria posiłków"
    }),
    columnHelper.accessor("mealsToCourse", {
      cell: (info) => {
        const mealsToCourse = info.getValue();

        return (
          <div
            className="flex flex-col h-full"
            style={{ height: 60 * (mealsToCourse?.length || 0) + 'px'}}>
            {mealsToCourse?.map((meal, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider backgroundColor="#EAECF0" color="#EAECF0" />}
                <div
                  style={{ height: 1 / (mealsToCourse?.length || 0) * 100 + '%'}}
                  className={`flex-grow flex flex-col justify-between pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md`}>
                  <p className="text-grayLight-600 text-sm font-medium">{meal.mealDto?.name || "Brak posiłku"}</p>
                </div>
              </React.Fragment>
            ))}
          </div>
        );
      },
      header: "Danie"
    }),
    columnHelper.accessor("mealsToCourse", {
      cell: (info) => {
        const mealsToCourse = info.getValue();

        return (
          <div
            className="flex flex-col"
            style={{ height: 60 * (mealsToCourse?.length || 0) + 'px'}}>
            {mealsToCourse?.map((meal, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider backgroundColor="#EAECF0" color="#EAECF0" />}
                <div
                  style={{ height: 1 / (mealsToCourse?.length || 0) * 100 + '%'}}
                  className={`basis-1/${mealsToCourse?.length} text-grayLight-600 flex-wrap flex text-sm font-medium gap-spacing-md pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md`}>
                  {Number(meal.mealDto?.allergens.length) > 0 ? meal.mealDto?.allergens.map(allergen => (
                    <BadgeWithMessage tooltipLabel={<p
                      className="rounded-lg pt-spacing-md pr-spacing-lg pb-spacing-md pl-spacing-lg text-sm text-grayLight-900 font-medium">{allergen.name}</p>}>{`${allergen.formalNumber}`}</BadgeWithMessage>
                  )) : 'Brak alergenów'}
                </div>
              </React.Fragment>
            ))}
          </div>
        );
      },
      header: 'Alergeny',
    })
  ];

  useEffect(() => {
    fetchMealPlans();
  }, [ selectedTenant, mealPlanDate ]);

  return (
    <div className="ParentAbsences__absenceTable flex flex-col pb-spacing-5xl"
         style={{ flexGrow: 1, overflowY: 'auto', marginBottom: '20px' }}>
      <CustomHorizontalTabs
        onChange={(value) => setSelectedTenant(value)}
        data={parentDashboardContext ? parentDashboardContext.tenants.map(tenant => ({
          label: tenant.tenantName,
          value: tenant.tenantId,
        })) : null}
      />
      <TableContainer h='90%' overflowY="auto" className="Branches__table lg:p-spacing-3xl bg-white rounded-lg">
        <DataTable
          hideSearch
          filterComponent={
            <div className="flex gap-spacing-xl">
              <ChooseMonths mode='month' currentDate={mealPlanDate} setDate={setMealPlanDate} />
            </div>
          }
          disableFilters={true}
          data={mealPlans}
          columns={columns}
          isLoading={isLoading}
          extraThead={
            <Thead>
              <Th colSpan={columns.length}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5">
                    <Box>
                      <Text whiteSpace="normal" overflow="hidden" textOverflow="ellipsis"
                            className="font-semibold text-base text-grayLight-900">
                        Jadłospisy
                      </Text>
                    </Box>
                    <Box>
                      <Text whiteSpace="normal" overflow="hidden" textOverflow="ellipsis"
                            className="font-normal text-sm text-grayLight-700">
                        Zobacz jadłospis na najbliższe dni / najbliższy miesiąc
                      </Text>
                    </Box>
                  </div>
                </div>
              </Th>
            </Thead>
          }
        />
      </TableContainer>
    </div>
      );
};

      export default ParentMealPlan