import React from 'react';

interface Props {
  elements: JSX.Element[];
  divider: JSX.Element;
}

const Breadcrumbs = ({ elements, divider }: Props) => {
  const formatTable = (table: JSX.Element[]): JSX.Element[] => {
    const formattedTable = [];

    for (let i = 0; i < table.length; i++) {
      formattedTable.push(React.cloneElement(table[i], { key: `element-${i}` }));
      if (i !== table.length - 1) {
        formattedTable.push(React.cloneElement(divider, { key: `divider-${i}` }));
      }
    }

    return formattedTable;
  };

  return (
    <div className="Breadcrumbs flex gap-spacing-lg items-center">{formatTable(elements)}</div>
  );
};

export default Breadcrumbs;
