import React from 'react';
import { LanguageIconName } from '../../../shared';

const LanguageIcon = ({ className, name }: { className: string; name: LanguageIconName }) => {
  switch (name) {
    case 'pl':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <g clipPath="url(#clip0_2524_6170)">
            <path
              d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
              fill="#F0F0F0"
            />
            <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37262 24 0 18.6274 0 12" fill="#D80027" />
          </g>
          <defs>
            <clipPath id="clip0_2524_6170">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return <>.</>;
  }
};
export default LanguageIcon;
