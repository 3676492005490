import React, { useState, useEffect } from "react";
import { useApi } from "../../../../../../context/ApiProvider";
import { useEditAdministratorContext } from "../../../../../../context/EditAdministratorProvider";
import { ModuleInterface, ModuleType } from "../../../../../../shared/type/module.type";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import CheckboxInput from "../../../../../Forms/CheckboxInput/CheckboxInput";

const AdministratorRights = () => {
  const { apiTenantAdminController } = useApi();
  const { administrator, setAdministrator, setAssignedModules, fetchAdministrators } = useEditAdministratorContext();
  const [ modules, setModules] = useState<ModuleInterface[]>([]);
  const [ isModified, setIsModifies] = useState<boolean>(false)

  const fetchAvailableModules = async () => {
    try {
      const result = await apiTenantAdminController('modules').get('');
      setModules(result.data);
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  const toggleStringInArray = (arr: string[], str: string): string[] => {
    const index = arr.indexOf(str);

    if (index === -1) {
      return [...arr, str];
    } else {
      return arr.filter((item) => item !== str);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchAdministrators();
      await fetchAvailableModules();

      setAssignedModules(modules.filter(module => administrator.availableModules.includes(module.name)));
    }
    fetchData();
  }, []);

  useEffect(() => {
    setAssignedModules(modules.filter(module => administrator.availableModules.includes(module.name)));
  }, [administrator]);

  return (
    <div className="AdministratorSettings flex flex-col gap-spacing-xl">
      <div className="flex flex-row justify-between">
        <div className="AdministratorSettings__headerContainer flex flex-col gap-spacing-md">
          <h2 className="text-lg font-semibold text-grayLight-900">Uprawnienia admina do przypisanych oddziałów</h2>
          <p className="text-sm font-normal text-grayLight-600">Nadane uprawnienia dotyczą wszystkich przypisanych
            oddziałów.</p>
        </div>
        <div className="AdministratorSettings__actionButtons">
          <div className='flex gap-spacing-lg'>
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="tertiaryColor"
              size="md"
              buttonProps={{
                disabled: !isModified
              }}
            >
              Anuluj
            </CustomChakraButton>
            <CustomChakraButton
              iconPosition="onlyText"
              hierarchy="primary"
              size="md"
              buttonProps={{
                disabled: !isModified
              }}
            >
              Zapisz zmiany
            </CustomChakraButton>
          </div>
        </div>
      </div>
      <div className="AdministratorSettings__formContainer pt-spacing-xl flex flex-col gap-spacing-xl">
        {modules.map((module) => (
          <CheckboxInput
            key={module.name}
            textSize="sm"
            checkboxParams={{
              value: module.name,
              isChecked: administrator?.availableModules.includes(module.name),
              onChange: (e) => {
                  setIsModifies(true);
                  setAdministrator((prev) => ({
                    ...prev,
                    availableModules: toggleStringInArray(prev.availableModules, e.target.value) as ModuleType[]
                  }));
              }
            }}
            text={
              <div>
                <p className='font-medium text-sm text-grayLight-700'>{module.displayName}</p>
                <p className='font-normal text-sm text-grayLight-600'>{module.description}</p>
              </div>
            }
          />
        ))}
      </div>
    </div>
  )
};

export default AdministratorRights;
