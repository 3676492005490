import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import React from 'react';

interface Props {
  text: string | React.ReactNode;
  textSize?: 'sm' | 'lg';
  customTextSize?: string;
  checkboxPosition?: 'items-start' | 'items-center';
  link?: {
    text: string;
    url: string;
  };
  checkboxParams?: CheckboxProps;
}

const CheckboxInput = ({ text, link, textSize, checkboxPosition = 'items-start', checkboxParams , customTextSize}: Props) => (
  <div className={`flex flex-row ${checkboxPosition} gap-2`}>
    <Checkbox colorScheme="customOrange" {...checkboxParams} />
    <p
      style={{fontSize: `${customTextSize}`}}
      className={`${textSize ? `text-${textSize}` : 'text-sm'} font-medium text-grayLight-600 dark:text-grayDark-200`}
    >
      {text}{' '}
      {link ? (
        <a
          href={link.url}
          className="underline text-grayLight-800 font-semibold dark:text-grayDark-200 p-0 m-o"
        >
          {link.text}
        </a>
      ) : null}
    </p>
  </div>
);

export default CheckboxInput;
