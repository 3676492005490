import { HttpStatusCode } from "axios";
import React, { useState } from "react";
import { ApiOldAppImportControllerUrl } from "../../../api/axios.url";
import { useAlertContext } from "../../../context/AlertProvider";
import { useApi } from "../../../context/ApiProvider";
import CustomChakraButton from "../../CustomChakraButton/CustomChakraButton";
import FileInput from "../../FileInput/FileInput";

const ImportData = () => {
  const { apiOldAppImportController } = useApi();
  const { setShow, setAlertProperties } = useAlertContext();

  const [file, setFile] = useState<{
    saldo: FileList | undefined,
    data: FileList | undefined
  }>({
    saldo: undefined,
    data: undefined,
  });

  const [ textResponse, setTextResponse ] = useState<{
    saldo: string | undefined,
    data: string | undefined
  }>({
    saldo: undefined,
    data: undefined,
  })

  const handleImport = async (importType: ApiOldAppImportControllerUrl) => {
    let formData = new FormData();

    if(importType === 'import-company'){
      if (!file.data || file.data.length === 0) {
        return;
      }

      if (file.data) {
        formData.append("file", file.data[0]);
      }
    }

    if(importType === 'import-saldo-info'){
      if (!file.saldo || file.saldo.length === 0) {
        return;
      }

      if (file.saldo) {
        formData.append("file", file.saldo[0]);
      }
    }

    try {
      const response = await apiOldAppImportController(importType).post('',  formData);
      if(importType === 'import-saldo-info'){
        setTextResponse((prev) => ({...prev, saldo: response.data }))
      }
      if(importType === 'import-company'){
        setTextResponse((prev) => ({...prev, data: response.data }))
      }
      if(response.status !== HttpStatusCode.Ok){
        setAlertProperties({
          timeout: 5000,
          title: "Błąd",
          description: `Błąd importu pliku`,
          status: "error"
        });
        setShow(true);
      }
    } catch (error: any) {

    }
  }

  return (
    <section className='w-full h-full p-spacing-4xl items-start justify-center'>
      <div className="Settings__header flex justify-between w-full">
        <p className="m-0 p-0 font-semibold text-lg text-grayLight-900">Import danych</p>
      </div>
      <div className="Settings__formContainer w-full">
        <div className="w-full flex flex-col gap-spacing-4xl overflow-y-auto pb-spacing-11xl">
          <div className="grow flex flex-col gap-spacing-lg">
            <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
              Importuj dane
            </div>
            <div className='flex gap-spacing-5xl'>
              <div className="w-96">
                <FileInput
                  onFileUpload={(file) => {
                    setFile((prev) => ({ ...prev, data: file }));
                  }}
                  description="PDF, PNG, JPG lub plik Excela (max. 2000 mb)"
                />
                <p>{textResponse.data}</p>
              </div>
              <div>
                <CustomChakraButton
                  hierarchy="secondaryColor"
                  size="sm"
                  iconPosition="onlyText"
                  buttonProps={{
                    onClick: () => {handleImport('import-company')},
                    className: "grow",
                    disabled: !file.data,
                  }}
                >
                  Importuj dane
                </CustomChakraButton>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-spacing-4xl overflow-y-auto pb-spacing-11xl">
          <div className="grow flex flex-col gap-spacing-lg">
            <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
              Importuj salda
            </div>
            <div className='flex gap-spacing-5xl'>
              <div className="w-96">
                <FileInput
                  onFileUpload={(file) => {
                    setFile((prev) => ({ ...prev, saldo: file }));
                  }}
                  description="PDF, PNG, JPG lub plik Excela (max. 2000 mb)"
                />
                <p>{textResponse.saldo}</p>
              </div>
              <div>
                <CustomChakraButton
                  hierarchy="secondaryColor"
                  size="sm"
                  iconPosition="onlyText"
                  buttonProps={{
                    onClick: () => {handleImport('import-saldo-info')},
                    className: "grow",
                    disabled: !file.saldo,
                  }}
                >
                  Importuj salda
                </CustomChakraButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImportData;
