import React from 'react';
import IconComponent from '../../IconComponent/IconComponent';

interface Props {
  state: boolean;
  labelText: string;
}

const AutoCheck = ({ state, labelText }: Props) => (
  <div className="flex items-center gap-spacing-md text-sm font-normal">
    <IconComponent iconName={state ? 'checkIconTrue' : 'checkIconFalse'} />
    <p>{labelText}</p>
  </div>
);

export default AutoCheck;
