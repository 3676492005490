import { Link } from 'react-router-dom';
import React from 'react';
import { StatusCodes } from 'http-status-codes';
import IconComponent from '../../IconComponent/IconComponent';
import { useResetPasswordContext } from '../../../context/ResetPasswordProvider';

const ConfirmEmail = () => {
  const { email, resetPassword } = useResetPasswordContext();

  const resendMail = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await resetPassword();
    } catch (error: any) {
      switch (error.response?.status) {
        case StatusCodes.BAD_REQUEST:
        case StatusCodes.UNAUTHORIZED:
        case StatusCodes.NOT_FOUND:
        case StatusCodes.CONFLICT:
          console.warn('An expected error occurred');
          break;
        default:
          console.warn('An unexpected error occurred');
          break;
      }
    }
  };

  return (
    <div className="LoginPage__login-form w-full flex flex-col justify-between items-start">
      <div className="grid gap-8 m-auto w-full lg:pl-36 lg:pr-36 pl-container-padding-mobile pr-container-padding-mobile">
        <div className="LoginPage__header grid gap-spacing-3xl">
          <IconComponent iconName="mail" className="m-auto" />
          <div className="grid gap-3">
            <h2 className="font-semibold text-4xl text-grayLight-900 text-center">
              Sprawdź skrzynkę <br /> mailową
            </h2>
            <p className="font-normal text-base text-grayLight-600 text-center">
              Na adres {email} wysłaliśmy
              <br />
              wiadomość z linkiem do resetowania hasła.
              <br />
              <br />
              Mail nie doszedł? Sprawdź folder SPAM lub wyślij link ponownie.
            </p>
          </div>
        </div>
        <form className="max-w-2xl grid gap-spacing-3xl w-full" onSubmit={resendMail}>
          <button
            className="rounded bg-brand-300 border-brand-300 py-2.5 px-4 font-semibold text-base"
            type="submit"
          >
            Wyślij link ponownie
          </button>
        </form>
        <div className="flex flex-row justify-start">
          <Link to="/" className="flex m-auto">
            <IconComponent iconName="arrowLeft" />
            <p className="text-sm inline">Wróć do strony logowania</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
