import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App';
import ParentAbsences from "./component/ChildOwnerComponents/ParentAbsences/ParentAbsences";
import ParentAssignedAccounts from "./component/ChildOwnerComponents/ParentAssignedAccounts/ParentAssignedAccounts";
import ParentMailBox from "./component/ChildOwnerComponents/ParentMailBox/ParentMailBox";
import SingleMessage from "./component/ChildOwnerComponents/ParentMailBox/ParentMessage/SingleMessage/SingleMessage";
import ParentNotification from "./component/ChildOwnerComponents/ParentMailBox/ParentNotification/ParentNotification";
import SingleNotification
  from "./component/ChildOwnerComponents/ParentMailBox/ParentNotification/SingleNotification/SingleNotification";
import ParentMain from "./component/ChildOwnerComponents/ParentMain/ParentMain";
import ParentMessage from "./component/ChildOwnerComponents/ParentMailBox/ParentMessage/ParentMessage";
import ParentMealPlan from "./component/ChildOwnerComponents/ParentMealPlan/ParentMealPlan";
import OrderSummary from "./component/ChildOwnerComponents/ParentOrder/OrderSummary/OrderSummary";
import ParentOrder from "./component/ChildOwnerComponents/ParentOrder/ParentOrder";
import ParentOrderHistory from "./component/ChildOwnerComponents/ParentOrderHistory/ParentOrderHistory";
import ParentOrders from "./component/ChildOwnerComponents/ParentOrders/ParentOrders";
import ParentAccountSettings
  from "./component/ChildOwnerComponents/ParentSettings/ParentAccountSettings/ParentAccountSettings";
import ParentPasswordSettings
  from "./component/ChildOwnerComponents/ParentSettings/ParentPasswordSettings/ParentPasswordSettings";
import ParentSettings from "./component/ChildOwnerComponents/ParentSettings/ParentSettings";
import Registration from "./component/AutenticationComponents/Registration/Registration";
import Branches from './component/SuperAdminComponents/Branches/Branches';
import SingleBranch from './component/SuperAdminComponents/Branches/SingleBranch/SingleBranch';
import Companies from './component/SuperAdminComponents/Companies/Companies';
import InvoiceData from './component/SuperAdminComponents/Companies/SingleCompany/InvoiceData/InvoiceData';
import ListOfTenants from './component/SuperAdminComponents/Companies/SingleCompany/ListOfTenants/ListOfTenants';
import SingleCompany from './component/SuperAdminComponents/Companies/SingleCompany/SingleCompany';
import Absences from "./component/TenantAdminComponents/Absences/Absences";
import Allergens from './component/TenantAdminComponents/Kitchen/Allergens/Allergens';
import DishesDatabase from "./component/TenantAdminComponents/Kitchen/FoodMenuContainer/DishesDatabase/DishesDatabase";
import MealPlan from "./component/TenantAdminComponents/Kitchen/FoodMenuContainer/MealPlan/MealPlan";
import FoodMenuContainer from "./component/TenantAdminComponents/Kitchen/FoodMenuContainer/FoodMenuContainer";
import Kitchen from './component/TenantAdminComponents/Kitchen/Kitchen';
import MealCategories from './component/TenantAdminComponents/Kitchen/SalesSystem/MealCategories/MealCategories';
import Rates from './component/TenantAdminComponents/Kitchen/SalesSystem/Rates/Rates';
import SalesSystem from './component/TenantAdminComponents/Kitchen/SalesSystem/SalesSystem';
import Servings from './component/TenantAdminComponents/Kitchen/SalesSystem/Servings/Servings';
import MailBox from "./component/TenantAdminComponents/MailBox/MailBox";
import Messages from "./component/TenantAdminComponents/MailBox/Messages/Messages";
import SingleAdminMessage
  from "./component/TenantAdminComponents/MailBox/Messages/SingleAdminMessage/SingleAdminMessage";
import Notification from "./component/TenantAdminComponents/MailBox/Notification/Notification";
import Reports from "./component/TenantAdminComponents/Reports/Reports";
import DaysOff from './component/TenantAdminComponents/School/DaysOff/DaysOff';
import SchoolStatutes from './component/TenantAdminComponents/School/SchoolStatutes/SchoolStatutes';
import ListOfCoFinancingInstitutions
  from "./component/TenantAdminComponents/School/ListOfCoFinancingInstitutions/ListOfCoFinancingInstitutions";
import School from './component/TenantAdminComponents/School/School';
import SchoolClasses from './component/TenantAdminComponents/School/OrganisationUnits/OrganisationUnits';
import Settings from './component/TenantAdminComponents/School/Settings/Settings';
import Administrators from "./component/TenantAdminComponents/Users/Administrators/Administrators";
import AdministratorsTable
  from "./component/TenantAdminComponents/Users/Administrators/AdministratorsTable/AdministratorsTable";
import AdministratorData
  from "./component/TenantAdminComponents/Users/Administrators/SingleAdministrator/AdministratorData/AdministratorData";
import AdministratorLog
  from "./component/TenantAdminComponents/Users/Administrators/SingleAdministrator/AdministratorLog/AdministratorLog";
import AdministratorRights
  from "./component/TenantAdminComponents/Users/Administrators/SingleAdministrator/AdministratorRights/AdministratorRights";
import SingleAdministrator
  from "./component/TenantAdminComponents/Users/Administrators/SingleAdministrator/SingleAdministrator";
import AccountHistory
  from "./component/TenantAdminComponents/Users/AccountHistory/AccountHistory";
import BalanceHistory
  from "./component/TenantAdminComponents/Users/BalanceHistory/BalanceHistory";
import Children from "./component/TenantAdminComponents/Users/Children/Children";
import ChildrenTable from "./component/TenantAdminComponents/Users/Children/ChildrenTable/ChildrenTable";
import KidBalanceHistory
  from "./component/TenantAdminComponents/Users/Children/SingleKid/KidBalanceHistory/KidBalanceHistory";
import KidCoFinancing from "./component/TenantAdminComponents/Users/Children/SingleKid/KidCoFinancing/KidCoFinancing";
import KidData from "./component/TenantAdminComponents/Users/Children/SingleKid/KidData/KidData";
import KidOrders from "./component/TenantAdminComponents/Users/Children/SingleKid/KidOrders/KidOrders";
import KidParents from "./component/TenantAdminComponents/Users/Children/SingleKid/KidParents/KidParents";
import SingleKid from "./component/TenantAdminComponents/Users/Children/SingleKid/SingleKid";
import Employees from "./component/TenantAdminComponents/Users/Employees/Employees";
import EmployeesTable from "./component/TenantAdminComponents/Users/Employees/EmployeesTable/EmployeesTable";
import EmployeeData from "./component/TenantAdminComponents/Users/Employees/SingleEmployee/EmployeeData/EmployeeData";
import SingleEmployee from "./component/TenantAdminComponents/Users/Employees/SingleEmployee/SingleEmployee";
import Parents from "./component/TenantAdminComponents/Users/Parents/Parents";
import ParentsTable from "./component/TenantAdminComponents/Users/Parents/ParentsTable/ParentsTable";
import ListOfOrder
  from "./component/TenantAdminComponents/Users/Parents/ParentsTable/SingleParents/ListOfOrder/ListOfOrder";
import ParentData
  from "./component/TenantAdminComponents/Users/Parents/ParentsTable/SingleParents/ParentData/ParentData";
import ParentKinds
  from "./component/TenantAdminComponents/Users/Parents/ParentsTable/SingleParents/ParentKinds/ParentKinds";
import SingleTeacher from "./component/TenantAdminComponents/Users/Teachers/SingleTeacher/SingleTeacher";
import TeacherData from "./component/TenantAdminComponents/Users/Teachers/SingleTeacher/TeacherData/TeacherData";
import Teachers from "./component/TenantAdminComponents/Users/Teachers/Teachers";
import TeachersTable from "./component/TenantAdminComponents/Users/Teachers/TeachersTable/TeachersTable";
import Users from "./component/TenantAdminComponents/Users/Users";
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from './context/ThemeProvider';
import Error from './page/Error/Error';
import AuthenticationScreen from './page/AuthenticationScreen/AuthenticationScreen';
import ConfirmEmail from './component/AutenticationComponents/ConfirmEmail/ConfirmEmail';
import SetNewPassword from './component/AutenticationComponents/SetNewPassword/NewPassword';
import RecoverPassword from './component/AutenticationComponents/RecoverPassword/RecoverPassword';
import AdminDashboard from './page/AdminDashboard/AdminDashboard';
import ChildOwnerDashboard from './page/ChildOwnerDashboard/ChildOwnerDashboard';
import TenantAdminDashboard from './page/TenantAdminDashboard/TenantAdminDashboard';
import SuperAdminDashboard from './page/SuperAdminDashboard/SuperAdminDashboard';
import customTheme from './shared/style/theme';

import './shared/style/style.css'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: '/registration/:id',
        element: <AuthenticationScreen />,
        children: [
          {
            path: '/registration/:id',
            element: <Registration />,
          },
        ]
      },
      {
        path: '/login',
        element: <AuthenticationScreen />,
        children: [
          {
            path: '/login/registration/:id',
            element: <Registration />,
          },
          {
            path: '/login/recover',
            element: <RecoverPassword />,
            children: [
              {
                path: '/login/recover/confirm',
                element: <ConfirmEmail />
              },
              {
                path: '/login/recover/new-password',
                element: <SetNewPassword />
              }
            ]
          }
        ]
      },
      {
        path: '/admin',
        element: <AdminDashboard />
      },
      {
        path: '/parent',
        element: <ChildOwnerDashboard />,
        children: [
          {
            path: '/parent/main',
            element: <ParentMain/>,
            children: [
              {
                path: '/parent/main/tpay/success',
                element: <ParentMain/>,
              },
              {
                path: '/parent/main/tpay/error',
                element: <ParentMain/>,
              }
            ]
          },
          {
            path: '/parent/settings',
            element: <ParentSettings />,
            children: [
              {
                path: '/parent/settings/account-settings',
                element: <ParentAccountSettings />,
              },
              {
                path: '/parent/settings/password-settings',
                element: <ParentPasswordSettings />,
              }
            ]
          },
          {
            path: '/parent/order',
            element: <ParentOrder/>,
            children: [
              {
                path: '/parent/order/summary',
                element: <OrderSummary/>,
              }
            ]
          },
          {
            path: '/parent/orders',
            element: <ParentOrders/>
          },
          {
            path: '/parent/order-history',
            element: <ParentOrderHistory/>
          },
          {
            path: '/parent/meal-plan',
            element: <ParentMealPlan/>
          },
          {
            path: '/parent/mail-box/messages/message/:id/*',
            element: <SingleMessage/>,
          },
          {
            path: '/parent/mail-box/notifications/notification/:id',
            element: <SingleNotification/>,
          },
          {
            path: '/parent/mail-box',
            element: <ParentMailBox/>,
            children: [
              {
                path: '/parent/mail-box/messages',
                element: <ParentMessage tenantId='' setRefreshMessages={() => {}}/>,
              },
              {
                path: '/parent/mail-box/notifications',
                element: <ParentNotification tenantId=''/>,
              }
            ]
          },
          {
            path: '/parent/assigned-accounts',
            element: <ParentAssignedAccounts/>
          },
          {
            path: '/parent/absences',
            element: <ParentAbsences/>
          }
        ]
      },
      {
        path: '/tenantAdmin',
        element: <TenantAdminDashboard />,
        children: [
          {
            path: '/tenantAdmin/id/:id/reports',
            element: <Reports/>,
          },
          {
            path: '/tenantAdmin/id/:id/mail-box',
            element: <MailBox/>,
            children: [
              {
                path: '/tenantAdmin/id/:id/mail-box/messages',
                element: <Messages tenantId='' setRefreshMessages={() => {}}/>,
                children: [
                  {
                    path: '/tenantAdmin/id/:id/mail-box/messages/message/:id/*',
                    element: <SingleAdminMessage/>,
                  },
                ]
              },
              {
                path: '/tenantAdmin/id/:id/mail-box/notification',
                element: <Notification/>,
              },
            ]
          },
          {
            path: '/tenantAdmin/id/:id/school',
            element: <School />,
            children: [
              {
                path: '/tenantAdmin/id/:id/school/id/:id',
                element: <DaysOff />
              },
              {
                path: '/tenantAdmin/id/:id/school/free-days',
                element: <DaysOff />
              },
              {
                path: '/tenantAdmin/id/:id/school/school-settings',
                element: <Settings />
              },
              {
                path: '/tenantAdmin/id/:id/school/school-statutes',
                element: <SchoolStatutes />
              },
              {
                path: '/tenantAdmin/id/:id/school/classes',
                element: <SchoolClasses />
              },
              {
                path: '/tenantAdmin/id/:id/school/co-financing-institutions',
                element: <ListOfCoFinancingInstitutions />
              }
            ]
          },
          {
            path: '/tenantAdmin/id/:id/absences',
            element: <Absences />
          },
          {
            path: '/tenantAdmin/id/:id/users',
            element: <Users/>,
            children: [
              {
                path: '/tenantAdmin/id/:id/users/administrators',
                element: <Administrators />,
                children: [
                  {
                    path: '/tenantAdmin/id/:id/users/administrators/administrators-table',
                    element: <AdministratorsTable />,
                  },
                  {
                    path: '/tenantAdmin/id/:id/users/administrators/single-administrator/:adminId',
                    element: <SingleAdministrator />,
                    children: [
                      {
                        path: '/tenantAdmin/id/:id/users/administrators/single-administrator/:adminId/administrator-rights',
                        element: <AdministratorRights />,
                      },
                      {
                        path: '/tenantAdmin/id/:id/users/administrators/single-administrator/:adminId/administrator-data',
                        element: <AdministratorData />,
                      },
                      {
                        path: '/tenantAdmin/id/:id/users/administrators/single-administrator/:adminId/assigned-branches',
                        element: <AdministratorData />,
                      }
                    ]
                  }
                ]
              },
              {
                path: '/tenantAdmin/id/:id/users/teachers',
                element: <Teachers />,
                children: [
                  {
                    path: '/tenantAdmin/id/:id/users/teachers/teachers-table',
                    element: <TeachersTable />,
                  },
                  {
                    path: '/tenantAdmin/id/:id/users/teachers/single-teacher/:teacherId/',
                    element: <SingleTeacher />,
                    children: [
                      {
                        path: '/tenantAdmin/id/:id/users/teachers/single-teacher/:teacherId/balance-history',
                        element: <BalanceHistory  consumerId={0} userId={1}/>,
                      },
                      {
                        path: '/tenantAdmin/id/:id/users/teachers/single-teacher/:teacherId/account-history',
                        element: <AccountHistory />,
                      },
                      {
                        path: '/tenantAdmin/id/:id/users/teachers/single-teacher/:teacherId/teacher-data',
                        element: <TeacherData />,
                      }
                    ]
                  }
                ]
              },
              {
                path: '/tenantAdmin/id/:id/users/parents',
                element: <Parents />,
                children: [
                  {
                    path: '/tenantAdmin/id/:id/users/parents/parents-table',
                    element: <ParentsTable />,
                  },
                  {
                    path: '/tenantAdmin/id/:id/users/parents/single-parent/:parentId',
                    element: <ParentsTable />,
                    children: [
                      {
                        path: '/tenantAdmin/id/:id/users/parents/single-parent/:parentId/list-of-orders',
                        element: <ListOfOrder />,
                      },
                      {
                        path: '/tenantAdmin/id/:id/users/parents/single-parent/:parentId/parent-kinds',
                        element: <ParentKinds />,
                      },
                      {
                        path: '/tenantAdmin/id/:id/users/parents/single-parent/:parentId/parent-data',
                        element: <ParentData />,
                      }
                    ]
                  }
                ]
              },
              {
                path: '/tenantAdmin/id/:id/users/children',
                element: <Children />,
                children: [
                  {
                    path: '/tenantAdmin/id/:id/users/children/children-table',
                    element: <ChildrenTable />,
                  },
                  {
                    path: '/tenantAdmin/id/:id/users/children/single-kid/:kidId',
                    element: <SingleKid />,
                    children: [
                      {
                        path: '/tenantAdmin/id/:id/users/children/single-kid/:kidId/orders',
                        element: <KidOrders />,
                      },
                      {
                        path: '/tenantAdmin/id/:id/users/children/single-kid/:kidId/transactions',
                        element: <KidBalanceHistory/>,
                      },
                      {
                        path: '/tenantAdmin/id/:id/users/children/single-kid/:kidId/parents',
                        element: <KidParents />,
                      },
                      {
                        path: '/tenantAdmin/id/:id/users/children/single-kid/:kidId/cofinancing',
                        element: <KidCoFinancing />,
                      },
                      {
                        path: '/tenantAdmin/id/:id/users/children/single-kid/:kidId/history',
                        element: <AccountHistory />,
                      },
                      {
                        path: '/tenantAdmin/id/:id/users/children/single-kid/:kidId/data',
                        element: <KidData />,
                      },
                    ]
                  }
                ]
              },
              {
                path: '/tenantAdmin/id/:id/users/employees',
                element: <Teachers />,
                children: [
                  {
                    path: '/tenantAdmin/id/:id/users/employees/employees-table',
                    element: <EmployeesTable />,
                  },
                  {
                    path: '/tenantAdmin/id/:id/users/employees/single-employee/:employeeId/',
                    element: <SingleEmployee />,
                    children: [
                      {
                        path: '/tenantAdmin/id/:id/users/employees/single-employee/:employeeId/balance-history',
                        element: <BalanceHistory  consumerId={0} userId={1}/>,
                      },
                      {
                        path: '/tenantAdmin/id/:id/users/employees/single-employee/:employeeId/account-history',
                        element: <AccountHistory />,
                      },
                      {
                        path: '/tenantAdmin/id/:id/users/employees/single-employee/:employeeId/employee-data',
                        element: <EmployeeData />,
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: '/tenantAdmin/id/:id/kitchen',
            element: <Kitchen />,
            children: [
              {
                path: '/tenantAdmin/id/:id/kitchen/sales-system',
                element: <SalesSystem />,
                children: [
                  {
                    path: '/tenantAdmin/id/:id/kitchen/sales-system/servings',
                    element: <Servings />
                  },
                  {
                    path: '/tenantAdmin/id/:id/kitchen/sales-system/rates',
                    element: <Rates />
                  },
                  {
                    path: '/tenantAdmin/id/:id/kitchen/sales-system/meal-categories',
                    element: <MealCategories />
                  }
                ]
              },
              {
                path: '/tenantAdmin/id/:id/kitchen/allergens',
                element: <Allergens />
              },
              {
                path: '/tenantAdmin/id/:id/kitchen/food-menu',
                element: <FoodMenuContainer />,
                children: [
                  {
                    path: '/tenantAdmin/id/:id/kitchen/food-menu/meal-plan',
                    element: <MealPlan />,
                  },
                  {
                    path: '/tenantAdmin/id/:id/kitchen/food-menu/dishes-database',
                    element: <DishesDatabase />,
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: '/superAdmin',
        element: <SuperAdminDashboard />,
        children: [
          {
            path: '/superAdmin/import-data',
            element: <Branches />,
          },
          {
            path: '/superAdmin/branches',
            element: <Branches />,
            children: [
              {
                path: '/superAdmin/branches/id/:id',
                element: <SingleBranch />
              },
              {
                path: '/superAdmin/branches/id/:id/reports',
                element: <Reports/>,
              },
              {
                path: '/superAdmin/branches/id/:id/mail-box',
                element: <MailBox/>,
                children: [
                  {
                    path: '/superAdmin/branches/id/:id/mail-box/messages',
                    element: <Messages tenantId='' setRefreshMessages={() => {}}/>,
                    children: [
                      {
                        path: '/superAdmin/branches/id/:id/mail-box/messages/message/:id/*',
                        element: <SingleAdminMessage/>,
                      },
                    ]
                  },
                  {
                    path: '/superAdmin/branches/id/:id/mail-box/notification',
                    element: <Notification/>,
                  },
                ]
              },
              {
                path: '/superAdmin/branches/id/:id/school',
                element: <School />,
                children: [
                  {
                    path: '/superAdmin/branches/id/:id/school/classes',
                    element: <SchoolClasses />
                  },
                  {
                    path: '/superAdmin/branches/id/:id/school/co-financing-institutions',
                    element: <ListOfCoFinancingInstitutions />
                  },
                  {
                    path: '/superAdmin/branches/id/:id/school/free-days',
                    element: <DaysOff />
                  },
                  {
                    path: '/superAdmin/branches/id/:id/school/school-statutes',
                    element: <SchoolStatutes />
                  },
                  {
                    path: '/superAdmin/branches/id/:id/school/school-settings',
                    element: <Settings />
                  }
                ]
              },
              {
                path: '/superAdmin/branches/id/:id/absences',
                element: <Absences />
              },
              {
                path: '/superAdmin/branches/id/:id/users',
                element: <Users/>,
                children: [
                  {
                    path: '/superAdmin/branches/id/:id/users/administrators',
                    element: <Administrators />,
                    children: [
                      {
                        path: '/superAdmin/branches/id/:id/users/administrators/administrators-table',
                        element: <AdministratorsTable />,
                      },
                      {
                        path: '/superAdmin/branches/id/:id/users/administrators/single-administrator/:adminId',
                        element: <SingleAdministrator />,
                        children: [
                          {
                            path: '/superAdmin/branches/id/:id/users/administrators/single-administrator/:adminId/administrator-rights',
                            element: <AdministratorRights />,
                          },
                          {
                            path: '/superAdmin/branches/id/:id/users/administrators/single-administrator/:adminId/administrator-data',
                            element: <AdministratorData />,
                          },
                          {
                            path: '/superAdmin/branches/id/:id/users/administrators/single-administrator/:adminId/assigned-branches',
                            element: <AdministratorData />,
                          },
                          {
                            path: '/superAdmin/branches/id/:id/users/administrators/single-administrator/:adminId/administrator-log',
                            element: <AdministratorLog />,
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: '/superAdmin/branches/id/:id/users/children',
                    element: <Children />,
                    children: [
                      {
                        path: '/superAdmin/branches/id/:id/users/children/children-table',
                        element: <ChildrenTable />,
                      },
                      {
                        path: '/superAdmin/branches/id/:id/users/children/single-kid/:kidId',
                        element: <SingleKid />,
                        children: [
                          {
                            path: '/superAdmin/branches/id/:id/users/children/single-kid/:kidId/orders',
                            element: <KidOrders />,
                          },
                          {
                            path: '/superAdmin/branches/id/:id/users/children/single-kid/:kidId/transactions',
                            element: <BalanceHistory consumerId={0} userId={1}/>,
                          },
                          {
                            path: '/superAdmin/branches/id/:id/users/children/single-kid/:kidId/parents',
                            element: <KidParents />,
                          },
                          {
                            path: '/superAdmin/branches/id/:id/users/children/single-kid/:kidId/cofinancing',
                            element: <KidCoFinancing />,
                          },
                          {
                            path: '/superAdmin/branches/id/:id/users/children/single-kid/:kidId/history',
                            element: <AccountHistory />,
                          },
                          {
                            path: '/superAdmin/branches/id/:id/users/children/single-kid/:kidId/data',
                            element: <KidData />,
                          },
                        ]
                      }
                    ]
                  },
                  {
                    path: '/superAdmin/branches/id/:id/users/teachers',
                    element: <Teachers />,
                    children: [
                      {
                        path: '/superAdmin/branches/id/:id/users/teachers/teachers-table',
                        element: <TeachersTable />,
                      },
                      {
                        path: '/superAdmin/branches/id/:id/users/teachers/single-teacher/:teacherId/',
                        element: <SingleTeacher />,
                        children: [
                          {
                            path: '/superAdmin/branches/id/:id/users/teachers/single-teacher/:teacherId/balance-history',
                            element: <KidBalanceHistory/>,
                          },
                          {
                            path: '/superAdmin/branches/id/:id/users/teachers/single-teacher/:teacherId/account-history',
                            element: <AccountHistory />,
                          },
                          {
                            path: '/superAdmin/branches/id/:id/users/teachers/single-teacher/:teacherId/teacher-data',
                            element: <TeacherData />,
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: '/superAdmin/branches/id/:id/users/parents',
                    element: <Parents />,
                    children: [
                      {
                        path: '/superAdmin/branches/id/:id/users/parents/parents-table',
                        element: <ParentsTable />,
                      },
                      {
                        path: '/superAdmin/branches/id/:id/users/parents/single-parent/:parentId',
                        element: <ParentsTable />,
                        children: [
                          {
                            path: '/superAdmin/branches/id/:id/users/parents/single-parent/:parentId/list-of-orders',
                            element: <ListOfOrder />,
                          },
                          {
                            path: '/superAdmin/branches/id/:id/users/parents/single-parent/:parentId/parent-kinds',
                            element: <ParentKinds />,
                          },
                          {
                            path: '/superAdmin/branches/id/:id/users/parents/single-parent/:parentId/parent-data',
                            element: <ParentData />,
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: '/superAdmin/branches/id/:id/users/employees',
                    element: <Employees />,
                    children: [
                      {
                        path: '/superAdmin/branches/id/:id/users/employees/employees-table',
                        element: <EmployeesTable />,
                      },
                      {
                        path: '/superAdmin/branches/id/:id/users/employees/single-employee/:employeeId/',
                        element: <SingleEmployee />,
                        children: [
                          {
                            path: '/superAdmin/branches/id/:id/users/employees/single-employee/:employeeId/balance-history',
                            element: <BalanceHistory  consumerId={0} userId={1}/>,
                          },
                          {
                            path: '/superAdmin/branches/id/:id/users/employees/single-employee/:employeeId/account-history',
                            element: <AccountHistory />,
                          },
                          {
                            path: '/superAdmin/branches/id/:id/users/employees/single-employee/:employeeId/employee-data',
                            element: <EmployeeData />,
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                path: '/superAdmin/branches/id/:id/kitchen',
                element: <Kitchen />,
                children: [
                  {
                    path: '/superAdmin/branches/id/:id/kitchen/sales-system',
                    element: <SalesSystem />,
                    children: [
                      {
                        path: '/superAdmin/branches/id/:id/kitchen/sales-system/servings',
                        element: <Servings />
                      },
                      {
                        path: '/superAdmin/branches/id/:id/kitchen/sales-system/rates',
                        element: <Rates />
                      },
                      {
                        path: '/superAdmin/branches/id/:id/kitchen/sales-system/meal-categories',
                        element: <MealCategories />
                      }
                    ]
                  },
                  {
                    path: '/superAdmin/branches/id/:id/kitchen/allergens',
                    element: <Allergens />
                  },
                  {
                    path: '/superAdmin/branches/id/:id/kitchen/food-menu',
                    element: <FoodMenuContainer />,
                    children: [
                      {
                        path: '/superAdmin/branches/id/:id/kitchen/food-menu/meal-plan',
                        element: <MealPlan />,
                      },
                      {
                        path: '/superAdmin/branches/id/:id/kitchen/food-menu/dishes-database',
                        element: <DishesDatabase />,
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: '/superAdmin/companies',
            element: <Companies />,
            children: [
              {
                path: '/superAdmin/companies/id/:id/company-data',
                element: <SingleCompany />
              },
              {
                path: '/superAdmin/companies/id/:id/invoices-data',
                element: <InvoiceData />
              },
              {
                path: '/superAdmin/companies/id/:id/branch-list',
                element: <ListOfTenants />
              }
            ]
          }
        ]
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
    <ChakraProvider theme={customTheme}>
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>
    </ChakraProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
