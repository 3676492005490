import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter, Modal
} from "@chakra-ui/react";
import { AxiosResponse, HttpStatusCode } from "axios";
import { Select } from "chakra-react-select";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../context/ApiProvider";
import { MealBaseInterface } from "../../../../../../shared/type/mealBase.type";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";

interface Props {
  isOpen: boolean,
  onClose: () => void,
  currentChosenMealBaseId: number,
  mealIds: number[]
}

const CopyMealFromBaseToBase = ({ isOpen, onClose, currentChosenMealBaseId, mealIds }: Props) => {
  const { setShow, setAlertProperties } = useAlertContext();
  const { id: tenantId } = useParams();
  const { apiTenantMealMealController, apiTenantMealController } = useApi();
  const [ mealBases, setMealBases ] = useState<MealBaseInterface[]>([]);

  const [ chosenMealBases, setChosenMealBases ] = useState<number[]>([])

  const fetchMealBaseList = async () => {
    try {
      const response: AxiosResponse<MealBaseInterface[]> = await apiTenantMealMealController('list').get('', {
        params: {
          tenantId
        }
      })
      setMealBases(response.data)
    } catch (error) {

    } finally {
    }
  };

  const handleCopy = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    for (const mealBaseId of chosenMealBases) {
      console.log({
        targetMealMenuId: mealBaseId,
        mealIds
      });
      try {
        const response = await apiTenantMealController('copy-meal').post('', {
          targetMealMenuId: mealBaseId,
          mealIds
        });

        if (response.status === HttpStatusCode.Ok) {
          setAlertProperties({
            timeout: 2000,
            title: "Sukces",
            description: `Poprawnie skopiowano poprawy`,
            status: "success"
          });
          setShow(true);
          onClose();
        } else {
          setAlertProperties({
            timeout: 2000,
            title: "Bład",
            description: `Błąd kopiowania potraw`,
            status: "error"
          });
          setShow(true);
        }
      } catch (error) {
        setAlertProperties({
          timeout: 2000,
          title: "Bład",
          description: `Błąd kopiowania potraw`,
          status: "error"
        });
        setShow(true);
      }
    }
  }


  useEffect(() => {
    fetchMealBaseList();
  }, []);

  return(
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <form onSubmit={handleCopy}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">Kopiuj do bazy</h2>
            <p className='text-grayLight-600 text-sm font-normal'>Wybierz bazę, do której chcesz zduplikować wybrane danie/dania.</p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <Select
              colorScheme="gray"
              isMulti
              placeholder="Wybierz..."
              value={mealBases
                .filter((unit) => chosenMealBases.includes(unit.id))
                .map((unit) => ({
                  label: unit.name,
                  value: unit.id
                }))}
              onChange={(value) => {
                setChosenMealBases(value.map((item: any) => item.value));
              }}
              options={mealBases.filter(base => base.id !==  currentChosenMealBaseId).map((base) => ({
                label: base.name,
                value: base.id,
                variant: "outline"
              }))}
            />
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: onClose
              }}
            >
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                disabled: chosenMealBases.length === 0,
                className: "grow",
                type: "submit"
              }}
            >
              Dodaj
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
};

export default CopyMealFromBaseToBase;
