const VerticalDotsIcon = ({ className }: { className: string }) => (
  <svg
    width="4"
    height="16"
    viewBox="0 0 4 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.00002 8.83337C2.46026 8.83337 2.83335 8.46028 2.83335 8.00004C2.83335 7.5398 2.46026 7.16671 2.00002 7.16671C1.53978 7.16671 1.16669 7.5398 1.16669 8.00004C1.16669 8.46028 1.53978 8.83337 2.00002 8.83337Z"
      stroke="#98A2B3"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.00002 3.00004C2.46026 3.00004 2.83335 2.62694 2.83335 2.16671C2.83335 1.70647 2.46026 1.33337 2.00002 1.33337C1.53978 1.33337 1.16669 1.70647 1.16669 2.16671C1.16669 2.62694 1.53978 3.00004 2.00002 3.00004Z"
      stroke="#98A2B3"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.00002 14.6667C2.46026 14.6667 2.83335 14.2936 2.83335 13.8334C2.83335 13.3731 2.46026 13 2.00002 13C1.53978 13 1.16669 13.3731 1.16669 13.8334C1.16669 14.2936 1.53978 14.6667 2.00002 14.6667Z"
      stroke="#98A2B3"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default VerticalDotsIcon;
