import { HttpStatusCode } from "axios";
import React from "react";
import { useAlertContext } from "../../../../context/AlertProvider";
import { useApi } from "../../../../context/ApiProvider";
import { useParentDashboardContextContext } from "../../../../context/ParentDashboardContext";
import { CustomButtonType } from "../../../../shared/type/customButton.type";
import { ClosestStakeToTakeInterface } from "../../../../shared/type/parentDashboardContext.type";

import './StakeCharacterCard.css';
import GetButtonMessage from "../../../../utils/GetButtonMessage";
import CustomBadge from "../../../CustomBadge/CustomBadge";
import CustomChakraButton from "../../../CustomChakraButton/CustomChakraButton";

const StakeCharacterCard = ({ stake, consumerId } : {
  stake: ClosestStakeToTakeInterface,
  consumerId: number,
}) => {
  const { apiAbsenceAndCourseController } = useApi();
  const {dayOfTheWeekName, date, stakeName, actualPrice, oldPrice, courses, boughtStakeId, absence, absenceChangePossible } = stake;
  const { fetchParentDashboardContext } = useParentDashboardContextContext()
  const { setAlertProperties, setShow: setShowAlert } = useAlertContext();

  const reportAbsence = async () => {
    try {
      const response = await apiAbsenceAndCourseController('report-or-revoke-absence').post('',
        {
          consumerId: consumerId,
          boughtStakesId: [boughtStakeId],
          absence: !absence,
        });
      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          description: `Poprawnie ${absence ? 'odwołano' : 'zgłoszono'} nieobecność "${stakeName}"`,
          timeout: 2000,
          title: "Sukces",
          status: "success",
        });
        setShowAlert(true);
        fetchParentDashboardContext();
      }
    } catch (error: any) {
      setAlertProperties({
        description: `Wystąpił błąd podczas ${absence ? 'odwoływania' : 'zgłaszania'} nieobecności - ${error.response.data.errors[0]}`,
        timeout: 5000,
        title: "Error",
        status: "error",
      });
      setShowAlert(true);
    }
  };

  const getButton = (absencePossible: boolean, presence: boolean,) => {
    let button: CustomButtonType = {
      size: 'sm',
      iconPosition: 'left',
      icon: 'xClose',
      iconColor: '#B42318',
      hierarchy: 'destructiveSecondary',
    };

    let buttonText = 'Odwołaj';

    if (!absencePossible && presence) {
      button.iconColor = '#98A2B3';
      button.buttonProps = {
        disabled: true,
      };
    }

    if (absencePossible && !presence) {
      buttonText = 'Przywróć';
      button.hierarchy = 'primary';
      button.icon = 'rollBack';
      button.iconPosition = 'right';
      button.iconColor = '#292524';
    }

    if (!absencePossible && !presence) {
      buttonText = 'Przywróć';
      button.hierarchy= 'primary';
      button.icon = 'rollBack';
      button.iconPosition = 'right';
      button.iconColor = '#98A2B3';
      button.buttonProps = {
        disabled: true,
      };
    }

    return (
      <CustomChakraButton
        buttonProps={{
          type: 'button',
          ...button.buttonProps,
          onClick: reportAbsence
        }}
        size={button.size}
        iconPosition={button.iconPosition}
        iconColor={button.iconColor}
        icon={button.icon}
        hierarchy={button.hierarchy}>
        {buttonText}
      </CustomChakraButton>
    );
  };


  return (
    <div className='StakeCharacterCard flex flex-col p-spacing-xl gap-spacing-xs rounded-xl w-96 bg-white'>
      <div className='flex gap-spacing-xs'>
        <p className='text-grayLight-900 text-sm'>{dayOfTheWeekName}</p>
        <p className='text-grayLight-600 text-sm'>{date}</p>
      </div>
      <div className='flex flex-col justify-between grow'>
        <div className='flex flex-col gap-spacing-xs'>
          <div className='flex justify-between'>
            <div>
              <p className='text-lg text-grayLight-900 font-semibold'>{stakeName}</p>
            </div>
            <div className='flex gap-spacing-xs'>
              <p className='text-lg text-grayLight-500 font-medium line-through'>{oldPrice}</p>
              <p className='text-lg text-grayLight-900 font-semibold'>{actualPrice}</p>
            </div>
          </div>
          <div className='flex flex-col gap-spacing-xs overflow-y-scroll max-h-40'>
            {courses.map(course => (
              <div key={`Course-${course.mealToDayId}`}>
                <div className='flex justify-end border-b box-border border-brand-300'>
                  <p className="text-sm font-normal text-grayLight-500">{course.courseName}</p>
                </div>
                <div className='flex flex-col'>
                  <div className='flex'>
                    <p className='StakeCharacterCard__16 text-grayLight-600 font-semibold'>{course.mealDto.name}</p>
                    <div className='flex gap-spacing-xs'>
                      {course.mealDto.allergens.map(allergen => <CustomBadge key={`AllergenKey-${allergen.id}`} color='brand' size='sm' children={allergen.formalNumber}/>)}
                    </div>
                  </div>
                  <div>
                    <p className='font-normal text-grayLight-600' style={{fontSize: '16px'}}>{course.mealDto.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='flex flex-col items-end justify-end'>
          <div>
            {getButton(absenceChangePossible, !absence)}
          </div>
          <div>
            <GetButtonMessage absencePossible={absenceChangePossible} presence={!absence} />
          </div>
        </div>
      </div>
    </div>
  )
};

export default StakeCharacterCard;