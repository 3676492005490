import { Spinner } from '@chakra-ui/react';

interface Props {
  children: JSX.Element;
  state: boolean;
}

const LoadingSpinner = ({ children, state }: Props) => {
  return <>{state ? <Spinner /> : children}</>;
};

export default LoadingSpinner;
