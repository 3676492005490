import { PurchasableItemInterface, ParentOrderContextInterface } from "../../shared/type/parentOrderContext.type";

export const getAvailableMeals = (parentOrderContext: ParentOrderContextInterface | undefined, consumerId: number | undefined, selectedDates: Date[]): PurchasableItemInterface[] => {
  const orderDaysForConsumer = parentOrderContext?.consumerOrderContexts?.find(
    consumer => consumer.consumerId === consumerId
  )?.orderDays;

  if (!orderDaysForConsumer) {
    return [];
  }

  const formatDate = (date: Date) => date.toLocaleDateString('en-CA');

  const selectedDatesFormatted = selectedDates.map(formatDate);

  const availableDays = orderDaysForConsumer.filter(orderDay =>
    selectedDatesFormatted.includes(orderDay.when)
  );

  if (availableDays.length === 0) {
    return [];
  }

  const mealsForAllDays = availableDays.map(day => day.purchasableItems || []);

  const getStakeIds = (meals: PurchasableItemInterface[]) => meals.map(meal => meal.stakeId);

  const stakeIdsForEachDay = mealsForAllDays.map(getStakeIds);

  const commonStakeIds = stakeIdsForEachDay.reduce((commonIds, stakeIds) => {
    return commonIds.filter(id => stakeIds.includes(id));
  });

  return mealsForAllDays[0].filter(meal => commonStakeIds.includes(meal.stakeId));
};
