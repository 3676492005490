import React, { useEffect } from "react";
import { Routes, Route, useParams, Link } from "react-router-dom";
import {
  useEditTeacherOrEmployeeContext
} from "../../../../../context/EditTeacherOrEmployeeProvider";
import useAuth from "../../../../../hooks/useAuth";
import Breadcrumbs from "../../../../Breadcrumbs/Breadcrumbs";
import IconComponent from "../../../../IconComponent/IconComponent";
import Navigation from "../../../../Navigation/Navigation";
import AccountHistory from "../../AccountHistory/AccountHistory";
import BalanceHistory from "../../BalanceHistory/BalanceHistory";
import TeacherData from "./TeacherData/TeacherData";

interface NavOptionsInterface {
  link: string;
  name: string;
  component: React.ReactElement;
}

const SingleTeacher = () => {
  const { auth } = useAuth();
  const { setId, setMode, user } = useEditTeacherOrEmployeeContext()
  const { teacherId, id } = useParams<{ teacherId: string, id: string }>();

  const navOptions: NavOptionsInterface[] = [
    {
      link: 'balance-history',
      name: 'Historia salda',
      component: <BalanceHistory consumerId={user?.consumerId || 0} userId={user?.userId || 0}/>
    },
    {
      link: 'account-history',
      name: 'Historia konta',
      component: <AccountHistory />
    },
    {
      link: 'teacher-data',
      name: 'Dane użytkownika',
      component: <TeacherData />
    }
  ];

  useEffect(() => {
    setId(Number(teacherId));
    setMode("teacher");
  }, []);

  useEffect(() => {
    setId(Number(teacherId));
    setMode("teacher");
  }, [teacherId, setId, setMode]);

  return (
    <section className="SingleCompany">
      <div
        className="SingleCompany__container pt-spacing-xl pr-spacing-3xl pb-spacing-xl pl-spacing-3xl gap-spacing-4xl flex flex-col">
        <div className="SingleCompany__actualCompanyStatus">
          <Breadcrumbs
            divider={<IconComponent iconName="chevronRight" />}
            elements={[
              <IconComponent iconName="home" />,
              <p className="font-medium text-sm text-grayLight-600">Nauczyciele</p>,
              <p
                className="font-semibold text-sm text-grayWarm-800">
                {/* {user.firstName} {user.lastName} */}
              </p>
            ]}
          />
        </div>
        <div className="SingleCompany__container flex flex-col gap-spacing-4xl">
          <Link to={auth.loggedUserDto.superAdmin ? `/superAdmin/branches/id/${id}/users/teachers/teachers-table` : `/tenantAdmin/id/${id}/users/teachers/teachers-table`} className="SingleCompany__backButtonContainer flex gap-spacing-md font-semibold text-base text-grayWarm-950">
            <IconComponent iconName="arrowLeft" />
            <p>Wróć</p>
          </Link>
          <div className="SingleCompany__contextContainer flex flex-col gap-spacing-xl">
            <Navigation navOptions={navOptions} />
            <Routes>
              {navOptions.map((item) => (
                <Route key={item.link} path={`/${item.link}`} element={item.component} />
              ))}
            </Routes>
          </div>
        </div>
      </div>
    </section>
  )
};

export default SingleTeacher;