import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import React from "react";
import {
  OrderHistoryInterface
} from "../../../../../../../../shared/type/order.type";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  orderDetailsList: OrderHistoryInterface | undefined;
}

const ShowListOfOrderDetails = ({ isOpen, onClose, orderDetailsList }: Props) => {
  const orderDate = new Date(orderDetailsList?.orderDto.creationDate || '');

  const data = orderDetailsList?.orderDto.boughtStakeDays.flatMap(day =>
    day.boughtStakes.map(item => ({
      date: new Date(day.when).toLocaleDateString(),
      name: item.name,
      price: item.price,
      paidAmount: item.paidAmount,
    }))
  ) || [];

  const groupedData: { [key: string]: { date: string, rows: Array<{ name: string, price: number, paidAmount: number }> } } = {};
  data.forEach(row => {
    if (!groupedData[row.date]) {
      groupedData[row.date] = { date: row.date, rows: [] };
    }
    groupedData[row.date].rows.push({
      name: row.name,
      price: row.price,
      paidAmount: row.paidAmount,
    });
  });

  const tableRows = [];
  for (const date in groupedData) {
    const { rows } = groupedData[date];
    tableRows.push({ date, rowSpan: rows.length, rowData: rows[0] });
    for (let i = 1; i < rows.length; i++) {
      tableRows.push({ date: '', rowSpan: 0, rowData: rows[i] });
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
      <form onSubmit={() => {}}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className="flex">
            <div className='flex flex-col text-grayLight-900 font-semibold text-lg border-r border-grayLight-200'>
              <h2 className="font-semibold text-lg">
                Zamówienie {orderDate.toLocaleDateString()} {orderDate.getHours()}:{orderDate.getMinutes()} {orderDetailsList?.paymentTitle}
              </h2>
              <p className="font-normal text-grayLight-700 text-sm">
                Dla: {orderDetailsList?.consumerDto.firstName} {orderDetailsList?.consumerDto.lastName}
              </p>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            className="flex w-full p-0 m-0"
            style={{
              paddingInlineEnd: "0px",
              paddingInlineStart: "0px",
              paddingLeft: "0px",
              paddingRight: "0px",
              maxHeight: "80vh", // Maksymalna wysokość modala
              overflowY: "auto"  // Przewijanie pionowe, gdy zawartość przekracza wysokość
            }}
          >
            <div className="w-full overflow-x-auto">
              <table className="w-full border border-grayLight-200 border-collapse">
                <thead className='bg-blue-25 sticky top-0 z-10'>
                <tr>
                  <th className="pt-spacing-lg pr-spacing-md pb-spacing-lg pl-spacing-md border border-grayLight-200">
                    <p className="text-sm font-medium text-grayLight-600">Dzień</p>
                  </th>
                  <th className="pt-spacing-lg pr-spacing-md pb-spacing-lg pl-spacing-md border border-grayLight-200 text-left">
                    <p className="text-sm font-medium text-grayLight-600">Nazwa</p>
                  </th>
                  <th className="pt-spacing-lg pr-spacing-md pb-spacing-lg pl-spacing-md border border-grayLight-200 text-left">
                    <p className="text-sm font-medium text-grayLight-600">Stawka</p>
                  </th>
                  <th className="pt-spacing-lg pr-spacing-md pb-spacing-lg pl-spacing-md border border-grayLight-200 text-left">
                    <p className="text-sm font-medium text-grayLight-600">Wpłata</p>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td className="border border-grayLight-200"></td>
                  <td className="border border-grayLight-200">
                    <p className="text-sm font-medium text-grayLight-900 text-right p-spacing-md">RAZEM</p>
                  </td>
                  <td className="border border-grayLight-200">
                    <p className="text-sm font-medium text-grayLight-900 p-spacing-md">{ orderDetailsList?.orderDto.totalCost}</p>
                  </td>
                  <td className="border border-grayLight-200">
                    <p className="text-sm font-medium text-grayLight-900 pt-spacing-xl p-spacing-md">{ orderDetailsList?.orderDto.totalPaid }</p>
                  </td>
                </tr>
                {tableRows.map((row, index) => (
                  <tr key={index}>
                    {row.date && (
                      <td
                        className="border border-grayLight-200 text-sm font-normal text-grayLight-600 pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md"
                        rowSpan={row.rowSpan}
                      >
                        {row.date}
                      </td>
                    )}
                    <td className="border border-grayLight-200 pt-spacing-xl pl-spacing-md pb-spacing-xl pr-spacing-md w-2/3">
                      <p className="text-sm font-medium text-grayLight-900">{row.rowData.name}</p>
                    </td>
                    <td className="border border-grayLight-200 pt-spacing-xl pl-spacing-md pb-spacing-xl pr-spacing-md">
                      <p className="text-sm font-medium text-grayLight-900">{ (row.rowData.price / 100).toFixed(2) }</p>
                    </td>
                    <td className="border border-grayLight-200 pt-spacing-xl pl-spacing-md pb-spacing-xl pr-spacing-md">
                      <p className="text-sm font-medium text-grayLight-900">{ (row.rowData.paidAmount / 100).toFixed(2) }</p>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </ModalBody>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default ShowListOfOrderDetails;
