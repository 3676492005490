import { Divider } from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { useApi } from "../../../../../../../../../context/ApiProvider";
import { SimpleChildrenInterface } from "../../../../../../../../../shared/type/children.type";
import CustomChakraButton from "../../../../../../../../CustomChakraButton/CustomChakraButton";

interface Props {
  mode: 'add' | "assign",
  setMode: React.Dispatch<React.SetStateAction<'add' | "assign">>;
  selectedKidsIds: number[];
  setSelectedKidsIds: React.Dispatch<React.SetStateAction<number[]>>;
}

interface SelectOption {
  label: JSX.Element;
  value: number;
  labelText: string;
}

const AddKidToParentAssign = ({ setMode, selectedKidsIds, setSelectedKidsIds }: Props) => {
  const { apiTenantConsumerController } = useApi();
  const { id: tenantId } = useParams<{ id: string }>();
  const [availableChildren, setAvailableChildren] = useState<SimpleChildrenInterface[]>([]);

  const fetchAvailableChildren = async () => {
    try {
      const response: AxiosResponse<SimpleChildrenInterface[]> = await apiTenantConsumerController('simple-data').get(`/${tenantId}`);
      setAvailableChildren(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAvailableChildren();
  }, [tenantId]);

  const selectOptions: SelectOption[] = availableChildren.map((unit) => ({
    label: (
      <div className="flex gap-spacing-md">
        <p>{unit.lastName}</p>
        <p>{unit.firstName}</p>
        <p>{unit.organisationUnitName}</p>
      </div>
    ),
    value: unit.id,
    labelText: `${unit.firstName} ${unit.lastName} ${unit.organisationUnitName}`.toLowerCase()
  }));

  return (
    <div className='flex flex-col gap-spacing-5xl'>
      <div className='basis-1/3'>
        <label className="font-medium text-sm text-grayLight-700">Wybierz dziecko z listy</label>
        <Select
          colorScheme="gray"
          isMulti
          placeholder="Wybierz dziecko lub dzieci"
          value={selectOptions.filter((option) => selectedKidsIds.includes(option.value))}
          onChange={(value) => {
            setSelectedKidsIds(value.map((item) => item.value));
          }}
          options={selectOptions}
          filterOption={(option, inputValue) =>
            option.data.labelText.includes(inputValue.toLowerCase())
          }
        />
      </div>
      <div className='basis-1/3 flex items-center'>
        <Divider color='#EAECF0' />
        <p className='text-sm font-medium text-grayLight-600'>Lub</p>
        <Divider color='#EAECF0' />
      </div>
      <div className='basis-1/3 flex items-center justify-center'>
        <CustomChakraButton
          hierarchy="secondaryColor"
          size="lg"
          iconPosition="onlyText"
          buttonProps={{
            disabled: selectedKidsIds.length > 0,
            onClick: () => {
              setMode('add')
            },
            className: "grow",
            type: "button"
          }}
        >
          Utwórz nowe konto dziecka lub dzieci
        </CustomChakraButton>
      </div>
    </div>
  );
};

export default AddKidToParentAssign;