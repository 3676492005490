import { Thead, Th, Box, Text, TableContainer, Checkbox } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { AxiosResponse, HttpStatusCode } from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../context/AlertProvider";
import { useApi } from "../../../../../context/ApiProvider";
import { MealBaseInterface, EditMealBaseInterface } from "../../../../../shared/type/mealBase.type";

import './DishesDatabase.css';
import { MealInterface } from "../../../../../shared/type/mealsMenu.type";
import BadgeWithMessage from "../../../../BadgeWithMessage/BadgeWithMessage";
import CustomAlert from "../../../../CustomAlert/CustomAlert";
import CustomChakraButton from "../../../../CustomChakraButton/CustomChakraButton";
import { DataTable } from "../../../../DataTable/DataTable";
import IconComponent from "../../../../IconComponent/IconComponent";
import AddEditDishesDatabase from "./AddEditDishesDatabase/AddEditDishesDatabase";
import AddEditMeal from "./AddEditMeal/AddEditMeal";
import AddToMealPlan from "./AddToMealPlan/AddToMealPlan";
import CopyMealFromBaseToBase from "./CopyMealFromBaseToBase/CopyMealFromBaseToBase";

const DishesDatabase = () => {
  const columnHelper = createColumnHelper<MealInterface>();
  const { setAlertProperties, setShow } = useAlertContext();
  const { apiTenantMealMealController, apiTenantMealController } = useApi();
  const { id: tenantId } = useParams();

  const [mealBases, setMealBases] = useState<MealBaseInterface[]>([]);
  const [currentChosenMealBaseId, setCurrentChosenMealBaseId] = useState<number | null>(null);
  const [currentChosenMealBaseMeals, setCurrentChosenMealBaseMeal] = useState<MealInterface[]>([]);

  const [editedMealBase, setEditedMealBase] = useState<EditMealBaseInterface>();
  const [editedMeal, setEditedMeal] = useState<MealInterface>();

  const [selectedMealsId, setSelectedMealsId] = useState<number[]>([]);

  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

  const [deleteElementId, setDeleteElementId] = useState<number>(0);

  const [showPopUp, setShowPopUp] = useState<{
    showAddMealsBasePopUp: boolean,
    showEditMealBasePopUp: boolean,
    showAddDishToMealBasePopUp: boolean,
    showAddEditMealPopUp: boolean,
    showCopyToMealBasePopUp: boolean,
    showDeleteMealPopUp: boolean,
  }>({
    showAddMealsBasePopUp: false,
    showEditMealBasePopUp: false,
    showAddDishToMealBasePopUp: false,
    showAddEditMealPopUp: false,
    showCopyToMealBasePopUp: false,
    showDeleteMealPopUp: false,
  });

  const fetchMealBaseList = async () => {
    setIsDataLoading(true);
    try {
      const response: AxiosResponse<MealBaseInterface[]> = await apiTenantMealMealController('list').get('', {
        params: {
          tenantId,
        },
      });
      setMealBases(response.data);
      if (response.data.length > 0) {
        setCurrentChosenMealBaseId(response.data[0].id);
      }
    } catch (error) {
      // handle error
    } finally {
      setIsDataLoading(false);
    }
  };

  const fetchCurrentMealList = async () => {
    if (currentChosenMealBaseId === null) return;

    setIsDataLoading(true);
    try {
      const response: AxiosResponse<MealInterface[]> = await apiTenantMealController('by-menu').get(`/${tenantId}/${currentChosenMealBaseId}`);
      setCurrentChosenMealBaseMeal(response.data.sort((a, b) => a.id - b.id));
    } catch (error) {
      // handle error
    } finally {
      setIsDataLoading(false);
    }
  };

  const handleCheckboxChange = (userId: number, isChecked: boolean) => {
    setSelectedMealsId((prev) =>
      isChecked ? [...prev, userId] : prev.filter((id) => id !== userId)
    );
  };

  const handleMasterCheckboxChange = (isChecked: boolean) => {
    if (isChecked) {
      const allUserIds = currentChosenMealBaseMeals.map((meal) => meal.id);
      setSelectedMealsId(allUserIds);
    } else {
      setSelectedMealsId([]);
    }
  };

  const columns = [
    columnHelper.display({
      id: 'select',
      header: () => (
        <Checkbox
          colorScheme="customOrange"
          isChecked={selectedMealsId.length === currentChosenMealBaseMeals.length}
          onChange={(e) => handleMasterCheckboxChange(e.target.checked)}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          colorScheme="customOrange"
          isChecked={selectedMealsId.includes(row.original.id)}
          onChange={(e) => handleCheckboxChange(row.original.id, e.target.checked)}
        />
      ),
    }),
    columnHelper.display({
      id: 'index',
      header: 'Lp.',
      cell: (info) => {
        return <p className="text-grayLight-600 font-normal text-sm">{info.row.index + 1}.</p>;
      },
    }),
    columnHelper.accessor('name', {
      cell: (info) => {
        const { name } = info.row.original;
        return (
          <p className="font-medium text-sm text-grayLight-900">
            {name}
          </p>
        );
      },
      header: 'Dania',
    }),
    columnHelper.accessor('description', {
      cell: (info) => {
        const { description } = info.row.original;
        return (
          <p className="font-medium text-sm text-grayLight-900">
            {description}
          </p>
        );
      },
      header: 'Dania',
    }),
    columnHelper.accessor('allergens', {
      cell: (info) => {
        const { allergens } = info.row.original;
        return (
          <div className="flex gap-spacing-xs flex-wrap max-w-40">
            {allergens.map((allergen) => (
              <BadgeWithMessage tooltipLabel={<p className="rounded-lg pt-spacing-md pr-spacing-lg pb-spacing-md pl-spacing-lg text-sm text-grayLight-900 font-medium">{allergen.name}</p>}>{`${allergen.formalNumber}`}</BadgeWithMessage>
            ))}
          </div>
        );
      },
      header: 'Alergeny',
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Edycja',
      cell: (info) => {
        const meal = info.row.original;
        return (
          <div className="flex gap-spacing-sm">
            <button
              onClick={() => {
                setDeleteElementId(meal.id);
                handleShowPopUpValue('showDeleteMealPopUp', true);
              }}
              className="font-semibold text-sm text-grayLight-600 bg-transparent border-none"
            >
              Usuń
            </button>
            <button
              onClick={() => {
                setEditedMeal(meal);
                handleShowPopUpValue('showAddEditMealPopUp', true);
              }}
              className="font-semibold text-sm text-grayWarm-950 bg-transparent border-none"
            >
              Edytuj
            </button>
          </div>
        );
      },
    }),
  ];

  const handleShowPopUpValue = (name: string, value: boolean) => {
    setShowPopUp((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    fetchMealBaseList();
  }, [tenantId]);

  useEffect(() => {
    fetchCurrentMealList();
  }, [currentChosenMealBaseId]);

  return (
    <section className="DishesDatabase w-full h-full overflow-auto flex flex-col gap-spacing-xl">
      <div className="DishesDatabase__dishesBaseTab__container h-11 w-full flex gap-spacing-xs border border-grayLight-200 bg-grayNeutral-50">
        {mealBases.map((item) => (
          <button
            onClick={() => {
              setCurrentChosenMealBaseId(item.id);
            }}
            className={`DishesDatabase__dishesBaseTab ${currentChosenMealBaseId === item.id ? ' DishesDatabase__dishesBaseTab--active' : null}`}
          >
            {item.name}
          </button>
        ))}

        <button
          onClick={() => {
            handleShowPopUpValue('showAddMealsBasePopUp', true);
          }}
          className="DishesDatabase__dishesBaseTab DishesDatabase__dishesBaseTab--active flex items-center"
        >
          + Dodaj bazę
        </button>
      </div>
      {/* Table */}
      <TableContainer h={800} overflowY="auto" className="Branches__table bg-white rounded-lg">
        <DataTable
          filterComponent={
            <div className="flex gap-spacing-xl">
              <CustomChakraButton
                size="sm"
                hierarchy="tertiaryColor"
                iconPosition="right"
                icon="copy"
                buttonProps={{
                  disabled: selectedMealsId.length === 0,
                  onClick: () => {
                    handleShowPopUpValue('showCopyToMealBasePopUp', true);
                  },
                }}
              >
                Kopiuj do bazy
              </CustomChakraButton>
              <CustomChakraButton
                size="sm"
                hierarchy="tertiaryColor"
                iconPosition="left"
                icon="plus"
                buttonProps={{
                  disabled: selectedMealsId.length === 0,
                  onClick: () => {
                    handleShowPopUpValue('showAddDishToMealBasePopUp', true);
                  },
                }}
              >
                Dodaj do jadłospisu
              </CustomChakraButton>
            </div>
          }
          extraThead={
            <Thead>
              <Th colSpan={columns.length}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5">
                    <Box>
                      <Text whiteSpace="normal" overflow="hidden" textOverflow="ellipsis" className="font-semibold text-base text-grayLight-900">
                        Baza dań
                      </Text>
                    </Box>
                    <Box>
                      <Text whiteSpace="normal" overflow="hidden" textOverflow="ellipsis" className="font-normal text-sm text-grayLight-700">
                        Tutaj możesz przeglądać, tworzyć i edytować dania. Stwórz nową bazę dań aby wyeksportować ją do innego oddziału.
                      </Text>
                    </Box>
                  </div>
                  <CustomChakraButton
                    size="md"
                    hierarchy="secondaryColor"
                    iconPosition="onlyText"
                    icon="plus"
                    buttonProps={{
                      onClick: () => {
                        handleShowPopUpValue('showEditMealBasePopUp', true);
                        setEditedMealBase(mealBases.find((item) => item.id === currentChosenMealBaseId));
                      },
                    }}
                  >
                    Edytuj bazę
                  </CustomChakraButton>
                  <CustomChakraButton
                    size="md"
                    hierarchy="primary"
                    iconPosition="left"
                    icon="plus"
                    buttonProps={{
                      onClick: () => {
                        handleShowPopUpValue('showAddEditMealPopUp', true);
                      },
                    }}
                  >
                    Dodaj danie
                  </CustomChakraButton>
                </div>
              </Th>
            </Thead>
          }
          disableFilters={true}
          columns={columns}
          isLoading={isDataLoading}
          data={currentChosenMealBaseMeals}
        />
      </TableContainer>
      {/* POPUPS */}
      <>
        {showPopUp.showAddMealsBasePopUp ? (
          <AddEditDishesDatabase
            mode="add"
            isOpen={showPopUp.showAddMealsBasePopUp}
            onClose={() => {
              fetchMealBaseList();
              fetchCurrentMealList();
              handleShowPopUpValue('showAddMealsBasePopUp', false);
            }}
          />
        ) : null}
        {showPopUp.showEditMealBasePopUp ? (
          <AddEditDishesDatabase
            mode="edit"
            initData={editedMealBase}
            isOpen={showPopUp.showEditMealBasePopUp}
            onClose={() => {
              fetchMealBaseList();
              fetchCurrentMealList();
              handleShowPopUpValue('showEditMealBasePopUp', false);
            }}
          />
        ) : null}
        {showPopUp.showCopyToMealBasePopUp ? (
          <CopyMealFromBaseToBase
            mealIds={selectedMealsId}
            currentChosenMealBaseId={currentChosenMealBaseId || 0}
            isOpen={showPopUp.showCopyToMealBasePopUp}
            onClose={() => {
              fetchMealBaseList();
              fetchCurrentMealList();
              handleShowPopUpValue('showCopyToMealBasePopUp', false);
            }}
          />
        ) : null}
        {showPopUp.showAddEditMealPopUp ? (
          <AddEditMeal
            mealMenuId={currentChosenMealBaseId || 0}
            initData={editedMeal}
            isOpen={showPopUp.showAddEditMealPopUp}
            onClose={() => {
              fetchCurrentMealList();
              if (editedMeal) {
                setEditedMeal(undefined);
              }
              handleShowPopUpValue('showAddEditMealPopUp', false);
            }}
          />
        ) : null}
        {showPopUp.showDeleteMealPopUp ? (
          <CustomAlert
            header="Usunąć danie?"
            content="Usunięcie dania jest nieodwracalne."
            confirmButton={{
              iconPosition: 'onlyText',
              size: 'lg',
              hierarchy: 'warning',
            }}
            confirmButtonText="Usuń"
            onConfirmAction={async () => {
              try {
                const response = await apiTenantMealController('').delete(`/${deleteElementId}`);

                if (response.status === HttpStatusCode.Ok) {
                  setAlertProperties({
                    timeout: 2000,
                    title: "Sukces",
                    description: `Stawka została usunięta`,
                    status: "success",
                  });
                  setShow(true);
                }
              } catch (error) {
                setAlertProperties({
                  timeout: 2000,
                  title: "Błąd",
                  description: `Nie udało się usunąć stawki`,
                  status: "error",
                });
                setShow(true);
              } finally {
                fetchCurrentMealList();
                handleShowPopUpValue('showDeleteMealPopUp', false);
              }
            }}
            cancelButton={{
              iconPosition: 'onlyText',
              size: 'lg',
              hierarchy: 'secondaryGray',
            }}
            cancelButtonText="Anuluj"
            onCancelAction={() => {
              handleShowPopUpValue('showDeleteMealPopUp', false);
              fetchCurrentMealList();
            }}
            handleOpen={showPopUp.showDeleteMealPopUp}
            icon={
              <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
                <IconComponent iconName="trash" />
              </div>
            }
          />
        ) : null}
        {showPopUp.showAddDishToMealBasePopUp ? (
          <AddToMealPlan
            selectedMeals={currentChosenMealBaseMeals.filter((meal) => selectedMealsId.includes(meal.id))}
            isOpen={showPopUp.showAddDishToMealBasePopUp}
            onClose={() => {
              handleShowPopUpValue('showAddDishToMealBasePopUp', false);
              fetchCurrentMealList();
            }}
          />
        ) : null}
      </>
    </section>
  );
};

export default DishesDatabase;
