import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '../../../../../context/ApiProvider';
import { CompanyType } from '../../../../../shared/type/company.type';
import handlePostCodeChange from '../../../../../utils/handlePostCodeChange';
import CustomAlert from '../../../../CustomAlert/CustomAlert';
import CustomChakraButton from '../../../../CustomChakraButton/CustomChakraButton';
import FormInput from '../../../../Forms/FormInput/FormInput';
import IconComponent from '../../../../IconComponent/IconComponent';

const CompanyData = () => {
  const { apiCompanyController } = useApi();
  const { id } = useParams();
  const [company, setCompany] = useState<CompanyType | null>(null);
  const [isNotEdited, setIsNotEdited] = useState(true);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);

  const fetchCompanyData = async () => {
    try {
      const result = await apiCompanyController('').get(`/${id}`);
      setCompany(result.data);
    } catch (error) {
      console.warn('Błąd pobrania elementu');
    } finally {
      setIsNotEdited(true);
    }
  };

  const updateCompanyData = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      console.log(company);
      await apiCompanyController('').put(`/${id}`, { ...company });
    } catch (error) {
      console.warn('Błąd pobrania elementu');
    } finally {
      fetchCompanyData();
      setIsNotEdited(true);
    }
  };

  const updateState = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsNotEdited(false);
    setCompany((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  return (
    <section className="CompanyData flex flex-col gap-5 w-full h-full overflow-scroll">
      <div className="CompanyData__header flex justify-between w-full">
        <p className="m-0 p-0 font-semibold text-lg text-grayLight-900">Edytuj dane firmy</p>
        <div className="CompanyData__header__actionButtons flex gap-spacing-lg">
          <CustomChakraButton
            iconPosition="onlyText"
            hierarchy="tertiaryColor"
            size="md"
            buttonProps={{
              onClick: () => {
                setOpenAlert(true);
              },
              disabled: isNotEdited
            }}
          >
            Anuluj
          </CustomChakraButton>
          <CustomChakraButton
            iconPosition="onlyText"
            hierarchy="primary"
            size="md"
            buttonProps={{
              onClick: () => formRef.current?.requestSubmit(),
              disabled: isNotEdited
            }}
          >
            Zapisz zmiany
          </CustomChakraButton>
        </div>
      </div>
      <div className="CompanyData__formContainer">
        <form className="w-full flex flex-row gap-8" onSubmit={updateCompanyData} ref={formRef}>
          <div className="grow flex flex-col gap-spacing-xl">
            <FormInput
              label="Nazwa firmy"
              inputParams={{
                value: company?.name,
                name: 'name',
                onChange: updateState
              }}
            />
            <FormInput
              label="Numer konta bankowego"
              inputParams={{
                value: company?.bankAccountNumber,
                type: 'text',
                name: 'bankAccountNumber',
                onChange: updateState
              }}
            />
            <FormInput
              label="Numer telefonu"
              inputParams={{
                value: company?.phone,
                type: 'tel',
                max: 9,
                min: 9,
                name: 'phone',
                onChange: updateState
              }}
            />
            <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
              Adres firmy
            </div>
            <FormInput
              label="Ulica"
              inputParams={{
                value: company?.street,
                name: 'street',
                onChange: updateState
              }}
            />
            <div className="flex justify-between gap-spacing-xl">
              <div className="grow">
                <FormInput
                  label="Kod pocztowy"
                  placeholder="00-000"
                  inputParams={{
                    value: company?.postCode,
                    maxLength: 6,
                    minLength: 6,
                    name: 'postCode',
                    onChange: (e) => handlePostCodeChange(e, updateState)
                  }}
                />
              </div>
              <div className="grow">
                <FormInput
                  label="Miasto"
                  placeholder="Wybierz"
                  inputParams={{
                    value: company?.city,
                    name: 'city',
                    onChange: updateState
                  }}
                />
              </div>
            </div>
          </div>
          <div className="grow flex flex-col gap-spacing-xl">
            <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
              Dane administratora
            </div>
            <FormInput
              label="Imię"
              placeholder="Podaj imię administratora"
              inputParams={{
                value: company?.mainAdmin?.firstName,
                disabled: true
              }}
            />
            <FormInput
              label="Nazwisko"
              placeholder="Podaj nazwwisko administratora"
              inputParams={{
                value: company?.mainAdmin?.lastName,
                disabled: true
              }}
            />
          </div>
        </form>
      </div>
      <CustomAlert
        handleOpen={openAlert}
        icon={<IconComponent iconName="trash" />}
        header={`Zapisać zmiany?`}
        content="W formularzu znajdują się niezapisane zmiany."
        cancelButtonText="Nie zapisuj"
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        confirmButtonText="Zapisz zmiany"
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'primary'
        }}
        onCancelAction={fetchCompanyData}
        onConfirmAction={() => updateCompanyData}
      />
    </section>
  );
};

export default CompanyData;
