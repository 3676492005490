import React from "react";
import { Routes, Route } from "react-router-dom";
import Administrators from "./Administrators/Administrators";
import Children from "./Children/Children";
import Employees from "./Employees/Employees";
import Parents from "./Parents/Parents";
import Teachers from "./Teachers/Teachers";

const Users = () => {
  const usersLinks = [
    {
      link: "children",
      element: <Children/>
    },
    {
      link: "parents",
      element: <Parents/>
    },
    {
      link: "teachers",
      element: <Teachers/>
    },
    {
      link: "employees",
      element: <Employees/>
    },
    {
      link: "administrators",
      element: <Administrators />
    }
  ];

  return (
    <Routes>
      {usersLinks.map((link) => (
        <Route key={link.link} path={`/${link.link}/*`} element={link.element} />
      ))}
    </Routes>
  );
};

export default Users;