import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter, Input
} from "@chakra-ui/react";
import { HttpStatusCode } from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../context/ApiProvider";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  dateWhen: string
}

const DuplicateMealPlan = ({ isOpen, onClose, dateWhen }: Props) => {
  const { apiTenantMealToDayController } = useApi();
  const { setShow, setAlertProperties } = useAlertContext();
  const { id: tenantId }  = useParams<{id: string}>();
  const [ targetDate, setTargetDate ] = useState<string | null>(null)

  const handleDuplicate = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try{
      const response = await apiTenantMealToDayController('copy-assignment-to-other-date').post('', {
        tenantId: Number(tenantId),
        sourceDate: dateWhen,
        targetDate: targetDate
      })
      if(response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          timeout: 2000,
          title: "Sukces",
          description: `Poprawnie duplikowano`,
          status: "success"
        });
        setShow(true);
        onClose();
      }
    } catch (error) {
      console.error(error);
        setAlertProperties({
          timeout: 2000,
          title: "Błąd",
          description: `Bład duplikowania`,
          status: "error"
        });
        setShow(true);
    }
  };

  return(
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <form onSubmit={handleDuplicate}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <div
              className="basis-5/12 flex flex-col text-grayLight-900 font-semibold text-lg border-r border-grayLight-200">
              <h2 className="font-semibold text-lg">
                Duplikuj jadłospis
              </h2>
              <p className="text-grayLight-600 text-sm font-normal">
                Wybierz datę w jakiej ma obowiązywać wskazany jadłospis.
              </p>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <div className="flex flex-col gap-spacing-xs">
              <label className="text-sm font-medium text-grayLight-700">Data</label>
              <div className="w-44">
                <Input
                  placeholder="Wybierz datę"
                  type="date"
                  lang="pl"
                  required
                  onChange={(e) => {
                    setTargetDate(e.target.value);
                  }}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: onClose
              }}
            >
              Anuluj
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                disabled: targetDate === null,
                className: "grow",
                type: "submit"
              }}
            >
              Duplikuj
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
};

export default DuplicateMealPlan;