import { Alert, AlertIcon, AlertTitle, AlertDescription, Box, CloseButton } from "@chakra-ui/react";
import { CustomAlertDialogInterface } from "../../shared/type/CustomAlertDialog.type";
import React, { useEffect, useState } from "react";

interface Props extends CustomAlertDialogInterface {
  onClose: () => void;
  timeout: number; // Optional timeout in milliseconds
}

const CustomAlertDialogComponent = ({ status, title, description, onClose, timeout }: Props) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (timeout) {
      const timer = setTimeout(() => {
        setVisible(false);
        setTimeout(() => {
          onClose();
        }, 500); // Duration of fade out effect
      }, timeout);

      // Clean up timer on unmount or timeout change
      return () => clearTimeout(timer);
    }
  }, [timeout, onClose]);

  return (
    <div
      className={`w-full pl-12 pr-12 fixed left-0 bottom-10 z-50 transition-opacity duration-500 ${visible ? 'opacity-100' : 'opacity-0'}`}
      style={{ zIndex: 9999 }}
    >
      <Alert
        status={status}
        className='rounded-xl flex flex-row justify-between'
      >
        <div className='flex'>
          <AlertIcon />
          <Box>
            <AlertTitle>{title}</AlertTitle>
            <AlertDescription>
              {description}
            </AlertDescription>
          </Box>
        </div>
        <CloseButton
          alignSelf='flex-start'
          position='relative'
          right={-1}
          top={-1}
          onClick={onClose}
        />
      </Alert>
    </div>
  );
};

export default CustomAlertDialogComponent;
