import { HttpStatusCode } from "axios";
import React, { ReactNode, useState, createContext, useContext, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CustomAlert from "../component/CustomAlert/CustomAlert";
import IconComponent from "../component/IconComponent/IconComponent";
import useAuth from "../hooks/useAuth";
import { FullParentInterface } from "../shared/type/parent.type";
import { useAlertContext } from "./AlertProvider";
import { useApi } from "./ApiProvider";

interface Props {
  children: ReactNode;
}

interface EditButtonStateInterface {
  isTopButtonActive: boolean,
  isBottomButtonActive: boolean,
}

interface EditParentContextProps {
  fetchParents: () => void;
  updateEmailPassword: () => void;
  editButtonState: EditButtonStateInterface;
  setEditButtonState: React.Dispatch<React.SetStateAction<EditButtonStateInterface>>;
  parent: FullParentInterface;
  setParent: React.Dispatch<React.SetStateAction<FullParentInterface>>;
  parentId: number;
  setParentId: React.Dispatch<React.SetStateAction<number>>;
  setShowDeleteAccount:  React.Dispatch<React.SetStateAction<boolean>>;
  setShowActivateAccount:  React.Dispatch<React.SetStateAction<boolean>>;
  setShowPasswordReset:  React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultParent: FullParentInterface = {
  id: 0,
  firstName: '',
  lastName: '',
  login: '',
  phone: null,
  password: '',
  roleName: 'PARENT',
  active: false,
  lastLogin: '',
  archived: false,
  bankAccountNumber: null,
}

const EditParentContext = createContext<EditParentContextProps | undefined>(undefined);

export const useEditParentContext = () => {
  const context = useContext(EditParentContext);
  if (!context) {
    throw new Error('useEditAdministratorContext must be used within a EditAdministratorProvider');
  }
  return context;
};

export const EditAdministratorProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { setShow, setAlertProperties } = useAlertContext();
  const { apiTenantParentController, apiTenantUserController, apiPublicSecurityController } = useApi();
  const { id: tenantId } = useParams();
  const [ parentId, setParentId ] = useState<number>(7)
  const [ parent, setParent] = useState<FullParentInterface>(defaultParent);
  const [ showChangeCredentialsEmail, setShowChangeCredentialsEmail ] = useState<boolean>(false);
  const [ showPasswordReset, setShowPasswordReset ] = useState<boolean>(false);
  const [ showDeleteAccount, setShowDeleteAccount ] = useState<boolean>(false);
  const [ showActivateAccount, setShowActivateAccount ] = useState<boolean>(false);
  const [ editButtonState, setEditButtonState ] = useState<EditButtonStateInterface>({
    isTopButtonActive: false,
    isBottomButtonActive: false,
  })

  const fetchParents = async () => {
    try {
      const result = await apiTenantParentController('').get(`/${tenantId}`);
      console.log("Fetched data:", result.data);

      const parents = result.data as FullParentInterface[];
      const resultParent = parents.find((parent) => parent.id === parentId);
      console.log("Found parent:", resultParent);

      if(resultParent){
        setParent(resultParent);
      }
    } catch (error) {
      console.error(error);
      throw new Error(`${error}`);
    } finally {
      console.log("Parent state in finally:", parent);
    }
  };

  const updateEmailPassword = async () => {
    try {
      const response = await apiTenantUserController('email-or-password-update').put('', {
        tenantId: tenantId,
        userId: parent.id,
        email: parent.login,
        newPassword: parent.password
      })
      if(response.status === HttpStatusCode.Ok){
        setAlertProperties({
          timeout: 5000,
          title: 'Sukces',
          description: 'Poprawnie zaktualizowano hasło i/lub email użytkownika',
          status: 'success',
        })
        setShow(true);
        setEditButtonState((prev => ({...prev, isBottomButtonActive: false})))
      }
    } catch (error: any) {
      const {status, data} = error.response;
      if(status === HttpStatusCode.BadRequest){
        setAlertProperties({
          timeout: 5000,
          title: `Błąd pola ${error.response?.data.violations[0].fieldName}`,
          description: `Podpowiedź: ${error.response?.data.violations[0].message}`,
          status: 'warning',
        })
        setShow(true)
      }
      if(status === HttpStatusCode.Conflict){
        setAlertProperties({
          timeout: 5000,
          title: 'Błąd',
          description: `${data.errorMessage}`,
          status: 'error',
        });
        setShow(true);
      }
    } finally {
    }
  }

  const updateParent = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      const response = await apiTenantParentController('basic-data').put(``, {
        tenantId: tenantId,
        userId: parentId,
        firstName: parent.firstName,
        lastName: parent.lastName,
        phoneNumber: parent.phone
      });

      if(response.status === HttpStatusCode.Created){
        setAlertProperties({
          timeout: 2000,
          title: "Sukces",
          description: `Poprawnie zaktualizowano rodzica`,
          status: "success"
        });
        setShow(true);
        fetchParents();
        setEditButtonState((prevState) => ({...prevState, isTopButtonActive: false}))
      } else {
        setAlertProperties({
          timeout: 2000,
          title: "Błąd",
          description: `Błąd aktualizacji rodzica`,
          status: "success"
        });
        setShow(true)
      }
    } catch (error: any) {
        setAlertProperties({
          timeout: 2000,
          title: "Błąd",
          description: `Błąd aktualizacji rodzica ${error.reponse.data.errorMessage}`,
          status: "success"
        });
      setShow(true)
    } finally {

    }
  };

  useEffect(() => {
    fetchParents();
  },[parentId])

  const contextValue = useMemo(
    () => ({
      fetchParents,
      parent,
      setParent,
      parentId,
      setParentId,
      editButtonState,
      setEditButtonState,
      updateEmailPassword,
      setShowDeleteAccount,
      setShowActivateAccount,
      setShowPasswordReset
    }),
    [
      fetchParents,
      parent,
      setParent,
      parentId,
      setParentId,
      editButtonState,
      setEditButtonState,
      updateEmailPassword,
      setShowDeleteAccount,
      setShowActivateAccount,
      setShowPasswordReset
    ]
  );

  return (
    <EditParentContext.Provider value={contextValue}>
      <form onSubmit={updateParent}>
        {children}
      </form>
      {showChangeCredentialsEmail ? <CustomAlert
        header="Zmienić login lub/i hasło użytkownika?"
        content={
        <div>
          <p className='text-sm font-normal text-grayLight-600'>
            Jeśli nowy login jest mailem i hasło jest puste, wówczas i zostanie wysłany do użytkownika zostanie wysłany maile rozpoczynający procedurę zmiany hasła.
            Jeśli nowy login nie jest mailem, wówczas zostanie przypisane podane hasło (jeśli hasło jest puste, wówczas system sam zaproponuje hasło)</p>
        </div>
        }
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'primary'
        }}
        confirmButtonText="Zapisz"
        onConfirmAction={async () => {
          try {
            await apiTenantUserController('email-or-password-update').put('', {
              tenantId: tenantId,
              userId: parentId,
              email: parent.login,
              newPassword: parent.password
            })
            setAlertProperties({
              timeout: 2000,
              title: "Sukces",
              description: `Poprawnie zaktualizowano ustawienia rodzica`,
              status: "success"
            });
            setEditButtonState((prev => ({...prev, isBottomButtonActive: false})));
            setShowChangeCredentialsEmail(false)
          } catch (error) {
            // @ts-ignore
            const { status } = error.response;
            if(status === HttpStatusCode.Conflict) {
              setAlertProperties({
                timeout: 2000,
                title: "Niepowodzenie",
                description: `Rodzica o podanym adresie email już istnieje w systemie.`,
                status: "warning"
              });
            } else {
              setAlertProperties({
                timeout: 2000,
                title: "Błąd",
                description: `Błąd aktualizacji ustawień rodzica ${error}`,
                status: "error"
              });
            }
          } finally {
            setShow(true);
          }
        }}
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryColor'
        }}
        cancelButtonText="Anuluj"
        onCancelAction={() => {
          fetchParents();
        }}
        handleOpen={showChangeCredentialsEmail}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-warning-100">
            <IconComponent iconName="question" color='#DC6803'/>
          </div>
        }
      /> : null}
      {showDeleteAccount ? <CustomAlert
        header="Usunąć konto użytkownika?"
        content='Usunięcie konta jest nieodwracalne.'
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'warning'
        }}
        confirmButtonText="Usuń"
        onConfirmAction={async () => {
          try {
            await apiTenantParentController('').delete(`/${tenantId}/${parentId}`,)
            setAlertProperties({
              timeout: 2000,
              title: "Sukces",
              description: `Poprawnie usunięto rodzica`,
              status: "success"
            });
            navigate(auth.loggedUserDto.superAdmin ? `/superAdmin/branches/id/${tenantId}/users/parents/parents-table` : `/tenantAdmin/id/${tenantId}/users/parents/parents-table`);
            setEditButtonState((prev => ({...prev, isBottomButtonActive: false})));
            setShowDeleteAccount(false)
          } catch (error) {
            setAlertProperties({
              timeout: 2000,
              title: "Błąd",
              description: `Błąd aktualizacji ustawień rodzica ${error}`,
              status: "error"
            });
          } finally {
            setShow(true);
          }
        }}
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        cancelButtonText="Anuluj"
        onCancelAction={() => {
          setShowDeleteAccount(false);
        }}
        handleOpen={showDeleteAccount}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
            <IconComponent iconName="trash" color='#D92D20'/>
          </div>
        }
      /> : null}
      {showActivateAccount ? <CustomAlert
        header="Aktywować konto użytkownika?"
        content='Aktywacja konta pozwoli użytkowinikowi zalogować się bez korzystania z maila aktywacyjnego.'
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'warning'
        }}
        confirmButtonText="Aktywuj"
        onConfirmAction={async () => {
          try {
            await apiTenantUserController('').post(`/activate/${parentId}`);
            fetchParents();
            setAlertProperties({
              timeout: 2000,
              title: "Sukces",
              description: `Poprawnie aktywowano rodzica`,
              status: "success"
            });
            setShowActivateAccount(false)
          } catch (error) {
            setAlertProperties({
              timeout: 2000,
              title: "Błąd",
              description: `Błąd aktualizacji ustawień rodzica ${error}`,
              status: "error"
            });
          } finally {
            setShow(true);
          }
        }}
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        cancelButtonText="Anuluj"
        onCancelAction={() => {
          setShowActivateAccount(false);
        }}
        handleOpen={showActivateAccount}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
            <IconComponent iconName="trash" color='#D92D20'/>
          </div>
        }
      /> : null}
      {showPasswordReset ? <CustomAlert
        header="Zresetować hasło użytkownika?"
        content=''
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'warning'
        }}
        confirmButtonText="Resetuj"
        onConfirmAction={async () => {
          try {
            await apiPublicSecurityController('reset').post('', {}, {
              params: {
                email: parent.login
              }
            })
            setAlertProperties({
              timeout: 2000,
              title: "Sukces",
              description: `Poprawnie zresetowano hasła rodzica`,
              status: "success"
            });
            setEditButtonState((prev => ({...prev, isBottomButtonActive: false})));
            setShowPasswordReset(false)
          } catch (error) {
            setAlertProperties({
              timeout: 2000,
              title: "Błąd",
              description: `Błąd zresetowano hasła rodzica ${error}`,
              status: "error"
            });
          } finally {
            setShow(true);
          }
        }}
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        cancelButtonText="Anuluj"
        onCancelAction={() => {
          fetchParents();
        }}
        handleOpen={showPasswordReset}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
            <IconComponent iconName="trash" color='#D92D20'/>
          </div>
        }
      /> : null}
    </EditParentContext.Provider>
  );
};
