import { AxiosResponse } from "axios";
import React, { ReactNode, useState, createContext, useContext, useEffect, useMemo } from "react";
import { ParentDashboardContextInterface } from "../shared/type/parentDashboardContext.type";
import { useApi } from "./ApiProvider";

interface Props {
  children: ReactNode;
}

interface BasketStatusInterface {
  show: boolean,
  forceShow: boolean,
}

interface ParentDashboardContextProps {
  parentDashboardContext: ParentDashboardContextInterface | undefined;
  basketStatus: BasketStatusInterface;
  setBasketStatus:  React.Dispatch<React.SetStateAction<BasketStatusInterface>>;
  fetchParentDashboardContext: () => void;
}

const ParentDashboardContextContext = createContext<ParentDashboardContextProps | undefined>(undefined);

export const useParentDashboardContextContext = () => {
  const context = useContext(ParentDashboardContextContext);
  if (!context) {
    throw new Error('useParentDashboardContextContext must be used within a ParentDashboardContextProvider');
  }
  return context;
};

export const ParentDashboardContextProvider = ({ children }: Props) => {
  const { apiParentDashboardContextController } = useApi();
  const [ parentDashboardContext, setParentDashboardContext ] = useState<ParentDashboardContextInterface>()
  const [ basketStatus, setBasketStatus ] = useState<BasketStatusInterface>({
    show: false,
    forceShow: false,
  });

  const fetchParentDashboardContext = async () => {
    try {
      const response: AxiosResponse<ParentDashboardContextInterface> = await apiParentDashboardContextController('').get('');
      setParentDashboardContext(response.data);
    } catch (error) {

    }
  };

  useEffect(() => {
    fetchParentDashboardContext()
  }, []);

  const contextValue = useMemo(
    () => ({
      parentDashboardContext,
      basketStatus,
      setBasketStatus,
      fetchParentDashboardContext
    }),
    [
      parentDashboardContext,
      basketStatus,
      setBasketStatus,
      fetchParentDashboardContext
    ]
  );

  return (
    <ParentDashboardContextContext.Provider value={contextValue}>
        {children}
    </ParentDashboardContextContext.Provider>
  );
};
