import React, { ReactNode, useMemo, useState, createContext, useContext } from 'react';

interface Props {
  children: ReactNode;
}

interface ThemeContextProps {
  toggleColorMode: () => void;
  isLightMode: boolean;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within an ThemeProvider');
  }
  return context;
};

export const ThemeProvider = ({ children }: Props) => {
  const [isLightMode, setIsLightMode] = useState<boolean>(true);

  const toggleColorMode = () => {
    setIsLightMode((prev) => !prev);
  };

  const contextValue = useMemo(
    () => ({
      toggleColorMode,
      isLightMode
    }),
    [toggleColorMode, isLightMode]
  );

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};
