import React, { createContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthInterface, ReactComponentProps } from '../shared';

export type AuthContextType = {
  auth: AuthInterface;
  updateAuth: (partialAuth: {
    loggedUserDto: {
      firstName: any;
      lastName: any;
      childOwner: any;
      tenantAdmin: any;
      availableModules: any;
      roleName: any;
      availableTenantsIds: any;
      admin: any;
      id: any;
      login: any;
      superAdmin: any
    };
    jwToken: { expiresIn: any; jwToken: any };
    enforceEmailUpdate: any;
    availableTenants: any
  }) => void;
  persist: boolean;
  updatePersist: (newPersist: boolean) => void;
  logout: () => void;
  restartAuth: () => void;
};

const defaultAuth: AuthInterface = {
  loggedUserDto: {
    id: 0,
    login: '',
    firstName: '',
    lastName: '',
    availableTenantsIds: [1],
    availableModules: [''],
    roleName: 'PARENT',
    admin: false,
    childOwner: false,
    tenantAdmin: false,
    superAdmin: false
  },
  availableTenants: [],
  enforceEmailUpdate: false,
  jwToken: {
    jwToken: '',
    expiresIn: 0
  }
};

const AuthContext = createContext<AuthContextType>({
  auth: defaultAuth,
  persist: true,
  updateAuth: () => {},
  updatePersist: () => {},
  logout: () => {},
  restartAuth: () => {}
});

export const AuthProvider: React.FC<ReactComponentProps> = ({ children }: ReactComponentProps) => {
  const [persist, setPersist] = useState(() => {
    const saved = localStorage.getItem('persist');
    return !!saved;
  });

  const [auth, setAuth] = useState<AuthInterface>(defaultAuth);
  const navigate = useNavigate();

  const restartAuth = (): void => {
    setAuth(defaultAuth);
  };

  const memoizedPersist = useMemo(() => {
    const updatePersist = (newPersist: boolean) => {
      setPersist(newPersist);
    };
    return { persist, updatePersist };
  }, [persist]);

  const memoizedAuth = useMemo(() => {
    const updateAuth = (partialAuth: Partial<AuthInterface>) => {
      setAuth((prevAuth) => ({ ...prevAuth, ...partialAuth }));
    };
    const logout = () => {
      setAuth(defaultAuth);
      localStorage.removeItem('persist');
      navigate('/login');
    };
    return { auth, updateAuth, logout };
  }, [auth, navigate]);

  const contextValue = useMemo(
    () => ({
      auth: memoizedAuth.auth,
      updateAuth: memoizedAuth.updateAuth,
      persist: memoizedPersist.persist,
      updatePersist: memoizedPersist.updatePersist,
      logout: memoizedAuth.logout,
      restartAuth: restartAuth
    }),
    [memoizedPersist, memoizedAuth]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthContext;
