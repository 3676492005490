import { useParams, useLocation, Link } from "react-router-dom";
import { FullParentInterface } from "../../../../../../../shared/type/parent.type";

import './SingleParentTab.css';
import CustomChakraButton from "../../../../../../CustomChakraButton/CustomChakraButton";
import IconComponent from "../../../../../../IconComponent/IconComponent";

interface Props{
  parent: FullParentInterface,
  unassignParent: (prentId: number) => Promise<void>,
}

const SingleParentTab = ({parent, unassignParent}: Props) => {
  const location = useLocation();
  const {kidId} = useParams<{kidId: string}>();

  const parentPath = location.pathname.replace(`children/single-kid/${kidId}/parents`, `parents/single-parent/${parent.id}/list-of-orders`);

  return (
    <div className='SingleParentTab flex p-spacing-lg bg-base-white w-full rounded-lg justify-between'>
      <div className='flex flex-col gap-spacing-xl'>
        <div>
          <Link to={parentPath}>
            <p
              style={{fontSize: '18px'}}
              className='font-semibold underline underline-offset-2'>
              {parent.firstName} {parent.lastName}
            </p>
          </Link>
        </div>
        <div className='flex flex-col gap-spacing-xl'>
          <div className="flex gap-spacing-md items-center">
            <IconComponent iconName="phone" color='#101828'/>
            <p
              style={{fontSize: '18px'}}
              className='font-semibold'>
              {parent.phone || '-'}
            </p>
          </div>
          <div className="flex gap-spacing-md items-center">
            <IconComponent iconName="message" color='#101828'/>
            <p
              style={{fontSize: '18px'}}
              className='font-semibold'>
              {parent.login || '-'}
            </p>
          </div>
        </div>
      </div>
      <div className='flex items-start'>
        <CustomChakraButton
          hierarchy='linkGray' size='lg' iconPosition='onlyText' buttonProps={{
          onClick: () => {
            unassignParent(parent.id)
          },
          type: 'button',
          style: {padding: 0, margin: 0}
        }}>
          Rozłącz konta</CustomChakraButton>
      </div>
    </div>
  )

};

export default SingleParentTab;