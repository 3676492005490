import React from 'react';

const LogOutIcon = ({ className }: { className: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.61351 7.6139L4.1074 4.10779M4.1074 15.8929L7.63993 12.3604M12.3842 12.3868L15.8903 15.8929M15.8903 4.10779L12.3572 7.64084M18.3333 10.0003C18.3333 14.6027 14.6023 18.3337 9.99996 18.3337C5.39759 18.3337 1.66663 14.6027 1.66663 10.0003C1.66663 5.39795 5.39759 1.66699 9.99996 1.66699C14.6023 1.66699 18.3333 5.39795 18.3333 10.0003ZM13.3333 10.0003C13.3333 11.8413 11.8409 13.3337 9.99996 13.3337C8.15901 13.3337 6.66663 11.8413 6.66663 10.0003C6.66663 8.15938 8.15901 6.66699 9.99996 6.66699C11.8409 6.66699 13.3333 8.15938 13.3333 10.0003Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LogOutIcon;
