import React from "react";
import { Routes, Route } from "react-router-dom";
import { KidConsumerProvider } from "../../../../context/KidConsumerProvider";
import ChildrenTable from "./ChildrenTable/ChildrenTable";
import SingleKid from "./SingleKid/SingleKid";

const Children = () => {
  const childrenLinks = [
    {
      link: "children-table",
      element: <ChildrenTable/>
    },
    {
      link: "single-kid/:kidId",
      element: (<KidConsumerProvider><SingleKid/></KidConsumerProvider>)
    }
  ];

  return (
      <Routes>
        {childrenLinks.map((link) => (
          <Route key={link.link} path={`/${link.link}/*`} element={link.element} />
        ))}
      </Routes>
  );
};

export default Children;