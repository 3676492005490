import React from 'react';

const UserCircle = ({ className }: { className: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.43033 16.199C4.93727 15.0047 6.12085 14.167 7.50008 14.167H12.5001C13.8793 14.167 15.0629 15.0047 15.5698 16.199M13.3334 7.91699C13.3334 9.75794 11.841 11.2503 10.0001 11.2503C8.15913 11.2503 6.66675 9.75794 6.66675 7.91699C6.66675 6.07604 8.15913 4.58366 10.0001 4.58366C11.841 4.58366 13.3334 6.07604 13.3334 7.91699ZM18.3334 10.0003C18.3334 14.6027 14.6025 18.3337 10.0001 18.3337C5.39771 18.3337 1.66675 14.6027 1.66675 10.0003C1.66675 5.39795 5.39771 1.66699 10.0001 1.66699C14.6025 1.66699 18.3334 5.39795 18.3334 10.0003Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UserCircle;
