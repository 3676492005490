const PdfIcon = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.75 4.5C2.75 2.70507 4.20507 1.25 6 1.25H11.9393L17.25 6.56066V16.5C17.25 18.2949 15.7949 19.75 14 19.75H6C4.20507 19.75 2.75 18.2949 2.75 16.5V4.5Z"
      stroke="#D0D5DD" stroke-width="1.5" />
    <path d="M12 0.75V2.5C12 4.70914 13.7909 6.5 16 6.5H17.75" stroke="#D0D5DD" stroke-width="1.5" />
    <path
      d="M12.8544 13.1719C12.293 13.1719 11.5904 13.2698 11.3605 13.3043C10.4092 12.3108 10.1385 11.7461 10.0776 11.5955C10.1601 11.3835 10.4472 10.5782 10.488 9.5439C10.5081 9.02605 10.3987 8.63911 10.1628 8.39382C9.92724 8.14893 9.64215 8.12988 9.56039 8.12988C9.27373 8.12988 8.79284 8.27484 8.79284 9.24556C8.79284 10.0878 9.18553 10.9815 9.29408 11.2125C8.72217 12.8777 8.10817 14.0177 7.97779 14.2521C5.67971 15.1173 5.5 15.9539 5.5 16.191C5.5 16.6171 5.80345 16.8715 6.31171 16.8715C7.5466 16.8715 8.67352 14.7982 8.85984 14.4382C9.73691 14.0888 10.9108 13.8723 11.2092 13.8207C12.0652 14.6361 13.0552 14.8536 13.4663 14.8536C13.7756 14.8536 14.5 14.8536 14.5 14.1089C14.5 13.4173 13.6136 13.1719 12.8544 13.1719ZM12.7949 13.6608C13.4619 13.6608 13.6382 13.8814 13.6382 13.998C13.6382 14.0712 13.6104 14.31 13.2529 14.31C12.9323 14.31 12.3788 14.1247 11.8341 13.7227C12.0613 13.6928 12.3973 13.6608 12.7949 13.6608ZM9.52538 8.60381C9.5862 8.60381 9.62624 8.62334 9.65925 8.66913C9.85109 8.93532 9.69641 9.80513 9.50814 10.4858C9.32639 9.90216 9.19 9.00663 9.3819 8.69157C9.41941 8.63008 9.46229 8.60381 9.52538 8.60381ZM9.20144 13.8137C9.44293 13.3259 9.7136 12.6149 9.86102 12.2127C10.156 12.7066 10.5529 13.165 10.7824 13.4136C10.0678 13.5643 9.52715 13.7148 9.20144 13.8137ZM5.9797 16.2561C5.96379 16.2373 5.96144 16.1975 5.97343 16.1497C5.99857 16.0496 6.19071 15.5536 7.58039 14.9319C7.38141 15.2454 7.07033 15.6932 6.72859 16.0278C6.48803 16.253 6.30071 16.3671 6.17184 16.3671C6.12573 16.3671 6.06221 16.3546 5.9797 16.2561Z"
      fill="#D92D20" />
  </svg>

);

export default PdfIcon;