import React, { useState, useEffect, useRef } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useAlertContext } from "../../../context/AlertProvider";
import { useParentDashboardContextContext } from "../../../context/ParentDashboardContext";
import { HttpStatusCode } from "axios";
import useAuth from "../../../hooks/useAuth";
import CustomChakraButton from "../../CustomChakraButton/CustomChakraButton";
import CustomHorizontalTabs from "../../CustomHorizontalTabs/CustomHorizontalTabs";
import KidCharacterCard from "./KidCharacterCard/KidCharacterCard";
import StakeCharacterCard from "./StakeCharacterCard/StakeCharacerCard.tsx";
import { useApi } from "../../../context/ApiProvider";
import './ParentMain.css';

const ParentMain = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext()
  const { parentDashboardContext, setBasketStatus } = useParentDashboardContextContext()
  const [chosenTenantId, setChosenTenantId] = useState<number | null>(null);
  const [chosenKidId, setChosenKidId] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const cardContainerRef = useRef<HTMLDivElement>(null);
  const searchParams = new URLSearchParams(location.search);
  const paymentId = searchParams.get('paymentId');
  const { apiParentPaymentSimulationController } = useApi();

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setStartX(e.pageX - (cardContainerRef.current?.offsetLeft || 0));
    setScrollLeft(cardContainerRef.current?.scrollLeft || 0);
  };

  const handleMouseLeaveOrUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - (cardContainerRef.current?.offsetLeft || 0);
    const walk = (x - startX) * 1.5;
    if (cardContainerRef.current) {
      cardContainerRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  const simulatePaymentSuccess = async (paymentId: string) => {
    try {
      const response = await apiParentPaymentSimulationController('').post(`/simulate-transaction-success?paymentId=${paymentId}`);

      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          description: `Operacja testowo oznaczona jako sukces`,
          timeout: 2000,
          title: "Sukces",
          status: "success",
        });
        setShowAlert(true);
      }
    } catch (error: any) {
      setAlertProperties({
        description: `${error.response?.data?.errors?.[0] || "Wystąpił błąd"}`,
        timeout: 5000,
        title: "Błąd",
        status: "error",
      });
      setShowAlert(true);
    }
  };

  useEffect(() => {
    setBasketStatus(() => ({ show: false, forceShow: false }));
  }, []);

  useEffect(() => {
    if (parentDashboardContext && parentDashboardContext.tenants.length > 0) {
      const firstTenant = parentDashboardContext.tenants[0];
      setChosenTenantId(firstTenant.tenantId);

      if (firstTenant.consumers.length > 0) {
        setChosenKidId(firstTenant.consumers[0].consumerDto.id);
      }
    }
  }, [parentDashboardContext]);

  useEffect(() => {
    if(paymentId){
      const pathSegments = location.pathname.split('/');
      const status = pathSegments[pathSegments.length - 1];
      if (window.location.hostname === "localhost") {
          simulatePaymentSuccess(paymentId);
      }
      if(status === 'success'){
        setAlertProperties({
          timeout: 5000,
          title: "Sukces",
          description: `Płatność zakończona powodzeniem`,
          status: "success"
        })
      } else {
        setAlertProperties({
          timeout: 2000,
          title: "Niepowodzenie",
          description: `Płatność zakończona niepowodzeniem`,
          status: "error"
        })
      }
      setShowAlert(true);
      navigate('/parent/main')

    }
  }, [paymentId]);

  return (
    <section className="ParentMainPage w-full h-full flex flex-col gap-spacing-3xl pt-spacing-3xl pr-spacing-4xl pb-spacing-3xl pl-spacing-3xl bg-grayLight-50">
      <div className="ParentMainPage__header flex justify-between">
        <div className="ParentMainPage__header__greetings flex flex-col gap-spacing-xs">
          <h1 className='text-3xl font-semibold text-grayLight-900'>Witaj, {auth.loggedUserDto.firstName}</h1>
          <p className='font-normal text-grayLight-600' style={{ fontSize: '16px' }}>
            W tym miejscu możesz sprawdzić najbliższe zamówienia, dodać kolejne posiłki lub <br />
            zgłosić nieobecność dziecka.
          </p>
        </div>
        <div className="ParentMainPage__header__actionButtons flex gap-spacing-2xl">
          <div>
            <Link to={location.pathname.replace('main', `absences`)}>
              <CustomChakraButton
                size='lg'
                hierarchy='secondaryColor'
                iconPosition='left'
              >
                Zgłoś nieobecność
              </CustomChakraButton>
            </Link>
          </div>
          <div>
            <Link to={location.pathname.replace('main', `order`)}>
              <CustomChakraButton
                size='lg'
                hierarchy='primary'
                iconPosition='left'
                icon='plus'
              >
                Zamów
              </CustomChakraButton>
            </Link>
          </div>
        </div>
      </div>

      <div className="ParentMainPage__content flex flex-col gap-spacing-3xl">
        <div className="ParentMainPage__content__chooseSchool">
          <CustomHorizontalTabs
            onChange={(value) => setChosenTenantId(value)}
            data={parentDashboardContext ? parentDashboardContext.tenants.map(tenant => ({
              label: tenant.tenantName,
              value: tenant.tenantId,
            })) : null}
          />
        </div>

        <div className="ParentMainPage__content__chooseKid">
          <CustomHorizontalTabs
            onChange={(value) => {
              setChosenKidId(value);
            }}
            data={parentDashboardContext && chosenTenantId ? parentDashboardContext.tenants.find(tenant => tenant.tenantId === chosenTenantId)?.consumers.map(consumer => {
              const { firstName, lastName, id } = consumer.consumerDto;
              return ({
                value: id,
                label: firstName + " " + lastName,
              });
            }) : null}
          />
        </div>
      </div>

      <div className='flex flex-col gap-spacing-5xl'>
        {(() => {
          if (chosenTenantId === null || chosenKidId === null) return <></>;

          const tenant = parentDashboardContext?.tenants.find(
            (tenant) => tenant.tenantId === chosenTenantId
          );

          const consumer = tenant?.consumers.find(
            (consumer) => consumer.consumerDto.id === chosenKidId
          );

          if (!tenant || !consumer) return <></>;

          const { tenantName } = tenant;
          const { firstName, lastName, remarks, organisationUnitName, saldo, cardNumber } = consumer.consumerDto;

          return (
            <KidCharacterCard
              saldo={saldo}
              organisationUnitName={organisationUnitName}
              remarks={remarks}
              cardNumber={cardNumber}
              firstName={firstName}
              lastName={lastName}
              tenantName={tenantName}
            />
          );
        })()}
      </div>

      <div className='flex flex-col gap-spacing-3xlp'>
        <div className='flex justify-between'>
          <p className='text-grayLight-900 font-semibold' style={{ fontSize: '18px' }}>Najbliższe posiłki do odbioru</p>
          <Link to={location.pathname.replace('main', `absences`)}>
            <CustomChakraButton
              hierarchy='tertiaryColor'
              size='sm'
              iconPosition='right'
              icon='arrowRight'
              buttonProps={{
                onClick: () => {}
              }}
            >
              Zobacz wszystkie zamówienia
            </CustomChakraButton>
          </Link>
        </div>
        <div
          ref={cardContainerRef}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeaveOrUp}
          onMouseUp={handleMouseLeaveOrUp}
          onMouseMove={handleMouseMove}
          className='flex gap-spacing-xl pb-spacing-3xl pl-spacing-md overflow-x-auto custom-scroll-hide'
        >
          {((): JSX.Element[] | JSX.Element => {
            if (chosenTenantId === null || chosenKidId === null) return <></>;

            const tenant = parentDashboardContext?.tenants.find(
              (tenant) => tenant.tenantId === chosenTenantId
            );

            const consumer = tenant?.consumers.find(
              (consumer) => consumer.consumerDto.id === chosenKidId
            );

            if (!tenant || !consumer) return <></>;

            return consumer.closestStakesToTake
              .slice(0, 6)
              .sort((a, b) => {
                let dateComparison: number;
                dateComparison = new Date(a.date).getTime() - new Date(b.date).getTime();
                if (dateComparison !== 0) {
                  return dateComparison;
                }
                return a.stakeName.localeCompare(b.stakeName);
              })
              .map(stake => (
                <StakeCharacterCard key={`StakeCard-${stake.boughtStakeId}`} stake={stake} consumerId={chosenKidId} />
              ));

          })()}
        </div>
      </div>
    </section>
  );
};

export default ParentMain;