import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { NavOptionsWithElementInterface } from "../../../shared/type/navOptions.type";
import Navigation from "../../Navigation/Navigation";
import ParentAccountSettings from "./ParentAccountSettings/ParentAccountSettings";
import ParentPasswordSettings from "./ParentPasswordSettings/ParentPasswordSettings";

const navOptions: NavOptionsWithElementInterface[] = [
  {
    link: 'account-settings',
    name: 'Ustawienia konta',
    component: <ParentAccountSettings />
  },
  {
    link: 'password-settings',
    name: 'Hasło',
    component: <ParentPasswordSettings />
  }]

const ParentSettings = () => {
  return (
    <section className="flex flex-col pt-spacing-3xl pb-12 gap-spacing-3xl">
      <div className="flex flex-col gap-6 pr-spacing-3xl pl-spacing-3xl">
        <div className='gap-spacing-3xl'>
          <h2 className='font-semibold text-grayLight-900' style={{ fontSize: '30px' }}>Ustawienia</h2>
        </div>
        <Navigation navOptions={navOptions} />
        <Routes>
          <Route path="/" element={<Navigate to="account-settings" replace />} />
          {navOptions.map((item) => (
            <Route key={item.link} path={`/${item.link}`} element={item.component} />
          ))}
        </Routes>
      </div>
    </section>
  );
};

export default ParentSettings;