import { TableContainer, Thead, Tr, Th, Box, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { HttpStatusCode } from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../context/ApiProvider";
import { useKidConsumerContext } from "../../../../../../context/KidConsumerProvider";
import {
  CoFinancingToConsumerInterface,
  EditCoFinancingAssignmentInterface
} from "../../../../../../shared/type/coFinancing.type";
import CustomAlert from "../../../../../CustomAlert/CustomAlert";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import { DataTable } from "../../../../../DataTable/DataTable";
import IconComponent from "../../../../../IconComponent/IconComponent";
import AddEditCoFinancing from "./AddCoFinancing/AddCoFinancing";

export interface EditedInterface extends EditCoFinancingAssignmentInterface {
  id: number
}

const KidCoFinancing = () => {
  const {id: tenantId} = useParams();
  const { apiTenantCoFinancingInstitutionController } = useApi();
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();
  const { kid, fetchKidConsumer } = useKidConsumerContext();
  const columnHelper = createColumnHelper<CoFinancingToConsumerInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ deleteElementId, setDeleteElementId ] = useState<number>(0);
  const [mode, setMode] = useState<'add' | 'edit'>('add');
  const [ editedElement, setEditedElement ] = useState<EditedInterface>()
  const [shopPopUp, setShowPopUp] = useState<{
    deleteCoFinancing: boolean,
    addEditCoFinancing: boolean,
  }>({
    deleteCoFinancing: false,
    addEditCoFinancing: false,
  });

  const columns = [
    columnHelper.accessor('coFinancingInstitution.name', {
      cell: (info) => {
        const coFinancingInstitutionName = info.getValue();
        return (
          <p className='text-grayLight-900 font-medium text-sm underline underline-offset-4'>
            {coFinancingInstitutionName}
          </p>
        );
      },
      header: 'Instytucja'
    }),
    columnHelper.accessor('from', {
      cell: (info) => {
        const from = info.getValue();
        return (
          <p className='text-grayLight-900 font-medium text-sm'>
            {from}
          </p>
        );
      },
      header: 'Od'
    }),
    columnHelper.accessor('to', {
      cell: (info) => {
        const to = info.getValue();
        return (
          <p className="text-grayLight-900 font-medium text-sm">
            {to}
          </p>
        );
      },
      header: "Do"
    }),
    columnHelper.accessor("stakeDto.name", {
      cell: (info) => {
        const stakeDtoName = info.getValue();
        return (
          <p className='text-grayLight-900 font-normal text-sm'>
            {stakeDtoName}
          </p>
        );
      },
      header: 'Stawka'
    }),
    columnHelper.accessor('coFinancingAmountPerDay', {
      cell: (info) => {
        const coFinancingAmountPerDay = info.getValue();
        return (
          <p className='text-grayLight-900 font-normal text-sm'>
            {coFinancingAmountPerDay}
          </p>
        );
      },
      header: 'Wartość dofinansowania'
    }),
    columnHelper.display({
      id: 'actions',
      header: '',
      cell: (info) => {
        const { id, to, from, coFinancingInstitution, coFinancingAmountPerDay, stakeDto } = info.row.original;
        return (
          <div className="flex gap-spacing-sm">
            <button
              onClick={() => {
                setMode('edit');
                setShowPopUp((prev) => ({...prev, addEditCoFinancing: true}));
                setEditedElement({
                  id: id,
                  fromDate: from,
                  toDate: to,
                  coFinancingAmountPerDay: coFinancingAmountPerDay,
                  stakeId: stakeDto.id,
                  cofinancingInstitutionId: coFinancingInstitution.id
                })
              }}
              className="font-semibold text-sm text-grayWarm-950 bg-transparent border-none">
              Edytuj
            </button>
            <button
              onClick={() => {
                setDeleteElementId(id);
                setShowPopUp((prev) => ({...prev, deleteCoFinancing: true}));
              }}
              className="font-semibold text-sm text-grayLight-600 bg-transparent border-none">
              Usuń
            </button>
          </div>
        );
      }
    })
  ];

  return (
    <TableContainer h={800} overflowY="auto" className="Branches__table bg-white rounded-lg">
      <DataTable
        disableFilters={true}
        extraThead={
          <Thead>
            <Tr>
              <Th colSpan={columns.length + 1}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5">
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-semibold text-base text-grayLight-900"
                      >
                        Dofinansowania
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-normal text-sm text-grayLight-700"
                      >
                        Lista dofinansowań
                      </Text>
                    </Box>
                  </div>
                  <div className="flex gap-spacing-lg">
                    <CustomChakraButton
                      size="md"
                      hierarchy="primary"
                      iconPosition="left"
                      icon="plus"
                      buttonProps={{
                        onClick: () => {
                          setMode('add'); // Ustaw tryb na dodawanie
                          setShowPopUp((prev) => ({...prev, addEditCoFinancing: true}));
                        },
                        type: 'button'
                      }}
                    >
                      Dodaj dofinansowanie
                    </CustomChakraButton>
                  </div>
                </div>
              </Th>
            </Tr>
          </Thead>
        }
        columns={columns}
        isLoading={isLoading}
        data={kid?.coFinancingToConsumerDtoList || []}
      />
      {shopPopUp.addEditCoFinancing && (
        <AddEditCoFinancing
          isOpen={shopPopUp.addEditCoFinancing}
          mode={mode}
          initData={editedElement}
          onClose={() => {
            fetchKidConsumer()
            setShowPopUp((prev) => ({...prev, addEditCoFinancing: false}))}
        }
        />
      )}
      {shopPopUp.deleteCoFinancing && (
        <CustomAlert
          header="Usunąć dofinansowanie?"
          content="Usunięcie dofinansowania ma bezpośredni wpływ na konto użytkownika."
          confirmButton={{
            iconPosition: 'onlyText',
            size: 'lg',
            hierarchy: 'warning'
          }}
          confirmButtonText="Usuń"
          onConfirmAction={async () => {
            try {
              const response = await apiTenantCoFinancingInstitutionController('unassign-cofinancingInstitution-from-consumer').delete(`/${tenantId}/${deleteElementId}`);
              if (response.status === HttpStatusCode.Ok) {
                setAlertProperties({
                  timeout: 2000,
                  title: 'Sukces',
                  description: `Poprawnie usunięto dofinansowanie`,
                  status: 'success',
                });
                setShowAlert(true);
                setDeleteElementId(0);
                setShowPopUp((prev) => ({...prev, deleteCoFinancing: false}));
                fetchKidConsumer()
              }
            } catch (error: any) {
              console.error(error);
              setAlertProperties({
                timeout: 2000,
                title: 'Błąd',
                description: `Nie udało się usunąć dofinansowanie: ${error.response.data.errorMessage}`,
                status: 'error',
              });
              setShowAlert(true);
            }
          }}
          cancelButton={{
            iconPosition: 'onlyText',
            size: 'lg',
            hierarchy: 'secondaryGray'
          }}
          cancelButtonText="Anuluj"
          onCancelAction={() => {
            fetchKidConsumer();
            setShowPopUp((prev) => ({...prev, deleteCoFinancing: false}));
          }}
          handleOpen={shopPopUp.deleteCoFinancing}
          icon={
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
              <IconComponent iconName="trash" />
            </div>
          }
        />
      )}
    </TableContainer>
  );
};

export default KidCoFinancing;
