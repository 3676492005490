import { OrderForChildren } from "../../shared/type/orderForParent.type";
import { PurchasableItemInterface, ParentOrderContextInterface } from "../../shared/type/parentOrderContext.type";
import formatDateForComparison from "../formatDateForComarison/formatDateForComarison";

export const addOrderToBasket = (
  commonMeal: PurchasableItemInterface[],
  selectedStake: number | undefined,
  parentOrderContext: ParentOrderContextInterface | undefined,
  consumerId: number | undefined,
  selectedDates: Date[],
  addItemToBasket: (order: OrderForChildren) => void,
  tenantId: string | undefined,
) => {
  const chosenStake = commonMeal.find(meal => meal.stakeId === selectedStake);

  if(!chosenStake) return

  const consumerOrderContext = parentOrderContext?.consumerOrderContexts.find(
    consumer => consumer.consumerId === consumerId
  );

  const daySelection = consumerOrderContext?.daySelection || [];

  selectedDates.forEach(date => {
    const currentDateString = formatDateForComparison(date.getFullYear(), date.getMonth() + 1, date.getDate());

    const connectedDatesGroup = daySelection.find(group =>
      group.includes(currentDateString)
    );

    addItemToBasket({
      tenantId: Number(tenantId),
      consumerId: consumerId || 0,
      orderedItems: [{
        connectedDates: connectedDatesGroup ? connectedDatesGroup : [],
        purchasableItem: {
          name: chosenStake.name,
          meals: chosenStake.meals,
          price: chosenStake.price,
          originalPrice: chosenStake.originalPrice,
          stakeId: chosenStake.stakeId,
          alreadyBoughtCount: chosenStake.alreadyBoughtCount,
          orderCount: chosenStake.orderCount,
          stakeLimitLeft: chosenStake.stakeLimitLeft,
          itemPurchaseLimit: chosenStake.itemPurchaseLimit
        },
        count: 1,
        when: formatDateForComparison(date.getFullYear(), date.getMonth() + 1, date.getDate())
      }],
      useCredit: false
    })
  })
}