import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from "@chakra-ui/react";
import { HttpStatusCode } from "axios";
import { Select } from "chakra-react-select";
import React, { useState, useEffect } from "react";
import { useAlertContext } from "../../../../context/AlertProvider";
import { useApi } from "../../../../context/ApiProvider";
import { useParentDashboardContextContext } from "../../../../context/ParentDashboardContext";
import CustomChakraButton from "../../../CustomChakraButton/CustomChakraButton";
import FormInput from "../../../Forms/FormInput/FormInput";

interface Props {
  isOpen: boolean,
  onClose: () => void,
}

const CreateNewThread = ({ isOpen, onClose }: Props) => {
  const { parentDashboardContext } = useParentDashboardContextContext();
  const { apiParentMessageController } = useApi();
  const { setShow, setAlertProperties } = useAlertContext();

  const [message, setMessage] = useState<{
    tenantId: number,
    topic: string,
    content: string;
  }>({
    tenantId: 0,
    topic: '',
    content: ''
  });

  useEffect(() => {
    if (parentDashboardContext?.tenants?.length === 1) {
      setMessage((prev) => ({
        ...prev,
        tenantId: parentDashboardContext.tenants[0].tenantId
      }));
    }
  }, [parentDashboardContext]);

  const confirmMessage = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      await apiParentMessageController('create-thread').post('', message);
      if (HttpStatusCode.Ok) {
        setAlertProperties({
          status: 'success',
          timeout: 2000,
          description: 'Poprawnie wysłano wiadomość',
          title: 'Sukces'
        });
        onClose();
      }
    } catch (error) {
      setAlertProperties({
        status: 'error',
        timeout: 5000,
        description: 'Błąd wysłania wiadomości',
        title: 'Błąd'
      });
    } finally {
      setShow(true);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <form onSubmit={confirmMessage}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col gap-spacing-xl">
            <h2 className="font-semibold text-lg text-grayLight-900">Nowa wiadomość</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-xl">

            <div className="flex flex-col gap-spacing-md">
              <label className="text-sm font-medium text-grayLight-700">Do</label>
              <Select
                placeholder="Wybierz adresata"
                isRequired
                onChange={(option) => {
                  setMessage((prev) => ({
                    ...prev,
                    tenantId: option ? option.tenantId : 0
                  }));
                }}
                value={parentDashboardContext?.tenants.find(tenant => tenant.tenantId === message.tenantId)}
                getOptionLabel={(tenant) => tenant.tenantName}
                getOptionValue={(tenant) => tenant.tenantId.toString()}
                options={parentDashboardContext?.tenants}
              />
            </div>

            <div className="flex flex-col gap-spacing-md">
              <FormInput
                label="Temat"
                inputParams={{
                  required: true,
                  onChange: (e) => {
                    setMessage((prev) => ({ ...prev, topic: e.target.value }));
                  }
                }}
              />
            </div>

            <div className="flex flex-col gap-spacing-md">
              <textarea placeholder='Napisz wiadomość' id="" cols={30} rows={10}
                        required
                        onChange={(e) => {
                          setMessage((prev) => ({ ...prev, content: e.target.value }));
                        }}
                        className='resize-none gap-spacing-3xl rounded-md border pt-2.5 pr-3.5 pb-2.5 pl-3.5 border-grayLight-300'/>
            </div>

          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: onClose
              }}
            >
              Anuluj
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                type: "submit"
              }}
            >
              Wyślij
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default CreateNewThread;
