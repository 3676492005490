import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from '@chakra-ui/react';
import { HttpStatusCode } from "axios";
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../context/AlertProvider";
import { useApi } from '../../../../../context/ApiProvider';
import { NewOrganisationUnitInterface } from '../../../../../shared';
import CustomChakraButton from '../../../../CustomChakraButton/CustomChakraButton';
import FormInput from '../../../../Forms/FormInput/FormInput';

interface Props {
  mode: 'add' | 'edit';
  isOpen: boolean;
  onClose: () => void;
  unitId?: number;
  unitName?: string;
}

const defaultNewOrganisationUnit: NewOrganisationUnitInterface = {
  tenantId: 0,
  name: ''
};

const AddEditOrganisationUnit = ({ mode, isOpen, onClose, unitId, unitName }: Props) => {
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();
  const [newOrganisationUnit, setNewOrganisationUnit] = useState<NewOrganisationUnitInterface>(
    defaultNewOrganisationUnit
  );
  const { apiTenantOrganisationUnitController } = useApi();
  const { id: tenant } = useParams();

  const handleRequest = async (e: React.FormEvent) => {
    e.preventDefault();
    let responseStatusCode: number;
    const dataUnit = {
      tenantId: Number(tenant),
      name: newOrganisationUnit.name
    };
    try {
      if (mode === 'add') {
         responseStatusCode = (await apiTenantOrganisationUnitController('').post('', dataUnit)).status;
      } else {
         responseStatusCode = (await apiTenantOrganisationUnitController('').put(`/${unitId}`, dataUnit)).status;
      }
      if(responseStatusCode === HttpStatusCode.Ok) {
        setAlertProperties({
          timeout: 2000,
          title: "Sukces",
          description: `Poprawnie ${mode === 'add' ? 'dodano' : 'edytowano'} klasę`,
          status: "success"
        })
      }
    } catch (error: any) {
      if(error.response.status === HttpStatusCode.Conflict) {
        setAlertProperties({
          timeout: 2000,
          title: "Konflikt",
          description: `Klasa o podanej nazwie już istnieje`,
          status: "warning"
        })
      } else {
        setAlertProperties({
          timeout: 2000,
          title: "Błąd",
          description: `Błąd dodawania klasy `,
          status: "error"
        })
      }
    } finally {
      setShowAlert(true);
      onClose();
    }
  };

  useEffect(() => {
    if (mode === 'edit' && unitName) {
      setNewOrganisationUnit({ ...defaultNewOrganisationUnit, name: unitName });
    }
    if (mode === 'add') {
      setNewOrganisationUnit({ ...defaultNewOrganisationUnit, name: '' });
    }
  }, [isOpen, mode, unitName]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <form onSubmit={handleRequest}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">{mode === 'add' ? 'Dodaj' : 'Edytuj'} klasę</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <FormInput
              label="Nazwa"
              placeholder="Podaj nazwę"
              inputParams={{
                required: true,
                name: 'name',
                minLength: 2,
                maxLength: 70,
                value: newOrganisationUnit.name,
                onChange: (e) => {
                  setNewOrganisationUnit((prev) => ({ ...prev, name: e.target.value }));
                }
              }}
            />
            <hr />
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow'
              }}
            >
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                type: 'submit'
              }}
            >
              Zapisz
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddEditOrganisationUnit;
