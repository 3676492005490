import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Allergens from './Allergens/Allergens';
import FoodMenuContainer from "./FoodMenuContainer/FoodMenuContainer";
import SalesSystem from './SalesSystem/SalesSystem';

const Kitchen = () => {
  const kitchenLinks = [
    {
      link: 'sales-system',
      element: <SalesSystem />
    },
    {
      link: 'allergens',
      element: <Allergens />
    },
    {
      link: 'food-menu',
      element: <FoodMenuContainer />
    },
    {
      link: 'pricing-plans',
      element: <p>Dania</p>
    },
    {
      link: 'mopr-mops-ops',
      element: <p>MOPR / MOPS / OPS</p>
    },
    {
      link: 'school-settings',
      element: <p>Ustawienia</p>
    }
  ];

  return (
    <Routes>
      {kitchenLinks.map((link) => (
        <Route key={link.link} path={`/${link.link}/*`} element={link.element} />
      ))}
    </Routes>
  );
};

export default Kitchen;
