import React from "react";
import { Routes, Route } from "react-router-dom";
import { EditAdministratorProvider } from "../../../../context/EditParentProvider";
import { TenantAdminBasketContextProvider } from "../../../../context/TenantAdminBasketProvider";
import ParentsTable from "./ParentsTable/ParentsTable";
import SingleParent from "./ParentsTable/SingleParents/SingleParent";

const Parents = () => {
  const parentsLink = [
    {
      link: "parents-table",
      element: <ParentsTable/>,
    },
    {
      link: "single-parent/:parentId",
      element: (
        <EditAdministratorProvider>
          <TenantAdminBasketContextProvider>
            <SingleParent/>
          </TenantAdminBasketContextProvider>
        </EditAdministratorProvider>)
    }
  ];

  return(
    <Routes>
      {parentsLink.map((link) => (
        <Route key={link.link} path={`/${link.link}/*`} element={link.element} />
      ))}
    </Routes>
  )
};

export default Parents;