import React from 'react';

const MinusIcon = ({ className, color }: { className: string, color: string }) => (
  <svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M1.16797 1H12.8346" stroke={color} strokeWidth="1.66667" strokeLinecap="round"
          strokeLinejoin="round" />
  </svg>

);

export default MinusIcon;
