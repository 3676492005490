import React from 'react';

const MailIcon = ({ className }: { className: string }) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28Z"
      fill="#FFF7E6"
    />
    <path
      d="M16.3335 22.167L25.8592 28.835C26.6306 29.375 27.0163 29.6449 27.4358 29.7495C27.8064 29.8419 28.194 29.8419 28.5645 29.7495C28.984 29.6449 29.3697 29.375 30.1411 28.835L39.6668 22.167M21.9335 37.3337H34.0668C36.027 37.3337 37.0071 37.3337 37.7558 36.9522C38.4144 36.6166 38.9498 36.0812 39.2854 35.4226C39.6668 34.6739 39.6668 33.6938 39.6668 31.7337V24.267C39.6668 22.3068 39.6668 21.3267 39.2854 20.578C38.9498 19.9195 38.4144 19.384 37.7558 19.0485C37.0071 18.667 36.027 18.667 34.0668 18.667H21.9335C19.9733 18.667 18.9932 18.667 18.2445 19.0485C17.586 19.384 17.0505 19.9195 16.715 20.578C16.3335 21.3267 16.3335 22.3068 16.3335 24.267V31.7337C16.3335 33.6938 16.3335 34.6739 16.715 35.4226C17.0505 36.0812 17.586 36.6166 18.2445 36.9522C18.9932 37.3337 19.9733 37.3337 21.9335 37.3337Z"
      stroke="#FFAC05"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MailIcon;
