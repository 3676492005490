import React from 'react';

const DotIcon = ({ className, color }: { className: string, color: string }) => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <circle cx="4" cy="4" r="3" fill={color} />
  </svg>

);

export default DotIcon;
