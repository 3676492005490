import React from 'react';
import { useSettingFormContext } from '../../../../../context/SettingsFormContext';
import CustomChakraButton from '../../../../CustomChakraButton/CustomChakraButton';
import './SettingsForm.css';
import AbsenceReportingOptions from './AbsenceReportingOptions/AbsenceReportingOptions';
import BillingSettings from './BillingSettings/BillingSettings';
import OtherSettings from './OtherSettings/OtherSettings';
import OutputSettings from './OutputSettings/OutputSettings';
import SalesDataSettings from './SalesDataSettings/SalesDataSettings';

const SettingsForm = () => {
  const { cancelForm, isEdited } = useSettingFormContext();

  return (
    <section className="Settings p-spacing-4xl w-full flex flex-col items-start justify-center">
      <div className="Settings__header flex justify-between w-full">
        <p className="m-0 p-0 font-semibold text-lg text-grayLight-900">Ustawienia</p>
        <div className="Settings__header__actionButtons flex gap-spacing-lg">
          <CustomChakraButton
            iconPosition="onlyText"
            hierarchy="tertiaryColor"
            size="md"
            buttonProps={{
              onClick: cancelForm,
              type: 'button',
              disabled: !isEdited
            }}
          >
            Anuluj
          </CustomChakraButton>
          <CustomChakraButton
            iconPosition="onlyText"
            hierarchy="primary"
            size="md"
            buttonProps={{ type: 'submit', disabled: !isEdited }}
          >
            Zapisz zmiany
          </CustomChakraButton>
        </div>
      </div>
      <div className="Settings__formContainer w-full">
        <div className="w-full flex flex-col gap-spacing-4xl overflow-y-auto pb-spacing-11xl">
          <SalesDataSettings />
          <AbsenceReportingOptions />
          <OutputSettings />
          <BillingSettings />
          <OtherSettings />
        </div>
      </div>
    </section>
  );
};

export default SettingsForm;
