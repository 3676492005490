import { extendTheme } from '@chakra-ui/react';

const customTheme = extendTheme({
  colors: {
    customOrange: {
      500: '#FFAC05'
    }
  },
  components: {
    Checkbox: {
      baseStyle: {
        control: {
          _checked: {
            bg: 'customOrange.500',
            borderColor: 'customOrange.500',
            _hover: {
              bg: 'customOrange.600',
              borderColor: 'customOrange.600'
            }
          }
        }
      }
    }
  }
});

export default customTheme;
