import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../../../context/ApiProvider";
import { NewKidInterface } from "../../../../../../../../shared/type/kid.type";
import CustomChakraButton from "../../../../../../../CustomChakraButton/CustomChakraButton";
import AddKidToParentAdd from "./AddKidToParentAdd/AddKidToParentAdd";
import AddKidToParentAssign from "./AddKidToParentAssign/AddKidToParentAssign";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const AddKidToParent  = ({ isOpen, onClose }: Props) => {
  const { id: tenantId, parentId } = useParams<{ id: string, parentId: string }>()
  const defaultChild: NewKidInterface = {
    tenantId: Number(tenantId),
    firstName: '',
    lastName: '',
    organisationUnitId: null,
    cardNumber: null,
    remarks: null,
    parentIds: [Number(parentId)],
  }
  const { apiTenantConsumerController } = useApi();
  const [ mode, setMode ] = useState<'assign' | 'add'>('assign')
  const [newChildren, setNewChildren] = useState<NewKidInterface[]>([defaultChild]);
  const [ selectedKidsIds, setSelectedKidsIds ] = useState<number[]>([])
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();

  const addNewChildren = () => {
    newChildren.forEach(async (child) => {
      try {
          const response = await apiTenantConsumerController('').post('', child);
          if(response){
            setAlertProperties({
              timeout: 2000,
              title: "Sukces",
              description: `Poprawnie dodano dziecko ${child.firstName} ${child.lastName}`,
              status: "success"
            });
            setShowAlert(true);
            onClose();
        }
      } catch (error) {
        console.error()
        setAlertProperties({
          timeout: 2000,
          title: "Błąd",
          description: `Błąd dodawania dziecka ${child.firstName} ${child.lastName}`,
          status: "error"
        });
        setShowAlert(true);
      }
    })
  }

  const assignChildren = () => {
    selectedKidsIds.forEach(async (childId) => {
      try {
        const response = await apiTenantConsumerController('assign-consumer-to-parent').post(`/${tenantId}/${childId}/${parentId}`);
        if(response) {
          setAlertProperties({
            timeout: 2000,
            title: "Sukces",
            description: `Poprawnie przypisano dziecko`,
            status: "success"
          });
          setShowAlert(true);
          onClose();
        }
      } catch (error) {
          setAlertProperties({
            timeout: 2000,
            title: "Błąd",
            description: `Błąd przypisywania dziecka`,
            status: "error"
          });
          setShowAlert(true);
      }
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <form onSubmit={() => {
        if(mode === 'add') {
          addNewChildren();
        }
        if(mode === 'assign') {
          assignChildren()
        }
      }}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className="flex">
            <div className='flex flex-col text-grayLight-900 font-semibold text-lg border-r border-grayLight-200'>
              <h2 className="font-semibold text-lg">Dodaj dziecko do konta rodzica</h2>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex flex-col gap-spacing-xl">
            {
              mode === "assign" ? (
                <AddKidToParentAssign
                  mode={mode}
                  setMode={setMode}
                  selectedKidsIds={selectedKidsIds}
                  setSelectedKidsIds={setSelectedKidsIds}
                />
              ) : (
                <AddKidToParentAdd
                  mode={mode}
                  setMode={setMode}
                  defaultChild={defaultChild}
                  newChildren={newChildren}
                  setNewChildren={setNewChildren}/>
              )
            }
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: onClose
              }}
            >
              Anuluj
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: () => {
                  if(mode === 'add') {
                    addNewChildren();
                  }
                  if(mode === 'assign') {
                    assignChildren()
                  }
                },
                type: "button"
              }}
            >
              Dodaj
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddKidToParent;