import React from 'react';

const ChevronIcon = ({
  className,
  color,
  arrowPosition
}: {
  className: string;
  color: string;
  arrowPosition: 'up' | 'down' | 'right' | 'left';
}) => {
  switch (arrowPosition) {
    case 'up':
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            d="M15 12.5L10 7.5L5 12.5"
            stroke={color}
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'down':
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            d="M5 7.5L10 12.5L15 7.5"
            stroke={color}
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'right':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <path
            d="M6 12L10 8L6 4"
            stroke={color}
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'left':
      return (
        <svg width="21"
             height="20"
             viewBox="0 0 21 20"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             className={className}>
          <path d="M13 15L8 10L13 5"
                stroke={color}
                strokeWidth="1.66667" strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>
      );
    default:
      return <svg />;
  }
};

export default ChevronIcon;
