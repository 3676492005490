import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, Routes, Route, Link } from "react-router-dom";
import { useApi } from '../../../../context/ApiProvider';
import { CompanyType } from '../../../../shared/type/company.type';
import Breadcrumbs from '../../../Breadcrumbs/Breadcrumbs';
import IconComponent from '../../../IconComponent/IconComponent';
import Navigation from '../../../Navigation/Navigation';
import CompanyData from './CompanyData/CompanyData';
import InvoiceData from './InvoiceData/InvoiceData';
import ListOfTenants from './ListOfTenants/ListOfTenants';

const navOptions = [
  {
    link: 'company-data',
    name: 'Dane firmy',
    component: <CompanyData />
  },
  {
    link: 'invoices-data',
    name: 'Dane do faktury',
    component: <InvoiceData />
  },
  {
    link: 'branch-list',
    name: 'Lista oddziałów',
    component: <ListOfTenants />
  }
];

const SingleCompany = () => {
  const { id } = useParams();
  const { apiCompanyController } = useApi();
  const [companyName, setCompanyName] = useState<string>('');

  const fetchCompanyData = async () => {
    try {
      const result: AxiosResponse<any | CompanyType> = await apiCompanyController('').get(`/${id}`);
      setCompanyName(result.data.name);
    } catch (error) {
      console.warn('Błąd pobrania elementu');
    } finally {
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  return (
    <section className="SingleCompany">
      <div className="SingleCompany__container pt-spacing-xl pr-spacing-3xl pb-spacing-xl pl-spacing-3xl gap-spacing-4xl flex flex-col">
        <div className="SingleCompany__actualCompanyStatus">
          <Breadcrumbs
            divider={<IconComponent iconName="chevronRight" />}
            elements={[
              <IconComponent iconName="home" />,
              <p className="font-medium text-sm text-grayLight-600">
                <Link to='/superAdmin/companies'>Lista firm</Link>
              </p>,
              <p className="font-semibold text-sm text-gray Warm-800">{companyName}</p>
            ]}
          />
        </div>
        <div className="SingleCompany__container flex flex-col gap-spacing-4xl">
          <Link to='/superAdmin/companies' className="SingleCompany__backButtonContainer flex gap-spacing-md font-semibold text-base text-grayWarm-950">
            <IconComponent iconName="arrowLeft" />
            <p>Wróć</p>
          </Link>
          <div className="SingleCompany__contextContainer flex flex-col gap-spacing-3xl">
            <Navigation navOptions={navOptions} />
            <Routes>
              {navOptions.map((item) => (
                <Route key={item.link} path={`/${item.link}`} element={item.component} />
              ))}
            </Routes>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleCompany;
