import { AxiosResponse } from "axios";
import { useState, useEffect } from "react";
import { useApi } from "../../../../context/ApiProvider";
import { MultiConsumerPaymentInterface } from "../../../../shared/type/multiConsumerPayment.type";
import { ParentSaldoConsumer } from "../../../../shared/type/parentSaldo.type";

interface Props {
  consumer: ParentSaldoConsumer,
  updateConsumerAmount: (consumerId: number, amount: string) => void,
}

const SaldoUserCard = ({ consumer, updateConsumerAmount }: Props) => {
  const { apiOrderController } = useApi();
  const { consumerName, saldoToPayString, provision } = consumer;
  const [ calculationResponse, setCalculationResponse ] = useState<MultiConsumerPaymentInterface | undefined>(undefined)

  const [ moneyAmount, setMoneyAmount ] = useState(0);

  const handleCalculateSaldo = async () => {
    try {

      const response: AxiosResponse<MultiConsumerPaymentInterface> = await apiOrderController('multi-consumer-top-up-payment').post('',
        {
          consumerInputs: [
            {
              consumerId: consumer.consumerId,
              topUpAmountMoneyString: `${moneyAmount}`,
              initialSaldo: saldoToPayString,
              provision: provision
            }
          ]
        }
      );
      setCalculationResponse(response.data)
    } catch (error) {
    }
  };

  let searchTimeout: NodeJS.Timeout;

  useEffect(() => {
    clearTimeout(searchTimeout)

    searchTimeout = setTimeout(() => {
      handleCalculateSaldo()
    }, 500)

    return () => clearTimeout(searchTimeout);
  }, [moneyAmount]);

  return (
    <div className='flex flex-col gap-spacing-xl w-full'>
      <p className='text-lg text-grayLight-900 font-semibold'>{consumerName}</p>
      <div className='flex flex-col gap-spacing-xl'>
        <div className='flex justify-between items-center w-52'>
          <label className='text-sm text-grayLight-700 font-medium'>Saldo:</label>
          <div>
            <p className='text-lg text-grayLight-700 font-normal'>{saldoToPayString} zł</p>
          </div>
        </div>
        <div className="flex justify-between items-center w-52">
          <label className="text-sm text-grayLight-700 font-medium">Wpłacam:</label>
          <div>
            <input
              onChange={(e) => {
                updateConsumerAmount(consumer.consumerId, e.target.value);
                setMoneyAmount(Number(e.target.value));
              }}
              type="number"
              step='0.01'
              className='rounded-md border box-border w-24'
              style={{ padding: '10px 14px' }} />
          </div>
        </div>
        <div className="border-b border-grayLight-300" />
        <div className='flex justify-between items-center w-52'>
          <label className='text-sm text-grayLight-700 font-medium'>Saldo po wpłacie:</label>
          <div>
            <p className='text-lg text-grayLight-700 font-normal'>
              {calculationResponse?.consumerTopUpCalculations
                ?.find(item => item.consumerId === consumer.consumerId)
                ?.saldoAfterPayment || '-'}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default SaldoUserCard;