import { AxiosResponse } from "axios";
import React, { ReactNode, useState, createContext, useContext, useMemo, useEffect, Dispatch } from "react";
import {
  CalculatePaymentInterfaceAssignedToConsumer
} from "../shared/type/calculatePaymentResponse.type";
import { OrderForParentInterface, OrderForChildren, OrderedItemInterface } from "../shared/type/orderForParent.type";
import { PlaceOrderForParentInterface } from "../shared/type/placeOrderForParent.type";
import { useApi } from "./ApiProvider";

interface Props {
  children: ReactNode;
}

interface UserDataInterface {
  consumerId: number,
  saldo: number,
  creditLimit: number,
  provision: string
}

interface ParentBasketContextProps {
  parentBasketContext: OrderForParentInterface;
  isEmpty: boolean;
  addItemToBasket: (order: OrderForChildren) => void;
  deleteProductFromBasket: (consumerId: number, itemToRemove: OrderedItemInterface) => void;
  changeProductQuantity: (consumerId: number, itemToChange: OrderedItemInterface, increment: boolean) => void;
  resetBasket: () => void;
  setUserData: Dispatch<React.SetStateAction<UserDataInterface[]>>;
  calculatedPayment: CalculatePaymentInterfaceAssignedToConsumer | undefined;
  placeOrderForPayment: () => void;
  orderSummary: PlaceOrderForParentInterface | undefined;
  handleUseCreditToggle: (consumerId: number, checked: boolean) => void;
}

const defaultBasket: OrderForParentInterface = {
  ordersForChildren: []
}

const ParentDashboardContextContext = createContext<ParentBasketContextProps | undefined>(undefined);

export const useParentBasketContext = () => {
  const context = useContext(ParentDashboardContextContext);
  if (!context) {
    throw new Error('useParentBasketContext must be used within a ParentBasketContextProvider');
  }
  return context;
};

export const ParentBasketContextProvider = ({ children }: Props) => {
  const { apiOrderController } = useApi();
  const [ parentBasketContext, setParentBasketContext ] = useState<OrderForParentInterface>(defaultBasket)
  const [ calculatedPayment, setCalculatedPayment ] = useState<CalculatePaymentInterfaceAssignedToConsumer | undefined>(undefined)
  const [ userData, setUserData ] = useState<UserDataInterface[]>([])
  const [ orderSummary, setOrderSummary ] = useState<PlaceOrderForParentInterface>();
  const [ useCreditByConsumer, setUseCreditByConsumer ] = useState<{ [consumerId: number]: boolean }>({});

  const [ isEmpty, setIsEmpty ] = useState<boolean>(true);

  const handleUseCreditToggle = (consumerId: number, checked: boolean) => {
    setUseCreditByConsumer(prevState => ({
      ...prevState,
      [consumerId]: checked,
    }));

    // Update `useCredit` status in the context
    setParentBasketContext(prevContext => ({
      ...prevContext,
      ordersForChildren: prevContext.ordersForChildren.map(order =>
        order.consumerId === consumerId ? { ...order, useCredit: checked } : order
      ),
    }));
  };

  function addItemToBasket(newItem: OrderForChildren) {
    setParentBasketContext(prevContext => {
      const existingOrderIndex = prevContext.ordersForChildren.findIndex(order => order.consumerId === newItem.consumerId);

      if (existingOrderIndex !== -1) {
        const existingOrder = prevContext.ordersForChildren[existingOrderIndex];

        let mergedOrderedItems = [...existingOrder.orderedItems];

        newItem.orderedItems.forEach(newOrderedItem => {
          const existingItemIndex = mergedOrderedItems.findIndex(item =>
            item.purchasableItem.name === newOrderedItem.purchasableItem.name &&
            item.purchasableItem.price === newOrderedItem.purchasableItem.price &&
            item.when === newOrderedItem.when
          );

          if (existingItemIndex !== -1) {
            mergedOrderedItems[existingItemIndex] = {
              ...mergedOrderedItems[existingItemIndex],
              count: mergedOrderedItems[existingItemIndex].count + newOrderedItem.count
            };
          } else {
            mergedOrderedItems.push(newOrderedItem);
          }
        });

        const updatedOrdersForChildren = [...prevContext.ordersForChildren];
        updatedOrdersForChildren[existingOrderIndex] = {
          ...existingOrder,
          orderedItems: mergedOrderedItems
        };

        return {
          ...prevContext,
          ordersForChildren: updatedOrdersForChildren
        };
      } else {
        return {
          ...prevContext,
          ordersForChildren: [...prevContext.ordersForChildren, newItem]
        };
      }
    });
  }

  async function calculatePayment() {
    try {
      const consumerCalculationRequests = parentBasketContext.ordersForChildren.map(children => {
        const foundConsumer = userData.find(consumer => consumer.consumerId === children.consumerId);

        if (foundConsumer) {
          const { saldo, creditLimit, provision } = foundConsumer;

          const useCredit = useCreditByConsumer[children.consumerId] ?? false;

          return {
            consumerId: children.consumerId,
            orderCalculationRequestDto: {
              orderedItems: children.orderedItems,
              initialSaldo: saldo ?? 0,
              credit: creditLimit,
              provision: provision,
              useCredit,
            }
          };
        } else {
          console.error(`No consumer found with id ${children.consumerId}`);
          return null;
        }
      }).filter(request => request !== null);

      if (consumerCalculationRequests.length > 0) {
        const response: AxiosResponse<CalculatePaymentInterfaceAssignedToConsumer> = await apiOrderController('calculate-up-payment').post('', {
          consumerCalculationRequests: consumerCalculationRequests
        });

        const updatedPayments: CalculatePaymentInterfaceAssignedToConsumer = response.data;

        setCalculatedPayment(updatedPayments);
        console.log(calculatedPayment);
      } else {
        console.error('No valid consumer data to process');
      }
    } catch (error) {
      console.error("Error calculating payment:", error);
    }
  }

  const placeOrderForPayment = async () => {
    try {
      // @ts-ignore
      const ordersForChildren = parentBasketContext.ordersForChildren.map(order => ({
        tenantId: order.tenantId,
        consumerId: order.consumerId,
        useCredit: order.useCredit,
        orderedItems: order.orderedItems.map(item => ({
          purchasableItem: {
            name: item.purchasableItem.name,
            meals: item.purchasableItem.meals.map(meal => ({
              mealToDayId: meal.mealToDayId,
              courseName: meal.courseName,
              mealDto: {
                // @ts-ignore
                id: meal.mealDto.id,
                // @ts-ignore
                name: meal.mealDto.name,
                // @ts-ignore
                description: meal.mealDto.description,
                // @ts-ignore
                allergens: meal.mealDto.allergens.map(allergen => ({
                  id: allergen.id,
                  formalNumber: allergen.formalNumber,
                  name: allergen.name
                }))
              }
            })),
            price: item.purchasableItem.price,
            originalPrice: item.purchasableItem.originalPrice,
            stakeId: item.purchasableItem.stakeId || 0,
            alreadyBoughtCount: item.purchasableItem.alreadyBoughtCount || 0,
            orderCount: item.purchasableItem.orderCount || 0,
            stakeLimitLeft: item.purchasableItem.stakeLimitLeft ?? null,
            itemPurchaseLimit: item.purchasableItem.itemPurchaseLimit ?? null
          },
          count: item.count,
          when: item.when
        }))
      }));

      const response: AxiosResponse<PlaceOrderForParentInterface> = await apiOrderController('place-order-for-parent').post('', {
        ordersForChildren: ordersForChildren
      });

      setOrderSummary(response.data)

    } catch (error) {
      console.error("Error placing order:", error);
    }
  };

  function deleteProductFromBasket(consumerId: number, itemToRemove: OrderedItemInterface) {
    setParentBasketContext(prevContext => ({
      ...prevContext,
      ordersForChildren: prevContext.ordersForChildren.map(order => {
        if (order.consumerId !== consumerId) return order;

        return {
          ...order,
          orderedItems: order.orderedItems.filter(item => {
            const isSameProduct = item.purchasableItem.name === itemToRemove.purchasableItem.name &&
              item.purchasableItem.price === itemToRemove.purchasableItem.price &&
              (item.when === itemToRemove.when ||
                (item.connectedDates && item.connectedDates.includes(itemToRemove.when)));
            return !isSameProduct;
          }),
        };
      }),
    }));
  }

  function changeProductQuantity(consumerId: number, itemToChange: OrderedItemInterface, increment: boolean) {
    setParentBasketContext(prevContext => ({
      ...prevContext,
      ordersForChildren: prevContext.ordersForChildren.map(order => {
        if (order.consumerId !== consumerId) return order;

        return {
          ...order,
          orderedItems: order.orderedItems.map(item => {
            const isTargetItem = item.purchasableItem.name === itemToChange.purchasableItem.name &&
              item.purchasableItem.price === itemToChange.purchasableItem.price &&
              (item.when === itemToChange.when ||
                (item.connectedDates && item.connectedDates.includes(itemToChange.when)));

            if (isTargetItem) {
              const newCount = increment ? item.count + 1 : Math.max(item.count - 1, 1);
              return { ...item, count: newCount };
            }
            return item;
          }),
        };
      }),
    }));
  }


  function resetBasket(){
    setParentBasketContext(defaultBasket);
  }

  useEffect(() => {
    if(parentBasketContext.ordersForChildren.length > 0){
      setIsEmpty(false)
    } else {
      setIsEmpty(true)
    }
    calculatePayment()
  }, [parentBasketContext]);

  const contextValue = useMemo(
    () => ({
      parentBasketContext,
      isEmpty,
      addItemToBasket,
      resetBasket,
      deleteProductFromBasket,
      changeProductQuantity,
      setUserData,
      calculatedPayment,
      placeOrderForPayment,
      orderSummary,
      handleUseCreditToggle
    }),
    [
      parentBasketContext,
      isEmpty,
      addItemToBasket,
      resetBasket,
      deleteProductFromBasket,
      changeProductQuantity,
      setUserData,
      calculatedPayment,
      placeOrderForPayment,
      orderSummary,
      handleUseCreditToggle
    ]
  );

  return (
    <ParentDashboardContextContext.Provider value={contextValue}>
      {children}
    </ParentDashboardContextContext.Provider>
  );
};
