import { ParentOrderContextInterface } from "../../shared/type/parentOrderContext.type";

import moment from "moment";
moment.locale('pl');

export const handleSelectSlot = (
  slotInfo: any,
  minDate: any,
  maxDate: any,
  parentOrderContext: ParentOrderContextInterface | undefined,
  consumerId: number | undefined,
  setSelectedDates:  React.Dispatch<React.SetStateAction<Date[]>>
) => {
  const selectedDate = moment(slotInfo.start);

  if (selectedDate.isBefore(minDate) || selectedDate.isAfter(maxDate)) {
    return;
  }

  const date = selectedDate.toDate();

  const consumerOrderContext = parentOrderContext?.consumerOrderContexts.find(
    consumer => consumer.consumerId === consumerId
  );

  const orderDay = consumerOrderContext?.orderDays.find(day =>
    moment(day.when).isSame(date, 'day')
  );

  if (orderDay?.salesBlocked || orderDay?.workingDay === false) {
    return;
  }

  const daySelection = consumerOrderContext?.daySelection || [];

  const selectedGroup = daySelection.find(group =>
    group.some(d => moment(d).isSame(selectedDate, 'day'))
  );

  let datesToSelect = [date];

  if (selectedGroup) {
    datesToSelect = selectedGroup.map(d => moment(d).toDate());
  }

  setSelectedDates((prevDates) => {
    const newDates = datesToSelect.filter(d =>
      !prevDates.some(existingDate => moment(existingDate).isSame(d, 'day'))
    );

    const shouldDeselectGroup = datesToSelect.some(d =>
      prevDates.some(existingDate => moment(existingDate).isSame(d, 'day'))
    );

    if (shouldDeselectGroup) {
      return prevDates.filter(existingDate =>
        !datesToSelect.some(d => moment(existingDate).isSame(d, 'day'))
      );
    }

    return [...prevDates, ...newDates];
  });
};