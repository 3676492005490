import { TableContainer, Thead, Th, useDisclosure } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useApi } from '../../../../context/ApiProvider';
import { CompanyType } from '../../../../shared/type/company.type';
import CustomAlert from '../../../CustomAlert/CustomAlert';
import CustomChakraButton from '../../../CustomChakraButton/CustomChakraButton';
import { DataTable } from '../../../DataTable/DataTable';
import IconComponent from '../../../IconComponent/IconComponent';
import AddNewCompany from '../AddNewCompany/AddNewCompany';

const defaultAlert = {
  isOpen: false,
  id: 0,
  name: ''
};

const CompaniesTable = () => {
  const columnHelper = createColumnHelper<CompanyType>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { apiCompanyController } = useApi();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [companiesData, setCompaniesData] = useState<CompanyType[] | []>([]);
  const [deleteElement, setDeleteElement] = useState<{
    isOpen: boolean;
    id: number;
    name: string;
  }>(defaultAlert);

  const fetchCompanies = async () => {
    try {
      setIsLoading(true);
      const result = await apiCompanyController('').get('');
      setCompaniesData(result.data);
      setIsLoading(false);
    } catch (error) {
      console.warn(error);
      setIsLoading(false);
    }
  };

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => {
        const name = info.getValue();
        return (
          <div className="text-sm font-semibold">
            {name}
          </div>
        );
      },
      header: 'Nazwa'
    }),
    columnHelper.accessor('street', {
      cell: (info) => {
        const { street, postCode, city } = info.row.original;
        return (
          <div className="text-sm font-medium">
            {street} {postCode} {city}
          </div>
        );
      },
      header: 'Adres'
    }),
    columnHelper.accessor('email', {
      cell: (info) => {
        const email = info.getValue();
        return <div className="text-sm font-medium">{email}</div>;
      },
      header: 'Email'
    }),
    columnHelper.accessor('phone', {
      cell: (info) => {
        const phone = info.getValue();
        return <div className="text-sm font-medium">{phone}</div>;
      },
      header: 'Telefon'
    }),
    columnHelper.accessor('tenants', {
      cell: (info) => {
        const tenants = info.getValue();
        return <div className="text-sm font-medium">{tenants?.length || 0}</div>;
      },
      header: 'Oddziały'
    }),
    columnHelper.accessor('mainAdmin', {
      cell: (info) => {
        const { lastName, firstName } = info.getValue();
        return (
          <div className="text-sm font-medium">
            {firstName} {lastName}
          </div>
        );
      },
      header: 'Główny admin'
    }),
    columnHelper.accessor('tenants', {
      cell: (info) => {
        const { id, name } = info.row.original;
        return (
          <button
            className="text-sm border-none bg-transparent font-semibold text-grayLight-600"
            onClick={() => {
              setDeleteElement({ id, name, isOpen: true });
            }}
          >
            Usuń
          </button>
        );
      },
      header: ''
    }),
    columnHelper.accessor('tenants', {
      cell: (info) => {
        const { id } = info.row.original;

        return (
          <button className="text-sm border-none bg-transparent font-semibold text-grayWarm-950">
            <Link to={`id/${id}/company-data`}>
            Edytuj
            </Link>
          </button>
        );
      },
      header: ''
    })
  ];

  useEffect(() => {
    fetchCompanies();
  }, []);

  return (
    <div className="Branches__tableContainer h-full flex flex-col justify-start lg:p-spacing-4xl">
      <TableContainer className="Branches__table lg:p-spacing-3xl bg-white rounded-lg">
        <DataTable
          data={companiesData}
          columns={columns}
          isLoading={isLoading}
          extraThead={
            <Thead>
              <Th colSpan={columns.length}>
                <div className="w-full flex items-center justify-between">
                  <p className="text-base font-semibold">Lista firm</p>
                  <div className="hidden lg:block">
                    <CustomChakraButton
                      hierarchy="primary"
                      size="md"
                      icon="plus"
                      iconPosition="left"
                      buttonProps={{
                        onClick: onOpen
                      }}
                    >
                      Dodaj firmę
                    </CustomChakraButton>
                  </div>
                </div>
              </Th>
            </Thead>
          }
        />
      </TableContainer>
      <div className="bg-white mt-spacing-lg h-20 p-spacing-xl lg:hidden">
        <button
          type="button"
          className="rounded bg-brand-300 w-full h-full border-brand-300 py-2.5 px-4 font-semibold text-base flex items-center justify-center"
        >
          <IconComponent iconName="plus" className="pr-3 h-5 w-5" />
          Dodaj oddział
        </button>
      </div>
      <AddNewCompany
        isOpen={isOpen}
        onClose={() => {
          onClose();
          fetchCompanies();
        }}
      />
      <CustomAlert
        handleOpen={deleteElement.isOpen}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
            <IconComponent iconName="trash" />
          </div>
        }
        header={`Usunąć firmę ${deleteElement?.name}?`}
        content="Usunięcie firmy jest nieodwracalne i wiążę się z utratą danych."
        cancelButtonText="Anuluj"
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        confirmButtonText="Usuń"
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'primary'
        }}
        onCancelAction={() => {
          setDeleteElement(defaultAlert);
        }}
        onConfirmAction={async () => {
          try {
            await apiCompanyController('').delete(`/${deleteElement?.id}`);
          } catch (error) {
            console.warn('Nie udało się usunąć firmy');
          } finally {
            fetchCompanies();
            setDeleteElement(defaultAlert);
          }
        }}
      />
    </div>
  );
};

export default CompaniesTable;
