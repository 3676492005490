import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Radio,
  Stack,
  RadioGroup,
  Input
} from "@chakra-ui/react";
import { Select } from 'chakra-react-select';
import { useParams } from "react-router-dom";
import { useApi } from '../../../../../context/ApiProvider';
import { OrganisationUnitInterface, NewDayOffInterface } from '../../../../../shared';
import CustomChakraButton from '../../../../CustomChakraButton/CustomChakraButton';
import CustomInputRadio from '../../../../Forms/CustomInputRadio/CustomInputRadio';
import FormInput from '../../../../Forms/FormInput/FormInput';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const AddDayOff = ({ isOpen, onClose }: Props) => {
  const { id: tenant } = useParams();
  const { apiTenantOrganisationUnitController, apiTenantFreeWorkingDayController } = useApi();

  const getDefaultDayOff = (): NewDayOffInterface => ({
    tenantId: Number(tenant),
    name: '',
    from: new Date().toISOString().split('T')[0], // Default date format
    to: new Date().toISOString().split('T')[0],
    freeDay: false,
    organisationIds: [0]
  });

  const [dayOff, setDayOff] = useState<NewDayOffInterface>(getDefaultDayOff());
  const [organizationUnits, setOrganizationUnits] = useState<OrganisationUnitInterface[]>([]);

  const handleClose = () => {
    onClose();
  };

  const addEditNewOffDay = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log(dayOff);
    try {
      await apiTenantFreeWorkingDayController('').post('', dayOff);
      handleClose();
    } catch (error) {
      console.warn(error);
    }
  };

  const fetchOrganizationUnits = async () => {
    try {
      const response = await apiTenantOrganisationUnitController('').get(`/${tenant}`);
      setOrganizationUnits(response.data);
      console.log(response);
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    fetchOrganizationUnits();
  }, []);

  const updateState = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDayOff((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl" isCentered>
      <form onSubmit={addEditNewOffDay}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">Dodaj dni wolne lub pracujące</h2>
            <p className="font-normal text-sm">
              Dodanie dnia wolnego oznacza automatyczną nieobecność dla wybranej grupy dzieci oraz
              zwrot na ich salda.{' '}
            </p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <div className="flex flex-col gap-spacing-md">
              <label className="text-sm font-medium text-grayLight-700">Sprzedaż</label>
              <RadioGroup
                value={`${dayOff.freeDay}`}
                onChange={(value) => {
                  setDayOff((prev) => ({ ...prev, freeDay: value === 'true' }));
                }}
              >
                <Stack>
                  <CustomInputRadio
                    header="Sprzedaż otwarta"
                    message="otwarcie sprzedaży w dni, które są zwykle dniami wolnymi np. odpracowywanie dnia Ziemi w sobotę"
                  >
                    <Radio
                      size="md"
                      value="true"
                      name="freeDay"
                      colorScheme="green"
                      className="flex flex-col"
                    />
                  </CustomInputRadio>
                  <CustomInputRadio
                    header="Sprzedaż zamknięta"
                    message="zamknięcie sprzedaży w tygodniu, np. wyjście do kina"
                  >
                    <Radio
                      size="md"
                      value="false"
                      name="freeDay"
                      colorScheme="green"
                      className="flex flex-col"
                    />
                  </CustomInputRadio>
                </Stack>
              </RadioGroup>
            </div>
            <FormInput
              label="Nazwa"
              placeholder="Podaj nazwę"
              bottomElement={
                <p className="text-sm font-normal text-grayLight-500">
                  Ta nazwa będzie wyświetlać się w kalendarzach użytkowników.
                </p>
              }
              inputParams={{
                required: true,
                value: dayOff.name,
                name: 'name',
                onChange: updateState
              }}
            />
            <div className="flex flex-col gap-spacing-xs">
              <label className="text-sm font-medium text-grayLight-700">Klasa</label>
              <Select
                colorScheme="gray"
                isMulti
                placeholder="Wybierz klasy których dotyczą dni"
                value={organizationUnits
                  .filter((unit) => dayOff.organisationIds.includes(unit.id))
                  .map((unit) => ({
                    label: unit.name,
                    value: unit.id
                  }))}
                onChange={(value) => {
                  setDayOff((prev) => ({
                    ...prev,
                    organisationIds: value.map((item: any) => item.value)
                  }));
                }}
                options={organizationUnits.map((unit) => ({
                  label: unit.name,
                  value: unit.id,
                  variant: 'outline'
                }))}
              />
            </div>
            <div className="flex flex-row items-start">
              <div className="flex basis-1/2 gap-spacing-xs">
                <div className="flex flex-col gap-spacing-xs">
                  <label className="text-sm font-medium text-grayLight-700">Data od</label>
                  <Input
                    type="date"
                    lang="pl"
                    required
                    value={dayOff.from}
                    name="from"
                    onChange={updateState}
                  />
                </div>
                <div className="flex flex-col gap-spacing-xs">
                  <label className="text-sm font-medium text-grayLight-700">Data do</label>
                  <Input
                    type="date"
                    lang="pl"
                    required
                    value={dayOff.to}
                    name="to"
                    onChange={updateState}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                onClick: handleClose
              }}
            >
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                type: 'submit'
              }}
            >
              Dodaj
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddDayOff;
