import { AxiosResponse, HttpStatusCode } from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useAlertContext } from "../../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../../context/ApiProvider";
import { ParentWithKidInterface } from "../../../../../../../shared/type/parent.type";
import CustomAlert from "../../../../../../CustomAlert/CustomAlert";
import CustomChakraButton from "../../../../../../CustomChakraButton/CustomChakraButton";
import IconComponent from "../../../../../../IconComponent/IconComponent";
import AddKidToParent from "./AddKidToParent/AddKidToParent";
import SingleKidTab from "./SingleKidTab/SingleKidTab";

const ParentKinds = () => {
  const { id: tenantId, parentId }  = useParams<{ id: string, parentId: string }>();
  const { setShow, setAlertProperties} = useAlertContext();
  const [ parentWithKids, setParentWithKids ] = useState<ParentWithKidInterface>();
  const [ unassignChildId, setUnassignChildId ] = useState<number>(0);
  const [ showDeleteAlert, setShowDeleteAlert ] = useState<boolean>(false)
  const { apiTenantParentController, apiTenantConsumerController } = useApi();
  const location = useLocation();
  const [ showAddKidToParentPopUp, setShowAddKidToParentPopUp ] = useState<boolean>(false);

  const fetchKids = async () => {
    try {
      const response: AxiosResponse<ParentWithKidInterface> = await apiTenantParentController('details').get(`/${tenantId}/${parentId}`);
      console.log(location);
      setParentWithKids(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const baseKidPath = location.pathname.replace(`parents/single-parent/${parentId}/parent-kinds`, '');

  const unassignChild = (kidId: number) => {
    setUnassignChildId(kidId);
    setShowDeleteAlert(true)
  }

  useEffect(() => {
    fetchKids();
  }, [tenantId, parentId])

  return (
    <>
    <div className='flex w-full h-full justify-between'>
      <div className='flex flex-col gap-spacing-xl flex-wrap'>
        {parentWithKids?.consumers.map(kid => <SingleKidTab key={kid.id} kid={kid} unassignChild={unassignChild} url={baseKidPath}/>)}
      </div>
      <div className=''>
        <CustomChakraButton size="md" hierarchy="primary" iconPosition="left" icon="plus" buttonProps={{
          type: 'button',
          onClick: () => {setShowAddKidToParentPopUp(true)}
        }}>
          Dodaj dziecko
        </CustomChakraButton>
      </div>
      {showAddKidToParentPopUp ? (
        <AddKidToParent onClose={() => {
          setShowAddKidToParentPopUp(false);
          fetchKids();
        }} isOpen={showAddKidToParentPopUp}/>
      ) : null}
    </div>
      <CustomAlert
        header="Rozłączanie dziecka i rodzica"
        content="Czy na pewno odłączyć dziecko od rodzica?"
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'warning'
        }}
        confirmButtonText="Usuń"
        onConfirmAction={
          async () => {
            try{
              const response = await apiTenantConsumerController('unassign-consumer-from-parent').post(`/${tenantId}/${unassignChildId}/${parentId}`);
              if(response.status === HttpStatusCode.Ok){
                setAlertProperties({
                  timeout: 2000,
                  title: 'Sukces',
                  description: `Poprawnie rozłączono dziecko od rodzica`,
                  status: 'success',
                });
                setShow(true);
                setShowDeleteAlert(false)
                fetchKids();
              }
            } catch (error: any) {
              console.error(error)
              setAlertProperties({
                timeout: 2000,
                title: 'Błąd',
                description: `Nie udało się rozłączyć dziecka od rodzica`,
                status: 'error',
              });
              setShow(true);
            }}
        }
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        cancelButtonText="Anuluj"
        onCancelAction={() => {
          setShowDeleteAlert(false)
          setUnassignChildId(0);
          fetchKids();
        }}
        handleOpen={showDeleteAlert}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
            <IconComponent iconName="trash" />
          </div>
        }
      />
    </>
  )
};

export default ParentKinds;