import React from 'react';

const BellIcon = ({ className, color }: { className: string, color: string }) => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.7952 17.5003C7.38281 18.019 8.15468 18.3337 9.00006 18.3337C9.84543 18.3337 10.6173 18.019 11.2049 17.5003M14.0001 6.66699C14.0001 5.34091 13.4733 4.06914 12.5356 3.13146C11.5979 2.19378 10.3261 1.66699 9.00006 1.66699C7.67397 1.66699 6.4022 2.19378 5.46452 3.13146C4.52684 4.06914 4.00006 5.34091 4.00006 6.66699C4.00006 9.24215 3.35045 11.0053 2.62478 12.1715C2.01266 13.1552 1.7066 13.6471 1.71783 13.7843C1.73025 13.9362 1.76244 13.9942 1.88487 14.085C1.99544 14.167 2.49388 14.167 3.49077 14.167H14.5093C15.5062 14.167 16.0047 14.167 16.1152 14.085C16.2377 13.9942 16.2699 13.9362 16.2823 13.7843C16.2935 13.6471 15.9875 13.1552 15.3753 12.1715C14.6497 11.0053 14.0001 9.24215 14.0001 6.66699Z"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default BellIcon;
