import { CalendarEventsInterface } from "../../shared/type/calendarEvents.type";

import moment from "moment";
moment.locale('pl');

export const eventPropGetter = (event: any, fixedEvents: CalendarEventsInterface[]): {
  style: {
    backgroundColor: string,
    display?: string,
    color: string,
    fontWeight: string,
    cursor?: string,
    border?: string,
    fontSize?: string,
  }} => {
  const isFixedEvent = fixedEvents.some((fixedEvent) =>
    moment(fixedEvent.start).isSame(moment(event.start), 'day')
  );

  if (isFixedEvent) {
    return {
      style: {
        backgroundColor: 'transparent',
        display: 'flex',
        color: '#000',
        fontWeight: 'bold',
        cursor: 'default',
      }
    };
  }

  return {
    style: {
      backgroundColor: 'transparent',
      color: '#363F72',
      border: 'none',
      fontSize: '12px',
      fontWeight: 'normal',
    }
  };
};
