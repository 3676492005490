import { TableContainer, Thead, Th, Box, Text, Flex } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { AxiosResponse } from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../../../../../../context/ApiProvider";
import { LogEntriesInterface, LogInterface } from "../../../../../../shared/type/logs.type";
import { PaginationInterface } from "../../../../../../shared/type/paginationInterface.type";
import { DataTable } from "../../../../../DataTable/DataTable";
import Pagination from "../../../../../Pagination/Pagination";

const AdministratorLog = () => {
  const columnHelper = createColumnHelper<LogInterface>();
  const { apiTenantLogEntitiesController } = useApi();
  const { id: tenantId, adminId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [logs, setLogs] = useState<LogInterface[]>([]);
  const [actualPage, setActualPage] = useState<PaginationInterface>({
    totalCount: 0,
    hasNextPage: false,
    next: {
      limit: 0,
      offset: 0
    }
  });
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 30,
    maxPageNumber: 0
  });

  const columns = [
    columnHelper.accessor('operationDateTime', {
      cell: (info) => {
        const operationDateTime = new Date(info.getValue());
        return <Box whiteSpace="normal" className='flex flex-col'>
          <p>{`${operationDateTime.toLocaleDateString()}`}</p>
          <p>{`${operationDateTime.toLocaleTimeString()}`}</p>
        </Box>
      },
      header: 'Data operacji'
    }),
    columnHelper.accessor('description', {
      cell: (info) => <Box whiteSpace="normal">{info.getValue()}</Box>,
      header: 'Opis'
    }),
    columnHelper.display({
      id: 'firstNameOfUserPerformingOperation',
      header: 'Wykonano przez',
      cell: (info) => {
        const { firstNameOfUserPerformingOperation, lastNameOfUserPerformingOperation } = info.row.original;
        return (
          <Box whiteSpace="normal">{firstNameOfUserPerformingOperation} {lastNameOfUserPerformingOperation}</Box>
        )
      }
    }),
    columnHelper.accessor('consumer', {
      cell: (info) => <Box whiteSpace="normal">{info.getValue()}</Box>,
      header: 'Powiązany konsument'
    })
  ];

  const fetchAdminLogs = async (pageNumber = 1) => {
    setIsLoading(true);
    try {
      const result: AxiosResponse<LogEntriesInterface> = await apiTenantLogEntitiesController('').get(`/${tenantId}/user/${adminId}`, {
        params: {
          pageNumber,
          pageSize: page.pageSize
        }
      });
      const { totalCount, hasNextPage, next } = result.data;
      setActualPage({ totalCount, hasNextPage, next });
      setLogs(result.data.data);
      setPage((prev) => ({
        ...prev,
        maxPageNumber: Math.ceil(totalCount / prev.pageSize)
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (pageNum: number) => {
    setPage((prev) => ({
      ...prev,
      pageNumber: pageNum,
    }));
  };

  useEffect(() => {
    fetchAdminLogs();
  }, []);

  return (
    <section className='Administrators p-spacing-4xl w-full'>
      <Flex direction="column" h="800px" className="Branches__table bg-white rounded-lg">
        <TableContainer h="100%" flex="1" minH="500px" overflowY="auto">
        <DataTable
          disableFilters={true}
          extraThead={
            <Thead>
              <Th colSpan={columns.length}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5">
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-semibold text-base text-grayLight-900"
                      >
                        Historia konta
                      </Text>
                    </Box>
                  </div>
                  <div className='flex flex-row items-end gap-spacing-md'>
                  </div>
                </div>
              </Th>
            </Thead>
          }
          columns={columns}
          isLoading={isLoading}
          data={logs}
        />
      </TableContainer>
        <Box position="relative" paddingTop="10px" paddingBottom="20px">
          <Pagination
            pageNumber={page.pageNumber}
            maxPageNumber={page.maxPageNumber}
            hasNextPage={actualPage.hasNextPage}
            onPageChange={handlePageChange}
          />
        </Box>
      </Flex>
    </section>
  );
};

export default AdministratorLog;
