import React from 'react';
import { CustomButtonType } from '../../shared/type/customButton.type';
import IconComponent from '../IconComponent/IconComponent';

import './CustomChakraButton.css';

interface CustomButtonTypeProps extends CustomButtonType {
  children: string | JSX.Element;
}

const CustomChakraButton = ({
                              children,
                              size,
                              hierarchy,
                              iconPosition,
                              icon,
                              buttonProps,
                              textProps,
                              iconColor
                            }: CustomButtonTypeProps) => {
  const buttonClasses = [
    `CustomChakraButton`,
    `flex`,
    `flex-row`,
    `items-center`,
    'justify-center',
    `gap-spacing-sm`,
    `box-border`,
    `border`,
    `CustomChakraButton--${size}`,
    `CustomChakraButton__text--${size}`,
    `CustomChakraButton--${hierarchy}`
  ];

  if (buttonProps && buttonProps.className) {
    buttonClasses.push(buttonProps.className);
  }

  const { className, ...restButtonProps } = buttonProps || {};

  const textClasses = [
    'CustomChakraButton__text',
    textProps?.className || ''
  ];

  return (
    <button className={buttonClasses.join(' ')} {...restButtonProps}>
      {['left-right', 'left', 'onlyIcon'].includes(iconPosition) && icon ? (
        <IconComponent iconName={icon} color={iconColor}/>
      ) : null}
      {['left-right', 'left', 'right', 'onlyText'].includes(iconPosition) ? (
        <p className={textClasses.join(' ')} {...textProps}>{children}</p>
      ) : null}
      {['left-right', 'right'].includes(iconPosition) && icon ? (
        <IconComponent iconName={icon} color={iconColor}/>
      ) : null}
    </button>
  );
};

export default CustomChakraButton;
