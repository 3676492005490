import { AxiosResponse } from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useApi } from "../../../../context/ApiProvider";
import {
  AnnouncementInterfaceWIthPagination,
  AnnouncementInterface
} from "../../../../shared/type/announcement.interface";
import { PaginationInterface } from "../../../../shared/type/paginationInterface.type";
import IconComponent from "../../../IconComponent/IconComponent";

interface Props {
  tenantId: string;
}

const ParentNotification = ({ tenantId }: Props) => {
  const { apiParentMessageController } = useApi();
  const [announcements, setAnnouncements] = useState<AnnouncementInterface[]>([]);
  const [actualPage, setActualPage] = useState<PaginationInterface>({
    totalCount: 0,
    hasNextPage: false,
    next: {
      limit: 0,
      offset: 0
    }
  });

  const [currentPage, setCurrentPage] = useState(1);

  const fetchAnnouncements = async (pageNumber: number) => {
    try {
      const response: AxiosResponse<AnnouncementInterfaceWIthPagination> = await apiParentMessageController('announcement-list').get('', {
        params: {
          tenantId,
          pageNumber,
          pageSize: 20
        }
      });

      const { data, next, hasNextPage, totalCount } = response.data;

      setActualPage({ next, hasNextPage, totalCount });
      setAnnouncements(data);

    } catch (error) {
      // Obsługa błędów
      console.error("Error fetching announcements:", error);
    }
  }

  useEffect(() => {
    fetchAnnouncements(currentPage);
  }, [tenantId, currentPage]);

  // Funkcje do zmiany strony
  const goToNextPage = () => {
    if (actualPage.hasNextPage) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now.getTime() - date.getTime();

    if (timeDiff < 24 * 60 * 60 * 1000) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
      return date.toLocaleDateString('pl-PL');
    }
  };

  return (
    <div className="ParentMessage flex flex-col">
      <div className='pt-spacing-md pr-spacing-4xl pb-spacing-md pl-spacing-4xl'>
        <div className='flex justify-end'>
          <div className='flex gap-spacing-lg'>
            <p className='text-sm font-medium text-grayLight-700'>
              Strona {currentPage} z {Math.ceil(actualPage.totalCount / 20)}
            </p>
            <div className='flex gap-spacing-lg'>
              <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                <IconComponent iconName='chevronLeft' className='h-5 w-5' />
              </button>
              <button onClick={goToNextPage} disabled={!actualPage.hasNextPage}>
                <IconComponent iconName='chevronRight' className='h-5 w-5' />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col'>
        {announcements
          .sort((a, b) => new Date(b.sent).getTime() - new Date(a.sent).getTime())
          .map((announcement, messageIndex) => (
            <button
              key={`Message-${messageIndex}`}
              className={`flex pt-spacing-lg pr-spacing-4xl pb-spacing-lg pl-spacing-4xl hover:bg-brand-100 ${!announcement.read && 'bg-grayLight-100'}`}>
              <Link to={`notification/${announcement.id}`} className='basis-3/12 flex items-center gap-spacing-lg'>
                <p className={`text-sm font-${announcement.read ? 'normal' : 'semibold'} text-grayLight-700`}>
                  {announcement.topic}
                </p>
              </Link>
              <div className="basis-6/12 flex justify-start">
                <p className='text-sm font-normal text-grayLight-700'>
                  {announcement.content}
                </p>
              </div>
              <div className="basis-3/12 flex items-center justify-center group">
                <p className="group-hover:hidden">{formatDate(announcement.sent)}</p>
              </div>
            </button>
          ))}

      </div>
    </div>
  )
};

export default ParentNotification;
