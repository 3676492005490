import React from 'react';
import { IconName } from '../../shared';
import IconComponent from '../IconComponent/IconComponent';

import './CustomBadge.css';

interface Props {
  children: React.ReactNode;
  size: 'sm' | 'md' | 'lg';
  color:
    | 'gray'
    | 'brand'
    | 'error'
    | 'warning'
    | 'success'
    | 'grayBlue'
    | 'blueLight'
    | 'blue'
    | 'indigo'
    | 'purple'
    | 'pink'
    | 'orange';
  icon?: IconName;
}

const CustomBadge = ({ children, size, color, icon }: Props) => {
  return (
    <div className={`CustomBadge inline-block CustomBadge--${color} CustomBadge--${size}`}>
      <div className={`flex CustomBadge__gap--${size} CustomBadge__text--${size} items-center`}>
        {icon ? <IconComponent iconName={icon} className="fill-blueDark" /> : null}
        <p className={`p-0 m-0 font-medium CustomBadge__text--${color}`}>{children}</p>
      </div>
    </div>
  );
};

export default CustomBadge;
