import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure
} from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';
import { CustomButtonType } from '../../shared/type/customButton.type';
import CustomChakraButton from '../CustomChakraButton/CustomChakraButton';

interface Props {
  handleOpen: boolean;
  header: string | JSX.Element;
  content: string | JSX.Element;
  icon?: JSX.Element;
  cancelButton?: CustomButtonType;
  cancelButtonText: string;
  confirmButton: CustomButtonType;
  confirmButtonText: string;
  onConfirmAction: () => void;
  onCancelAction: () => void;
}

const CustomAlert = ({
  header,
  content,
  confirmButton,
  cancelButton,
  cancelButtonText,
  confirmButtonText,
  onConfirmAction,
  onCancelAction,
  handleOpen,
  icon
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const handleConfirm = () => {
    onConfirmAction();
    onClose();
  };

  const handleCancel = () => {
    onCancelAction();
    onClose();
  };

  useEffect(() => {
    if (handleOpen) {
      onOpen();
    }
  }, [handleOpen, onOpen]);

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay
        sx={{
          backdropFilter: 'blur(10px)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }}
      >
        <AlertDialogContent maxW="600px" className="p-spacing-xl">
          <div className="flex gap-spacing-xl">
            <div className="flex justify-center items-start pt-4">{icon}</div>
            <div>
              <AlertDialogHeader className="font-semibold text-lg pb-0">{header}</AlertDialogHeader>
              <AlertDialogBody className="font-normal text-sm">{content}</AlertDialogBody>
            </div>
          </div>

          <AlertDialogFooter className="gap-spacing-md">
            {cancelButton && (
              <CustomChakraButton
                size={cancelButton.size}
                buttonProps={{ ...cancelButton.buttonProps, ref: cancelRef, onClick: handleCancel }}
                icon={cancelButton.icon}
                iconPosition={cancelButton.iconPosition}
                hierarchy={cancelButton.hierarchy}
              >
                {cancelButtonText}
              </CustomChakraButton>
            )}
            <CustomChakraButton
              size={confirmButton.size}
              buttonProps={{ ...confirmButton.buttonProps, onClick: handleConfirm }}
              icon={confirmButton.icon}
              iconPosition={confirmButton.iconPosition}
              hierarchy={confirmButton.hierarchy}
            >
              {confirmButtonText}
            </CustomChakraButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default CustomAlert;
