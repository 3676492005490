import React from 'react';

const HomeIcon = ({ className }: { className: string }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.66667 13.1668H12.3333M8.18141 1.30345L2.52949 5.69939C2.15168 5.99324 1.96278 6.14017 1.82669 6.32417C1.70614 6.48716 1.61633 6.67078 1.56169 6.866C1.5 7.08639 1.5 7.3257 1.5 7.80433V13.8334C1.5 14.7669 1.5 15.2336 1.68166 15.5901C1.84144 15.9037 2.09641 16.1587 2.41002 16.3185C2.76654 16.5001 3.23325 16.5001 4.16667 16.5001H13.8333C14.7668 16.5001 15.2335 16.5001 15.59 16.3185C15.9036 16.1587 16.1586 15.9037 16.3183 15.5901C16.5 15.2336 16.5 14.7669 16.5 13.8334V7.80433C16.5 7.3257 16.5 7.08639 16.4383 6.866C16.3837 6.67078 16.2939 6.48716 16.1733 6.32417C16.0372 6.14017 15.8483 5.99324 15.4705 5.69939L9.81859 1.30345C9.52582 1.07574 9.37943 0.961888 9.21779 0.918123C9.07516 0.879506 8.92484 0.879506 8.78221 0.918123C8.62057 0.961888 8.47418 1.07574 8.18141 1.30345Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HomeIcon;
