import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import { useApi } from '../../../context/ApiProvider';
import useAuth from '../../../hooks/useAuth';
import { PasswordConditionInterface } from "../../../shared/type/passwordCondition.type";
import FormInput from '../../Forms/FormInput/FormInput';
import IconComponent from '../../IconComponent/IconComponent';
import AutoCheck from '../../Forms/AutoCheck/AutoCheck';

const SetNewPassword = () => {
  const { updateAuth, auth } = useAuth();
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState<string>('');
  const [repeatNewPassword, setRepeatNewPassword] = useState<string>('');
  const location = useLocation();
  const { apiPublicSecurityController } = useApi();
  const params = new URLSearchParams(location.search);
  const email = params.get('email');
  const token = params.get('passwordResetHash');

  const [passwordConditions, setPasswordConditions] = useState<PasswordConditionInterface>({
    length: false,
    specialChar: false,
    digit: false
  });

  const submitChangePassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await apiPublicSecurityController('change-password').post('', {
        token,
        email,
        newPassword
      });

      const {
        id,
        login,
        firstName,
        lastName,
        availableTenantsIds,
        availableModules,
        roleName,
        admin,
        childOwner,
        tenantAdmin,
        superAdmin
      } = response.data.loggedUserDto;
      const { availableTenants } = response.data;
      const { enforceEmailUpdate } = response.data;
      const { jwToken, expiresIn } = response.data.jwToken;

      updateAuth({
        loggedUserDto: {
          id,
          login,
          firstName,
          lastName,
          availableTenantsIds,
          availableModules,
          roleName,
          admin,
          childOwner,
          tenantAdmin,
          superAdmin
        },
        availableTenants,
        enforceEmailUpdate,
        jwToken: {
          jwToken,
          expiresIn
        }
      });

      window.localStorage.setItem('isLogged', 'true');
      window.localStorage.setItem('persist', 'true');
      window.localStorage.setItem('jwsToken', jwToken);

      navigateToDashboard();
    } catch (error: any) {
      switch (error.response?.status) {
        case StatusCodes.BAD_REQUEST:
        case StatusCodes.UNAUTHORIZED:
        case StatusCodes.NOT_FOUND:
        case StatusCodes.CONFLICT:
          console.warn('An expected error occurred');
          break;
        default:
          console.warn('An unexpected error occurred');
          break;
      }
    }
  };

  const navigateToDashboard = () => {
    if (auth.loggedUserDto.superAdmin) {
      navigate('/superAdmin/branches');
    } else if (auth.loggedUserDto.admin || auth.loggedUserDto.tenantAdmin) {
      navigate('/tenantAdmin');
    } else if (auth.loggedUserDto.childOwner) {
      navigate('/childOwner');
    } else {
      navigate('/login');
    }
  };

  const checkPasswordConditions = (value: string) => {
    setPasswordConditions({
      length: value.length >= 8,
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      digit: /\d/.test(value)
    });
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNewPassword(value);
    checkPasswordConditions(value);
  };

  return (
    <div className="LoginPage__login-form w-full flex flex-col justify-between items-start">
      <div className="grid gap-8 m-auto w-full lg:pl-36 lg:pr-36 pl-container-padding-mobile pr-container-padding-mobile">
        <div className="LoginPage__header grid gap-spacing-3xl">
          <IconComponent iconName="lock" className="m-auto" />
          <div className="grid gap-3">
            <h2 className="font-semibold text-4xl text-grayLight-900 text-center">
              Ustaw nowe hasło
            </h2>
            <p className="font-normal text-base text-grayLight-600 text-center">
              Pamiętaj, nowe hasło musi się różnić od poprzedniego
            </p>
          </div>
        </div>
        <form className="max-w-2xl grid gap-spacing-3xl w-full" onSubmit={submitChangePassword}>
          <FormInput
            label="Hasło"
            inputParams={{
              placeholder: 'Wpisz hasło',
              type: 'password',
              required: true,
              onChange: handlePasswordChange,
              value: newPassword
            }}
          />
          <FormInput
            label="Powtórz hasło"
            inputParams={{
              placeholder: 'Powtórz hasło',
              type: 'password',
              required: true,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setRepeatNewPassword(e.target.value);
              },
              value: repeatNewPassword
            }}
          />
          <div className="grid gap-spacing-lg">
            <AutoCheck
              state={passwordConditions.length}
              labelText="Hasło musi zawierać minimum 8 znaków"
            />
            <AutoCheck
              state={passwordConditions.specialChar || false}
              labelText="Minimum jeden znak specjalny"
            />
            <AutoCheck state={passwordConditions.digit || false} labelText="Minimum jedna cyfra" />
          </div>
          <button
            className="rounded bg-brand-300 border-brand-300 py-2.5 px-4 font-semibold text-base"
            type="submit"
          >
            Ustaw hasło
          </button>
        </form>
        <div className="flex flex-row justify-start">
          <Link to="/" className="flex m-auto">
            <IconComponent iconName="arrowLeft" />
            <p className="text-sm inline">Wróć do strony logowania</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SetNewPassword;
