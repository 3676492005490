const HidePasswordIcon = ({ className, color }: { className: string, color: string }) => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M8.05721 2.81924C8.36202 2.77417 8.67645 2.75 9.00031 2.75C12.8291 2.75 15.3415 6.12863 16.1855 7.46512C16.2877 7.62688 16.3388 7.70776 16.3673 7.8325C16.3888 7.92619 16.3888 8.074 16.3673 8.16768C16.3387 8.29243 16.2873 8.37384 16.1844 8.53668C15.9595 8.89261 15.6166 9.39283 15.1624 9.93534M5.04324 4.03628C3.42169 5.13628 2.32084 6.66453 1.81583 7.46396C1.71321 7.6264 1.66191 7.70762 1.6333 7.83236C1.61182 7.92605 1.61181 8.07383 1.63328 8.16752C1.66187 8.29226 1.71295 8.37313 1.8151 8.53488C2.65916 9.87137 5.17156 13.25 9.00031 13.25C10.5441 13.25 11.8739 12.7007 12.9666 11.9575M2.25031 1.25L15.7503 14.75M7.40932 6.40901C7.00215 6.81618 6.75031 7.37868 6.75031 8C6.75031 9.24264 7.75767 10.25 9.00031 10.25C9.62163 10.25 10.1841 9.99816 10.5913 9.59099"
      stroke={color} stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default HidePasswordIcon;
