import React from 'react';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import ConfirmRegistrationEmail
  from "./component/AutenticationComponents/ConfirmRegistrationEmail/ConfirmRegistrationEmail";
import Registration from "./component/AutenticationComponents/Registration/Registration";
import { AlertProvider } from "./context/AlertProvider";
import { ApiProvider } from './context/ApiProvider';
import { LocalStorageProvider } from "./context/LocalStorageProvider";
import { ParentDashboardContextProvider } from "./context/ParentDashboardContext";
import { TenantIdProvider } from "./context/TenantIdProvider";
import AuthenticationScreen from './page/AuthenticationScreen/AuthenticationScreen';
import PersistLogin from './component/PersistLogin/PersistLogin';
import RequireAuth from './component/RequireAuth/RequireAuth';
import ChildOwnerDashboard from './page/ChildOwnerDashboard/ChildOwnerDashboard';
import TenantAdminDashboard from './page/TenantAdminDashboard/TenantAdminDashboard';
import SuperAdminDashboard from './page/SuperAdminDashboard/SuperAdminDashboard';
import AdminDashboard from './page/AdminDashboard/AdminDashboard';
import { AuthProvider } from './context/AuthProvider';
import { SideNavProvider } from './context/SideNavProvider';

function App() {

  return (
    <div
      className={`transition-all duration-2000 w-screen h-screen overflow-hidden`}
    >
      <LocalStorageProvider>
        <AlertProvider>
      <AuthProvider>
        <ApiProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/*" element={<AuthenticationScreen />} />
            <Route path="/login/*" element={<AuthenticationScreen />} />
            {/* Protected routes */}
            <Route element={<PersistLogin />}>
              <Route element={<RequireAuth allowedRoles={['admin']} />}>
                <Route path="/admin/*" element={<AdminDashboard />} />
              </Route>
              <Route element={<RequireAuth allowedRoles={['childOwner']} />}>
                <Route path="/parent/*" element={
                  <ParentDashboardContextProvider>
                    <SideNavProvider>
                      <ChildOwnerDashboard />
                    </SideNavProvider>
                  </ParentDashboardContextProvider>
                } />
              </Route>
              <Route element={<RequireAuth allowedRoles={['tenantAdmin']} />}>
                <Route
                  path="/tenantAdmin/*"
                  element={
                    <SideNavProvider>
                      <TenantIdProvider>
                        <TenantAdminDashboard />
                      </TenantIdProvider>
                    </SideNavProvider>
                  }
                />
              </Route>
              <Route element={<RequireAuth allowedRoles={['superAdmin']} />}>
                <Route
                  path="/superAdmin/*"
                  element={
                    <TenantIdProvider>
                      <SuperAdminDashboard />
                    </TenantIdProvider>
                  }
                />
              </Route>
            </Route>
          </Routes>
        </ApiProvider>
      </AuthProvider>
        </AlertProvider>
      </LocalStorageProvider>
    </div>
  );
}

export default App;
