import React from 'react';
import { Link } from "react-router-dom";
import { useSideNavContext } from '../../context/SideNavProvider';
import { SideMenuOptionInterface } from '../../shared';
import SideMenuButton from './SideMenuButton';
import IconComponent from '../IconComponent/IconComponent';
import './SideMenu.css'; // Dodajemy plik CSS

interface Props {
  menuOptions: SideMenuOptionInterface[];
}

const SideMenu = ({ menuOptions }: Props) => {
  const { toggleShowNav, toggleMinimalNav, toggleMode, isMinimal } = useSideNavContext();

  const handleToggleMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (toggleMode === 'hide') {
      toggleShowNav(false);
    }
    if (toggleMode === 'minimal') {
      toggleMinimalNav(!isMinimal);
    }
  };

  return (
    <nav className={`SideMenu w-full flex flex-col h-full justify-between ${isMinimal ? 'SideMenu--collapsed' : 'SideMenu--expanded'}`}>
      <div className="w-full">
        <div className="SideMenu__logoContainer pl-spacing-2xl pt-spacing-3xl pb-spacing-2xl">
          <IconComponent iconName="logo" className={isMinimal ? 'w-20' : 'w-56'} />
        </div>
        <div className="SideMenu__menuContainer grid gap-spacing-md pl-spacing-xl pr-spacing-xl">
          {menuOptions.map((menuOption) => (
            <SideMenuButton key={menuOption.header + menuOption.icon} menuOption={menuOption} isMinimal={isMinimal} />
          ))}
        </div>
      </div>
      <div className="SideMenu__bottomButtonsContainer gap-spacing-md pl-spacing-xl pr-spacing-xl">
        <div className="SideMenuButton grid w-full gap-spacing-md">
          <div className="flex rounded-lg w-full">
            <div className="grid grid-cols-[auto,1fr] items-center p-4 rounded-lg cursor-pointer hover:bg-brand-100 w-full pr-spacing-lg pl-spacing-lg pt-spacing-md pb-spacing-md">
              <div className="w-6 h-6 flex items-center justify-center">
                <IconComponent iconName="settings" />
              </div>
              {!isMinimal && (
                <Link to='settings'>
                  <div className="ml-2 SideMenuButton--expanded">
                    Ustawienia
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="SideMenuButton grid w-full gap-spacing-md">
          <div className="flex rounded-lg w-full">
            <button
              className="grid grid-cols-[auto,1fr] p-4 rounded-lg cursor-pointer hover:bg-brand-100 w-full pr-spacing-lg pl-spacing-lg pt-spacing-md pb-spacing-md mt-6 mb-6"
              onClick={handleToggleMenu}
            >
              <div className="w-6 h-6 flex items-center justify-center">
                <IconComponent iconName="settings" />
              </div>
              {!isMinimal && (
                <p className="ml-2 SideMenuButton--expanded">
                  Schowaj menu
                </p>
              )}
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default SideMenu;