import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useApi } from '../../context/ApiProvider';
import FormInput from '../Forms/FormInput/FormInput';
import CheckboxInput from '../Forms/CheckboxInput/CheckboxInput';
import { AuthInterface } from '../../shared';
import useAuth from '../../hooks/useAuth';

const Login = () => {
  const authContext = useAuth();
  const navigate = useNavigate();
  const { apiPublicSecurityController } = useApi();

  const [formEmail, setFormEmail] = useState<string>('');
  const [formPassword, setFormPassword] = useState<string>('');

  const [invalidDataResponse, setInvalidDataResponse] = React.useState<{
    status: boolean;
    message: string | null;
  }>({
    status: false,
    message: ''
  });

  const loginUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const result = await apiPublicSecurityController('login').post<AuthInterface>('', {
        login: formEmail,
        password: formPassword
      });
      // console.log(result.data)
      const {
        id,
        login,
        firstName,
        lastName,
        availableTenantsIds,
        availableModules,
        roleName,
        admin,
        childOwner,
        tenantAdmin,
        superAdmin
      } = result.data.loggedUserDto;
      const { availableTenants } = result.data;
      const { enforceEmailUpdate } = result.data;
      const { jwToken, expiresIn } = result.data.jwToken;

      authContext.updateAuth({
        loggedUserDto: {
          id,
          login,
          firstName,
          lastName,
          availableTenantsIds,
          availableModules,
          roleName,
          admin,
          childOwner,
          tenantAdmin,
          superAdmin
        },
        availableTenants,
        enforceEmailUpdate,
        jwToken: {
          jwToken,
          expiresIn
        }
      });

      window.localStorage.setItem('isLogged', 'true');
      window.localStorage.setItem('persist', 'true');
      window.localStorage.setItem('jwsToken', jwToken);
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        setInvalidDataResponse({
          status: true,
          message: error.response?.data.errorMessage
        });
        return;
      }
      setInvalidDataResponse({
        status: true,
        message: 'Błąd połączenia z serwerem'
      });
    }
  };

  useEffect(() => {
    if (authContext.auth.loggedUserDto.superAdmin) {
      navigate('/superAdmin/branches');
    } else if (authContext.auth.loggedUserDto.admin) {
      navigate('/tenantAdmin/id/undefined/school/classes');
    } else if (authContext.auth.loggedUserDto.tenantAdmin) {
      navigate('/tenantAdmin/id/undefined/school/classes');
    } else if (authContext.auth.loggedUserDto.childOwner) {
      navigate('/parent/main');
    } else {
      navigate('/login');
    }
  }, [authContext.auth, navigate]);

  return (
    <div className="LoginPage__login-form w-full flex flex-col justify-between items-start">
      <div className="grid gap-8 m-auto w-full pl-container-padding-mobile pr-container-padding-mobile">
        <div className="LoginPage__header grid gap-spacing-lg">
          <h2 className="font-semibold text-4xl text-grayLight-900">Zaloguj się</h2>
          <p className="font-normal text-base text-grayLight-600">
            Zaloguj się danymi otrzymanymi z oddziału.
          </p>
        </div>
        <form className="max-w-2xl grid gap-spacing-3xl w-full" onSubmit={loginUser}>
          <div className='w-full'>
            <FormInput
              label="Email*"
              inputParams={{
                value: formEmail,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setFormEmail(e.target.value);
                },
                placeholder: 'Wpisz adres email lub otrzymany login',
                type: 'text',
                required: true
              }}
            />
          </div>
          <div className="w-full">
            <FormInput
              label="Hasło*"
              inputParams={{
                value: formPassword,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  setFormPassword(e.target.value);
                },
                placeholder: "Wpisz hasło",
                minLength: 8,
                type: "password",
                required: true
              }}
              state={invalidDataResponse.status ? "error" : "normal"}
              bottomElement={
                invalidDataResponse.status && invalidDataResponse.message ? (
                  <p className="whitespace-normal text-sm text-error-600 dark:text-grayDark-200">
                    {invalidDataResponse.message}
                  </p>
                ) : (
                  <p className="whitespace-normal text-sm text-grayLight-600 dark:text-grayDark-200">
                    Hasło musi zawierać minimum 8 znaków, jeden znak specjalny i jedną <br /> cyfrę
                  </p>
                )
              }
            />
          </div>
          <div className="flex flex-row justify-end">
            <Link to="/login/recover">
              <p className="text-sm font-semibold cursor-pointer">Przypomnij hasło</p>
            </Link>
          </div>
          <div>
            <CheckboxInput text="Zapamiętaj mnie" />
          </div>
          <button
            type="submit"
            className="rounded bg-brand-300 border-brand-300 py-2.5 px-4 font-semibold text-base"
          >
            Zaloguj się
          </button>
        </form>
        <div className="flex flex-row justify-start">
          <p className="text-sm">
            Nie masz konta? <b className="font-semibold cursor-pointer">Zarejestruj się</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
