import React from "react";
import getMonthFromNumber from "../../utils/getMonth";
import CustomChakraButton from "../CustomChakraButton/CustomChakraButton";
import IconComponent from "../IconComponent/IconComponent";

type MonthDateType = { month: number; year: number; day: number };

interface Props {
  setDate: React.Dispatch<React.SetStateAction<MonthDateType>>;
  currentDate: MonthDateType;
  mode: "day" | "month";
  hideTodayButton?: boolean
}

const ChooseMonths = ({ setDate, currentDate, mode, hideTodayButton }: Props) => {

  const handleChangeDate = (value: number) => {
    setDate((prev) => {
      if (mode === "day") {
        const newDate = new Date(prev.year, prev.month - 1, prev.day + value);
        return {
          year: newDate.getFullYear(),
          month: newDate.getMonth() + 1,
          day: newDate.getDate(),
        };
      } else {
        const newMonth = prev.month + value;
        const newYear =
          newMonth < 1 ? prev.year - 1 : newMonth > 12 ? prev.year + 1 : prev.year;
        const adjustedMonth = ((newMonth - 1 + 12) % 12) + 1;
        return { year: newYear, month: adjustedMonth, day: 1 };
      }
    });
  };

  return (
    <div className="flex gap-spacing-2xl">
      {!hideTodayButton && (
        <CustomChakraButton
          size="sm"
          hierarchy="linkGray"
          iconPosition="onlyText"
          icon="plus"
          buttonProps={{
            onClick: () => {
              const today = new Date();
              setDate({
                year: today.getFullYear(),
                month: today.getMonth() + 1,
                day: today.getDate(),
              });
            },
          }}
        >
          Dzisiaj
        </CustomChakraButton>
      ) }

      <div className="flex">
        <div
          className="border cursor-pointer border-grayLigh-300 rounded-tl-lg rounded-bl-lg pt-spacing-md pr-spacing-lg pb-spacing-md pl-spacing-lg flex items-center justify-center"
          onClick={() => {
            handleChangeDate(-1);
          }}
        >
          <IconComponent iconName="chevronLeft" className="w-5 h-5" color="#344054" />
        </div>
        <div
          className="border border-grayLigh-300 w-40 bg-grayNeutral-50 pt-spacing-md pr-spacing-lg pb-spacing-md pl-spacing-lg flex items-center justify-center gap-spacing-md">
          <IconComponent iconName="calendar" className='h-5 w-5'/>
          <p className="text-sm font-semibold text-grayLight-700">
            {mode === "day" && currentDate.day} {getMonthFromNumber(currentDate.month)}{" "}
          </p>
          <p className="text-sm font-semibold text-grayLight-700">
            {currentDate.year}
          </p>
        </div>
        <div
          className="border cursor-pointer border-grayLigh-300 rounded-tr-lg rounded-br-lg pt-spacing-md pr-spacing-lg pb-spacing-md pl-spacing-lg flex items-center justify-center"
          onClick={() => {
            handleChangeDate(1);
          }}
        >
          <IconComponent iconName="chevronRight" className="w-5 h-5" color="#344054" />
        </div>
      </div>
    </div>
  );
};

export default ChooseMonths;
