import { ConsumerDtoInterface, SummaryItemInterface, OrderDtoInterface } from "./order.type";
import { PaginationInterface } from "./paginationInterface.type";

export enum PaymentType {
  SALDO = 'SALDO',
  CASH = 'CASH',
  PAYMENT_STARTED = 'PAYMENT_STARTED',
  TPAY = 'TPAY',
}

export interface OrderItemInterface {
  orderDate: string,
  consumerDto: ConsumerDtoInterface,
  summaryItems: SummaryItemInterface[],
  paymentType: PaymentType,
  saldoBefore: string,
  paymentValue: string,
  payment: number,
  orderValue: number,
  orderValueString: string,
  saldoAfter: string,
  doneBy: {
    id: number,
    firstName: string,
    lastName: string
  },
  orderDto: OrderDtoInterface,
  paymentId: number
}

export interface ParentOrderHistoryInterface {
  orderDate: string,
  orderItems: OrderItemInterface[],
  totalPayment: {
    payment: string,
    orderValue: string
  } | null,
  paymentId: number,
}

export interface ParentOrderHistoryInterfaceWithPagination extends PaginationInterface {
  data: ParentOrderHistoryInterface[]
}