import React from 'react';

const CutleryIcon = ({ className }: { className: string }) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.4532 9.98593C6.25821 10.1816 5.94147 10.1821 5.7459 9.98698L1.91007 6.16031C1.16092 5.409 0.740234 4.3913 0.740234 3.33031C0.740234 2.43694 1.0385 1.57426 1.57997 0.875458C1.74911 0.657175 2.06859 0.658381 2.26413 0.853366L8.57613 7.14738C8.77163 7.34232 8.77213 7.65881 8.57726 7.85437L6.4532 9.98593ZM11.7662 9.64676C11.5697 9.84181 11.5692 10.1594 11.7649 10.3552L17.9365 16.5268C18.1318 16.722 18.1318 17.0386 17.9365 17.2339L17.2336 17.9368C17.0384 18.132 16.7218 18.132 16.5265 17.9368L10.3536 11.7639C10.1584 11.5686 9.84178 11.5686 9.64652 11.7639L3.47362 17.9368C3.27836 18.132 2.96178 18.132 2.76652 17.9368L2.06344 17.2337C1.86825 17.0385 1.86817 16.722 2.06326 16.5268L11.1499 7.43066C11.2676 7.31289 11.2721 7.12349 11.1601 7.00031C10.9747 6.81627 10.8276 6.59738 10.7273 6.35625C10.6269 6.11512 10.5752 5.85651 10.5752 5.59531C10.5752 5.33412 10.6269 5.07551 10.7273 4.83438C10.8276 4.59324 10.9747 4.37435 11.1601 4.19031L15.1484 0.174387C15.3427 -0.0212753 15.6588 -0.0226739 15.8548 0.171261L16.0738 0.387856C16.2704 0.582379 16.2718 0.899594 16.0768 1.09578L13.5452 3.64302C13.3495 3.83992 13.3517 4.15853 13.55 4.35274L13.796 4.59363C13.9921 4.7856 14.3062 4.7837 14.4999 4.58938L17.0365 2.04496C17.2317 1.84918 17.5487 1.84894 17.7442 2.04441L17.956 2.25621C18.1514 2.45169 18.1512 2.76869 17.9554 2.96387L15.411 5.50046C15.2167 5.69419 15.2148 6.00832 15.4068 6.20437L15.6471 6.44983C15.8415 6.64839 16.1606 6.65032 16.3574 6.45412L18.9027 3.91659C19.0995 3.72036 19.4186 3.72232 19.613 3.92096L19.8329 4.14558C20.025 4.34191 20.0228 4.6565 19.8279 4.85008L15.8101 8.84031C15.0301 9.62031 13.7701 9.62031 13.0001 8.84031C12.8768 8.72824 12.6873 8.7324 12.5691 8.84978L11.7662 9.64676Z"
      fill="#667085"
    />
  </svg>
);

export default CutleryIcon;
