import { TableContainer, Thead, Th, Box, Text, Tr, Flex } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { AxiosResponse } from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { useApi } from "../../../../../../../context/ApiProvider";
import {
  OrderHistoryInterface,
  OrderHistoryInterfaceWithPagination
} from "../../../../../../../shared/type/order.type";
import { PaginationInterface } from "../../../../../../../shared/type/paginationInterface.type";
import CustomChakraButton from "../../../../../../CustomChakraButton/CustomChakraButton";
import { DataTable } from "../../../../../../DataTable/DataTable";
import Pagination from "../../../../../../Pagination/Pagination";
import AddCashToSaldo from "./AddCashToSaldo/AddCashToSaldo";
import SetOrderOnCash from "./SetOrderOnCash/SetOrderOnCash";
import SetOrderOnCredit from "./SetOrderOnCredit/SetOrderOnCredit";
import ShowListOfOrderDetails from "./ShowListOfOrderDetails/ShowListOfOrderDetails";

type OrderType =  'addToSaldo' | 'orderOnCash' | null;

const ListOfOrder = () => {
  const { id: tenantId, parentId }  = useParams<{ id: string, parentId: string }>();
  const { apiOrderHistoryController } = useApi();
  const columnHelper = createColumnHelper<OrderHistoryInterface>();
  const [ listOfOrder, setListOfOrder ] = useState<OrderHistoryInterface[]>([]);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ cashDepositType, setCashDepositType ] = useState<OrderType>(null)

  const [popUp, setPopUp] = useState<{
    showOrderOnCredit: boolean;
    showOrderOnCash: boolean;
    showAddToSaldo: boolean;
  }>({
    showOrderOnCredit: false,
    showOrderOnCash: false,
    showAddToSaldo: false,
  })

  const [ showDetails, setShowDetails ] = useState<{
    isOpen: boolean,
    id: number
  }>({
    isOpen: false,
    id: 0
  });

  const [actualPage, setActualPage] = useState<PaginationInterface>({
    totalCount: 0,
    hasNextPage: false,
    next: {
      limit: 0,
      offset: 0
    }
  });
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 30,
    maxPageNumber: 0
  });

  const fetchListOfOrder = async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<OrderHistoryInterfaceWithPagination> = await apiOrderHistoryController('').post('', {
        "tenantId": tenantId,
        "parentId": parentId,
        "pageNumber": page.pageNumber,
        "pageSize": page.pageSize
      })
      const { data, next, hasNextPage, totalCount } = response.data;
      setListOfOrder(data);
      setActualPage({ next, hasNextPage, totalCount });
      setPage((prev) => ({
        ...prev,
        maxPageNumber: Math.ceil(totalCount / page.pageSize),
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    switch (cashDepositType){
      case "addToSaldo":
        setPopUp((prev) => ({...prev, showAddToSaldo: true}));
        break;
      case "orderOnCash":
        setPopUp((prev) => ({...prev, showOrderOnCash: true}));
        break;
      default:
        break;
    }

    setCashDepositType(null)

  }, [cashDepositType]);

  const columns = [
    columnHelper.accessor('orderDate', {
      cell: (info) => {
        const { orderDate } = info.row.original;

        return (
          <p className='text-grayLight-900 font-medium text-sm underline underline-offset-4'>
            {orderDate}
          </p>
        );
      },
      header: 'Data operacji'
    }),
    columnHelper.accessor('consumerDto', {
      cell: (info) => {
        const consumerDto = info.getValue();

        return (
          <div className='flex flex-col'>
            <p className="text-grayLight-900 font-medium text-sm underline underline-offset-4">
              {consumerDto.firstName} {consumerDto.lastName}
            </p>
            <p className="text-grayLight-600 font-normal text-sm">
              Klasa {consumerDto.organisationUnitName}
            </p>
          </div>
        );
      },
      header: "Dziecko"
    }),
    columnHelper.accessor("summaryItems", {
      cell: (info) => {
        const summaryItems = info.getValue();

        return (
          <div className="flex flex-col">
            {summaryItems.map(item => (
              <p key={item.amount + item.name} className="text-grayLight-600 font-normal text-sm">
                {item.amount}x {item.name}
              </p>
            ))}
          </div>
        );
      },
      header: "Podsumowanie zamówienia"
    }),
    columnHelper.accessor("orderValue", {
      cell: (info) => {
        const summaryItems = info.getValue();

        return (
          <p className="text-grayLight-900 font-medium text-sm">
            {summaryItems}
          </p>
        );
      },
      header: "Wartość zamówienia"
    }),
    columnHelper.accessor("paymentType", {
      cell: (info) => {
        const paymentType = info.getValue();

        return (
          <p className="text-grayLight-600 font-medium text-sm">
            {paymentType}
          </p>
        );
      },
      header: "Typ płatności"
    }),
    columnHelper.accessor("paymentValue", {
      cell: (info) => {
        const paymentValue = info.getValue();

        return (
          <p className="text-grayLight-600 font-medium text-sm">
            {paymentValue}
          </p>
        );
      },
      header: "Wartość wpłaty"
    }),
    columnHelper.accessor("paymentTitle", {
      cell: (info) => {
        const paymentTitle = info.getValue();

        return (
          <p className="text-grayLight-600 font-medium text-sm">
            {paymentTitle ? paymentTitle : '-'}
          </p>
        );
      },
      header: "Tytuł płatności"
    }),
    columnHelper.accessor("status", {
      cell: (info) => {
        const status = info.getValue();

        return (
          <p className="text-grayLight-600 font-medium text-sm">
            {status}
          </p>
        );
      },
      header: "Status"
    }),
    columnHelper.display({
      id: 'actions',
      header: '',
      cell: (info) => {
        const { orderDto } = info.row.original;

        return (
          <div className="flex gap-spacing-sm">
              <button
                onClick={() => {setShowDetails({
                  id: orderDto.id,
                  isOpen: true,
                })}}
                className="font-semibold text-sm text-grayWarm-950 bg-transparent border-none">
                Szczegóły
              </button>
          </div>
        );
      }
    })
  ];

  const handlePageChange = (pageNum: number) => {
    setPage((prev) => ({
      ...prev,
      pageNumber: pageNum,
    }));
  };

  useEffect(() => {
    fetchListOfOrder();
  }, [parentId]);

  return (
    <section className="ParentsTable p-spacing-xl w-full">
      <Flex direction="column" h="800px" className="ListOfOrder__table bg-white rounded-lg">
        <TableContainer h="100%" flex="1" minH="500px" overflowY="auto">
          <DataTable
          disableFilters={true}
          filterComponent={
            <div className='flex gap-spacing-md'>
              <CustomChakraButton
                hierarchy='linkGray'
                size='lg'
                iconPosition='onlyText'
                buttonProps={{
                  type: 'button',
                  onClick: () => {setPopUp((prev) => ({...prev , showOrderOnCredit: true}))}
                }}
              >Zamówienie na kredyt</CustomChakraButton>
              <Select
                className="w-52 h-11 border-amber-600"
                placeholder="Wpłata gotówkowa"
                value={cashDepositType ? { value: cashDepositType, label: cashDepositType === 'addToSaldo' ? 'Wpłata na saldo' : 'Zamówienie posiłków' } : null}
                onChange={(selectedOption) => {
                  setCashDepositType(selectedOption?.value as OrderType);
                }}
                components={{
                  IndicatorSeparator: () => null
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: '#44403C',
                  },
                })}
                styles={{
                  option: (baseStyles) => ({
                    ...baseStyles,
                    "&:hover": {
                      backgroundColor: "#fff7e6"
                    }
                  }),
                  control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: '#FFBB32',
                  }),
                }}
                options={[
                  {
                    value: 'addToSaldo',
                    label: 'Wpłata na saldo',
                  },
                  {
                    value: 'orderOnCash',
                    label: 'Zamówienie posiłków',
                  },
                ]}
              />

            </div>
          }
          extraThead={
            <Thead>
              <Tr>
              <Th colSpan={columns.length}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5">
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-semibold text-base text-grayLight-900"
                      >
                        Lista zamówień i wpłat
                      </Text>
                    </Box>
                  </div>
                  {/* <div className="flex gap-spacing-lg"> */}
                  {/*   <CustomChakraButton size="md" hierarchy="secondaryColor" iconPosition="left" icon="plus" buttonProps={{ */}
                  {/*     disabled: true */}
                  {/*   }}> */}
                  {/*     Dodaj zamówienie gotówkowe */}
                  {/*   </CustomChakraButton> */}
                  {/* </div> */}
                </div>
              </Th>
              </Tr>
            </Thead>
          }
          columns={columns}
          isLoading={isLoading}
          data={listOfOrder}
        />
      </TableContainer>
        <Box position="relative" paddingTop="10px" paddingBottom="20px">
          <Pagination
            pageNumber={page.pageNumber}
            maxPageNumber={page.maxPageNumber}
            hasNextPage={actualPage.hasNextPage}
            onPageChange={handlePageChange}
          />
        </Box>
      </Flex>
      { showDetails.isOpen && (
        <ShowListOfOrderDetails
          orderDetailsList={listOfOrder.find(item => item.orderDto.id === showDetails.id)}
          isOpen={showDetails.isOpen}
          onClose={
            () => {
              setShowDetails({isOpen: false, id: 0});
            }
          }
        />
      )}
      { popUp.showOrderOnCredit && (
        <SetOrderOnCredit
          isOpen={popUp.showOrderOnCredit}
          onClose={
            () => {
              setPopUp((prev) => ({...prev, showOrderOnCredit: false}));
              fetchListOfOrder();
            }
          }
        />
      )}
      { popUp.showOrderOnCash && (
        <SetOrderOnCash
          isOpen={popUp.showOrderOnCash}
          onClose={
            () => {
              setPopUp((prev) => ({...prev, showOrderOnCash: false}));
              fetchListOfOrder();
            }
        }
        />
      )}
      { popUp.showAddToSaldo && (
        <AddCashToSaldo
          isOpen={popUp.showAddToSaldo}
          onClose={
            () => {
              setPopUp((prev) => ({...prev, showAddToSaldo: false}));
              fetchListOfOrder();
            }
          } />
      )}
    </section>
  )
};

export default ListOfOrder;