import React, { ReactNode, useMemo, useState, createContext, useContext } from "react";

interface Props {
  children: ReactNode;
}

interface TenantIdContextProps {
  tenantId: string | undefined,
  setTenantId:  React.Dispatch<React.SetStateAction<string | undefined>>
}

const TenantIdContext = createContext<TenantIdContextProps | undefined>(undefined);

export const useTenantIdContext = () => {
  const context = useContext(TenantIdContext);
  if (!context) {
    throw new Error('useThemeContext must be used within an ThemeProvider');
  }
  return context;
};

export const TenantIdProvider = ({ children }: Props) => {
  const [tenantId, setTenantId] = useState<string>();

  const contextValue = useMemo(
    () => ({
      tenantId,
      setTenantId
    }),
    [
      tenantId,
      setTenantId
    ]
  );

  return <TenantIdContext.Provider value={contextValue}>{children}</TenantIdContext.Provider>;
};
