import { Input } from "@chakra-ui/react";
import React, { useState } from "react";
import { useParentOrderContextContext } from "../../../context/ParentOrderContext";
import formatDate from "../../../utils/formatDate";
import IconComponent from "../../IconComponent/IconComponent";

interface Props {
  setDate: React.Dispatch<React.SetStateAction<{year: number, month: number, day: number}>>
}

const WideDateInput = ({ setDate }: Props) => {
  const { parentOrderContext } = useParentOrderContextContext();

  const todayDate = new Date();

  const [chosenDate, setChosenDate] = useState<Date>(todayDate);

  const previousDay = new Date(chosenDate);
  previousDay.setDate(chosenDate.getDate() - 1);

  const nextDay = new Date(chosenDate);
  nextDay.setDate(chosenDate.getDate() + 1);

  const changeDate = (newDate: Date) => {
    setDate({
      year: newDate.getFullYear(),
      month: newDate.getMonth() + 1,
      day: newDate.getDate(),
    });
    setChosenDate(newDate);
  };

  const getDayName = (date: Date): string => {
    const dayName = date.toLocaleDateString("pl-PL", { weekday: "long" });
    return dayName.charAt(0).toUpperCase() + dayName.slice(1);
  };

  const isMinDate = (): boolean => formatDate(chosenDate) === formatDate(todayDate)
  const isMaxDate = (): boolean => formatDate(chosenDate) === parentOrderContext?.maxOrderDate

  return (
    <div className="flex justify-between">
      <button
        disabled={isMinDate()}
        onClick={() => {
          changeDate(previousDay);
        }}
        className='flex gap-spacing-md items-center'>
        <div className={`${isMinDate() && 'hidden'}`}>
          <IconComponent iconName='chevronLeft' />
        </div>
        <div className="flex flex-col" style={{ gap: '4px' }}>
          <p className='text-grayLight-600 text-sm font-semibold'>{getDayName(previousDay)}</p>
          <p className='text-grayLight-500 text-sm font-normal'>
            {formatDate(previousDay, 'dd-mmm-yyyy')}
          </p>
        </div>
      </button>

      <div className='flex flex-col justify-center items-center'>
        <div>
          <p className='text-grayLight-900 text-xl font-semibold'>{getDayName(chosenDate)}</p>
        </div>
        <div>
          <Input
            type="date"
            name="date"
            backgroundColor='#FFFFFF'
            value={formatDate(chosenDate)}
            min={formatDate(todayDate)}
            max={parentOrderContext?.maxOrderDate}
            onChange={(e) => setChosenDate(new Date(e.target.value))}
          />
        </div>
      </div>

      <button
        disabled={isMaxDate()}
        onClick={() => {
          changeDate(nextDay);
        }}
        className="flex gap-spacing-md items-center">
        <div className="flex flex-col" style={{ gap: "4px" }}>
          <p className='text-grayLight-600 text-sm font-semibold'>{getDayName(nextDay)}</p>
          <p className='text-grayLight-500 text-sm font-normal'>
            {formatDate(nextDay, 'dd-mmm-yyyy')}
          </p>
        </div>
        <div className={`${isMaxDate() && "hidden"}`}>
          <IconComponent iconName="chevronRight" />
        </div>
      </button>
    </div>
  );
};

export default WideDateInput;
