import { Select } from "chakra-react-select";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
// @ts-ignore
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { useParentBasketContext } from "../../../../context/ParentBasketProvider";
import { useParentDashboardContextContext } from "../../../../context/ParentDashboardContext";
import { useParentOrderContextContext } from "../../../../context/ParentOrderContext";
import { CalendarEventsInterface } from "../../../../shared/type/calendarEvents.type";
import { PurchasableItemInterface } from "../../../../shared/type/parentOrderContext.type";
import {
  handleSelectSlot,
  toggleSelectAllDaysInMonth,
  eventPropGetter,
  dayPropGetter, getAvailableMeals, addOrderToBasket
} from "../../../../utils/calendarOrderHelper";
import handleNavigate from "../../../../utils/calendarOrderHelper/handleNavigate";
import CustomHorizontalTabs from "../../../CustomHorizontalTabs/CustomHorizontalTabs";
import CustomDayCell from "./CustomDayCell/CustomDayCell";
import CustomToolbar from "./CustomToolbar/CustomToolbar";
import "moment/locale/pl";

import "./OrderOnPeriodOfTime.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const OrderOnPeriodOfTime = () => {
  const { setBasketStatus, parentDashboardContext, fetchParentDashboardContext } = useParentDashboardContextContext();
  const { parentOrderContext, setParentOrderContextDate, tenantId } = useParentOrderContextContext();
  const { addItemToBasket, parentBasketContext } = useParentBasketContext();

  const [ selectedStake, setSelectedStake ] = useState<number>()

  const [ selectedDates, setSelectedDates ] = useState<Date[]>([]);
  const [ selectAllDaysInMonth, setSelectAllDaysInMonth] = useState(false);
  const [ viewDate, setViewDate] = useState(new Date());
  const [ selectedConsumerId, setSelectedUserId ] = useState<number>()
  const [ commonMeal, setCommonMeals ] = useState<PurchasableItemInterface[]>([])

  const [fixedEvents, setFixedEvents] = useState<CalendarEventsInterface[]>([]);

  const clearSelectedDates = () => {
    setSelectedDates([])
  }

  function generateFixedEvents() {
    const consumerOrderContext = parentOrderContext?.consumerOrderContexts.find(
      consumer => consumer.consumerId === selectedConsumerId
    );

    if (!consumerOrderContext) {
      setFixedEvents([]);
      return;
    }

    const boughtItems = consumerOrderContext.orderDays.flatMap(day =>
      (day.purchasableItems || [])
        .filter(item => item.alreadyBoughtCount > 0)
        .map(item => ({
          title: (
            <>
              <p className="font-medium text-xs text-grayBlue-700">
                {item.name} ({item.alreadyBoughtCount} szt.)
              </p>
            </>
          ),
          start: moment(day.when, "YYYY-MM-DD").startOf("day").toDate(),
          end: moment(day.when, "YYYY-MM-DD").endOf("day").toDate(),
          purchasableItemId: item.stakeId,
          isFixed: true,
          consumerId: selectedConsumerId,
        }))
    );

    setFixedEvents(boughtItems);
  }

  const basketItems = useMemo(() => {
    if (!parentBasketContext?.ordersForChildren) {
      return [];
    }

    return parentBasketContext.ordersForChildren
      .filter(orderForChild => orderForChild.consumerId === selectedConsumerId)
      .flatMap((orderForChild) =>
        orderForChild.orderedItems.map((orderedItem) => ({
          title: (
            <>
              <p className="font-medium text-xs text-grayBlue-700">
                {orderedItem.purchasableItem.name} ({orderedItem.count} szt.)
              </p>
            </>
          ),
          start: moment(orderedItem.when, "YYYY-MM-DD").startOf("day").toDate(),
          end: moment(orderedItem.when, "YYYY-MM-DD").endOf("day").toDate(),
          isInBasket: true,
        }))
      );
  }, [parentBasketContext, selectedConsumerId]);

  moment.locale('pl');
  const localizer = momentLocalizer(moment);

  const views = useMemo(() => [Views.MONTH], []);
  const minDate = moment().startOf('day');
  const maxDate = moment(parentOrderContext?.maxOrderDate);

  useEffect(() => {
    setBasketStatus({
      forceShow: true,
      show: true,
    });
  }, [setBasketStatus]);

  const customHandleNavigate = (action: Date | "PREV" | "NEXT" | "TODAY") => {
    let newDate;

    if (action === "PREV") {
      newDate = moment(viewDate).subtract(1, "month");
    } else if (action === "NEXT") {
      newDate = moment(viewDate).add(1, "month");
    } else if (action === "TODAY") {
      newDate = moment();
    } else {
      newDate = moment(action);
    }

    if (newDate.isBefore(minDate, 'month') || newDate.isAfter(maxDate, 'month')) {
      return;
    }

    setSelectedDates([])

    setViewDate(newDate.toDate());

    setParentOrderContextDate({
      year: newDate.year(),
      month: newDate.month() + 1,
    });
  };

  const events = useMemo(() => {
    const combinedEvents = [
      ...fixedEvents.filter(event => event.consumerId === selectedConsumerId),
      ...basketItems,
      ...selectedDates.map(date => ({
        start: date,
        end: date,
        isInBasket: false,
        isFixed: false
      }))
    ];

    return combinedEvents;
  }, [fixedEvents, basketItems, selectedDates, selectedConsumerId]);

  useEffect(() => {
    if (!selectedConsumerId && parentDashboardContext) {
      const firstConsumer = parentDashboardContext.tenants
        .flatMap(tenant => tenant.consumers)
        .map(consumer => consumer.consumerDto.id)[0];
      setSelectedUserId(firstConsumer);
    }
    if (!viewDate) {
      setViewDate(new Date());
    }
  }, [parentDashboardContext, selectedConsumerId]);

  useEffect(() => {
    fetchParentDashboardContext();
  }, [viewDate]);

  useEffect(() => {
    setCommonMeals(getAvailableMeals(parentOrderContext, selectedConsumerId, selectedDates))
  }, [selectedDates]);

  useEffect(() => {
    generateFixedEvents()
  }, [selectedConsumerId, parentOrderContext, parentDashboardContext]);

  return (
    <section className="OrderOnPeriodOfTime w-full h-full flex bg-grayLight-50">
      <div
        className='basis-2/3 flex flex-col gap-spacing-5xl pt-spacing-md pr-spacing-3xl pb-spacing-3xl pl-spacing-3xl'>
        <div className='flex flex-col gap-spacing-4xl'>
          <div className='flex flex-col gap-spacing-lg'>
            <p className='font-semibold text-2xl text-grayLight-900'>
              Wybierz posiłek i daty
            </p>
            <p className='font-normal text-grayLight-600' style={{ fontSize: '16px' }}>
              Wybierz rodzaj posiłku oraz dni w jakie dziecko ma otrzymać posiłek, następnie dodaj je do koszyka.
            </p>
          </div>
          <div>
            <CustomHorizontalTabs
              onChange={(value) => {
                setSelectedUserId(value)
              }}
              data={
                parentDashboardContext
                  ? Array.from(
                    new Map(
                      parentDashboardContext.tenants
                        .flatMap(tenant => tenant.consumers)
                        .map(consumer => [consumer.consumerDto.id, {
                          label: `${consumer.consumerDto.firstName} ${consumer.consumerDto.lastName}`,
                          value: consumer.consumerDto.id
                        }])
                    ).values()
                  )
                  : null
              }
            />
          </div>
        </div>
        <div className="OrderOnPeriodOfTime__selectServing z-40">
          <Select
            isDisabled={selectedDates.length === 0}
            colorScheme="gray"
            noOptionsMessage={() => 'Brak wspólnych posiłków na wybrane dni'}
            placeholder="Wybierz posiłek"
            onChange={(value) => {
              if(value) {
                setSelectedStake(value.value)
              }
            }}
            options={commonMeal.map(meal => ({
              label: meal.name,
              value: meal.stakeId,
            }))}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: "white",
              }),
            }}
          />
        </div>
        <div className="OrderOnPeriodOfTime__calendarContainer overflow-y-scroll z-10">
          <Calendar
            selectable={true}
            date={viewDate}
            views={views}
            defaultView={Views.MONTH}
            localizer={localizer}
            onSelectSlot={(slotInfo: any) => {
              handleSelectSlot(slotInfo, minDate, maxDate, parentOrderContext, selectedConsumerId, setSelectedDates);
            }}
            onNavigate={handleNavigate}
            min={minDate.toDate()}
            max={maxDate.toDate()}
            style={{ height: 600 }}
            components={{
              month: {
                dateHeader: (props: any) => (
                  <CustomDayCell
                    date={props.date}
                    events={events.filter(event => moment(event.start).isSame(props.date, 'day'))}
                  />
                )
              },
              toolbar: () => (
                <CustomToolbar
                  addToBasked={() => {
                    addOrderToBasket(commonMeal, selectedStake, parentOrderContext, selectedConsumerId, selectedDates, addItemToBasket, tenantId?.toString())
                  }}                  label={moment(viewDate).format('MMMM YYYY')}
                  onNavigate={customHandleNavigate}
                  clearSelectedDates={clearSelectedDates}
                  onToggleSelectAll={(isSelected: boolean) => {
                    toggleSelectAllDaysInMonth(isSelected, selectedConsumerId, viewDate, setSelectAllDaysInMonth, parentOrderContext, setSelectedDates);
                  }}
                  isSelectAllChecked={selectAllDaysInMonth}
                />
              ),
            }}
            eventPropGetter={(event: any) => (
              eventPropGetter(event, fixedEvents)
            )}
            dayPropGetter={(date: Date) => (
              dayPropGetter(date, selectedDates, parentOrderContext, selectedConsumerId)
            )}
          />

        </div>
      </div>
      <div className='basis-1/3'></div>
    </section>
  );
};

export default OrderOnPeriodOfTime;
