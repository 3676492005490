import React from 'react';

const RollBackIcon = ({ className, color }: { className: string; color: string }) => (
  <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M1.75 4.75H11.875C13.739 4.75 15.25 6.26104 15.25 8.125C15.25 9.98896 13.739 11.5 11.875 11.5H8.5M1.75 4.75L4.75 1.75M1.75 4.75L4.75 7.75"
      stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
  </svg>


);

export default RollBackIcon;
