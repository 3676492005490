import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navigation from '../../../Navigation/Navigation';
import MealCategories from './MealCategories/MealCategories';
import Rates from './Rates/Rates';
import Servings from './Servings/Servings';

const salesSystemNavigationOptions = [
  {
    name: 'Wydawki',
    link: 'servings',
    element: <Servings />
  },
  {
    name: 'Stawki',
    link: 'rates',
    element: <Rates />
  },
  {
    name: 'Kategorie posiłków',
    link: 'meal-categories',
    element: <MealCategories />
  }
];

const SalesSystem = () => {
  return (
    <section className="SalesSystem p-spacing-4xl w-full">
      <Navigation
        navOptions={salesSystemNavigationOptions.map((item) => ({
          name: item.name,
          link: item.link
        }))}
      />
      <Routes>
        {salesSystemNavigationOptions.map((link) => (
          <Route key={link.link} path={`/${link.link}`} element={link.element} />
        ))}
      </Routes>
    </section>
  );
};

export default SalesSystem;
