import CustomChakraButton from "../../CustomChakraButton/CustomChakraButton";
import IconComponent from "../../IconComponent/IconComponent";

import './ConfirmRegistrationEmail.css';

const ConfirmRegistrationEmail = ({ userEmail, supportEmail, onBack }: {
  onBack: () => void;
  userEmail: string,
  supportEmail: string,
}) => {
  return (
    <section
      className="ConfirmRegistrationEmail flex flex-col gap-10 justify-between lg:justify-start items-center w-screen h-screen bg-white px-4 py-6 sm:px-8 sm:py-12">
      <img
          alt='background'
          src={`${process.env.PUBLIC_URL}/img/dot-grid.png`}
          className="background-image fixed z-10 top-0"
          style={{ width: "70vw", height: "70vh" }}/>
      <div className="flex flex-col z-50 items-center gap-8 w-full lg:w-96">
        <div>
          <IconComponent iconName="mail" />
        </div>
        <div className="flex flex-col items-center gap-4 text-center">
          <h2 className="text-grayLight-900 font-semibold text-2xl sm:text-3xl">
            Sprawdź skrzynkę mailową
          </h2>
          <div className="flex flex-col text-grayLight-600 gap-2 text-base sm:text-lg">
            <p>
              Na adres <b>{userEmail}</b> wysłaliśmy wiadomość z linkiem aktywacyjnym.
            </p>
            <p>Upewnij się, że wiadomość nie trafiła do folderu SPAM.</p>
            {supportEmail && (
              <p className="text-sm">
                Jeśli email nie dotarł do Twojej skrzynki mailowej, prosimy o kontakt z {supportEmail}.
              </p>
            )}
          </div>
        </div>
        <div>
          <CustomChakraButton
            hierarchy="linkGray"
            iconPosition="left"
            size="sm"
            icon="arrowLeft"
            buttonProps={{
              onClick: onBack,
            }}
          >
            Wróć do rejestracji
          </CustomChakraButton>
        </div>
      </div>
      <div className="flex justify-between items-center w-full lg:w-96 text-grayLight-600 text-sm">
        <p>© Jemwszkole.pl 2024</p>
        <a href="mailto:help@jemwszkole.pl" className="flex items-center gap-2">
          <IconComponent iconName="send" />
          <span>help@jemwszkole.pl</span>
        </a>
      </div>
    </section>

  )
};

export default ConfirmRegistrationEmail;