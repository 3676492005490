import { Menu, MenuButton, Box, MenuList, MenuItem } from "@chakra-ui/react";
import React, { ReactNode, useState, useEffect } from "react";
import { useParentBasketContext } from "../../../context/ParentBasketProvider";
import { useParentDashboardContextContext } from "../../../context/ParentDashboardContext";
import useAuth from "../../../hooks/useAuth";
import CustomChakraButton from "../../CustomChakraButton/CustomChakraButton";
import IconComponent from "../../IconComponent/IconComponent";

import './ParentHeader.css';
import Basket from "../../Basket/Basket";
import PayOffTheBalance from "../../ChildOwnerComponents/PayOffTheBalance/PayOffTheBalance";

interface Props {
  dropdownMenuList: ReactNode;
  notificationList: ReactNode | null;
}

const ParentHeader = ({ dropdownMenuList, notificationList }: Props) => {
  const { basketStatus, setBasketStatus } = useParentDashboardContextContext();
  const { parentBasketContext } = useParentBasketContext();
  const { auth } = useAuth();
  const [ showPayOfTheBalancePopUp, setShowPayOfTheBalancePopUp ] = useState<boolean>(false);
  const { parentDashboardContext } = useParentDashboardContextContext();

  return (
    <header className="w-full Header flex flex-row pl-8 pr-8 pt-2.5 pb-2.5">
      <div className='Header__selectContainer'>
        { parentDashboardContext?.showPaySaldoButton && (
          <CustomChakraButton
            hierarchy='secondaryColor'
            size='lg'
            iconPosition="left"
            icon='wallet'
            buttonProps={{
              onClick: () => {
                setShowPayOfTheBalancePopUp(true);
              }
            }}
          >Spłać saldo</CustomChakraButton>
        )}
      </div>
      <div className="Header__actionContainer flex flex-1 justify-end gap-spacing-md">
          <div className="ParentHeader__actions flex gap-spacing-md items-center justify-center">
            <Menu>
              <MenuButton as={Box} aria-label="Options" className="cursor-pointer flex items-center">
                <div className="Header_ringIconContainer flex justify-center items-center cursor-pointer">
                  <div className="absolute">
                    <IconComponent iconName="bell" color="#344054" />
                  </div>
                  <div
                    className="basketPriceCircle flex items-center justify-center relative bg-brand-600 border border-white box-border w-4 h-4 rounded-full">
                    {/* <p className='text-xs'>1</p> */}
                  </div>
                </div>
              </MenuButton>
              <MenuList>{notificationList || (
                <MenuItem disabled>
                  Brak powiadomień
                </MenuItem>
              )}</MenuList>
            </Menu>
            <Menu>
              <MenuButton as={Box} aria-label="Options" className="cursor-pointer flex items-center">
                <div
                  className="ParentHeaderUsername flex items-center justify-center w-9 h-9 bg-grayLight-100 border-grayLight-300 rounded-full">
                  <p className="ParentHeaderUsername__text m-o p-0">
                    {auth.loggedUserDto.firstName && auth.loggedUserDto.firstName.length > 0 && auth.loggedUserDto.firstName[0].toUpperCase()}
                    {auth.loggedUserDto.lastName && auth.loggedUserDto.lastName.length > 0 && auth.loggedUserDto.lastName[0].toUpperCase()}
                  </p>
                </div>
              </MenuButton>
              <MenuList>{dropdownMenuList}</MenuList>
            </Menu>
          </div>
        <button
          className={`ParentHeader__basketContainer flex items-center justify-center p-spacing-md gap-spacing-sm rounded-md ${basketStatus.show && "bg-brand-100 ParentHeader__basketContainer--shadow"}`}
          disabled={basketStatus.forceShow}
          onClick={() => setBasketStatus((prev) => ({...prev, show: !prev.show}))}>
          <p className='text-sm font-semibold text-grayLight-900'>Koszyk</p>
          <div className='flex items-center justify-center'>
            <div className="absolute">
              <IconComponent iconName='basket' color='#344054'/>
            </div>
            <div className='basketPriceCircle flex items-center justify-center relative bg-brand-600 border border-white box-border w-4 h-4 rounded-full'>
              <p className='text-xs font-semibold text-grayLight-900'>
                {parentBasketContext.ordersForChildren.flatMap(consumer => consumer.orderedItems).length}
              </p>
            </div>
          </div>
        </button>
      </div>
      <div className={`BasketContainer border-l bg-white ${basketStatus.show ? 'translate-x-0' : 'translate-x-full'}`}>
        <Basket />
      </div>
      {showPayOfTheBalancePopUp && <PayOffTheBalance isOpen={showPayOfTheBalancePopUp} onClose={() => {setShowPayOfTheBalancePopUp(false)}}/>}
    </header>
  );

};

export default ParentHeader;