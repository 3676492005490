import React from "react";
import { Routes, Route } from "react-router-dom";
import { EditTeacherOrEmployeeProvider } from "../../../../context/EditTeacherOrEmployeeProvider";
import SingleTeacher from "./SingleTeacher/SingleTeacher";
import TeachersTable from "./TeachersTable/TeachersTable";

const Teachers = () => {
  const teachersLinks = [
    {
      link: "teachers-table",
      element: <TeachersTable/>,
    },
    {
      link: "single-teacher/:teacherId",
      element: <SingleTeacher/>,
    }
  ];

  return (
    <EditTeacherOrEmployeeProvider>
      <Routes>
        {teachersLinks.map((link) => (
          <Route key={link.link} path={`/${link.link}/*`} element={link.element} />
        ))}
      </Routes>
    </EditTeacherOrEmployeeProvider>
  );
};

export default Teachers;