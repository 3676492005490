import React from "react";
import moment from "moment";
import { CalendarEventsInterface } from "../../shared/type/calendarEvents.type";
import { ParentOrderContextInterface } from "../../shared/type/parentOrderContext.type";

function generateFixedEvents(
  parentOrderContext: ParentOrderContextInterface | undefined,
  consumerId: number,
  setFixedEvents: React.Dispatch<React.SetStateAction<CalendarEventsInterface[]>>
) {
  const consumerOrderContext = parentOrderContext?.consumerOrderContexts.find(
    (consumer) => consumer.consumerId === consumerId
  );

  if (!consumerOrderContext) {
    setFixedEvents([]);
    return;
  }

  const boughtItems = consumerOrderContext.orderDays.flatMap((day) =>
    (day.purchasableItems || [])
      .filter((item) => item.alreadyBoughtCount > 0)
      .map((item) => ({
        title: `${item.name} (${item.alreadyBoughtCount} szt.)`,
        start: moment(day.when, "YYYY-MM-DD").startOf("day").toDate(),
        end: moment(day.when, "YYYY-MM-DD").endOf("day").toDate(),
        purchasableItemId: item.stakeId,
        isFixed: true,
        consumerId: consumerId,
      }))
  );

  setFixedEvents(boughtItems);
}

export default generateFixedEvents;
