import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

type RoleType = 'admin' | 'childOwner' | 'tenantAdmin' | 'superAdmin';

interface Roles {
  allowedRoles: RoleType[];
}

const RequireAuth = ({ allowedRoles }: Roles) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth) {
    return <Navigate to="/login" replace />;
  }

  const isAuthorized = () => {
    const { admin, childOwner, tenantAdmin, superAdmin } = auth.auth.loggedUserDto;

    if (allowedRoles.includes('admin') && admin) {
      return true;
    }
    if (allowedRoles.includes('childOwner') && childOwner) {
      return true;
    }
    if (allowedRoles.includes('tenantAdmin') && tenantAdmin) {
      return true;
    }
    return allowedRoles.includes('superAdmin') && superAdmin;
  };

  if (isAuthorized()) {
    return <Outlet />;
  }

  if (auth.auth.loggedUserDto.id) {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  return <Navigate to="/" state={{ from: location }} replace />;
};

export default RequireAuth;
